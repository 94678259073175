/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NoDataAvailable from '../../components/NoDataAvailable';
import CustomModal from '../../components/CustomModal';
import LoadingSpinner from '../../utils/LoadingSpinner';
import Pagination from '../../components/Pagination';
import customerAvatar from '../../assets/images/customerAvatar.png';
import iconEdit from '../../assets/svgs/PhonebookEdit';
import iconChat from '../../assets/svgs/PhonebookChat';
import iconChatlog from '../../assets/svgs/PhonebookChatlog';
import crownIcon from '../../assets/svgs/icon-crown.svg';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const FavoriteCustomer = (props) => {
  const {
    favouriteCustomerDataFun,
    perfCharObjid,
    favCustomertData,
    editCustomerNote,
    isFavCustLoading,
    history,
    count,
  } = props;

  const [chatData, setChatData] = useState(null);
  const [isResumeModalshow, setIsResumeModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [custEditNote, setCustEditNote] = useState('');
  const [editNotesModal, setEditNotesModal] = useState(false);
  const [, setLog] = useState({ chat: true, calls: false });
  const [notesInputFeild, setNotesInputFeild] = useState([]);

  const createNewChatRoom = (updatedConv = []) => {
    const newSession = {
      id: 0,
      custId: chatData.customerObjid,
      title: chatData.customerName,
      messages: [],
      premium: 0,
      unread: 0,
      iscurrentlyActiveCust: true,
      custPic: chatData.customerProfilePic,
      sessionStatus: 'pending',
      customerchanneltype: '',
      performerContinueMsgCount: 0,
      lastMessageTime: new Date(),
      isPremiumCustomer: chatData.isPremiumCustomer,
      customerHostReview: chatData.customerHostReview,
      isCustomerOnline: chatData.isCustomerOnline,
      sendEmailNotification: chatData.sendEmailNotification,
    };
    const updatedConversation = updatedConv.map(conv => ({
      ...conv,
      iscurrentlyActiveCust: false,
    }));
    updatedConversation.unshift(newSession);
    setConversation(updatedConversation);
    history.push('/chat');
  };
  const checkActiveSession = (customer) => {
    setTimeout(() => {
      history.replace({ pathname: '/chat', state: { fromLogsPage: true, id: customer.customerObjid } });
    }, 400);
  };

  const renderResumeChatModal = () => {
    if (chatData && chatData.isCustomerBlockedByChar) {
      return (
        <CustomModal
          header="Blocked User"
          body="You can not chat, you blocked this customer."
          buttonText="Ok"
          footer="Dismiss"
          footerLink={() => setIsResumeModalShow(false)}
          open={isResumeModalshow}
          onClick={() => setIsResumeModalShow(false)}
          toggle={() => setIsResumeModalShow(false)}
        />
      );
    }

    if (chatData && chatData.isChatInitiateBlock) {
      return (
        <CustomModal
          header="Not Enabled"
          body="This customer has not enabled you for initiating a chat."
          buttonText="Ok"
          footer="Dismiss"
          footerLink={() => setIsResumeModalShow(false)}
          open={isResumeModalshow}
          onClick={() => setIsResumeModalShow(false)}
          toggle={() => setIsResumeModalShow(false)}
        />
      );
    }

    if (chatData) {
      setTimeout(() => {
        history.replace({ pathname: '/chat', state: { fromLogsPage: true, id: chatData.customerObjid } });
      }, 400);
      // checkActiveSession();
      return '';
    }
  };

  const handleChatOnClick = (customer) => {
    setChatData(customer);
    customer.isChatInitiateBlock ? setIsResumeModalShow(true) : checkActiveSession(customer);
  };

  useEffect(() => {
    const customInputFeild = [];
    const updatedInputFeild = (
      <label htmlFor="InputNotesFeild" key="InputNotesFeild" className="noteTextArea">
        <textarea
          type="text"
          className="InputNotes form-control"
          id="InputNotesFeild"
          value={custEditNote.custmerNote}
          onChange={event => setCustEditNote({
            ...custEditNote,
            custmerNote: event.currentTarget.value,
          })}
        />
      </label>
    );
    customInputFeild.push(updatedInputFeild);
    setNotesInputFeild(customInputFeild);
  }, [custEditNote]);

  const postCustomerNote = () => {
    // call api
    const formData = new FormData();
    formData.append('character_objid', perfCharObjid);
    formData.append('cst_contact_objid', custEditNote.customerObjid);
    formData.append('note', custEditNote.custmerNote);
    // formData.append('note_type', "favcust_character");
    editCustomerNote(formData);
  };

  const showOnlineStatus = (isCustomerOnline, sendEmailNotification) => {
    if (isCustomerOnline) {
      return <span className="online-small-icon" />;
    // eslint-disable-next-line no-else-return
    } else if (sendEmailNotification) {
      return <span className="online-away-icon" />;
    }
    return <span className="offline-small-icon" />;
  };
  
  const handleClickOnEditNote = (cust = {}, isCallsLog = false) => {
    if (isCallsLog) {
      setCustEditNote(cust);
      setEditNotesModal(true);
      setLog({
        chat: false,
        calls: true,
      });
    } else {
      setCustEditNote(cust);
      setEditNotesModal(true);
      setLog({
        chat: true,
        calls: false,
      });
    }
  };

  const handleCustomerList = (PageNumber) => {
    setCurrentPage(PageNumber);
  };

  useEffect(() => {
    const FavCustApiUrl = `api/xrefcustomercharacter/list/?type=favorite_customer&perf_character_objid=${perfCharObjid}&page=${currentPage}&page_size=15`;
    favouriteCustomerDataFun(FavCustApiUrl).then((response) => {
    }).catch((error) => {
      console.log('error', error);
    });
  }, [perfCharObjid, currentPage]);

  const favouriteCustomerRecords = favCustomertData.map(customer => (
    <li key={customer.customerObjid} className="customer-section">
      <div className="customerImg">
        <div className="customerImgInner">
          <img
            src={customer.customerProfilePic ? customer.customerProfilePic : customerAvatar}
            height="50px"
            width="50px"
            alt="customer profile"
            role="presentation"
          />
          {customer.isPremiumCustomer ? <img src={crownIcon} className="is_premium_customer" alt="crown" /> : ''}
          {showOnlineStatus(customer.isCustomerOnline, customer.sendEmailNotification)}
        </div>
        <div className="customerInfo">
          <Link
            to={`/customerProfile/${customer.customerObjid}`}
          >
            {customer.customerName}
          </Link>
          <p>{customer.custmerNote}</p>
        </div>
      </div>
      <div className="phonebook-option">
        <Button
          color="primary"
          outline
          onClick={() => handleChatOnClick(customer)}
        >
          <span className="buttonIcon" />
          {iconChat()}
            Chat
        </Button>
        <Link
          to={{
            pathname: `/customerChatlog/${customer.customerObjid}`,
            state: {
              customer,
            },
          }}
          className="feed-user-icon like"
        >
          <Button
            color="primary"
            outline
          >
            <span className="buttonIcon" />
            {iconChatlog()}
            Chat Logs
          </Button>
        </Link>
        <Button
          color="primary"
          outline
          onClick={() => handleClickOnEditNote(customer, false)}
        >
          {iconEdit()}
          <span className="buttonIcon" />
          Edit Notes
        </Button>
      </div>
    </li>
  ));

  return (
    <>
      {renderResumeChatModal()}
      <section className="phonebook-wrap">
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <h3 className="topHead">Favorite Customer</h3>
        </div>
        <CardBody>
        {isFavCustLoading ? <LoadingSpinner /> : (
            <Card className="phoneBookCard">
              {favCustomertData.length ? (
                <>
                  <ul>
                    {favouriteCustomerRecords}
                  </ul>
                  {count > 0 && (
                    <div className="pull-right">
                      <Pagination
                        totalCount={count}
                        pageSize={15}
                        onPageClick={handleCustomerList}
                        currentPage={currentPage}
                      />
                    </div>
                  )}
                </>
              ) : <NoDataAvailable />
              }
            </Card>
          )} 
        </CardBody>
        <CustomModal
          header="Note"
          body=""
          isInputFeild
          htmlElements={notesInputFeild}
          buttonText="OK"
          open={editNotesModal}
          onClick={postCustomerNote}
          toggle={() => setEditNotesModal(false)}
        />
      </section>
    </>
  );
};

FavoriteCustomer.defaultProps = {
  perfCharObjid: 0,
  favCustomertData: [],
};

FavoriteCustomer.propTypes = {
  favouriteCustomerDataFun: PropTypes.func.isRequired,
  perfCharObjid: PropTypes.number,
  favCustomertData: PropTypes.instanceOf(Array),
  history: PropTypes.instanceOf(Object).isRequired,
};

export default FavoriteCustomer;
