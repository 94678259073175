import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
} from 'reactstrap';

import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import './style.scss';
import CustomModal from '../../../../components/CustomModal';
import { renderSwitch } from '../../../../utils/formUtils';

const StatusCard = (props) => {
  const {
    data, putStatusUpdate,
    getPresenceUser,
    rouletteHeartbeat,
    // handleRoulette,
    // updateStatus,
    history,
  } = props;

  const {
    charId,
    chat,
    phone,
    video,
  } = data;

  const [isChatCallWarningModalOpen, setIsChatCallWarningModalOpen] = useState(false);

  useEffect(() => {
    if (charId && charId !== 0) {
      const presenceUrl = `/perfcharactergroup/list/?objid2perf_character=${charId}&group=PRESENCE_SMS|PRESENCE_VOICE|PRESENCE_VIDEO`;
      getPresenceUser(presenceUrl);
    }
  }, []);

  const handleClickOnContinue = () => {
    const heartbeatData = {
      character_id: charId,
    };
    rouletteHeartbeat(heartbeatData).then(() => {
      history.push('/roulette');
    });
  };

  const renderRouletteModal = () => (
    <CustomModal
      header="Roulette"
      body="Your video/mic will not be available for outside calls while you are live on Roulette.
      Also, your voice calls will get turned off in the app."
      buttonText="Continue"
      footer="Dismiss"
      footerLink={() => setIsChatCallWarningModalOpen(false)}
      open={isChatCallWarningModalOpen}
      onClick={handleClickOnContinue}
      toggle={() => setIsChatCallWarningModalOpen(false)}
    />
  );

  const onSwitchChange = (event) => {
    if (event.target.id === 'video') {
      console.log('video call on');
    }
    const { target: { checked, id } } = event;
    putStatusUpdate(charId, checked, id);
  };

  return (
    <>
      {renderRouletteModal()}
      <Card className="customCard bg-dark">
        <CardHeader className="customCardHeader"><h2>Status</h2></CardHeader>
        <CardBody>
          <Row className="customCardHeaderList">
            <Col xs={6} className=""> Chat </Col>
            <Col xs={6} className="text-right ">
              <Field
                id="sms"
                name="chatStatus"
                checked={chat}
                component={renderSwitch}
                onChange={onSwitchChange}
              />
            </Col>
          </Row>

          <Row className="customCardHeaderList">
            <Col xs={6} className=""> Phone Calls </Col>
            <Col xs={6} className="text-right">
              <Field
                id="voice"
                name="phoneStatus"
                checked={phone}
                component={renderSwitch}
                onChange={onSwitchChange}
              />
            </Col>
          </Row>

          <Row className="customCardHeaderList">
            <Col xs={6} className=""> Video Calls </Col>
            <Col xs={6} className="text-right ">
              <Field
                id="video"
                name="videoStatus"
                checked={video}
                component={renderSwitch}
                onChange={onSwitchChange}
              />
            </Col>
          </Row>

          <Row className="customCardHeaderList">
            <Col xs={6} className=""> Roulette </Col>
            <Col xs={6} className="text-right ">
              <Button color="primary text-white" onClick={() => setIsChatCallWarningModalOpen(true)}>
                Launch
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

StatusCard.defaultProps = {
  data: {
    charId: null,
    chat: false,
    phone: false,
    video: false,
  },
  history: {},
};

StatusCard.propTypes = {
  data: PropTypes.shape({
    charId: PropTypes.number,
    chat: PropTypes.bool,
    phone: PropTypes.bool,
    video: PropTypes.bool,
  }),
  putStatusUpdate: PropTypes.func.isRequired,
  getPresenceUser: PropTypes.func.isRequired,
  rouletteHeartbeat: PropTypes.func.isRequired,
  // updateStatus: PropTypes.func.isRequired,
  // handleRoulette: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object),
};

export default StatusCard;
