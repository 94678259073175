import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardImg,
} from 'reactstrap';
import './style.scss';
import FeedFavIcon from '../../../assets/svgs/feed-fav-icon';
import DisLikeHeartIcon from '../../../assets/svgs/disLikeHeartIcon';

function ProfileCard(props) {
  const {
    image,
    isLiked,
    pictureControl,
    imgObjId,
    changeImageLikeStauts,
    isLikeAll,
  } = props;

  const [isPhotoLiked, setIsPhotoLiked] = useState(isLiked);
  const [likeButtonDisable, setLikeButtonDisable] = useState(false);

  const chagePhotoStatus = (photoLikeStatus) => {
    setLikeButtonDisable(true);
    setIsPhotoLiked(!photoLikeStatus);
    changeImageLikeStauts(!photoLikeStatus, imgObjId)
      .then(() => {
        setLikeButtonDisable(false);
      })
      .catch(() => {
        setIsPhotoLiked(photoLikeStatus);
        setLikeButtonDisable(false);
      });
  };

  useEffect(() => {
    if (isLikeAll) {
      setIsPhotoLiked(true);
    }
  }, [isLikeAll]);

  return (
    <li className="feed-card-li">
      <Card className="feed-card-wrap">
        <button
          type="button"
          onClick={pictureControl}
          className="p-0 border-0 imgBtn"
        >
          <CardImg
            className="feedCardImage card-profile"
            variant="top"
            alt="feedImage"
            src={image}
          />
        </button>
        <button
          onClick={() => chagePhotoStatus(isPhotoLiked)}
          disabled={likeButtonDisable}
          type="button"
          className="addWish like inner-like-btn"
        >
          {isPhotoLiked ? <FeedFavIcon /> : <DisLikeHeartIcon />}
        </button>
      </Card>
    </li>
  );
}

ProfileCard.defaultProps = {
  imgObjId: 0,
};

ProfileCard.propTypes = {
  image: PropTypes.string.isRequired,
  isLiked: PropTypes.bool.isRequired,
  pictureControl: PropTypes.func.isRequired,
  changeImageLikeStauts: PropTypes.func.isRequired,
  imgObjId: PropTypes.number,
  isLikeAll: PropTypes.bool.isRequired,
};

export default ProfileCard;
