/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import EmojiPicker, { Theme } from 'emoji-picker-react';
import api from '../../../../../actions/api';

// // Fetch emojis from the API when the component mounts
// useEffect(() => {
//   if (emojis && !emojis.length) {
//     api.post('api/chatmsgemoji/create/', {
//       chatmsg,
//       emoji,
//       session_objid,
//     })
//       .then(response => response.data)
//       .then((data) => {
//         console.log(data.results, 'data');
//         setEmojis(data.results); // API returns an array of emoji objects
//       })
//       .catch((error) => {
//         console.error('Error fetching emojis:', error);
//       });
//   }
// }, [emojis]);

// eslint-disable-next-line react/prop-types
const EmojiSelector = async (emoji) => {
  try {
    const formData = new FormData();
    formData.append('chatmsg', emoji.msgId);
    formData.append('emoji_unicode', emoji.unified);
    formData.append('session_objid', emoji.activeUserId && emoji.activeUserId);
    const { data } = await api.post('/chatmsgemoji/create/', formData, {
      // headers: {
      //   'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
      // },
    });
    // const selectedEmoji = emojis.find(em => em.objid === data.emoji);
    emoji.onSelectedEmoji(emoji);
  } catch (error) {
    emoji.onSelectedEmoji(emoji);
    console.error('Error fetching emojis:', error);
  }
};
// const settings = {
//   dots: false,
//   infinite: true,
//   speed: 100,
//   slidesToShow: 5,
//   slidesToScroll: 3,
//   arrow: true,
// };
const EmojiList = ({
  // eslint-disable-next-line react/prop-types
  emojis, onSelectedEmoji, activeUserId, msgId, setShowEmojiList,
}) => {
  const [isOpened, setIsOpened] = useState(true);
  const pickerRef = useRef(null);

  const emojiHandler = (emoji) => {
    EmojiSelector(emoji);
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowEmojiList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => { document.removeEventListener('mousedown', handleClickOutside); };
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 'auto', // Adjust this value to move the picker higher
        left: '0',
        top: '-5px',
        zIndex: '1000', // Ensure it's above other elements
        width: '320px',
      }}
      className="EmojiSlider"
      ref={pickerRef}
    >
      <EmojiPicker
        width={320}
        style={{
          position: 'absolute',
          bottom: '0', // Position above the trigger button
          zIndex: '1000',
          width: '320px',
        }}
        reactionsDefaultOpen
        onEmojiClick={emoji => emojiHandler({
          ...emoji, msgId, activeUserId, onSelectedEmoji,
        })}
        theme="dark"
        autoFocusSearch={false}
      />
      {/* <Picker reactionsDefaultOpen onReactionClick={emojiHandler} /> */}

      {/* <Slider {...settings}>
      {emojis &&
      emojis.map(emoji =>
        <div role="presentation"
        onClick={() =>
          EmojiSelector(emoji)}
          key={emoji.id}>
          <img
          className="emojiListImgs"
          src={emoji.image_path} alt="emoji" title={emoji.title} /></div>)}
    </Slider> */}
    </div>
  );
};
export default EmojiList;
