export default function (state = [], action = {}) {
  switch (action.type) {
    case 'MOBILE_CHAT_LOGIN_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'MOBILE_CHAT_LOGIN_FULFILLED':
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        mobileData: {
          mobileUser: action.payload.data.xmpp_user,
          mobilePass: action.payload.data.xmmp_pass,
          charObjID: action.payload.data.character_objid,
        },
        error: false,
      };

    case 'MOBILE_CHAT_LOGIN_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return {
        ...state,
      };
  }
}
