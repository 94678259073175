/* eslint-disable */
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import {
  Card,
  Button,
  Input,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Badge,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import Pagination from '../../components/Pagination';
import NoDataAvailable from '../../components/NoDataAvailable';
import LoadingSpinner from '../../utils/LoadingSpinner';
import CustomModal from '../../components/CustomModal';
import customerAvatar from '../../assets/images/customerAvatar.png';
import iconEdit from '../../assets/svgs/PhonebookEdit';
import iconChatlog from '../../assets/svgs/PhonebookChatlog';
import iconCallsLog from '../../assets/svgs/icon-video-voice';
import iconChat from '../../assets/svgs/PhonebookChat';
import './style.scss';
import crownIcon from '../../assets/svgs/icon-crown.svg';
import { FaChevronDown } from "react-icons/fa";
import polling from '../../utils/polling';
import { useSelector } from 'react-redux';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
const Phonebook = (props) => {
  const {
    getPhonebookData,
    editCustomerNote,
    editCustomerCallsNote,
    phoneBookData,
    // eslint-disable-next-line react/prop-types
    getCallsData,
    callsLogData,
    callLogsLoader,
    getPhonebookDataapiLoader,
    perfObjid,
    checkActiveSessionAvailable,
    setConversation,
    conversation,
    history,
    minMessageToInitaiteChat,
    count,
    isTier2,
    getCustomerOnlineStatus,
    totalCallsCount,
  } = props;

  const [customerDetailComp, setCustomerDetailComp] = useState([]);
  const [customerVoiceDetailComp, setCustomerVoiceDetailComp] = useState([]);
  const [editNotesModal, setEditNotesModal] = useState(false);
  const [notesInputFeild, setNotesInputFeild] = useState([]);
  const [custEditNote, setCustEditNote] = useState('');
  const [isOpenPhoto, setOpenPhoto] = useState(false);
  const [mainSrcPicCon, setMainSrcPicCon] = useState('');

  const [activeTab, setActiveTab] = useState('chat-log-tab');

  const reduxMissedCalls = useSelector(state => state.missedCallers);

  const [isResumeModalshow, setIsResumeModalShow] = useState(false);
  const [sortedByState, setSortedByState] = useState({
    online_first: false,
    sortedBy: 'Recent Interactions',
    activeTab: 'chat-log-tab',
  });
  const [chatData, setChatData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [callsCurrentPage, setCallsCurrentPage] = useState(1);
  const [runOnlyOnce, setRunOnlyOnce] = useState(false);
  const [totalMissedCalls, setTotalMissedCalls] = useState();
  const [, setLog] = useState({ chat: true, calls: false });
  const prevContact = usePrevious(phoneBookData[0]?.customerObjid);
  const pictureControl = (event = {}) => {
    const imageFile = event && event.target && event.target.src;
    if (imageFile) {
      setOpenPhoto(true);
      setMainSrcPicCon(imageFile);
    }
  };

  const getTotalCount = (array) => {
    const totalCount = array.reduce((total, obj) => total + obj.count, 0);
    return totalCount;
  };

  useEffect(() => {
    const missCallsCount = localStorage.getItem('missedCallers') && JSON.parse(localStorage.getItem('missedCallers'));
    if (missCallsCount && missCallsCount.length) {
      setTotalMissedCalls(getTotalCount(missCallsCount));
    }
  }, []);

  useEffect(() => {
    const missCallsCount = localStorage.getItem('missedCallers') && JSON.parse(localStorage.getItem('missedCallers'));
    if (missCallsCount && missCallsCount.length && reduxMissedCalls?.data) {
      setTotalMissedCalls(getTotalCount(reduxMissedCalls?.data));
    }
  }, [reduxMissedCalls]);

  useEffect(() => {
    if (phoneBookData.length && !runOnlyOnce) {
      const customerIds = phoneBookData.map(customer => customer.customerObjid);
      polling.set(getCustomerOnlineStatus, customerIds, true);
      setRunOnlyOnce(true);
    }
    if (prevContact && phoneBookData && phoneBookData.length
        && prevContact !== phoneBookData[0].customerObjid) {
      polling.clear();
      setRunOnlyOnce(false);
    }
  }, [phoneBookData, prevContact]);

  useEffect(() => {
    if (perfObjid) {
      let url = '';
      if (activeTab === 'chat-log-tab') {
        url = `cfgxmppsessions/list/?phonebook=True&character_objid=${perfObjid}&cust2char__gt=-1&page=${currentPage}&page_size=15&ordering=-objid&online_first=${sortedByState.online_first ? 'True' : 'False'}`;
        getPhonebookData(url);
      }
     
    }
  }, [perfObjid, currentPage, sortedByState, activeTab]);

  useEffect(() => {
    if (perfObjid) {
      let url = '';
      if (activeTab === 'video-voice-log-tab') {
          url = `cfgvideosession/list/?phonebook=True&character_objid=${perfObjid}&page=${callsCurrentPage}&page_size=15&ordering=-objid&online_first=${sortedByState.online_first ? 'True' : 'False'}`;
          getCallsData(url);
      }
    }
  }, [perfObjid, callsCurrentPage, sortedByState, activeTab]);

  useEffect(() => {
    const customInputFeild = [];
    const updatedInputFeild = (
      <label htmlFor="InputNotesFeild" key="InputNotesFeild" className="noteTextArea">
        <textarea
          type="text"
          className="InputNotes form-control"
          id="InputNotesFeild"
          value={custEditNote.custmerNote}
          onChange={event => setCustEditNote({
            ...custEditNote,
            custmerNote: event.currentTarget.value,
          })}
        />
      </label>
    );
    customInputFeild.push(updatedInputFeild);
    setNotesInputFeild(customInputFeild);
  }, [custEditNote]);

  const debouncedHandle = useCallback(lodash.debounce(url => getPhonebookData(url),
    1000, []));

  // Implement customer search Filter
  const onInputChangeSearchHandler = (event) => {
    const inputText = event?.target?.value;
    const parsedInputText = inputText.toLowerCase();
    const url = `cfgxmppsessions/list/?phonebook=True&character_objid=${perfObjid}&cust2char__gt=-1&customer_screen_name_filter=${parsedInputText}`;
    debouncedHandle(url);
  };

  const postCustomerNote = () => {
    // call api
    const formData = new FormData();
    formData.append('character_objid', perfObjid);
    formData.append('cst_contact_objid', custEditNote.customerObjid);
    formData.append('note', custEditNote.custmerNote);
    // formData.append('note_type', "phonebook_character");
    // if (log.calls === true) {
    // formData.append('note_type ', 'Video/Voice Notes');
    //   editCustomerCallsNote(formData);
    // } else {
    //   editCustomerNote(formData);
    // }
    if (activeTab === 'chat-log-tab' ) {
      editCustomerNote(formData);
    } else {
      editCustomerCallsNote(formData);
    }
    
   
  };

  const showOnlineStatus = (isCustomerOnline, sendEmailNotification) => {
    if (isCustomerOnline) {
      return <span className="online-small-icon" />;
    // eslint-disable-next-line no-else-return
    } else if (sendEmailNotification) {
      return <span className="online-away-icon" />;
    }
    return <span className="offline-small-icon" />;
  };

  const handleClickOnEditNote = (cust = {}, isCallsLog = false) => {
    if (isCallsLog) {
      setCustEditNote(cust);
      setEditNotesModal(true);
      setLog({
        chat: false,
        calls: true,
      });
    } else {
      setCustEditNote(cust);
      setEditNotesModal(true);
      setLog({
        chat: true,
        calls: false,
      });
    }
  };

  const createNewChatRoom = (updatedConv = []) => {
    const newSession = {
      id: 0,
      custId: chatData.customerObjid,
      title: chatData.customerName,
      messages: [],
      premium: 0,
      unread: 0,
      iscurrentlyActiveCust: true,
      custPic: chatData.customerProfilePic,
      sessionStatus: 'pending',
      customerchanneltype: '',
      performerContinueMsgCount: 0,
      lastMessageTime: new Date(),
      isPremiumCustomer: chatData.isPremiumCustomer,
      customerHostReview: chatData.customerHostReview,
      isCustomerOnline: chatData.isCustomerOnline,
      sendEmailNotification: chatData.sendEmailNotification,
    };
    const updatedConversation = updatedConv.map(conv => ({
      ...conv,
      iscurrentlyActiveCust: false,
    }));
    updatedConversation.unshift(newSession);
    setConversation(updatedConversation);
    history.push('/chat');
  };

  const checkActiveSession = async (customer) => {
    try {
      // const url = `/cfgxmppsessions/list/?character_objid=${perfObjid}&phonebook=true&page_size=25&page=${1}&cust2char__gt=-1&exclude_msg_paths=SESSION_CMD|Sys2Cust&exclude_missed_sessions=true`;
      // const {data} = await axios.get(url);
      // console.log({data});
      history.replace({ pathname: '/chat', state: { fromLogsPage: true, id: customer.customerObjid, customer } });
    } catch (error) {
      console.log(error);
    }
   
  };

  const renderResumeChatModal = () => {
    if (chatData && chatData.isCustomerBlockedByChar) {
      return (
        <CustomModal
          header="Blocked User"
          body="You can not chat, you blocked this customer."
          buttonText="Ok"
          footer="Dismiss"
          footerLink={() => setIsResumeModalShow(false)}
          open={isResumeModalshow}
          onClick={() => setIsResumeModalShow(false)}
          toggle={() => setIsResumeModalShow(false)}
        />
      );
    }

    if (chatData && chatData.isChatInitiateBlock) {
      return (
        <CustomModal
          header="Not Enabled"
          body="This customer has not enabled you for initiating a chat."
          buttonText="Ok"
          footer="Dismiss"
          footerLink={() => setIsResumeModalShow(false)}
          open={isResumeModalshow}
          onClick={() => setIsResumeModalShow(false)}
          toggle={() => setIsResumeModalShow(false)}
        />
      );
    }

    if (chatData) {
      // setTimeout(() => {
      //   history.replace({ pathname: '/chat', state: { fromLogsPage: true, id: chatData.customerObjid } });
      // }, 400);
       checkActiveSession(chatData);
      return '';
    }

    // return (
    //   <CustomModal
    //     header="Note"
    //     body={`Host Initiated chats can go premium after ${minMessageToInitaiteChat} messages.`}
    //     buttonText="Resume"
    //     footer="Dismiss"
    //     footerLink={() => setIsResumeModalShow(false)}
    //     open={isResumeModalshow}
    //     onClick={checkActiveSession}
    //     toggle={() => setIsResumeModalShow(false)}
    //   />
    // );
  };

  const handleChatOnClick = (customer) => {
    setChatData(customer);
    customer.isChatInitiateBlock ? setIsResumeModalShow(true) : checkActiveSession(customer);
  };

  const handleCustomerList = (PageNumber) => {
    setCurrentPage(PageNumber);
  };
  const handleCallsCustomerList = (currentPageNumber) => {
    setCallsCurrentPage(currentPageNumber);
  };
  // console.log('handleCallsCustomerList', handleCallsCustomerList(1));
  useEffect(() => {
    if (phoneBookData.length) {
      // const filteredCustomer = phoneBookData
      //   .filter(customer => customer.isCustomerBlockedByChar !== true);

      const updatedCustomerDetailComp = phoneBookData.map(customer => (
        <li key={customer.customerObjid} className="customer-section">
          <div className="customerImg customerImgPhonebook">
            <div className="customerImgInner">
              <img
                src={customer.customerProfilePic || customerAvatar}
                height="50px"
                width="50px"
                alt="customer profile"
                onClick={pictureControl}
                onKeyUp={pictureControl}
                role="presentation"
              />
              {customer.isPremiumCustomer ? <img src={crownIcon} className="is_premium_customer" alt="crown" /> : ''}
              {showOnlineStatus(customer.isCustomerOnline, customer.sendEmailNotification)}
            </div>
            <div className="customerInfo">
              <Link
                to={`/customerProfile/${customer.customerObjid}`}
              >
                {customer.customerName}
              </Link>
              <p>{customer.custmerNote}</p>
            </div>
          </div>
          <div className="phonebook-option">
            <Button
              color="primary"
              outline
              onClick={() => handleChatOnClick(customer)}
            >
              <span className="buttonIcon" />
              {iconChat()}
                Chat
            </Button>
            <Link
              to={{
                pathname: `/customerChatlog/${customer.customerObjid}`,
                state: {
                  customer,
                },
              }}
              className="feed-user-icon like"
            >
              <Button
                color="primary"
                outline
              >
                <span className="buttonIcon" />
                {iconChatlog()}
                Chat Logs
              </Button>
            </Link>
            <Button
              color="primary"
              outline
              onClick={() => handleClickOnEditNote(customer, false)}
            >
              {iconEdit()}
              <span className="buttonIcon" />
              Edit Notes
            </Button>
          </div>
        </li>
      ));
      setCustomerDetailComp(updatedCustomerDetailComp);
    }
  }, [phoneBookData]);

  useEffect(() => {
    let missedCallersMap, phonebookWithCounts;
    if (callsLogData.length) {
       if (reduxMissedCalls && reduxMissedCalls?.data?.length) {
        missedCallersMap = new Map(reduxMissedCalls?.data?.map(call => [call.id.toString(), call.count]));
        console.log({missedCallersMap});
         phonebookWithCounts = callsLogData.map(contact => {
          const count = missedCallersMap.get(contact.customerObjid.toString()) || '';
          console.log("contact.customerObjid.toString()", contact.customerObjid.toString(), count);
          return { ...contact, missedCallCount: count };
        });
        console.log({phonebookWithCounts});
       } else {
        phonebookWithCounts = callsLogData;
       }
       console.log('reduxMissedCalls data:', reduxMissedCalls.data);
       console.log('callsLogData:', callsLogData);
      const updatedCustomerDetailComp = phonebookWithCounts.map(customer => (
        <li key={customer.customerObjid} className="customer-section">
          <div className="customerImg customerImgPhonebook">
            <div className="customerImgInner">
              <img
                src={customer.customerProfilePic || customerAvatar}
                height="50px"
                width="50px"
                alt="customer profile"
                onClick={pictureControl}
                onKeyUp={pictureControl}
                role="presentation"
              />
              {customer.isPremiumCustomer ? <img src={crownIcon} className="is_premium_customer" alt="crown" /> : ''}
              {/* {customer.is_customer_online ?
                <span className="online-small-icon" /> :
                <span className="offline-small-icon" />} */}
              {showOnlineStatus(customer.isCustomerOnline, customer.sendEmailNotification)}
            </div>
            <div className="customerInfo">
              <Link
                to={`/customerProfile/${customer.customerObjid}`}
              >
                {customer.customerName}
              </Link>
              <p>{customer.custmerNote}</p>
            </div>
          </div>
          <div className="phonebook-option">
            <Button
              color="primary"
              outline
              onClick={() => handleChatOnClick(customer)}
            >
              <span className="buttonIcon" />
              {iconChat()}
                Chat
            </Button>
            <Link
              to={{
                pathname: `/customercallslog/${customer.customerObjid}`,
                state: {
                  customer,
                },
              }}
              className="feed-user-icon like"
            >
              <Button
                color="primary"
                outline
              >
                <span className="buttonIcon" />
               <>
               {iconCallsLog()}
                Video/Voice Logs {" "}
               { customer?.missedCallCount && <Badge pill className="whiteBadge">{customer?.missedCallCount}</Badge>}
               </> 
              </Button>
            </Link>
            <Button
              color="primary"
              outline
              onClick={() => handleClickOnEditNote(
                customer,
                true,
              )}
            >
              {iconEdit()}
              <span className="buttonIcon" />
              Edit Notes
            </Button>
          </div>
        </li>
      ));
      setCustomerVoiceDetailComp(updatedCustomerDetailComp);
    }
  }, [callsLogData]);

  const sortingHandler = () => {
    setSortedByState((state) =>( {
      online_first: !state.online_first,
      sortedBy: !state.online_first ? 'Online Status' : 'Recent Interactions',
      activeTab: activeTab === 'chat-log-tab' ? 'chat-log-tab' : 'video-voice-log-tab'
    }));
  };


  return (
    <>
      {renderResumeChatModal()}
      {isOpenPhoto && (
        <Lightbox
          ClassName="Lightbox"
          mainSrc={mainSrcPicCon}
          onCloseRequest={() => setOpenPhoto(false)}
        />
      )}
      <section className="phonebook-wrap">
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <h3 className="topHead">Phonebook</h3>
          <div className="searchCustomers">
            <Input type="text" onChange={onInputChangeSearchHandler} placeholder="Search..." className="form-control" />
          </div>
        </div>

        <CardHeader tag="h6" className="p-0 border-bottom-0">
          <Nav tabs fill>
            <NavItem>
              <NavLink active={activeTab === 'chat-log-tab'} onClick={() => setActiveTab('chat-log-tab')}>
                Chat Logs
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === 'video-voice-log-tab'} onClick={() => setActiveTab('video-voice-log-tab')}>
                Video/Voice Logs {" "}
               {totalMissedCalls && <Badge pill className="whiteBadge">{totalMissedCalls}</Badge>}
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>

        <CardBody>
        <p style={{color: '#89888d'}}>Sorted By: <buton style={{color: '#FD7663', cursor: 'pointer'}} onClick={sortingHandler}>{sortedByState.sortedBy} <FaChevronDown /></buton></p>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="chat-log-tab">
              {getPhonebookDataapiLoader ? <LoadingSpinner /> : (
                <Card className="phoneBookCard">
                  {phoneBookData.length ? (
                    <>
                      <ul>
                        {customerDetailComp}
                      </ul>
                      {count > 0 && (
                        <div className="pull-right">
                          <Pagination
                            totalCount={count}
                            pageSize={15}
                            onPageClick={handleCustomerList}
                            currentPage={currentPage}
                          />
                        </div>
                      )}
                    </>
                  ) : <NoDataAvailable />}
                </Card>
              ) }
            </TabPane>
            <TabPane tabId="video-voice-log-tab">
              {callLogsLoader ? <LoadingSpinner /> : (
                <Card className="phoneBookCard">
                  {callsLogData.length ? (
                    <>
                      <ul>
                        {customerVoiceDetailComp}
                      </ul>
                      {totalCallsCount > 0 && (
                      <div className="pull-right">
                        <Pagination
                          totalCount={totalCallsCount}
                          pageSize={15}
                          onPageClick={ handleCallsCustomerList}
                          currentPage={ callsCurrentPage}
                        />
                      </div>
                      )}
                    </>
                  ) : <NoDataAvailable />}
                </Card>
              ) }

            </TabPane>
          </TabContent>
        </CardBody>

        <CustomModal
          header="Note"
          body=""
          isInputFeild
          htmlElements={notesInputFeild}
          buttonText="OK"
          open={editNotesModal}
          onClick={postCustomerNote}
          toggle={() => setEditNotesModal(false)}
        />
      </section>
    </>
  );
};

Phonebook.defaultProps = {
  phoneBookData: [],
  callsLogData: [],
  callLogsLoader: false,
  getPhonebookDataapiLoader: true,
  perfObjid: 0,
  minMessageToInitaiteChat: 0,
  count: 0,
  isTier2: false,
};

Phonebook.propTypes = {
  getPhonebookData: PropTypes.func.isRequired,
  editCustomerNote: PropTypes.func.isRequired,
  editCustomerCallsNote: PropTypes.func.isRequired,
  phoneBookData: PropTypes.instanceOf(Array),
  callsLogData: PropTypes.instanceOf(Array),
  getPhonebookDataapiLoader: PropTypes.bool,
  callLogsLoader: PropTypes.bool,
  isTier2: PropTypes.bool,
  perfObjid: PropTypes.number,
  checkActiveSessionAvailable: PropTypes.func.isRequired,
  setConversation: PropTypes.func.isRequired,
  conversation: PropTypes.instanceOf(Array).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  minMessageToInitaiteChat: PropTypes.string,
  count: PropTypes.number,
  getCustomerOnlineStatus: PropTypes.func.isRequired,
};

export default Phonebook;
