import api from './api';

const getReviews = (id, url) => {
  if (url) {
    return ({
      type: 'GET_REVIEWS',
      payload: api.get(url),
    });
  }
  return ({
    type: 'GET_REVIEWS',
    payload: api.get(`/cstreview/list/?objid2perf_character=${id}&ispublished=1&isactive=1&page_size=5`),
  });
};

export default getReviews;
