import _ from 'lodash';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const required = value => (value ? undefined : 'Required');

const spaceTrim = value => (value && value.trim() ? undefined : 'Required');

const maxChar250 = value => (value && value.length <= 250 ? undefined : 'Please ensure this field has no more than 250 characters.');

const maxChar40 = value => (value && value.length <= 40 ? null : 'Must be 40 character or less');

const minChar15 = value => (value && value.length >= 15 ? null : 'Must be contain 15 character');

const maxChar150 = value => (value && value.length <= 150 ? null : 'Must be 150 character or less');


const maxLength = max => value => (
  value && value.length > max ? `Must be ${max} characters or less` : undefined
);

const minLength = (min, foreString = '') => (value) => {
  let words = 0;
  if (value) {
    words = value.match(/\S+/g).length;
  }
  if (!value) return '';

  return (
    words <= (min - 1) ? `${foreString} must be minimum of ${min} words or more` : undefined
  );
};

const maxLength60 = maxLength(60);
const minWord100 = minLength(100);
const minWord100Profile = minLength(100, 'Profile description');


// const minVoiceCallValue = JSON.parse(localStorage.getItem('minVoiceCallRate'));
// const maxVoiceCallValue = JSON.parse(localStorage.getItem('maxVoiceCallRate'));
// const maxVedioCallValue = JSON.parse(localStorage.getItem('maxVedioCallRate'));
// const minVedioCallRate = JSON.parse(localStorage.getItem('minVedioCallRate'));

// eslint-disable-next-line no-restricted-globals
const number = value => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);

const minValue = min => value => (value && value < min ? `Must be at least ${min}` : undefined);

// const minVoiceCallRateFun = minValue(Number(minVoiceCallValue));
// const minVedioCallRateFun = minValue(Number(minVedioCallRate));

const minVoiceCallRateFun = minValue(28);
const minVedioCallRateFun = minValue(50);

const maxValue = max => value => (value && value > max ? `Must be less than ${max}` : undefined);
// const maxVoiceCallRateFun = maxValue(Number(maxVoiceCallValue));
// const maxVedioCallRateFun = maxValue(Number(maxVedioCallValue));

const maxVoiceCallRateFun = maxValue(200);
const maxVedioCallRateFun = maxValue(300);

const email = value => (EMAIL_REGEX.test(value) ? undefined : 'Email is invalid');

const mustMatch = field => (value, allValues) => {
  const data = allValues.toJS();
  return !_.isEmpty(data) && data[field] === value ? undefined : 'Fields Must Match';
};

const maxFileSize = (file = {}) => (file && file.size > 40000000 ? 'File size must be less or equal to 40MB' : undefined);

const isValueSelected = value => (value === 'Select');

const errorToFields = (data = {}) => {
  const errors = {};
  if (data) {
    _.forEach(data.errors, (error) => {
      const key = error.source.pointer.replace('/data/attributes/', '');
      const fieldName = _.upperFirst(key.replace('_', ' '));
      errors[key] = `${fieldName} ${error.detail}`;
    });
  }

  return errors;
};

const validate = (values) => {
  const errors = {};
  if (!values.videoChatRate) {
    errors.videoChatRate = 'Please enter video chat rate';
  }
  if (!values.headline) {
    errors.headline = 'Please enter headline';
  }
  if (!values.description) {
    errors.description = 'Please enter description';
  }
  if (!values.voiceChatRate) {
    errors.voiceChatRate = 'Please enter Voice call rate';
  }
  return errors;
};
// eslint-disable-next-line no-restricted-globals
const noDecimalValue = value => (value && !Number.isInteger(Number(value)) ? 'Decimal value is not acceptable' : undefined);

const minLengthV2 = min => value => (
  value && value.length < min ? `Must be ${min} characters or more` : undefined
);

const exactLength = len => value => (
  value && value.length === len ? null : `Must be equal to ${len} characters.`
);

const exactLen9 = exactLength(9);
const exactLen3 = exactLength(3);
const minLen6 = minLengthV2(6);
const minLen7 = minLengthV2(7);
const minLen9 = minLengthV2(9);

const maxLen12 = maxLength(12);
const maxLen18 = maxLength(18);
const maxLen34 = maxLength(34);
const exactLen5 = exactLength(5);
const maxLen11 = maxLength(11);

export {
  errorToFields,
  maxValue,
  minValue,
  number,
  required,
  spaceTrim,
  email,
  mustMatch,
  maxFileSize,
  maxLength60,
  minWord100,
  maxChar250,
  noDecimalValue,
  minWord100Profile,
  isValueSelected,
  maxChar40,
  minChar15,
  maxChar150,
  validate,
  exactLen9,
  exactLen3,
  minLen6,
  minLen7,
  minLen9,
  maxLen12,
  maxLen18,
  maxLen34,
  exactLen5,
  maxLen11,
  minVoiceCallRateFun,
  maxVoiceCallRateFun,
  minVedioCallRateFun,
  maxVedioCallRateFun,
};
