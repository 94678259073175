import api from './api';

export const fetchProfileFeedData = data => ({
  type: 'FETCH_PROFILE_FEEDS_DATA',
  payload: api.get(data),
});

export const fetchUpdateProfileData = data => ({
  type: 'UPDATE_ALLPHOTO_LIKE_STATUS',
  payload: data,
});
