export default function (state = [], action = {}) {
  switch (action.type) {
    case 'POST_PAYOUT_DETAILS_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'POST_PAYOUT_DETAILS_FULFILLED':
      return {
        ...state,
        isLoading: false,
        postResponse: action.payload.data,
        error: false,
      };

    case 'POST_PAYOUT_DETAILS_REJECTED':
      // eslint-disable-next-line no-case-declarations
      // const errorMsg = action?.payload?.response?.data?.message ||
      // 'Bank details updation failed';
      // toast.error(errorMsg);
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case 'GET_PAYOUT_DETAILS_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'GET_PAYOUT_DETAILS_FULFILLED':
      return {
        ...state,
        isLoading: false,
        payoutDetails: action.payload.data,
        error: false,
      };

    case 'GET_PAYOUT_DETAILS_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    default:
      return {
        ...state,
      };
  }
}
