import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Validations } from '../../utils/formUtils';
import cloude from '../../assets/svgs/outline-cloude';
import arrow from '../../assets/svgs/arrow-up-clode';
import './style.scss';

const DragAndDrop = (props) => {
  const {
    handleOnDrop,
    meta: { error, touched },
    validationError,
    fileType,
  } = props;
  const onDrop = useCallback(handleOnDrop);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });

  const getName = (name) => {
    if (name && name.length > 50) {
      return `${name.slice(0, 50)}...`;
    }
    return name;
  };

  const files = acceptedFiles.map(file => (
    <li className="file-name" key={file.lastModified}>{getName(file.name)}</li>
  ));

  const acceptValue = (type) => {
    if (type === 'image') {
      return 'image/jpg, image/jpeg';
    }
    if (type === 'video') {
      return 'video/*';
    }
    return 'image/jpg, image/jpeg, video/*';
  };

  const inputProperties = {
    accept: acceptValue(fileType),
    multiple: false,
  };

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps(inputProperties)} />
        <div className="cld-arr-icon">
          <span className="cloude-icon">{cloude()}</span>
          <div className="arrow-icon">{arrow()}</div>
        </div>
        <p>Drag and drop your files here</p>
        <Button type="button" outline color="primary" className="addFilesBtn">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7 0C7.55228 0 8 0.447715 8 1V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V1C6 0.447715 6.44772 0 7 0Z" fill="inherit" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 7C0 6.44772 0.447715 6 1 6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H1C0.447715 8 0 7.55228 0 7Z" fill="inherit" />
          </svg>
          <span className="ml-1 mt-1">Add File</span>
        </Button>
      </div>
      <aside>
        <ul style={{ padding: '0' }}>{files}</ul>
      </aside>
      {touched && error && (
        <Validations
          {...{
            error,
            validationError,
          }}
        />
      )}
    </section>
  );
};

DragAndDrop.defaultProps = {
  meta: {},
  validationError: '',
  fileType: '',
};

DragAndDrop.propTypes = {
  handleOnDrop: PropTypes.func.isRequired,
  meta: PropTypes.instanceOf(Object),
  validationError: PropTypes.string,
  fileType: PropTypes.string,
};

export default DragAndDrop;
