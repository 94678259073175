export default function (state = [], action = {}) {
  switch (action.type) {
    case 'GET_CALLS_DATA_PENDING':
      return {
        ...state,
        getCallsDataapiLoader: true,
        error: false,
      };

    case 'GET_CALLS_DATA_FULFILLED':
      return {
        ...state,
        getCallsDataapiLoader: false,
        data: action.payload.data.results,
        error: false,
        count: action.payload.data.count,
      };

    case 'GET_CALLS_DATA_REJECTED':
      return {
        ...state,
        getCallsDataapiLoader: false,
        error: true,
      };

    case 'UPDATE_CALLS_DATA_PENDING':
      return {
        ...state,
        updateCallsDataapiLoader: true,
        error: false,
      };

    case 'UPDATE_CALLS_DATA_FULFILLED': {
      const updatedResults = state.data.map((result) => {
        if (result.customer2cst_contact_objid === action.payload.data?.cst_contact_objid) {
          return {
            ...result,
            notes: action.payload.data.note,
          };
        }
        return result;
      });
      return {
        ...state,
        data: [
          ...updatedResults,
        ],
        updateCallsDataapiLoader: false,
        error: false,
      };
    }

    case 'UPDATE_CALLS_DATA_REJECTED':
      return {
        ...state,
        updateCallsDataapiLoader: false,
        error: true,
      };

    default:
      return {
        ...state,
      };
  }
}
