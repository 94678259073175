import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  Row,
  Col,
} from 'reactstrap';

import './style.scss';
import MESSAGES from '../../constants';
// import NOTIFICATION_ICON from '../../../../assets/svgs';
import { renderSwitch } from '../../../../utils/formUtils';

const NotificationSettings = (props) => {
  const {
    performerData, setSmsNotifOff, setSmsNotifOn, setIsSmsNotif, isSmsNotif,
  } = props;

  const onSmsNotifToggle = (e) => {
    const { target: { checked } } = e;
    setIsSmsNotif(checked);
    let charContact;
    if (performerData?.characters?.length) {
      const { characters } = performerData;
      const { character2cst_contact: contact } = characters[0];
      charContact = contact;
    }
    if (checked) {
      // put api on turning ON
      const data = {
        contact_objid: charContact,
        flag_name: 'TURN_OFF_SMS_NOTIFICATION',
      };
      setSmsNotifOn(data);
    } else {
      // post api on turning OFF
      const data = {
        contact2cst_contact: charContact,
        flag_name: 'TURN_OFF_SMS_NOTIFICATION',
      };
      setSmsNotifOff(data);
    }
  };

  // ----- SMS notification toggle starts -----
  const smsNotification = () => (
    <>
      <Row className="notification-row">
        <Col className="d-flex">
          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="18.6164" cy="18.9998" rx="18.6164" ry="18.615" fill="#1F1F1F" />
            <path d="M20.2243 12.1577C20.2243 12.2355 20.2196 12.3122 20.2104 12.3874C22.8604 13.186 23.5786 15.8725 23.5786 17.3287V22.7951H25.2558C25.7189 22.7951 26.0943 23.192 26.0943 23.6816C26.0943 24.1712 25.7189 24.568 25.2558 24.568H11.8386C11.3754 24.568 11 24.1712 11 23.6816C11 23.192 11.3754 22.7951 11.8386 22.7951H13.5157V17.3287C13.5157 15.8725 14.2339 13.186 16.884 12.3874C16.8748 12.3122 16.87 12.2355 16.87 12.1577C16.87 11.1785 17.6209 10.3848 18.5472 10.3848C19.4734 10.3848 20.2243 11.1785 20.2243 12.1577Z" fill="#FD7663" />
            <path d="M20.2243 25.4545C20.2243 26.4336 19.4734 27.2274 18.5472 27.2274C17.6209 27.2274 16.87 26.4336 16.87 25.4545H20.2243Z" fill="#FD7663" />
          </svg>
          <div className="content-text">
            <h4 className="mb-2">{MESSAGES.NOTIF_SMS_HEADING}</h4>
            <p className="mb-1">{MESSAGES.NOTIF_SMS_SUB_HEADING}</p>
            <p className="mb-1">
              ( Note : Only applicable when you are logged into mobile app.)
            </p>
          </div>
        </Col>
        <Col xs={6} className="text-right ">
          <Field
            id="smsNotif"
            name="SMS Notification"
            checked={isSmsNotif}
            component={renderSwitch}
            onChange={onSmsNotifToggle}
          />
        </Col>
      </Row>
    </>
  );
  // ----- SMS notification toggle ends -----

  return (
    <>
      <h2 className="mt-3" style={{ lineHeight: 1 }}>
        <span className="headTitle">Notifications</span>
      </h2>
      <div>
        {smsNotification()}
      </div>
    </>
  );
};

NotificationSettings.defaultProps = {

};

NotificationSettings.propTypes = {
  performerData: PropTypes.instanceOf(Object).isRequired,
  setSmsNotifOff: PropTypes.func.isRequired,
  setSmsNotifOn: PropTypes.func.isRequired,
  setIsSmsNotif: PropTypes.func.isRequired,
  isSmsNotif: PropTypes.bool.isRequired,
};

export default NotificationSettings;
