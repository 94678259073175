/* eslint-disable */

import React from 'react';

export default () => (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.0432 13.224C13.316 13.0635 13.5696 12.8674 13.7968 12.6384C14.4912 11.9385 14.8819 10.9896 14.883 9.99987V8.74661H12.778C12.5913 8.74661 12.44 8.59344 12.44 8.40441C12.44 8.21547 12.5913 8.06222 12.778 8.06222H14.883V6.79099H12.778C12.5913 6.79099 12.44 6.63782 12.44 6.44879C12.44 6.25977 12.5913 6.10659 12.778 6.10659H14.883V5.73326C14.883 5.42805 14.846 5.12675 14.7743 4.83537H12.778C12.5913 4.83537 12.44 4.6822 12.44 4.49317C12.44 4.30414 12.5913 4.15097 12.778 4.15097H14.5338C14.3531 3.7617 14.1054 3.40316 13.798 3.09345C13.1034 2.39332 12.1612 2 11.1788 2C10.1963 2 9.25419 2.39332 8.55957 3.09345C7.86487 3.79357 7.47461 4.74317 7.47461 5.73326V6.10871C7.58725 6.10164 7.70085 6.09806 7.81518 6.09806C10.8303 6.09806 13.2745 8.57328 13.2745 11.6266C13.2745 12.182 13.1937 12.7183 13.0432 13.224ZM10.6496 16.3525C11.3491 15.9214 11.9453 15.3355 12.3919 14.6418C13.2 14.4272 13.9453 14.0001 14.5464 13.3942C15.4395 12.4941 15.9414 11.2732 15.9414 10.0003C15.9414 9.85873 15.997 9.72312 16.0963 9.62312C16.1956 9.52304 16.3302 9.46686 16.4705 9.46686C16.6109 9.46686 16.7454 9.52304 16.8447 9.62312C16.9439 9.72312 16.9997 9.85873 16.9997 10.0003C16.9978 11.4629 16.4542 12.8722 15.4755 13.9523C14.4967 15.0323 13.1532 15.7054 11.7079 15.8395V17.4667C11.7079 17.6082 11.6522 17.7438 11.553 17.8438C11.4537 17.9438 11.3191 18 11.1788 18C11.0385 18 10.9039 17.9438 10.8046 17.8438C10.7053 17.7438 10.6496 17.6082 10.6496 17.4667V16.3525Z" fill="#FD7663"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.6341 11.6203C12.6341 14.3143 10.4774 16.4983 7.81705 16.4983C5.15667 16.4983 3 14.3143 3 11.6203C3 8.92618 5.15667 6.74219 7.81705 6.74219C10.4774 6.74219 12.6341 8.92618 12.6341 11.6203ZM8.22947 9.8409H5.47404C5.11067 9.8409 4.8133 10.1391 4.8133 10.5035V12.7365C4.8133 13.1009 5.11067 13.3991 5.47404 13.3991H8.22947C8.59284 13.3991 8.89021 13.1009 8.89021 12.7365V10.5035C8.89021 10.1325 8.59284 9.8409 8.22947 9.8409ZM10.2192 10.2532C10.2522 10.2333 10.2918 10.2134 10.3315 10.2068C10.5892 10.1472 10.8204 10.3459 10.8204 10.5912V12.6386C10.8204 12.7114 10.8007 12.7777 10.7676 12.8373C10.6553 13.0295 10.4174 13.0957 10.2257 12.983L9.18839 12.3867V10.8495L10.2192 10.2532Z" fill="#FD7663"/>
</svg>

);
