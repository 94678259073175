import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';

import './OtpVerificationModal.scss';

const OtpVerificationModal = (props) => {
  const {
    className,
    header,
    body,
    open,
    toggle,
    getVerificationOTP,
    sentCodeOnNumber,
    // ccode,
    resendVerificationCode,
    counter,
  } = props;

  // const [number, setNumber] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [otp, setOtp] = useState();

  const handleChange = (otpValue) => {
    setOtp(otpValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!otp) {
      setErrMsg('Please Enter OTP!');
    } else if (otp && otp.length < 4) {
      setErrMsg('Please Enter 4 digit Code!');
    } else {
      setErrMsg('');
      getVerificationOTP(otp);
      setOtp('');
    }
  };

  const resendVerificationFun = () => {
    resendVerificationCode(sentCodeOnNumber);
  };

  return (
    <Modal
      className={`EditInfo-Modal ${className}`}
      isOpen={open}
      toggle={toggle}
      backdrop
    >
      <ModalHeader className="chat-modal-header" toggle={toggle} />
      <ModalBody className="chat-modal-body">
        <h4>{header}</h4>
        <p className="verification-code-text">{body}</p>
        <p className="verification-code-text verification-num-text">
          +
          {/* {ccode} */}
          {sentCodeOnNumber}
        </p>
        <form onSubmit={handleSubmit} className="otp-input-form">
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={4}
            className="otp-input"
          />

          {/* <input className="form-control" type="number"
          value={value} onChange={e => onChangeHandler(e)} /> */}
          {errMsg ? <span className="err_Msg">{errMsg}</span> : ''}
          <div className="text-center">
            <Button type="submit" color="primary" className="doneBtn text-white">Submit</Button>
          </div>
          <p className="recieve-code-text">
            Didn’t receive code?
            {counter > 0 ? (
              <span color="primary">
                00:
                {counter}
              </span>
            ) : (
              <span
                role="presentation"
                onClick={resendVerificationFun}
              >
                Resend
              </span>
            )}
          </p>
        </form>
      </ModalBody>
      {/* <ModalFooter className="chat-modal-footer">
        <p role="presentation" onClick={footerLink}>{footer}</p>
      </ModalFooter> */}
    </Modal>
  );
};

OtpVerificationModal.defaultProps = {
  className: '',
  header: '',
  body: '',
  open: false,
  sentCodeOnNumber: '',
  // ccode: null,
  counter: 0,
};

OtpVerificationModal.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  getVerificationOTP: PropTypes.func.isRequired,
  sentCodeOnNumber: PropTypes.string,
  // ccode: PropTypes.number,
  resendVerificationCode: PropTypes.func.isRequired,
  counter: PropTypes.number,
};

export default OtpVerificationModal;
