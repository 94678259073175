/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import {
  Button,
  FormFeedback, FormGroup, FormText, Input, Label,
} from 'reactstrap';
import axios from '../../actions/api';

const CosmoPayment = ({ handleFormSubmit, setPaymentOption, SAN }) => {
  const [inputValue, setInputValue] = useState(SAN);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [inputError, setInputError] = useState('');

  const debounceTimer = useRef(null);

  const checkValidity = async (value) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('cosmo_payment_san_number', value);
      const response = await axios.post('/cosmopayment/card_status/',
        formData);
      setIsValid(response.data.card_active_status);
      const cosmoResponse = response.data?.cosmo_payment_response?.Description;
      if (response.data.card_active_status) {
        setInputError('');
      } else {
        setInputError(cosmoResponse);
      }
    } catch (error) {
      console.error('Error while checking validity:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    // if (/^\d*$/.test(value.trim()) || value.trim() === '') {

    setIsUserTyping(true);
    // Clear the previous timer
    if (value.length <= 10) {
      setInputValue(value);
      setIsValid(false);
      setInputError('SAN Number should be either 9 or 10 digits long.');
    }
    if (value.length >= 9 && value.length <= 10) {
      clearTimeout(debounceTimer.current);
      // Set a new timer for debouncing
      debounceTimer.current = setTimeout(() => {
        if (value !== '') checkValidity(value);
        // setIsUserTyping(false);
      }, 600); // Adjust the delay as needed
      // setTimeout(() => {
      //   // setIsValid(true);
      //   setInputError('');
      // }, 1500);
    }
    if (value === '') {
      setInputError('');
    }
    // } else {
    //   setInputError('Only Numeric values are allowed');
    // }
  };


  const handleKeyDown = (event) => {
    // List of disallowed characters (mathematical operators)
    const disallowedCharacters = ['+', '-', '*', '/', '^', 'e', 'E'];
    // Prevent the default behavior of disallowed characters
    if (disallowedCharacters.includes(event.key)) {
      event.preventDefault();
    }
  };

  // useEffect(() => {
  //   if (SAN && SAN.length >= 9 && SAN.length < 11) setIsValid(true);
  // });

  return (
    <div className="ar_payment_form_box">
      <h2 className="ar_payment_form_title">Cosmo Payment</h2>
      <FormGroup className="position-relative">
        <Label className="ar_payment_form_label">SAN Number*</Label>
        <Input
          valid={inputValue !== '' && isValid && !loading && !inputError}
          invalid={inputValue !== '' && !isValid && !loading && inputValue?.trim() !== '' && inputError}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          type="number"
          value={inputValue}
          id="SAN"
          placeholder="Enter SAN number"
          className="ar_payment_form_input"
        />
        {inputError && <span className="text-danger">{inputError}</span>}
        {isValid && !loading && isUserTyping && !inputError && <FormFeedback valid>SAN Number is valid</FormFeedback>}
        {loading && !inputError && <FormFeedback className="d-block">Loading...</FormFeedback>}
        {!isValid && !loading && inputValue?.trim() !== '' && isUserTyping && !inputError && <FormFeedback>Invalid SAN Number</FormFeedback>}
        <FormText className="ar_payment_form_note mt-1">
          Enter valid SAN number to check if it is valid.
        </FormText>
      </FormGroup>

      <Button style={{ pointerEvents: !isValid && 'none' }} onClick={() => handleFormSubmit('cosmo', inputValue)} disabled={inputValue?.length < 9 || inputValue?.length > 10 && !isValid} color="primary" className="updateBtn px-5 ar_theme_btn">Save</Button>
      <Button
        onClick={() => setPaymentOption('')}
        className="btn btn-outline-primary"
      >
        Change Bank Details
      </Button>
    </div>
  );
};

export default CosmoPayment;
