import React from 'react';
import PropTypes from 'prop-types';

import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';

// import arousrLogo from '../../../../assets/svgs/logo.svg';
import underAgeIcon from '../../../../assets/svgs/UnderAge.svg';
import './style.scss';

const UnderAgeModal = (props) => {
  const { open, toggle, onClick } = props;

  const clickHandler = () => {
    onClick();
    toggle();
  };

  return (
    <Modal isOpen={open} toggle={toggle} className="anderAge-Modal">
      <ModalHeader toggle={toggle} />
      <ModalBody className="text-center">
        <img
          src={underAgeIcon}
          alt="arousr-logo"
          className="modal-center-img"
        />
        <h4 className="mb-2">Report Underage</h4>
        <p>Do you really want to report this customer as an underage?</p>
        <div className="text-center">
          <Button
            type="submit"
            color="primary"
            className="doneBtn text-white"
            onClick={clickHandler}
          >
            Submit
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

UnderAgeModal.defaultProps = {
  open: false,
  onClick: () => {},
};

UnderAgeModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default UnderAgeModal;
