import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import './style.scss';
import NotificationSettings from './components/Notifications';
import ResetPassword from './components/ResetPassword';


const Settings = (props) => {
  const { perfObjId, getPerformer, performerData } = props;

  const [isSmsNotif, setIsSmsNotif] = useState(true);

  useEffect(() => {
    if (perfObjId) {
      getPerformer(perfObjId);
    }
  }, []);

  useEffect(() => {
    if (performerData?.characters?.length) {
      const { characters } = performerData;
      const { turn_off_chat_sms_notification: smsNotif } = characters[0];
      setIsSmsNotif(smsNotif);
    }
  }, [performerData?.characters?.length]);

  return (
    <>
      <div>
        <NotificationSettings
          isSmsNotif={isSmsNotif}
          setIsSmsNotif={setIsSmsNotif}
          {...props}
        />
        <ResetPassword {...props} />
      </div>
    </>
  );
};

Settings.defaultProps = {
  perfObjId: 0,
};

Settings.propTypes = {
  perfObjId: PropTypes.number,
  getPerformer: PropTypes.func.isRequired,
  performerData: PropTypes.instanceOf(Object).isRequired,
};

export default Settings;
