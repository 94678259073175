/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import {
  Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, ButtonGroup,
  FormGroup,
  Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import './style.scss';

const NotesModal = (props) => {
  const ref = useRef('');
  const { isOpen, activeUser, setOpenNotesModal, SaveNotesHandler, fetchNotes } = props;
  const [value, setValue] = useState('');
  const [status, setStatus] = useState({status: '', message: ''});

useEffect(() => {
  const TextArea = document.getElementById(`NotesArea`);
  if (TextArea) {
    TextArea.style.height = "auto";
    TextArea.style.height = TextArea.scrollHeight + "px";
  }
}, []);

const toggle = () => {
  setOpenNotesModal(false);
}

useEffect(() => {
  fetchNotes(setValue);
}, [])

  return (
    <div>
      <Modal backdrop={'static'} isOpen={isOpen} toggle={toggle} className="Whisperer-modal Notes-modal" data="public modal">
        <ModalHeader className="chat-modal-header camHeader p-0" toggle={toggle}></ModalHeader>
        <ModalBody className="chat-modal-body paid-pic-modal">
          <h3 className='modal-title'>Notes About Customer</h3>
          <div className='modal-note-box'>
            <FormGroup>
              <Input
               type="textarea" name="text" placeholder="Add Notes" id='NotesArea' value={value} onChange={(e) => {
                setValue(e.target.value);
                setStatus({
                  status: '',
                  message: '' 
                })
                }} />
            </FormGroup>
          </div>
          <div className='d-flex justify-center'>
            <Button
              disabled={status.status === 'success' || status.status === 'error' || value.length === ''} 
              onClick={() => SaveNotesHandler(value, setStatus)}
              className="modal-btn copyBtn">Save</Button>
          </div>
        </ModalBody>
        <ModalFooter className="chat-modal-footer camFooter" />
      </Modal>
    </div>
  );
}

NotesModal.defaultProps = {
  isOpen: false,
};

NotesModal.propTypes = {
  isOpen: PropTypes.bool,
};

export default NotesModal;