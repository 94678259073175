import React from 'react';
import Proptypes from 'prop-types';
import './style.scss';
import Wave from './components/Wave';
import RotatingPlane from './components/RotatingPlane';
import DoubleBounce from './components/DoubleBounce';
import Spinner from './components/Spinner';
import { randomInt } from '../helpers';

class LoadingSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.spinners = {
      wave: Wave,
      bounce: DoubleBounce,
      plane: RotatingPlane,
      spinner: Spinner,
    };
  }

  render() {
    const { spinner, customHeight = 'height-700' } = this.props;
    if (spinner) {
      const Loader = this.spinners[spinner];
      return <span className={customHeight}><Loader /></span>;
    }
    const randomIdx = randomInt(1, Object.keys(this.spinners).length);
    const Loader = this.spinners[Object.keys(this.spinners)[randomIdx - 1]];
    return <span className="height-700"><Loader /></span>;
  }
}

LoadingSpinner.defaultProps = {
  spinner: 'spinner',
  customHeight: 'height-700',
};

LoadingSpinner.propTypes = {
  spinner: Proptypes.string,
  customHeight: Proptypes.string,
};

export default LoadingSpinner;
