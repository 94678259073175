import api from './api';

export const getChatCountData = charID => async (dispatch) => {
  try {
    dispatch({
      type: 'CHAT_COUNT_NOTIFICATION_REQUEST',
    });
    const { data } = await api.get(`/chatmsg/msg_count/?character_objid=${charID}`);
    dispatch({
      type: 'CHAT_COUNT_NOTIFICATION_SUCCESS',
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: 'CHAT_COUNT_NOTIFICATION_FAILED',
      payload: error?.response?.data,
    });
  }
};

export const postChatCountData = data => ({
  type: 'INCREMENT_CHAT_COUNT_NOTIFICATION',
  payload: { count: data },
});
