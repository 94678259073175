import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import perfCharacterReducer from './getPerfCharacter';
import allCharactersReducer from './getAllCharacters';
import perfPerformerReducer from './getPerfPerformer';
import systemMessagesReducer from './getSystemMessages';
import reviewsReducer from './getReviews';
import authReducer from './authReducer';
import mobileChatAuthReducer from './mobileChatAuthReducer';
import getPerformerReducer from './getPerformerReducer';
import balanceReducer from './balanceReducer';
import getUserIpReducer from './getUserIpReducer';
import supportReducer from './supportReducer';
import updateCharacterReducer from './updateCharacterReducer';
import updatePerformerReducer from './updatePerformerReducer';
import authTokenReducer from './getAuthTokenReducer';
import alertReducer from './alertReducer';
import logoutReducer from './logoutReducer';
import sysConfigReducer from './sysConfigReducer';
import affiliationLinkReducer from './affiliationLinkReducer';
import exclutionListReducer from './exclutionListReducer';
import getCountryState from './getStateCountryList';
import characterProductList from './characterProductList';
import getPresence from './getPresence';
import affiliateConfig from './affiliationConfig';
import chatNotiPermission from './setChatPermission';
import rouletteData from './rouletteData';

import endVideoSession from './endVideoSession';
import startVideoSession from './startVideoSession';
import videoCallData from './videoCallData';
import chatlogReducer from './chatlogReducer';
import chatMsgHistoryReducer from './chatMsgHistoryReducer';
import InfinteScrollChat from './pastChatReducer';
import chatReducer from './chatConversationReducer';
import rouletteHeartbeat from './rouletteHeartbeat';
import lastVideoCallData from './lastVideoCallData';
import feedReducer from './feedReducer';
import customerProfileReducer from './customerProfileReducer';
import phoneBook from './phoneBook';
import payoutSettingsReducer from './payoutSettingsReducer';
import customerOnlineStatusReducer from './customerOnlineStatusReducer';
import Schedule from './ScheduleReducer';
import callLogs from './CallLogs';
import MissedCallsReducer from './MissedCallsReducer';
import WhispererReducer from './whispererReducer';
import favCustomerReducer from './favCustomerReducer';
import chatCountReducer from './chatCountReducer';

const rootReducer = combineReducers({
  routing: routerReducer,
  perfCharacter: perfCharacterReducer,
  perfPerformer: perfPerformerReducer,
  allCharacters: allCharactersReducer,
  systemMessages: systemMessagesReducer,
  reviews: reviewsReducer,
  login: authReducer,
  mobileLogin: mobileChatAuthReducer,
  logout: logoutReducer,
  form: formReducer,
  feed: feedReducer, // used for feed
  phonebook: phoneBook,
  customerProfile: customerProfileReducer, // used for feeds customers Profile
  performer: getPerformerReducer,
  support: supportReducer,
  balance: balanceReducer,
  token: authTokenReducer,
  updateCharacter: updateCharacterReducer,
  updatePerformer: updatePerformerReducer,
  userIp: getUserIpReducer,
  alert: alertReducer,
  endVideo: endVideoSession,
  startVideo: startVideoSession,
  chatLog: chatlogReducer,
  sysConfig: sysConfigReducer,
  affiliationLink: affiliationLinkReducer,
  exclutionList: exclutionListReducer,
  stateCountry: getCountryState,
  chat: chatReducer,
  productList: characterProductList,
  presence: getPresence,
  affiliateConfig,
  chatNotification: chatNotiPermission,
  roulette: rouletteData,
  rouletteHeartbeat,
  videoCallData,
  lastVideoCallData,
  chatMsgHistory: chatMsgHistoryReducer,
  pastChats: InfinteScrollChat,
  payoutSettings: payoutSettingsReducer,
  customerOnlineStatus: customerOnlineStatusReducer,
  Schedule,
  callLogs,
  missedCallers: MissedCallsReducer,
  whisperer: WhispererReducer,
  favCustomerReducer,
  chatCount: chatCountReducer,
});

export default rootReducer;
