const mapChatLogState = (state, payload) => {
  let updatedData = payload.data;
  console.log(state, payload);
  if (payload?.data?.previous && state.data) {
    const updatedResults = [...state.data.results, ...payload.data.results];
    updatedData = {
      ...updatedData,
      results: updatedResults,
    };
  }
  return updatedData;
};

export default function (state = [], action = {}) {
  switch (action.type) {
    case 'GET_CHAT_LOG_DATA_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'GET_CHAT_LOG_DATA_FULFILLED':
      return {
        ...state,
        isLoading: false,
        data: mapChatLogState(state, action.payload),
        error: false,
      };

    case 'GET_CHAT_LOG_DATA_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    default:
      return {
        ...state,
      };
  }
}
