import { connect } from 'react-redux';
import Affiliate from '../scenes/Affiliate';
import { getTransactions } from '../actions/balanceActions';
import transtype from '../constants/transtype';
import getAffliateLinkAction from '../actions/actionGetAffiliationLink';
import getAffiliationConfig from '../actions/getAffiliateConfig';
import getPerformer from '../actions/actionGetPerformer';

const getAffiConfigData = (affiData = {}, sysConfig = []) => {
  const creditToUSDConfig = sysConfig.find(config => config.config_name === 'Credit_to_USD');
  const usedConfigValue = creditToUSDConfig && creditToUSDConfig.value;
  const {
    hard_conversion_bonus_credits: firstPurchasebonus,
    soft_conversion_bonus_credits: joinBonus,
    comp_percentage: revShareBonus,
  } = affiData;
  const isShowPayout = firstPurchasebonus || joinBonus || revShareBonus;
  const fpbDollar = (usedConfigValue * firstPurchasebonus).toFixed(2);
  const jbDollar = (usedConfigValue * joinBonus).toFixed(2);
  return {
    firstPurchasebonus,
    joinBonus,
    revShareBonus,
    fpbDollar,
    jbDollar,
    isShowPayout,
  };
};

const addProperitesInResults = (balanceData) => {
  const { results = [] } = balanceData;
  let updatedResults = [];

  updatedResults = results && results.map((result) => {
    const roundOfChange = result && Math.round(result.credit_amount);
    const { trans_type2cfg_credit_trans_type: tt2cctt } = result;
    if (tt2cctt) {
      return ({
        ...result,
        description: transtype[`${tt2cctt.trans_type}`],
        credit_amount: roundOfChange,
      });
    }
    return ({
      ...result,
      credit_amount: roundOfChange,
    });
  });
  return updatedResults;
};

const mapStateToProps = (state) => {
  const loadingBalanceData = state.balance && state.balance.isLoading;
  const loadingPerfPerformer = state.perfPerformer && state.perfPerformer.isLoading;
  const loadingData = (loadingPerfPerformer || loadingBalanceData);
  const balanceData = (state.balance && state.balance.data) || {};
  const affiliationLinkResult = (state?.affiliationLink?.data?.results) || [];
  const { url, short_url: shortURL } = (affiliationLinkResult.length && affiliationLinkResult[0])
  || [];
  const loginData = (state?.login?.data) || {};
  const {
    auth2contact_objid: { objid: cstObjectId },
    perfperformer: { objid: perfObjid },
  } = loginData;
  const { count } = balanceData;
  const updatedTranTableDate = (balanceData && addProperitesInResults(balanceData)) || {};
  const affLinkData = { affiliationLink: url, shortURL };

  const { affiliateConfig: { data: { results: affiConfigResult = [] } = {} } = {} } = state;
  const affData = affiConfigResult.length ? affiConfigResult[0] : {};

  const { sysConfig: { data: { results: sysconfig = [] } = {} } = {} } = state;
  const sysConfig = sysconfig.length ? sysconfig : [];

  return ({
    updatedTranTableDate,
    count,
    affLinkData,
    cstObjectId,
    loadingData,
    affiConfig: getAffiConfigData(affData, sysConfig),
    perfObjid,
  });
};

const mapDispatchToProps = dispatch => ({
  getTransactionTableData: url => dispatch(getTransactions(url)),
  getAffliateLink: url => dispatch(getAffliateLinkAction(url)),
  getAffiliateConfig: url => dispatch(getAffiliationConfig(url)),
  getPerformer: objectId => dispatch(getPerformer(objectId)),
});

const AffiliateContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Affiliate);

export default AffiliateContainer;
