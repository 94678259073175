export const mapChatMsgData = (msgs = []) => msgs.map(msg => ({
  ...msg,
  msgPath: msg.msg_path,
  // createDateTime: msg.unformatted_create_datetime,
  currentUTC: msg.unformatted_create_datetime,
  text: msg.message_txt,
  media: msg.media,
  objid: msg.objid,
  status: 'read',
  chat_status: msg.msg_path === 'Char2Cust' ? msg.delivery_status : '',
  type: msg.msg_path,
  isPremiumChat: !(msg.billing_status === 'NonBillable'),
  direction: msg.msg_path === 'Char2Cust' ? 'out' : 'in',
  sessionId: msg.session_id,
  newSessionID: msg.session2cfg_xmpp_sessions_objid,
}));

export const sortMessages = messages => messages.sort(
  (a, b) => new Date(a.currentUTC) - new Date(b.currentUTC),
);

const uniqueMessageId = message => message.objid;

export const updateConversationWithMsg = (
  dispatch,
  newActiveUserId,
  results,
  conversations,
  setConversationAction,
  setactiveUser,
  isScroll,
) => {
  const msgs = mapChatMsgData(results);
  const currentSession = conversations.find(conv => conv.custId === Number(newActiveUserId));
  // let updatedMsgs = [];
  if (msgs.length) {
    const newMessages = msgs.filter(
      message => !currentSession?.messages.some(
        existingMessage => uniqueMessageId(existingMessage) === uniqueMessageId(message),
      ),
    );
    // updatedMsgs = newMessages;
    const updatedSession = {
      ...currentSession,
      // messages: sortMessages([...newMessages, ...currentSession.messages]),
      messages: newMessages.concat(currentSession.messages),
      unread: 0,
      iscurrentlyActiveCust: true,
    };

    // if (currentSession && currentSession.id && !isScroll) {
    //   setactiveUser(currentSession);
    // }

    const updatedConversation = conversations.map((conv) => {
      if (conv.id === currentSession?.id) {
        return updatedSession;
      }
      if (conv && conv.iscurrentlyActiveCust && !isScroll) {
        return {
          ...conv,
          iscurrentlyActiveCust: false,
          messages: [],
        };
      }
      return { ...conv, messages: [] };
    });
    dispatch(setConversationAction(updatedConversation));
  }
  return results;
};


export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const mapChatLogData = (results = []) => results.map(result => ({
  id: result.session_id,
  active: result.active,
  objid: result.objid,
  createDataTime: result.create_datetime,
  totalMsg: (result.char2cust + result.cust2char),
  title: result.customer_screen_name,
  lastMsg: result.preview_message_perf || result?.preview_message,
  messages: [],
  premium: result.billing_flag,
  lastMessageTime: result.last_activity,
  unread: result.unread_messages_to_char_count,
  custPic: result.customer_profile_pic,
  custId: result.customer_objid,
  sessionStatus: result.active ? 'on' : 'off',
  performerContinueMsgCount: 0,
  iscurrentlyActiveCust: false,
  isPremiumCustomer: result.is_premium_customer,
  sendEmailNotification: result.send_email_notification,
  customerHostReview: result.customer_host_review,
  isCustomerOnline: result.is_customer_online,
  isFavCustomer: result.is_favourite_customer,
}));
