/* eslint-disable */

import React from 'react';

export default () => (
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.08579 11.0809L10.2525 4.91421C11.0335 4.13316 11.0335 2.86684 10.2525 2.08579L9.58088 1.41421C8.79983 0.633165 7.5335 0.633166 6.75245 1.41421L0.585786 7.58088C0.210714 7.95595 0 8.46466 0 8.99509V10.6667C0 11.219 0.447715 11.6667 1 11.6667H2.67157C3.20201 11.6667 3.71071 11.456 4.08579 11.0809Z" fill="#FD7663"/>
<path d="M5.25 1.75024L9.91667 6.41691" stroke="#292A2B" strokeWidth="2" strokeLinejoin="round"/>
</svg>

);
