import React from 'react';

export default fill => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M6.375 9.84387C5.21184 9.84387 4.26562 10.7901 4.26562 11.9532V17.672C4.26562 18.8352 5.21184 19.7814 6.375 19.7814C7.53816 19.7814 8.48438 18.8352 8.48438 17.672V11.9532C8.48438 10.7901 7.53816 9.84387 6.375 9.84387Z" fill={fill} />
      <path d="M2.85938 11.9533C2.85938 11.7505 2.88614 11.5545 2.91928 11.3611C2.07188 11.635 1.45312 12.4221 1.45312 13.3596V16.2658C1.45312 17.2033 2.07188 17.9904 2.91928 18.2643C2.88614 18.0708 2.85938 17.8749 2.85938 17.6721V11.9533Z" fill={fill} />
      <path d="M17.625 9.84387C16.4618 9.84387 15.5156 10.7901 15.5156 11.9532V17.672C15.5156 18.8352 16.4618 19.7814 17.625 19.7814C17.8364 19.7814 18.0367 19.7407 18.2293 19.6826C17.9676 20.5502 17.1705 21.1876 16.2188 21.1876H13.9799C13.6886 20.371 12.9155 19.7814 12 19.7814C10.8368 19.7814 9.89062 20.7276 9.89062 21.8907C9.89062 23.0539 10.8368 24.0001 12 24.0001C12.9155 24.0001 13.6886 23.4105 13.9799 22.5939H16.2188C18.1572 22.5939 19.7344 21.0167 19.7344 19.0782V17.672V11.9532C19.7344 10.7901 18.7882 9.84387 17.625 9.84387Z" fill={fill} />
      <path d="M21.0808 11.3611C21.114 11.5545 21.1407 11.7505 21.1407 11.9533V17.6721C21.1407 17.8749 21.114 18.0708 21.0808 18.2643C21.9282 17.9904 22.547 17.2033 22.547 16.2658V13.3596C22.547 12.4221 21.9282 11.635 21.0808 11.3611Z" fill={fill} />
      <path d="M12 0.00012207C6.18412 0.00012207 1.45312 4.73112 1.45312 10.547V10.5649C1.87205 10.2478 2.35673 10.0157 2.89158 9.91179C3.22022 5.16814 7.17441 1.40637 12 1.40637C16.8256 1.40637 20.7798 5.16814 21.1084 9.91175C21.6432 10.0157 22.1279 10.2478 22.5469 10.5649V10.547C22.5469 4.73112 17.8159 0.00012207 12 0.00012207Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" transform="translate(0 0.00012207)" />
      </clipPath>
    </defs>
  </svg>

);
