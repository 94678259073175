/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Form,
  Label,
  Input,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
// import NoDataAvailable from '../../components/NoDataAvailable';
// import LoadingSpinner from '../../utils/LoadingSpinner';
import './style.scss';
import { ToastContainer, toast } from 'react-toastify';
import ImagePanel from './ImagePanel';
// import editProfile from '../../assets/svgs/edit_profile';
// import edit from '../../assets/svgs/edit';
import { renderField } from '../../utils/formUtils';
import {
  // required,
  maxLength60,
  // minWord100,
  minWord100Profile,
  // maxValue200,
  // maxValue300,
  // minValue50,
  // minValue100,
  // minValue28,
  // noDecimalValue,
} from '../../utils/formUtils/validator';
import CustomModal from '../../components/CustomModal';
import EditInfoContainer from '../../containers/EditInfoContainer';
import PayoutSettingsContainer from '../../containers/PayoutSettingsContainer';
// import close from '../../assets/svgs/cancel';
// import tick from '../../assets/svgs/rightTick';
import VideoChatRateModal from '../../components/ChatRateModal/VideoChatRateModal';
import VoiceChatRateModal from '../../components/ChatRateModal/VoiceChatRateModal';
import inputEditIcon from '../../assets/images/inputEditIcon.svg';

const Profile = (props) => {
  const {
    reset,
    updateProfile,
    formData,
    isCharacterLoading,
    errors,
    getPerfCharacter,
    profile: {
      charId,
      perfId,
      displayName,
      headline,
      description,
      // videoChatRate,
      // voiceChatRate,
      // backgroundPic,
      // primaryPic,
      perf2CstContactId,
    },
    characters,
    getPayoutDetails,
    location,
    minVoiceChatRateVal,
    maxVoiceChatRateVal,
    minVedioChatRateVal,
    maxVedioChatRateVal,
    // initialValues,
  } = props;

  const [activeTab, setActiveTab] = React.useState('profile-settings');
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isUpdateButtonDisabled, setUpdateButtonDisabled] = useState(true);
  const [isVideoChatRateModalOpen, setIsVideoChatRateModalOpen] = useState(false);
  const [isVoiceChatRateModalOpen, setIsVoiceChatRateModalOpen] = useState(false);
  const [videoChatRateRes, setVideoChatRate] = useState('');
  const [voiceChatRateRes, setVoiceChatRate] = useState('');
  const [videoModalFormValue, setVideoModalFormValue] = useState('');
  const [voiceModalFormValue, setVoiceModalFormValue] = useState('');

  useEffect(() => {
    localStorage.setItem('minVoiceCallRate', JSON.stringify(minVoiceChatRateVal));
    localStorage.setItem('maxVoiceCallRate', JSON.stringify(maxVoiceChatRateVal));
    localStorage.setItem('minVedioCallRate', JSON.stringify(minVedioChatRateVal));
    localStorage.setItem('maxVedioCallRate', JSON.stringify(maxVedioChatRateVal));
  }, []);

  useEffect(() => {
    if (location.state && location.state.activeTabState) {
      setActiveTab('payout-settings');
    }
  }, []);

  useEffect(() => {
    toast.dismiss();
  }, [activeTab]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    perfId && getPerfCharacter(perfId).then((response) => {
      const { videochatrate: videoRate } = response.value.data;
      const { voice_rate: voiceRate } = response.value.data.characters[0];
      setVideoChatRate(videoRate);
      setVoiceChatRate(voiceRate);
    }).catch(err => console.log('err', err));
    window.scrollTo(0, 0);

    // calling get payout details api
    if (perf2CstContactId) {
      getPayoutDetails(perf2CstContactId);
    }
  }, [videoChatRateRes, voiceChatRateRes]);
  useEffect(() => {
    const isProfileUpdate = ('headline' in errors) || ('description' in errors);
    if (isProfileUpdate) {
      setUpdateButtonDisabled(true);
    } else {
      setUpdateButtonDisabled(false);
    }
  });

  const takeVideoModalFieldValueFun = (val) => {
    setVideoModalFormValue(val);
  };
  const takeVoiceModalFieldValueFun = (val) => {
    setVoiceModalFormValue(val);
  };

  const openVideoChatRateModalHandler = () => {
    setIsVideoChatRateModalOpen(true);
  };
  const openVoiceChatRateModalHandler = () => {
    setIsVoiceChatRateModalOpen(true);
  };

  const handleUpdateProfile = () => {
    const payload = {
      id: charId,
      headline,
      description,
      // videoChatRate,
      // voiceChatRate,
    };
    updateProfile(payload);
    setUpdateButtonDisabled(true);
    setUpdateModalOpen(true);
  };

  const renderHeadline = () => {
    let letter = 0;
    if (headline) {
      letter = headline.length;
    }
    return (
      <>
        <Field
          name="headline"
          validate={[maxLength60]}
          component={renderField}
        />

        <span className="pull-right">{`${letter}/60`}</span>
      </>
    );
  };

  const renderDescription = () => {
    let words = 0;
    if (description) {
      words = description.match(/\S+/g).length;
    }

    return (
      <Col>
        <Field
          name="description"
          validate={[minWord100Profile]}
          component={renderField}
          type="textarea"
        />

        <span className="pull-right">{`${words} words`}</span>
      </Col>
    );
  };

  // const renderVideoChatRate = () => (
  //   <Field
  //     type="number"
  //     // validate={[maxValue300, minValue100, noDecimalValue]}
  //     name="videoChatRate"
  //     component={renderField}
  //     disabled="disabled"
  //   />
  // );

  // const renderVoiceChatRate = () => (
  //   <Field
  //     type="number"
  //     // validate={[maxValue200, minValue50, noDecimalValue]}
  //     name="voiceChatRate"
  //     component={renderField}
  //     disabled="disabled"
  //   />
  // );

  const renderModalForUpdate = () => (
    <CustomModal
      header="Media was submitted"
      body="If approved, changes will appear within 72 hours"
      buttonText="OK"
      // footer="CANCEL"
      // footerLink={() => setAfterUpdateProfilePicture(false)}
      open={isUpdateModalOpen}
      // onClick={resetBackgroundPicPanel}
      toggle={() => setUpdateModalOpen(false)}
    />
  );

  const renderModalForVideoChatRate = () => (
    <VideoChatRateModal
      open={isVideoChatRateModalOpen}
      toggle={() => setIsVideoChatRateModalOpen(false)}
      updateProfile={updateProfile}
      charId={charId}
      videoChatRateRes={videoChatRateRes}
      takeFieldValueFun={takeVideoModalFieldValueFun}
    />
  );

  const renderModalForVoiceChatRate = () => (
    <VoiceChatRateModal
      open={isVoiceChatRateModalOpen}
      toggle={() => setIsVoiceChatRateModalOpen(false)}
      updateProfile={updateProfile}
      charId={charId}
      voiceChatRateRes={voiceChatRateRes}
      takeFieldValueFun={takeVoiceModalFieldValueFun}
    />
  );

  return (
    <>
      <ToastContainer pauseOnFocusLoss={false} containerId="paymentDetails" />
      <Form className="mt-3">
        {renderModalForUpdate()}
        {renderModalForVideoChatRate()}
        {renderModalForVoiceChatRate()}
        <Card className="profile bg-none profile-wrap">
          <CardHeader tag="h6" className="p-0 border-bottom-0">
            <Nav tabs fill>
              <NavItem>
                <NavLink active={activeTab === 'profile-settings'} onClick={() => setActiveTab('profile-settings')}>
                  Profile Settings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={activeTab === 'edit-info'} onClick={() => setActiveTab('edit-info')}>
                  Edit Info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={activeTab === 'payout-settings'} onClick={() => setActiveTab('payout-settings')}>
                  Bank Details
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="profile-settings">
                <CardBody className="p-0">
                  <Row>
                    <Col md={12}>
                      <ImagePanel
                        backgroundPic={characters.length > 0 && characters[0]
                        && characters[0].background_pic_url}
                        primaryPic={
                        characters.length > 0 && characters[0] && characters[0].primary_pic_url
                      }
                        updateProfile={updateProfile}
                        charId={charId}
                        displayName={displayName}
                        reset={reset}
                        error={errors.updateChar}
                        formData={formData}
                        isCharacterLoading={isCharacterLoading}
                      />
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col md={9}>
                      <Row className="row_detail">
                        <Col xs={4}>
                          <Label htmlFor="displayName">
                            Display Name
                          </Label>
                          <Input id="displayName" value={displayName} readOnly style={{ opacity: '0.6' }} />
                        </Col>
                        <Col xs={4}>
                          <Label htmlFor="headline">
                            Video Chat Rate (Credits/Min)
                          </Label>
                          <div className="chatRateContainer">
                            {/* {renderVideoChatRate()} */}
                            {videoModalFormValue
                              ? <span>{videoModalFormValue}</span>
                              : <span>{videoChatRateRes}</span>
                          }
                            {/* <Input id="displayName" value={modalFormValue ?
                            modalFormValue : videoChatRateRes} readOnly
                            style={{ opacity: '0.6' }} /> */}
                            <img src={inputEditIcon} alt="" role="presentation" onClick={openVideoChatRateModalHandler} />
                          </div>
                        </Col>
                        <Col xs={4}>
                          <Label htmlFor="headline">
                            Voice Call Rate (Credits/Min)
                          </Label>
                          <div className="chatRateContainer">
                            {/* {renderVoiceChatRate()} */}
                            {voiceModalFormValue
                              ? <span>{voiceModalFormValue}</span>
                              : <span>{voiceChatRateRes}</span>
                          }
                            {/* <Input id="displayName" value={voiceChatRateRes}
                          readOnly style={{ opacity: '0.6' }} /> */}
                            <img src={inputEditIcon} alt="" role="presentation" onClick={openVoiceChatRateModalHandler} />
                          </div>
                        </Col>
                      </Row>
                      <Row className="row_detail">
                        <Col>
                          <Label htmlFor="headline">
                            Headline
                          </Label>
                          {renderHeadline()}
                        </Col>
                      </Row>
                      <Row className="row_detail">
                        <Col>
                          <Label htmlFor="headline">
                            Description
                          </Label>
                          <Row>
                            {renderDescription()}
                          </Row>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col xs={4}>
                          <Button color="primary" className="updateBtn px-5" onClick={handleUpdateProfile} disabled={isUpdateButtonDisabled}>Update</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </TabPane>
              <TabPane tabId="edit-info">
                <EditInfoContainer />
              </TabPane>
              <TabPane tabId="payout-settings">
                {(activeTab === 'payout-settings' && <PayoutSettingsContainer />)}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Form>
    </>
  );
};

Profile.defaultProps = {
  profile: {
    headline: 'N/A',
    description: 'N/A',
    videoChatRate: 0,
    voiceChatRate: 0,
  },
  formData: {
    primaryPicUrl: '',
    backgroundPicUrl: '',
  },
  isCharacterLoading: false,
  errors: {
    updateChar: false,
    // headline: '',
  },
};

Profile.propTypes = {
  profile: PropTypes.shape({
    headline: PropTypes.string,
    description: PropTypes.string,
    videoChatRate: PropTypes.number,
    voiceChatRate: PropTypes.number,
  }),
  formData: PropTypes.shape({
    primaryPicUrl: PropTypes.string,
    backgroundPicUrl: PropTypes.string,
  }),
  reset: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  isCharacterLoading: PropTypes.bool,
  errors: PropTypes.shape({
    updateChar: PropTypes.bool,
    // headline: PropTypes.string,
  }),
  characters: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  getPerfCharacter: PropTypes.func.isRequired,
  getPayoutDetails: PropTypes.func.isRequired,
};

export default Profile;
