import api from './api';

const setSmsNotifOff = data => ({
  type: 'SET_SMS_NOTIF_OFF',
  payload: api.post('/cstcontactflags/create/', data),
});

export const setSmsNotifOn = data => ({
  type: 'SET_SMS_NOTIF_ON',
  payload: api.put('/cstcontactflags/update/', data),
});

export default setSmsNotifOff;
