/* eslint-disable camelcase */
import { connect } from 'react-redux';
import moment from 'moment';
import { sortBy } from 'lodash';

import Dashboard from '../scenes/Dashboard';
import getAllCharacters from '../actions/actionGetAllCharacters';
import getSystemMessages from '../actions/actionGetSystemMessages';
import getPerformer from '../actions/actionGetPerformer';
import getReviews from '../actions/actionGetReviews';
import getSysConfigAction from '../actions/actionGetSysConfig';
import setChatNotificationPermission from '../actions/actionSetChatNotificcationPermission';


const getPurchaseData = ({ recent_photo_purchases, recent_video_purchases }) => ({
  photos: recent_photo_purchases,
  videos: recent_video_purchases,
});

const getInteractionsData = (characterD) => {
  const {
    recent_character_favorite_count,
    recent_character_favorite_change,
    recent_photo_favorite_count,
    recent_photo_favorite_change,
    recent_photo_like_count,
    recent_photo_like_change,
    recent_video_favorite_count,
    recent_video_favorite_change,
    recent_video_like_count,
    recent_video_like_change,
  } = characterD;

  return ({
    profile: {
      favorite: recent_character_favorite_count,
      favoriteRate: recent_character_favorite_change,
    },
    photos: {
      favorite: recent_photo_favorite_count,
      favoriteRate: recent_photo_favorite_change,
      liked: recent_photo_like_count,
      likedRate: recent_photo_like_change,
    },
    videos: {
      favorite: recent_video_favorite_count,
      favoriteRate: recent_video_favorite_change,
      liked: recent_video_like_count,
      likedRate: recent_video_like_change,
    },
  });
};

const getBodyHeaderData = (characterD, performerD) => ({
  ranking: characterD.ranking,
  rating: characterD.stars,
  responseTime: performerD.week_avg_response_time,
  avgSessionLength: performerD.week_avg,
  session: performerD.performer_weekly_session_count,
});

const getMessages = (systMessages) => {
  const messages = [];
  systMessages.forEach((msg) => {
    messages.push({
      id: msg.objid,
      isurgent: msg.isurgent === 1,
      subject: msg.message_subject,
      arrivalTime: moment(msg.create_datetime).fromNow(),
      messageContent: msg.message_content,
    });
  });
  return messages;
};

const getMyReviews = (reviews) => {
  const myReviews = [];
  sortBy(reviews, r => r.create_datetime).reverse().forEach((review) => {
    myReviews.push({
      id: review.objid,
      rating: parseInt(review.rating, 10),
      comment: review.comment,
      name: review.customer_screen_name,
      profilePic: review.customer_profile_pic,
      isPremiumCustomer: review.is_premium_customer,
      isCustomerOnline: review.is_customer_online,
      sendEmailNotification: review.send_email_notification,
    });
  });
  return myReviews;
};

const getRankingsData = (charactersList) => {
  const rankings = [];
  charactersList.forEach((character) => {
    rankings.push({
      id: character.objid,
      avatar: character.primary_pic_url,
      name: character.display_name,
      rating: character.stars,
      ranking: character.ranking,
    });
  });
  return rankings;
};

export const mapStateToProps = (state) => {
  const {
    allCharacters = {},
    systemMessages = {},
    reviews = {},
    login: { data: { perfcharacter = [], perfperformer = {} } = {}, isLoading } = {},
    performer: { data: { characters = {} } = {} } = {},
  } = state;

  let characterData = {};
  if (characters.length > 0) {
    const [data] = characters;
    characterData = data;
  }
  let characterId = -1;
  if (perfcharacter.length) {
    characterId = perfcharacter[0].objid;
  }

  const loadingData = (
    systemMessages.isLoading || reviews.isLoading || isLoading
  );

  let systemMessagesData = [];
  let allCharactersData = [];
  let unReadSystemMessageCount = 0;
  if (systemMessages.data) {
    systemMessagesData = systemMessages.data.results;
    unReadSystemMessageCount = systemMessages?.data?.unread_count || 0;
  }

  if (allCharacters.data) {
    allCharactersData = allCharacters.data.results;
  }
  let headerData = {};
  if (characters.length > 0) {
    headerData = getBodyHeaderData(characters[0], perfperformer);
  }

  return ({
    systemMessages: getMessages(systemMessagesData),
    rankingsData: getRankingsData(allCharactersData),
    reviews: getMyReviews(reviews.data),
    purchaseData: getPurchaseData(characterData),
    interactionsData: getInteractionsData(characterData),
    unReadSystemMessageCount,
    characterId,
    performerId: perfperformer.objid,
    perf2cstcontactId: perfperformer.perf2cst_contact,
    headerData,
    loadingData,
  });
};

export const mapDispatchToProps = dispatch => ({
  getMyReviews: id => dispatch(getReviews(id)),
  getMessages: id => dispatch(getSystemMessages(id)),
  getAllCharacters: () => dispatch(getAllCharacters()),
  getPerformer: objectId => dispatch(getPerformer(objectId)),
  getSysConfig: url => dispatch(getSysConfigAction(url)),
  setChatNotificationPermission: data => dispatch(setChatNotificationPermission(data)),
});

const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);

export default DashboardContainer;
