import Cookies from 'universal-cookie';

export const setCookies = (name, value, options = {}) => {
  const cookies = new Cookies();
  const updatedOptions = {
    ...options,
    path: '/',
  };
  cookies.set(name, value, updatedOptions);
};

export const getCookies = (name) => {
  const cookies = new Cookies();
  return cookies.get(name);
};

export const removeCookies = (name, options = {}) => {
  const cookies = new Cookies();
  const updatedOptions = {
    ...options,
    path: '/',
  };
  cookies.remove(name, updatedOptions);
};
