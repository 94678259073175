import { connect } from 'react-redux';
import MyInfo from '../scenes/MyInfo';

const mapStateToProps = (state) => {
  const {
    login: { data: { perfcharacter = [] } = {} } = {},

  } = state;

  const perfObjid = (perfcharacter.length && perfcharacter[0].objid) || 0;

  return {
    perfObjid,
  };
};

const mapDispatchToProps = () => ({
//   getPhonebookData: data => dispatch(getPhonebookData(data)),
});

const MyInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyInfo);

export default MyInfoContainer;
