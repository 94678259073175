import React from 'react';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import PropTypes from 'prop-types';

import './VideoModal.scss';

const VideoModal = (props) => {
  const {
    className,
    open,
    toggle,
    videoId,
  } = props;

  // const handleClick = () => {
  //   onClick();
  //   toggle();
  // };


  return (
    <Modal
      className={`video-Modal ${className}`}
      isOpen={open}
      toggle={toggle}
      backdrop={false}
    >
      <ModalHeader className="chat-modal-header" toggle={toggle} />
      <ModalBody className="chat-modal-body">
        <div className="text-center">
          <iframe
            src={`https://iframe.videodelivery.net/${videoId}`}
            title={`video_${videoId}`}
            height="365px"
            width="365px"
            allowFullScreen
            // eslint-disable-next-line react/no-unknown-property
            controls="0"
            autoPlay="0"
            frameBorder="0"
            scrolling="no"
          />
          {/* <h4>{header}</h4>
          <p>
            {body}
          </p>
          {(isRadioButton || isInputFeild) && htmlElements}
          <button type="button" onClick={handleClick} className="btn">{buttonText}</button> */}
        </div>
      </ModalBody>
      {/* <ModalFooter className="chat-modal-footer">
        <p role="presentation" onClick={footerLink}>{footer}</p>
      </ModalFooter> */}
    </Modal>
  );
};

VideoModal.defaultProps = {
  className: '',
  open: false,
  videoId: '',
};

VideoModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  videoId: PropTypes.string,
};

export default VideoModal;
