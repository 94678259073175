import React from 'react';
import {
  Card, CardBody,
} from 'reactstrap';
import TestVideo from './components/TestVideo';
import TestAudio from './components/TestAudio';
import TestNotification from './components/TestNotification';
import './style.scss';
import AccessGuide from './components/TestSetupGuide';

const TestSetup = () => (
  <>
    <h2 className="mt-3"><span className="headTitle"> Test Setup </span></h2>
    <Card className="test-setup-wrap bg-dark">
      <CardBody>
        <TestVideo />
        <TestAudio />
        <TestNotification />
        <AccessGuide />
      </CardBody>
    </Card>
  </>
);

export default TestSetup;
