import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import RefundConfirmationModal from '../RefundConfirmationModal';

const BalanceRefund = ({
  isRefunded, objid, handleRefundClick,
}) => {
  const [confirmationModal, setShowConfirmationModal] = useState(false);
  const renderConfirmationModal = () => (
    <RefundConfirmationModal
      objid={objid}
      header="Are you sure?"
        // buttonText="OK"
      open={confirmationModal}
      onClick={handleRefundClick}
      deleteId={objid}
      toggle={() => setShowConfirmationModal(false)}
    />
  );


  return (
    <>
      {renderConfirmationModal()}
      { isRefunded
        ? (
          <span>
            Refunded
          </span>
        ) : (
          <span className="link" role="presentation" onClick={() => setShowConfirmationModal(true)}>
            Refund
          </span>
        )}
    </>
  );
};

BalanceRefund.defaultProps = {
  isRefunded: true,
  objid: {},
};

BalanceRefund.propTypes = {
  isRefunded: PropTypes.bool,
  // eslint-disable-next-line
  objid: PropTypes.object,
  handleRefundClick: PropTypes.func.isRequired,
};

export default BalanceRefund;
