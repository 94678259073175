import React from 'react';

export default fill => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M7.29175 14.6767V18.5417C7.29175 18.8117 7.46508 19.0508 7.72175 19.1358C7.78591 19.1567 7.85175 19.1667 7.91675 19.1667C8.11175 19.1667 8.30008 19.075 8.42008 18.9117L10.6809 15.835L7.29175 14.6767Z" fill={fill} />
      <path d="M19.7371 0.115022C19.5454 -0.0208112 19.2937 -0.0391445 19.0854 0.0700222L0.335382 9.86169C0.113715 9.97752 -0.0171179 10.2142 0.00121545 10.4634C0.0203821 10.7134 0.186216 10.9267 0.422049 11.0075L5.63455 12.7892L16.7354 3.29752L8.14538 13.6467L16.8812 16.6325C16.9462 16.6542 17.0146 16.6659 17.0829 16.6659C17.1962 16.6659 17.3087 16.635 17.4079 16.575C17.5662 16.4784 17.6737 16.3159 17.7012 16.1334L19.9929 0.716689C20.0271 0.483356 19.9287 0.251689 19.7371 0.115022Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill={fill} />
      </clipPath>
    </defs>
  </svg>


);
