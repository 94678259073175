/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import {
  Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, ButtonGroup,
} from 'reactstrap';
import PropTypes from 'prop-types';
import './style.scss';
import inputEditIcon from '../../../../assets/images/inputEditIcon.svg';

const ChatMediaShareOptionModal = (props) => {
  const ref = useRef('');
  const { isOpen, closeChatMediaShareModal, selectedChatMedia, sendMessage, minImagePurchaseCredits,
  minVideoPurchaseCredits, performerMediaPrices = {} } = props;

  const [modal, setModal] = useState(false);
  // const [defaultImageCredits, setDefaultImageCredits] = useState(minImagePurchaseCredits);
  // const [defaultVideoCredits, setDefaultVideoCredits] = useState(minVideoPurchaseCredits);  

  const [imageCredits, setImageCredits] = useState(performerMediaPrices.chatImagePrice);
  const [videoCredits, setVideoCredits] = useState(performerMediaPrices.chatVideoPrice);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [creditToUSD, setCreditToUSD] = useState('');
  const [isCheckedDefault, setisCheckedDefault] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  const toggle = () => {
    closeChatMediaShareModal();
    setModal(!modal);
  };

  useEffect(() => {
    setModal(isOpen);
  }, []);


  useEffect(() => {
    const photoDefaultPriceLocal = JSON.parse(localStorage.getItem('photoDefaultPrice'));
    const videoDefaultPriceLocal = JSON.parse(localStorage.getItem('videoDefaultPrice'));

    if (photoDefaultPriceLocal) {
      setImageCredits(photoDefaultPriceLocal);
    }
    if (videoDefaultPriceLocal) {
      setVideoCredits(videoDefaultPriceLocal);
    }
  }, [isCheckedDefault]);

  useEffect(() => {
    if (selectedChatMedia.type === "chat_video") {
      setCreditToUSD((videoCredits * 0.05).toFixed(2));
      
    } else {
      setCreditToUSD((imageCredits * 0.05).toFixed(2));
    }
  }, [imageCredits, videoCredits]);

  const blockInvalidChar = e => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();

  const handleInputDisabled = () => {
    setIsInputDisabled(false);
    if (isCheckedDefault) {
      setisCheckedDefault(!isCheckedDefault);
    }
  }

  const handleChange = (e) => {
    let isChecked = e.target.checked;
    setisCheckedDefault(isChecked);
    setIsInputDisabled(!isInputDisabled);
    if (isChecked) {
      if (selectedChatMedia.type === "chat_video") {
        localStorage.setItem('videoDefaultPrice', JSON.stringify(videoCredits));
      } else {
        localStorage.setItem('photoDefaultPrice', JSON.stringify(imageCredits));
      }
    }
  }

  const onChangeImageHandler = (e) => {
    if (isNaN(e.target.value)) {
      setError(true);
      setErrorMsg("Please enter numbers only");
      return false;
    }
    if (e.target.value.charAt(0) === '0') {
      setError(true);
      setErrorMsg("First digit can't be 0");
      return false;
    }
    setImageCredits(e.target.value);
    let maxNum = 4;
    if (e.target.value.length > maxNum) {
      setImageCredits(e.target.value.slice(0, maxNum));
      setError(true);
      setErrorMsg("Credit Must be less then 9999!");
      setTimeout(() => {
        setError(false);
      }, 2000);
    } else {
      setError(false);
    }
  }

  const onChangeVideoHandler = (e) => {
    if (isNaN(e.target.value)) {
      setError(true);
      setErrorMsg("Please enter numbers only");
      return false;
    }
    if (e.target.value.charAt(0) === '0') {
      setError(true);
      setErrorMsg("First digit can't be 0");
      return false;
    }
    setVideoCredits(e.target.value);
    let maxNum = 4;
    if (e.target.value.length > maxNum) {
      setVideoCredits(e.target.value.slice(0, maxNum));
      setError(true);
      setErrorMsg("Credit Must be less then 9999!");
      setTimeout(() => {
        setError(false);
      }, 2000);
    } else {
      setError(false);
    }
  }

  const shareChatMedia = () => {
    if (selectedChatMedia.type === "chat_image") {
      if (!imageCredits) {
        setError(true);
        setErrorMsg("Field can't be empty!");
        return;
      }
      if (imageCredits < Number(minImagePurchaseCredits)) {
        setError(true);
        setErrorMsg(`Credit Must be ${minImagePurchaseCredits} or Greater!`);
        return;
      }
      if (imageCredits > 9999) {
        setError(true);
        setErrorMsg("Credit Must be less then 9999!");
        return;
      }
    }

    if (selectedChatMedia.type === "chat_video") {
      if (!videoCredits) {
        setError(true);
        setErrorMsg("Field can't be empty!");
        return;
      }
      if (videoCredits < Number(minVideoPurchaseCredits)) {
        setError(true);
        setErrorMsg(`Credit Must be ${minVideoPurchaseCredits} or Greater!`);
        return;
      }
      if (videoCredits > 9999) {
        setError(true);
        setErrorMsg("Credit Must be less then 9999!");
        return;
      }
    }
    const updatedChatMediaObj = {
      text: '',
      media: selectedChatMedia.media,
      productId: selectedChatMedia.productObjId,
      mediaType: selectedChatMedia.mediaType,
      videoId: selectedChatMedia.videoId,
      creditValue: selectedChatMedia.type === "chat_video" ? videoCredits : imageCredits,
      defaultCreditsBool: isCheckedDefault,
    }
    sendMessage(updatedChatMediaObj);
    closeChatMediaShareModal();
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="sharePrivateMedia-modal" data="chat-media-share-modal">
        <ModalHeader className="chat-modal-header camHeader" toggle={toggle}>Share</ModalHeader>
        <ModalBody className="chat-modal-body paid-pic-modal">
          <div className='image-wrap'>
            {selectedChatMedia.type === "chat_image" ? (
              <img src={selectedChatMedia.photoUrl}/>
            ) : (
              <iframe
                src={`https://iframe.videodelivery.net/${selectedChatMedia.videoId}`}
                title={`video_${selectedChatMedia.videoId}`}
                height="300"
                width="300"
                allowFullScreen
              />
            )
            }
          </div>
          
          <Row>
            <Col xs={12} className="mt-2">
              <label>Enter Credits</label>
              <div className="input-edit-icon-wrap">
              {selectedChatMedia.type === "chat_video" ? 
                (<input 
                  id="creditsLabel"
                  name="creditInput"
                  type="text" 
                  className="form-control credit-field" 
                  value={videoCredits}
                  onChange={onChangeVideoHandler}
                  onKeyDown={blockInvalidChar}
                  placeholder="Enter Amount (Credits)"
                  disabled={isInputDisabled}
                  // disabled={!toggleSwitch}
                />
                ) : (
                  <input 
                    id="creditsLabel"
                    name="creditInput"
                    type="text" 
                    className="form-control credit-field" 
                    value={imageCredits}
                    onChange={onChangeImageHandler}
                    onKeyDown={blockInvalidChar}
                    placeholder="Enter Amount (Credits)"
                    disabled={isInputDisabled}
                    // disabled={!toggleSwitch}
                  />
                )            
               }
                <img src= {inputEditIcon} alt="" onClick={handleInputDisabled}/>
              </div>
              { error && <span style={{color: 'red', marginTop: '5px', display: 'block', fontSize: '14px'}}>{errorMsg}</span> }

              <div className="d-flex justify-content-between mt-2">
                <div className="checkbox-container">
                  <input type="checkbox"
                    id="default-credits"
                    name="defaultCredits"
                    value="" 
                    checked={isCheckedDefault}
                    onChange={e => handleChange(e)}
                  />
                  <label htmlFor="default-credits">Save as default rate for {selectedChatMedia.type === "chat_video" ? 'video' : 'image' ? 'photo' : ''} </label>
                </div>
                <div className="cost-to-customer">
                  <span>Cost to customer</span>
                  <span className="credit-usd">{creditToUSD} USD</span>
                </div>
              </div>
            </Col>
          </Row>
          <ButtonGroup>
            <Button className="ml-2 modal-btn" onClick={shareChatMedia}>Share</Button>
          </ButtonGroup>
        </ModalBody>
        <ModalFooter className="chat-modal-footer camFooter" />
      </Modal>
    </div>
  );
}

ChatMediaShareOptionModal.defaultProps = {
  isOpen: false,
};

ChatMediaShareOptionModal.propTypes = {
  isOpen: PropTypes.bool,
  closeChatMediaShareModal: PropTypes.func.isRequired,
};

export default ChatMediaShareOptionModal;
