import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaStar } from 'react-icons/fa';
import './style.scss';

const CustomerReviews = (props) => {
  const { newRatingHandlerFun, value } = props;
  const [hover, setHover] = useState();
  const [click, setClick] = useState();
  const [val, setVal] = useState();

  useEffect(() => {
    setVal(value);
    setClick(value);
  }, [value]);

  const handleClick = (rate) => {
    setVal(rate);
    setClick(rate);
  };

  const handleMouseHover = (rate) => {
    setHover(rate);
    setVal(0);
  };

  const handleMouseLeave = () => {
    setHover(0);
    setVal(click);
  };

  return (
    <div>
      {new Array(5).fill(undefined).map((ele, index) => (
        <FaStar
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onClick={() => {
            handleClick(index + 1);
            newRatingHandlerFun(index + 1);
          }}
          onMouseEnter={() => handleMouseHover(index + 1)}
          onMouseLeave={() => handleMouseLeave()}
          className={
            index + 1 <= hover || index + 1 <= val ? 'yellowStar' : 'greyStar'
          }
        />
      ))}
    </div>
  );
};

CustomerReviews.defaultProps = {
  value: 0,
};

CustomerReviews.propTypes = {
  newRatingHandlerFun: PropTypes.func.isRequired,
  value: PropTypes.number,
};

export default CustomerReviews;
