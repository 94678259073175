/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {
  Card, CardBody,
  CardHeader, Input,
} from 'reactstrap';
import NoDataAvailable from '../../../../components/NoDataAvailable';
// import InfiniteScroll from 'react-infinite-scroll-component';

// import { values } from 'lodash';
// eslint-disable-next-line
import Customer from './customer';
import './style.scss';
import LoadingSpinner from '../../../../utils/LoadingSpinner';
import InfiniteScroll from 'react-infinite-scroll-component';
// import chat from '../../../../assets/svgs/message';


const CustomerList = (props) => {
  const {
    setCustomerListOrder,
    conv, getCustomerChatLog,
    charObjid,
    onChatRoomHandler,
    loadingListApi,
    chatLogsLoading,
    activeUser,
    nextChatLog,
    hasMoreCustomers,
    onlineStatusHandler,
    updatedOnlineStatus,
  } = props;
  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    const list = conv && conv.map((session) => {
      if (session.custId === activeUser?.custId) {
        return (
          <Customer
            key={activeUser.id}
            title={activeUser.title || session?.title}
            online={false}
            lastmessage={activeUser?.lastMsg}
            lastMessageTime={activeUser.lastMessageTime}
            showUnread={0}
            handleCustomerList={() => setCustomerListOrder({
              custId: activeUser.custId,
              session: activeUser.id,
              objid: activeUser.objid,
            })}
            custPic={activeUser.custPic}
            // eslint-disable-next-line no-undef
            activeUser={activeUser?.iscurrentlyActiveCust}
            onChatRoomHandler={onChatRoomHandler}
            isPremiumCustomer={activeUser.isPremiumCustomer}
            isCustomerOnline={onlineStatusHandler(activeUser)}
            sendEmailNotification={activeUser.sendEmailNotification}
          />
        );
      }
      return (
        <Customer
          key={session.id}
          title={session.title}
          online={false}
          lastmessage={session.lastMsg}
          lastMessageTime={session.lastMessageTime}
          showUnread={session.unread}
          handleCustomerList={() => setCustomerListOrder({
            custId: session.custId,
            sessionId: session?.id,
            objid: session?.objid,
          })}
          custPic={session.custPic}
          // eslint-disable-next-line no-undef
          activeUser={session.iscurrentlyActiveCust}
          onChatRoomHandler={onChatRoomHandler}
          isPremiumCustomer={session.isPremiumCustomer}
          isCustomerOnline={onlineStatusHandler(session)}
          sendEmailNotification={session.sendEmailNotification}
        />
      );
    });
    setCustomerList(list);
    // eslint-disable-next-line no-undef
  }, [conv, updatedOnlineStatus, activeUser]);

  // useEffect(() => {
  //   if (searchInput) {
  //     const searchedConv = customerList
  //       ?.filter(session => session?.title?.search(searchInput) > -1);
  //     setConversations(searchedConv);
  //   } else {
  //     setConversations(customerList);
  //   }
  // }, [searchInput]);

  const debouncedHandle = useCallback(
    lodash.debounce(url => getCustomerChatLog(url, conv), 1000, []),
  );

  const handleCustSearch = (event) => {
    const searchText = event?.target?.value;
    const searchTextToLower = searchText.toLowerCase();
    const url = `/cfgxmppsessions/list/?character_objid=${charObjid}&phonebook=true&customer_screen_name_filter=${searchTextToLower}&cust2char__gt=-1&exclude_msg_paths=SESSION_CMD|Sys2Cust`;
    debouncedHandle(url);
  };

  return (
    <Card className="ChatSection ChatSectionCustList bg-dark border-0 mr-3 h-100">
      <CardHeader className="CustomerListHeader border-0 mt-0 pb-0">
        <Input
          type="text"
          placeholder="Search"
          className="customerSearch"
          onChange={event => handleCustSearch(event)}
        />
      </CardHeader>
      <CardBody className="px-0">
        <div style={{ position: 'relative' }}>
          <InfiniteScroll
            dataLength={customerList.length}
            next={nextChatLog}
            hasMore={hasMoreCustomers}
            loader={<div className="ar-chatLoader-absolute"><LoadingSpinner /></div>}
            scrollThreshold={0.9}
            height="calc(100vh - 225px)"
            style={{ overflowX: 'hidden', overflowY: 'scroll' }}
            endMessage={(
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            )}
          >
            {customerList}
          </InfiniteScroll>
        </div>

        {/* {loadingListApi || chatLogsLoading ? <LoadingSpinner /> : (
          <>
            {conv.length ? customerList : <NoDataAvailable />}
          </>
        )} */}
      </CardBody>
    </Card>
  );
};

CustomerList.defaultProps = {
  conv: [],
  loadingListApi: false,
  // chatSessionData: [],
};

CustomerList.propTypes = {
  setCustomerListOrder: PropTypes.func.isRequired,
  getCustomerChatLog: PropTypes.func.isRequired,
  charObjid: PropTypes.number.isRequired,
  loadingListApi: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  conv: PropTypes.array,
  onChatRoomHandler: PropTypes.func.isRequired,
  // chatSessionData: PropTypes.instanceOf(Array),
};

export default CustomerList;
