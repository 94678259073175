const playSound = (url) => {
  const audio = new Audio(url);
  audio.play()
    .then(() => {
      console.log('sound play');
    })
    .catch(err => console.log(err));
};

export default playSound;
