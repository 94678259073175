/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import './style.scss';
import { AiOutlineWarning } from 'react-icons/ai';

const AlreadySharedMediaModal = (props) => {
  const { isOpen, isSelectAlreadySharedMedia, yeshareMedia, closeAlredaySharedModalPopup } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => {
    closeAlredaySharedModalPopup();
    setModal(!modal);
  };

  useEffect(() => {
    setModal(isOpen);
  }, []);

  const sharedMedia = () => {
    yeshareMedia(isSelectAlreadySharedMedia);
  };
  const closeModal = () => {
    closeAlredaySharedModalPopup();
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="EditInfo-Modal delete-number-modal RefundConfirmationModal" data="chat-media-share-modal">
        <ModalHeader className="chat-modal-header camHeader" toggle={toggle} />
        <ModalBody className="chat-modal-body paid-pic-modal">
          <div>
            <AiOutlineWarning style={{
              width: '50px',
              height: '50px',
              color: 'var(--primary)',
            }}
            />
          </div>
          <h4>
            You already shared same media with this customer.
          </h4>
          <p>Do you want to share it again?</p>
          <div className="d-flex">
            <Button
                type="submit"
                color="primary"
                className="btn btn-primary mr-2"
                onClick={sharedMedia}
            >
                Yes
            </Button>
            <Button
                type="submit"
                color="primary"
                className="btn btn-primary"
                onClick={closeModal}
            >
                No
            </Button>
            </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

AlreadySharedMediaModal.defaultProps = {
  isOpen: false,
};

AlreadySharedMediaModal.propTypes = {
  isOpen: PropTypes.bool,
};

export default AlreadySharedMediaModal;
