import React from 'react';
import Proptypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';

const Spinner = ({ fullscreen }) => (
  <>
    {
      fullscreen
        ? (
          <div className="text-center loaderClass loadingSpinner">
            <ClipLoader
              sizeUnit="px"
              size={65}
              color="#f07563"
              loading
            />
          </div>
        )
        : (
          <div className="text-center">
            <ClipLoader
              sizeUnit="px"
              size={65}
              color="#f07563"
              loading
            />
          </div>
        )
    }
  </>
);

Spinner.defaultProps = {
  fullscreen: false,
};

Spinner.propTypes = {
  fullscreen: Proptypes.bool,
};

export default Spinner;
