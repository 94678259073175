/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import {
  Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, ButtonGroup,
  FormGroup,
  Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import './style.scss';
import inputEditIcon from '../../../../assets/images/inputEditIcon.svg';
import axios from '../../../../actions/api'
import { useSelector } from 'react-redux';
import whispererBot from '../../../../assets/gif/chat_bot.gif';
import whispererRefresh from '../../../../assets/images/refresh.png';
import WarnIcon from '../../../../assets/svgs/WarnIcon.svg';

let timeoutId;

const WhispererModal = (props) => {
  const ref = useRef('');
  const { isOpen, activeUser } = props;
  const [modal, setModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [botMsg, setBotMsg] = useState('');
  const [chatBotMsgID, setChatBotMsgID] = useState('');
  const [placeHolderMsg, setPlaceHolderMsg] = useState('Whisperer is thinking...');
  const [timeOutInSeconds, setTimeOutInSeconds] = useState(30000);
  const textareaRef = useRef(null);
  const botSuggestedMsg = useSelector((state) => state.whisperer);
  const WhispererTimeOut = useSelector((state) => state.sysConfig?.data?.results);

useEffect(() => {
  const TextArea = document.getElementById(`noTextArea`);
  if (botSuggestedMsg) {
    if (botSuggestedMsg?.msg !== 'Whisperer is thinking...' || botSuggestedMsg?.msg !== '') {
      clearTimeout(timeoutId);
    }
    if (botSuggestedMsg?.msg?.includes('Looks like customer message might have some keywords that are prohibited on Arousr')) {
      setIsError(true);
      setBotMsg(botSuggestedMsg.msg);
    } else {
      setIsError(false);
    }

    
    setBotMsg(botSuggestedMsg.msg);
  }

  if (TextArea) {
    TextArea.style.height = "auto";
    TextArea.style.height = TextArea.scrollHeight + "px";
  }
}, [botSuggestedMsg]);

useEffect(() => {
  if (WhispererTimeOut?.length) {
   let timeOutInSecs = WhispererTimeOut.find((el) => el.config_name === 'WHISPERER_TIMEOUT');
   if (timeOutInSecs !== -1) {
    setTimeOutInSeconds(Number(timeOutInSecs.value) * 1000)
   }
   
  }
}, [WhispererTimeOut]);

  useEffect(() => {
    setModal(true);
  }, []);

  const regenerateHandler = async (msgAcceptedByHost=false, regenerateRequested=false, chatbotMsg) => {
    try {
      setIsError(false);
      if (chatbotMsg) {
        const formData = new FormData();
        if (activeUser && activeUser.messages && activeUser.messages.length) {
          const lastMsgID = activeUser.messages[activeUser.messages.length - 1].objid;
          formData.append('chatbotmsg_id ', chatbotMsg);
          formData.append('suggested_message_accept', msgAcceptedByHost);
          formData.append('resuggest', regenerateRequested);
          if (regenerateRequested) {
            setBotMsg("");
            const apiRequest = await axios.post('/whispererchataction/', formData);
          // promise that resolves after 30 seconds
       // Set up timeout promise
      //  let timeoutId;
          const timeoutPromise = new Promise((resolve, reject) => {
             timeoutId = setTimeout(() => {
              reject(new Error('Request timed out'));
            }, timeOutInSeconds); // 30 seconds

          });

        const BotMsgUpdated = new Promise((resolve, reject) => {
          setTimeout(() => {
            clearTimeout(timeoutId);
            resolve();
          }, 32000)
          
        });
  
        const resp = await Promise.race([BotMsgUpdated, timeoutPromise]);
        if (apiRequest.status === 200) {
          setPlaceHolderMsg('Whisperer is thinking...')
        }
          } else {
            const resp = await axios.post('/whispererchataction/', formData);
          }
        }
      }
    } catch (error){
        // Handle errors
        setIsError(true);
        console.log(error);
        if (error.message === 'Request timed out') {
          setBotMsg('Whisperer is currently experiencing high traffic. Please try again.');
        } else {
          setBotMsg(`We're having trouble connecting to Whisperer right now. Please try again`)
        }
    }
  };
  

  const toggle = () => {
    setModal(!modal);
    if (chatBotMsgID) {
      regenerateHandler('False', 'False', chatBotMsgID)
    }
    props.setWhispererModal(false);
    setBotMsg("");
  };

  // const WhispererHandler = async () => {
  //   try {
  //     const formData = new FormData();
  //     if (activeUser && activeUser.messages && activeUser.messages.length) {
  //       const lastMsgID = activeUser.messages[activeUser.messages.length - 1].objid;
  //       formData.append('chatmsg_id', lastMsgID);
  //       const resp = await axios.post('/whispererchatsuggestion/', formData);
  //       if (resp.status === 200) {
  //         setPlaceHolderMsg('Whisperer is thinking...')
  //       }
  //     }
  //   } catch (error){
  //     console.log(error);
  //   }
  // }


  function getlastMsgID(messages) {
    const sortedMsgs = messages
    .sort((a, b) => new Date(a.currentUTC) - new Date(b.currentUTC));
    let lastCust2HostIndex = -1;
    let finalMsg = '';

    // eslint-disable-next-line no-plusplus
    for (let i = sortedMsgs.length - 1; i >= 0; i--) {
      const msg = sortedMsgs[i];

      if (msg.type === 'Cust2Char') {
        lastCust2HostIndex = i;
        finalMsg = msg;
        break;
      }
    }

    if (lastCust2HostIndex === -1) {
      return '';
    }

    // eslint-disable-next-line no-plusplus
    for (let i = lastCust2HostIndex + 1; i < sortedMsgs.length; i++) {
      const msg = sortedMsgs[i];

      if (msg.type === 'Char2Cust') {
        return '';
      } if (msg.type === 'Sys2Char') {
        // eslint-disable-next-line no-continue
        continue;
      } else if (msg.type === 'Cust2Char') {
        finalMsg = msg;
        return finalMsg;
      }
    }
    return finalMsg;
  }

  const WhispererHandler = async () => {
    try {
      setIsError(false);
      const formData = new FormData();
      if (activeUser && activeUser.messages && activeUser.messages.length) {
        const lastMsgID = getlastMsgID(activeUser.messages);
        if (!lastMsgID) {
          throw new Error('The Last Message is not sent by Customer.')
        }
        formData.append('chatmsg_id', lastMsgID?.objid);
        const apiRequest = await axios.post('/whispererchatsuggestion/', formData);
        if (apiRequest && apiRequest.status === 200) {
          setPlaceHolderMsg('Whisperer is thinking...');
          setChatBotMsgID(apiRequest?.data?.ai_bot_chat_msg_objid)
        }
        // let timeoutId;
        const timeoutPromise = new Promise((resolve, reject) => {
           timeoutId = setTimeout(() => {
            reject(new Error('Request timed out'));
          }, timeOutInSeconds); // 30 seconds

        });

      const BotMsgUpdated = new Promise((resolve, reject) => {
        setTimeout(() => {
          clearTimeout(timeoutId);
          resolve();
        }, 32000)
      });
  
        const resp = await Promise.race([BotMsgUpdated, timeoutPromise]);
      }
    } catch (error) {
      setIsError(true);
      // Handle errors
      console.log(error);
      if (error.message === 'Request timed out') {
        setBotMsg('Whisperer is currently experiencing high traffic. Please try again.');
      } else if (error.message === 'The Last Message is not sent by Customer.') {
        setBotMsg('The Last Message is not sent by Customer.');
      } 
      else {
        setBotMsg(`We're having trouble connecting to Whisperer. Please try again`)
      }
    }
  };
  

 // Prevent text selection
 const handleSelect = (event) => {
  event.preventDefault();
  textareaRef.current.selectionStart = textareaRef.current.selectionEnd;
}
  
  useEffect(() => {
    setBotMsg("");
    WhispererHandler();
  }, []);


  return (
    <div>
      <Modal backdrop={'static'} isOpen={modal} toggle={toggle} className="Whisperer-modal" data="public modal">
        <ModalHeader className="chat-modal-header camHeader p-0" toggle={toggle}></ModalHeader>
        <ModalBody className="chat-modal-body paid-pic-modal">
          <div className='image-wrap'>
            <img src={whispererBot} alt="Whisperer" />
            <span className='bot_bg_circle'></span>
          </div>
          <h3 className='modal-title'>WHISPERER</h3>
          <div className='whisperer-modal-reply-box'>
            <FormGroup>
              <Label for="exampleText">Suggested Reply</Label>
              {isError ? <span className='WarningIcon'><img src={WarnIcon} alt='warning' /> </span> : ''}
              <Input readonly disabled ref={textareaRef} onSelect={handleSelect} placeholder={placeHolderMsg} value={botMsg ? botMsg : ''} type="textarea" name="text" id="noTextArea" className={isError && 'isWarningActive'} />
              {botMsg && <div style={{pointerEvents: isError ? 'none' : 'initial'}} onClick={() => regenerateHandler("False", "True", chatBotMsgID)}><span className='refresh-suggestion-icon'><img src={whispererRefresh} alt="Refresh" /></span></div>}
              {botMsg && <div className='Overlay'></div>}
            </FormGroup>
          </div>
          <div className='d-flex justify-center'>
            <Button onClick={() => props.copyToTextAreaHandler(botMsg, toggle, () => regenerateHandler("True", "False", chatBotMsgID), chatBotMsgID)} disabled={!botMsg || isError ? true : false} className="mr-2 modal-btn copyBtn">Copy</Button>
            <Button onClick={() => props.sendWhispererMsgHandler(botMsg, toggle, () => regenerateHandler("True", "False", chatBotMsgID), true, chatBotMsgID)} disabled={!botMsg || isError ? true : false} className="ml-3 modal-btn">Send</Button>
          </div>
        </ModalBody>
        <ModalFooter className="chat-modal-footer camFooter" />
      </Modal>
    </div>
  );
}

WhispererModal.defaultProps = {
  isOpen: false,
};

WhispererModal.propTypes = {
  isOpen: PropTypes.bool,
};

export default WhispererModal;