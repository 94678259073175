/* eslint-disable camelcase */
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import updateLoginData from '../actions/actionUpdateData';
import updateCharacterAction from '../actions/actionUpdatePerfCharacter';
import updatePerformer from '../actions/actionUpdatePerfPerformer';
// import login from '../actions/actionLoginPerf';
import getPerfCharacterData from '../actions/actionGetPerformer';
import { validate } from '../utils/formUtils/validator';
import Profile from '../scenes/Profile';
import { getPayoutDetails } from '../actions/actionPayoutSettings';

const getValidCredits = (rate) => {
  if (rate > 200) {
    return 200;
  }
  if (rate < 0) {
    return 0;
  }
  return Number(rate);
};

const mapMinVoiceChatRate = (sysConfigDataRes = []) => {
  const minVoiceChatRate = sysConfigDataRes.find(result => result.config_name === 'min_voice_call_rate') || {};
  return minVoiceChatRate.value;
};
const mapMaxVoiceChatRate = (sysConfigDataRes = []) => {
  const maxVoiceChatRate = sysConfigDataRes.find(result => result.config_name === 'max_voice_call_rate') || {};
  return maxVoiceChatRate.value;
};
const mapMinVideoChatRate = (sysConfigDataRes = []) => {
  const minVedioChatRate = sysConfigDataRes.find(result => result.config_name === 'min_video_call_rate') || {};
  return minVedioChatRate.value;
};
const mapMaxVideoChatRate = (sysConfigDataRes = []) => {
  const maxVedioChatRate = sysConfigDataRes.find(result => result.config_name === 'max_video_call_rate') || {};
  return maxVedioChatRate.value;
};


// Map data from API
const fromApiAdapter = (charData = {}, perfData = {}, formData = {}) => {
  const {
    objid,
    display_name,
    headline: headlineValue,
    profile_description,
    videochatrate = 0,
    primary_pic_url = '',
    background_pic_url = '',
    voice_rate = '',
  } = charData;

  // Form data initialized with data from login response
  const {
    headline = headlineValue,
    description = profile_description,
    videoChatRate = videochatrate,
    voiceChatRate = voice_rate,
  } = formData;

  return {
    charId: objid,
    perfId: perfData.objid,
    displayName: display_name,
    headline,
    description,
    videoChatRate: getValidCredits(videoChatRate),
    primaryPic: primary_pic_url,
    backgroundPic: background_pic_url,
    perf2CstContactId: perfData?.perf2cst_contact || '',
    voiceChatRate,
  };
};

// Filter profile data to call perfperformer and perfcharacter end points separately
const profileSelector = (dispatch, profile) => {
  const apiAdapter = {
    id: 'objid',
    headline: 'headline',
    description: 'profile_description',
    videoChatRate: 'videochatrate',
    backgroundPic: 'background_picture',
    primaryPic: 'primary_picture',
    voiceChatRate: 'voicechatrate',
  };

  // mapping api keys
  const toApi = {};
  Object.keys(profile).forEach((key) => {
    toApi[apiAdapter[key]] = profile[key];
  });

  // update login data in redux
  const updateStore = (response, dataToUpdate, keyToUpdate) => {
    const { value: { data = {} } = {} } = response;
    const key = Object.keys(toApi).filter(it => it !== 'objid')[0];
    let payload = {};

    if (keyToUpdate) {
      payload = { [keyToUpdate]: data[keyToUpdate] };
    } else {
      payload = { [key]: data[key] };
    }

    dispatch(updateLoginData(payload, dataToUpdate));
  };

  const formData = new FormData();
  Object.keys(toApi).forEach((key) => {
    formData.append(key, toApi[key]);
  });

  const putImageData = (image) => {
    dispatch(
      updateCharacterAction(
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            type: 'formData',
          },
        },
      ),
    ).then(response => updateStore(response, 'character', image));
  };

  if ('videochatrate' in toApi) {
    dispatch(updatePerformer(formData)).then(response => updateStore(response, 'performer'));
  } else if ('background_picture' in toApi) {
    putImageData('background_pic_url');
  } else if ('primary_picture' in toApi) {
    putImageData('primary_pic_url');
  } else {
    dispatch(updateCharacterAction(formData)).then(
      response => updateStore(response, 'character'),
    );
  }
};

export const mapStateToProps = (state) => {
  const {
    form: {
      profileSetting: { values = {}, syncErrors = {} } = {},
    } = {},
    login: { data: { perfcharacter = [{}], perfperformer = {} } = {} } = {},
    performer: { data: { characters = [{}], videochatrate } = {} } = {},
    sysConfig: { data: { results = [] } = {} } = {},
    updateCharacter,
  } = state;

  const {
    headline,
    profile_description,
    primary_pic_url,
    background_pic_url,
    voice_rate,
  } = characters[0];


  const initialFormValues = {
    headline,
    description: profile_description,
    videoChatRate: videochatrate,
    primaryPic: primary_pic_url,
    backgroundPic: background_pic_url,
    voiceChatRate: voice_rate,
  };

  let primaryPicUrl = '';
  if (values.primaryPic && values.primaryPic.length > 0 && typeof values.primaryPic[0] !== 'string') {
    primaryPicUrl = URL.createObjectURL(values.primaryPic[0]);
  }

  let backgroundPicUrl = '';
  if (values.backgroundPic && values.backgroundPic.length > 0 && typeof values.backgroundPic[0] !== 'string') {
    backgroundPicUrl = URL.createObjectURL(values.backgroundPic[0]);
  }

  return {
    formData: { primaryPicUrl, backgroundPicUrl },
    characters,
    profile: fromApiAdapter(perfcharacter[0], perfperformer, values),
    initialValues: initialFormValues,
    isCharacterLoading: updateCharacter.isLoading,
    errors: { ...syncErrors, updateChar: updateCharacter.error || false },
    minVoiceChatRateVal: mapMinVoiceChatRate(results),
    maxVoiceChatRateVal: mapMaxVoiceChatRate(results),
    minVedioChatRateVal: mapMinVideoChatRate(results),
    maxVedioChatRateVal: mapMaxVideoChatRate(results),
  };
};

export const mapDispatchToProps = dispatch => ({
  updateProfile: data => profileSelector(dispatch, data),
  getPerfCharacter: objectId => dispatch(getPerfCharacterData(objectId)),
  getPayoutDetails: data => dispatch(getPayoutDetails(data)),
});

const FormWrapper = reduxForm({
  form: 'profileSetting',
  enableReinitialize: true,
  validate,
})(Profile);

const ProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormWrapper);

export default ProfileContainer;
