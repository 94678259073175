import React from 'react';
import dollar from '../assets/svgs/dollar';
import message from '../assets/svgs/message';
import video from '../assets/svgs/video';
import autoPlay from '../assets/svgs/autoPlay';
import call from '../assets/svgs/call';

const transtype = {
  TIP001: <div className="text-center">{ dollar(20, 20) }</div>,
  SMS001: <div className="text-center">{ message(20, 20) }</div>,
  DE0003: <div className="text-center">{ autoPlay(20, 20) }</div>,
  DE0006: <div className="text-center">{ call(20, 20) }</div>,
  DE0007: <div className="text-center">{ video(20, 20) }</div>,
};

export default transtype;
