export default function (state = [], action = {}) {
  switch (action.type) {
    case 'FETCH_PROFILE_FEEDS_DATA_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'FETCH_PROFILE_FEEDS_DATA_FULFILLED':
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        error: false,
      };

    case 'FETCH_PROFILE_FEEDS_DATA_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case 'UPDATE_ALLPHOTO_LIKE_STATUS':
      return {
        ...state,
        data: {
          ...state.data,
          isliked_all: action.payload,
        },
        isLoading: false,
        error: false,
      };

    default:
      return {
        ...state,
      };
  }
}
