/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Button,
  Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
// import balance from '../../../../assets/svgs/money3';
import refreshIcon from '../../../../assets/svgs/refreshCard.svg';
import CustomModal from '../../../../components/CustomModal';
import ViewAbleInfoModal from '../ViewAbleInfoModal';
import './style.scss';
import LOCATIONS, { PREFERENCES, US_ACCOUNT_TYPES } from '../../../PayoutSettings/constants';
import { encryptDataUtil } from '../../../../utils/helpers';


const CreditDetails = (props) => {
  const {
    amount = '0',
    credits = '0',
    objectId = -1,
    view = 'paymentHistory',
    performerData,
    handleTableChange,
    getPerformer,
    requestPayOut,
    isLoading,
    minPayoutBalance,
    setPerformersData,
    payoutSettings,
  } = props;

  const redirectToBankDetails = () => {
    props.history.push({
      pathname: '/profile',
      state: {
        activeTabState: true,
      },
    });
  };

  const { payoutDetails } = payoutSettings;

  const [isPayoutModalOpen, setPayoutModalOpen] = useState(false);
  const [isSucessPayoutModalOpen, setisSucessPayoutModalOpen] = useState(false);
  const [isPayoutDetailsModal, setIsPayoutDetailsModal] = useState(false);
  const [payoutDetailData, setPayoutDetailData] = useState(null);
  const [bankLocation, setBankLocation] = useState(null);
  const [isNoDetailFound, setIsNoDetailFound] = useState(false);
  const [isSubmitDetailAnyway, setIsSubmitDetailAnyway] = useState(false);
  const [isViewAbleInfo, setIsViewAbleInfo] = useState(false);

  useEffect(() => {
    if (payoutDetails?.results?.length) {
      const { results } = payoutDetails;
      const {
        iban: IBAN, institution_number: INSTITUTION, routing_number: ROUTING,
        swift_code: SWIFT, cosmo_payment_san_number: COSMOSAN,
      } = results[0];

      if (IBAN || INSTITUTION || ROUTING || SWIFT || COSMOSAN) {
        const {
          bank_location: { country: bankLocationId },
          perf_payment_method: prefMethod,
        } = results[0];
        setBankLocation(bankLocationId);
        const location = LOCATIONS.filter(loc => loc.value === bankLocationId)[0] || null;
        const preference = PREFERENCES[bankLocationId].filter(
          pref => pref.value === prefMethod,
        )[0] || null;

        let details = { location, preference };

        if (bankLocationId === 'United States') {
          const {
            account_number: accountNo,
            routing_number: routingNo,
            account_type: accountTypeVal,
            // swift_code: swiftCode,
          } = results[0];

          const accountType = US_ACCOUNT_TYPES.filter(
            type => type.value === accountTypeVal,
          )[0] || null;

          details = {
            ...details, accountNo, accountType, routingNo,
          };
          setPayoutDetailData(details);
        } else if (bankLocationId === 'Canada') {
          const {
            account_number: accountNo,
            institution_number: institutionNo,
            branch_number: branchNo,
          } = results[0];
          details = {
            ...details, accountNo, institutionNo, branchNo,
          };
          setPayoutDetailData(details);
        } else if (bankLocationId === 'UK & Euro Zone (IBAN)') {
          const { iban } = results[0];
          details = { ...details, iban };
          setPayoutDetailData(details);
        } else if ((bankLocationId === 'Other(International)')) {
          const {
            account_number: accountNo,
            account_type: accountTypeVal,
            swift_code: swiftCode,
            cosmo_payment_san_number: cosmoSanNum,
          } = results[0];
          const accountType = US_ACCOUNT_TYPES.filter(
            type => type.value === accountTypeVal,
          )[0] || null;
          if (preference && preference.value === 'Cosmo') {
            details = {
              ...details, cosmoSanNum,
            };
          } else {
            details = {
              ...details, accountNo, accountType, swiftCode,
            };
          }
          setPayoutDetailData(details);
        }
      }
    }
  }, [payoutDetails]);

  const callRequestPayoutApi = () => {
    requestPayOut({ performer_objid: objectId }).then(() => {
      const updatePerformerData = {
        ...performerData,
        unrequested_credits: 0,
        unrequested_usd_amt: 0,
      };
      setPerformersData(updatePerformerData);
      setisSucessPayoutModalOpen(true);
      handleTableChange(view);
    });
  };

  const postPayoutRequest = () => {
    if (Number(amount) >= Number(minPayoutBalance)) {
      // callRequestPayoutApi();
      if (payoutDetailData) {
        setIsPayoutDetailsModal(true);
      } else {
        setIsSubmitDetailAnyway(true);
        // setIsNoDetailFound(true);
      }

      // if (payoutDetailData) {
      //   setIsPayoutDetailsModal(true);
      // }
      // else {
      //   setIsNoDetailFound(true);
      // }
    } else {
      setPayoutModalOpen(true);
    }
  };

  const PAYOUT_US = () => {
    const {
      location, preference, accountNo, accountType, routingNo,
    } = payoutDetailData;

    // const isDirectDeposit = preference.value === 'Direct' || false;
    if (payoutDetailData) {
      return (
        <div>
          <h4>
            {`"${preference.label}"`}
            {' '}
            option
          </h4>
          <p>
            {' '}
            {location.label}
          </p>
          <div className="container payout-bg-white">
            <Row className="border-bottom-style">
              <Col>Routing Number</Col>
              <Col>
                {((routingNo && encryptDataUtil(`${routingNo}`, 3)) || '')}
              </Col>
            </Row>
            <Row className="border-bottom-style">
              <Col>Account Number</Col>
              <Col>
                {(accountNo && encryptDataUtil(accountNo, 4)) || ''}
              </Col>
            </Row>
            <Row className="border-bottom-style">
              <Col>Account Type</Col>
              <Col>{accountType.label}</Col>
            </Row>
          </div>
        </div>
      );
    }
    return null;
  };

  const PAYOUT_CANADA = () => {
    if (payoutDetailData) {
      const {
        location, preference, accountNo, institutionNo, branchNo,
      } = payoutDetailData;
      return (
        <div>
          <h4>
            {`"${preference.label}"`}
            {' '}
            option
          </h4>
          <p>
            {' '}
            {location.label}
          </p>
          <div className="container payout-bg-white">
            <Row className="border-bottom-style">
              <Col>Institution Number</Col>
              <Col>
                {(institutionNo && encryptDataUtil(`${institutionNo}`, 1)) || ''}
              </Col>
            </Row>
            <Row className="border-bottom-style">
              <Col>Branch Number</Col>
              <Col>
                {(branchNo && encryptDataUtil(`${branchNo}`, 3)) || ''}
              </Col>
            </Row>
            <Row className="border-bottom-style">
              <Col>Account Number</Col>
              <Col>
                {(accountNo && encryptDataUtil(accountNo, 4)) || ''}
              </Col>
            </Row>
          </div>
        </div>
      );
    }
    return null;
  };

  const PAYOUT_EUROZONE = () => {
    if (payoutDetailData) {
      const { location, preference, iban } = payoutDetailData;
      return (
        <div>
          <h4>
            {`"${preference.label}"`}
            {' '}
            option
          </h4>
          <p>
            {' '}
            {location.label}
          </p>
          <div className="payout-bg-white">
            <p className="mb-1">IBAN Number</p>
            <p className="mb-1 font-weight-bold">{(iban && encryptDataUtil(iban, 3)) || ''}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  const PAYOUT_OTHER = () => {
    const {
      location, preference, accountNo, accountType, swiftCode, cosmoSanNum,
    } = payoutDetailData;
    // const isDirectDeposit = preference.value === 'Direct' || false;
    if (payoutDetailData && preference && preference.value === 'Cosmo') {
      return (
        <div>
          <h4>
            {`"${preference.label}"`}
            {' '}
            option
          </h4>
          <p>
            {' '}
            {location.label}
          </p>
          <div className="payout-bg-white">
            <p className="mb-1">SAN Number</p>
            <p className="mb-1 font-weight-bold">{(cosmoSanNum && encryptDataUtil(cosmoSanNum, 3)) || ''}</p>
          </div>
        </div>
      );
    }
    return (
      <div>
        <h4>
          {`"${preference.label}"`}
          {' '}
          option
        </h4>
        <p>
          {' '}
          {location.label}
        </p>
        <div className="container payout-bg-white">
          <Row className="border-bottom-style">
            <Col>Swift Code</Col>
            <Col>
              {((swiftCode && encryptDataUtil(swiftCode, 3)) || '')}
            </Col>
          </Row>
          <Row className="border-bottom-style">
            <Col>Account Number</Col>
            <Col>
              {(accountNo && encryptDataUtil(accountNo, 4)) || ''}
            </Col>
          </Row>
          <Row className="border-bottom-style">
            <Col>Account Type</Col>
            <Col>{accountType.label}</Col>
          </Row>
        </div>
      </div>
    );
  };


  // eslint-disable-next-line arrow-body-style
  const PayoutDetailsModalBody = {
    'United States': PAYOUT_US,
    Canada: PAYOUT_CANADA,
    'UK & Euro Zone (IBAN)': PAYOUT_EUROZONE,
    'Other(International)': PAYOUT_OTHER,
  };

  const ViewAbleInfoModalBody = () => (
    <div className="viewable-info-table">
      <p>
        Payment is issued every Monday*, for previous weeks earnings.
        <br />
        * If Monday is a Canadian Holiday, pay is issued Tuesday.
      </p>
      <p>Minimum payment requested is $100.00</p>
      <div className="view-info-table-wrap">
        <table>
          <tr>
            <th>Country</th>
            <th>Method</th>
            <th>Fees</th>
            <th>Delay</th>
          </tr>
          <tr>
            <td>USA / Canada</td>
            <td>Direct Deposit</td>
            <td>$5 USD/CAD if pay is under $250.  Free if over $250.</td>
            <td>2 days</td>
          </tr>
          <tr>
            <td>Europe / UK</td>
            <td>WISE Transfer</td>
            <td>A standard fee from WISE will apply to this transfer.</td>
            <td>2-4 days</td>
          </tr>
          <tr>
            <td>Other countries</td>
            <td>Wire Transfer</td>
            <td>$11.00 USD</td>
            <td>2-4 days</td>
          </tr>
          <tr>
            <td>Other countries</td>
            <td>Cosmo Transfer</td>
            <td>A standard fee from Cosmo will apply to this transfer.</td>
            <td>2-4 days</td>
          </tr>
        </table>
      </div>
      <p>
        Please make sure you send us complete and accurate payment
        information, as we cannot check if your account number is valid.
        We send funds using the exact
        information you submitted to us, using the form.
      </p>
      <h6>Did not get your money?</h6>
      <p>
        Please wait until Friday before requesting information about your payment,
        as banks often cause additional delays which are out of our control.
      </p>
      <p>
        If the following Monday, you still did not have your money,
        we will send you the wire receipt so you can show it to your bank.
        If they cannot trace it, we will initiate an investigation request
        with our bank.  Most of the time, the cause is because we were given
        incorrect information by the chat host, such as incorrect account
        number.  If the error was caused by incorrect information you submitted,
        a $25USD fee will be deducted from the payment when it will be re-sent to you.
        Also, this will take between 2-4 weeks as banks are slow and we need to have the
        funds returned to us before we re-issue your payment.
        If the error was on our side, there will be no fees.
      </p>
      <h6>How to change your payment info.</h6>
      <p>
        In order to change your payment info, you need to fill the form again,
        completely.  We always send payments to the last information we have,
        at the moment we send the pay.
      </p>
    </div>
  );

  const viewAbleInfoHander = () => {
    setIsViewAbleInfo(true);
  };

  const renderViewAbleInfoModal = () => (
    <ViewAbleInfoModal
      header="How will we pay you?"
      body={ViewAbleInfoModalBody()}
      open={isViewAbleInfo}
      toggle={() => setIsViewAbleInfo(false)}
      className="view-able-info-modal"
    />
  );

  const renderModalForUpdate = () => (
    <>
      <CustomModal
        header="Info"
        body={`The minimum amount for payment request is $${minPayoutBalance}`}
        buttonText="OK"
        // footer="CANCEL"
        // footerLink={() => setAfterUpdateProfilePicture(false)}
        open={isPayoutModalOpen}
        // onClick={resetBackgroundPicPanel}
        toggle={() => setPayoutModalOpen(false)}
      />
      <CustomModal
        header="Info"
        body="Payout request has been submitted successfully"
        buttonText="OK"
        // footer="CANCEL"
        // footerLink={() => setAfterUpdateProfilePicture(false)}
        open={isSucessPayoutModalOpen}
        // onClick={resetBackgroundPicPanel}
        toggle={() => setisSucessPayoutModalOpen(false)}
      />
      <CustomModal
        header=""
        body={(bankLocation !== null && PayoutDetailsModalBody[bankLocation]())}
        buttonText="Submit"
        // footer="CANCEL"
        // footerLink={() => setAfterUpdateProfilePicture(false)}
        open={isPayoutDetailsModal}
        onClick={callRequestPayoutApi}
        toggle={() => setIsPayoutDetailsModal(false)}
        className="height-disabled"
      />
      <CustomModal
        header="Info"
        body="It seems you have not filled Bank details yet, Please do the needful before requesting a pay."
        buttonText="Ok"
        onClick={redirectToBankDetails}
        open={isNoDetailFound}
        toggle={() => setIsNoDetailFound(false)}
      />
      <CustomModal
        header="Info"
        body="Please fill out your bank details on balance screen for ease. Continue requesting pay for now? "
        buttonText="Continue Anyway"
        buttonText2="Fill bank details"
        onClick={callRequestPayoutApi}
        onClick2={redirectToBankDetails}
        open={isSubmitDetailAnyway}
        toggle={() => setIsSubmitDetailAnyway(false)}
      />
    </>
  );

  return (
    <>
      {renderModalForUpdate()}
      {renderViewAbleInfoModal()}
      <div className="customCard Credits-customCard mt-3">
        <Row className="headerBorderLeft mb-2">
          <Col lg={6} md={5}>
            <span className="align-bottom headingH2Balance">Balance</span>
          </Col>
          <Col lg={6} md={7}>
            <div className="balanceCard d-flex align-items-center bg-primary position-relative rounded p-3">
              <Row className="flex-1 align-items-start balance-card-row">
                <Col md={4} className="pr-0 pt-3">
                  <h3 className="mb-0 cardAmount">
                    {`$${amount}`}
                    <sub><small>USD</small></sub>
                  </h3>
                  <p className="mb-0 cardPera">
                    Your current balance
                  </p>
                </Col>
                <Col md={3} className="pt-3">
                  <h4 className="mb-1 cardSubAmnt">
                    { credits }
                  </h4>
                  <p className="mb-0 cardPera">
                    CREDITS
                  </p>
                </Col>
                <Col md={5} className="pt-3">
                  <Button color="secondary" className="cardBtn bg-dark px-4 request-pay" onClick={postPayoutRequest}>
                    Request Pay
                  </Button>
                  <Row
                    className="pr-1"
                    style={{
                      left: '31px', fontSize: '12px', lineHeight: 1,
                    }}
                  >
                    Cash out Deadline 12 PM EST Sundays
                  </Row>
                </Col>
              </Row>
              {isLoading ? (
                <div className="css-loader mr-1" />
              ) : (
                <img
                  role="presentation"
                  className="cursor-pointer refreshIcon"
                  src={refreshIcon}
                  width="29"
                  alt="refresh_credits_avatar"
                  onClick={() => {
                    if (objectId > 0) {
                      getPerformer(objectId);
                    }
                  }}
                />
              )
              }
              <Row
                className="credit-update-info"
                style={{
                  left: '31px', bottom: '6px', fontSize: '12px', lineHeight: 1,
                }}
              >
                This information is updated every 5 minutes.
              </Row>
              <p className="how-wepay-link" onClick={viewAbleInfoHander} role="presentation"><u>How will we pay you?</u></p>
            </div>
          </Col>
        </Row>

        <Row className="">
          <div className="flexOne2Row balanceTabs align-bottom">
            <ul className="tabbedLink">
              <li
                role="presentation"
                onClick={() => handleTableChange('paymentHistory')}
                className={view === 'paymentHistory' ? 'active' : ''}
              >
                Payment History
              </li>

              <li
                role="presentation"
                onClick={() => handleTableChange('transaction')}
                className={view === 'transaction' ? 'active' : ''}
              >
                Transactions
              </li>

              <li
                role="presentation"
                onClick={() => handleTableChange('myTip')}
                className={view === 'myTip' ? 'active' : ''}
              >
                My Tips
              </li>
            </ul>
          </div>
        </Row>
      </div>
    </>
  );
};

CreditDetails.defaultProps = {
  amount: '0',
  credits: '0',
  objectId: -1,
  view: 'paymentHistory',
  isLoading: false,
  minPayoutBalance: '0',
  performerData: {},
  payoutSettings: {},
};

CreditDetails.propTypes = {
  amount: PropTypes.string,
  credits: PropTypes.string,
  objectId: PropTypes.number,
  getPerformer: PropTypes.func.isRequired,
  view: PropTypes.string,
  handleTableChange: PropTypes.func.isRequired,
  requestPayOut: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  minPayoutBalance: PropTypes.string,
  setPerformersData: PropTypes.func.isRequired,
  performerData: PropTypes.instanceOf(Object),
  payoutSettings: PropTypes.instanceOf(Object),
  // eslint-disable-next-line
  history: PropTypes.object,
};

export default CreditDetails;
