import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const LogoutMobileOnRoulletteOff = ({ history }) => {
  const timeOut = setTimeout(() => {
    localStorage.clear();
    history.push('/not-mobile');
    clearTimeout(timeOut);
  }, 4000);

  return (
    <div className="logout_mobile_on_roulletteoff">
      <p>You turned off roullette, Now you can continue chat on desktop!</p>
    </div>
  );
};

LogoutMobileOnRoulletteOff.defaultProps = {
  history: {},
};

LogoutMobileOnRoulletteOff.propTypes = {
  history: PropTypes.instanceOf(Object),
};

export default LogoutMobileOnRoulletteOff;
