

import React from 'react';

export default (width, height) => (
  <React.Fragment>
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 23.3 23.1"
      style={{ enableBackground: 'new 0 0 23.3 23.1', fill: '#fff', stroke: '#fff' }}
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            className="st0"
            d="M4.5,2.1C5.6,2.8,7.8,5,8.4,5.9C8.1,6.3,7.4,7.1,7,7.4c-0.8,0.8-1.3,1.3-1.3,2c0,0.9,0.4,2.7,2.8,5.1
          c2.3,2.3,4.6,3.5,5.7,3.5h0.1h0.1c0.7-0.1,1.2-0.6,2.5-1.8c0.3-0.3,0.7-0.7,1-0.9c1,0.6,3.1,2.3,3.7,4c-0.5,0.6-2.2,2.2-4.5,2.2
          c-2.9,0-7-2.2-10.3-5.4C4.7,14,1.7,9.5,2.1,5.9C2.1,4.3,3,3.1,4.5,2.1 M4.4,0.6H4.3c-7.3,4.3-1.8,13,1.5,16.3s7.8,5.8,11.2,5.8
          c3.4,0,5.9-2.9,5.8-3.4c-0.7-2.9-4.6-5.6-5.2-5.6l0,0c-0.5,0-2.9,2.9-3.4,2.9l0,0c-0.6,0-2.5-0.9-4.8-3.1S7,9.7,7,9.3
          c0-0.4,2.6-2.4,2.8-3.5C9.8,4.9,5.3,0.6,4.4,0.6L4.4,0.6z"
          />
        </g>
      </g>
    </svg>
  </React.Fragment>
);
