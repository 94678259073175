import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import './style.scss';

const PortalFooter = () => {
  const [year, setYear] = useState();
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setYear(currentYear);
  });
  return (
    <Row className="dashboardFooterCls bg-dark mt-4">
      <div className="portal-footer">
        <p>
          Copyright &copy; Arousr &nbsp;
          {year}
        </p>
      </div>
    </Row>
  );
};

export default PortalFooter;
