import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import Button from 'reactstrap/lib/Button';
import FeedFavIcon from '../../assets/svgs/feed-fav-icon';
import LoadingSpinner from '../../utils/LoadingSpinner';
import InitialScreen from '../../components/NoDataAvailable';
import ProfileCard from './ProfileCard';
import BackButtonIcon from '../../assets/svgs/backButtonIcon';
import customerAvatar from '../../assets/images/customerAvatar.png';

function CustomerProfile(props) {
  const {
    performerObjId,
    dispatchProfileData,
    customerPicture,
    customerProfileData,
    loadingData,
    cutomerImageLike,
    cutomerImageDislike,
    customerAllImagesLike,
    fetchUpdateProfileData,
    match: { params: { id: customerId = 0 } = {} } = {},
    history,
  } = props;

  const [isOpenPhoto, setOpenPhoto] = useState(false);
  const [mainSrcPicCon, setMainSrcPicCon] = useState(null);

  const pictureControl = (event = {}) => {
    const imageFile = event && event.target && event.target.src;
    if (imageFile) {
      setOpenPhoto(true);
      setMainSrcPicCon(imageFile);
    }
  };

  const closePhoto = () => {
    setOpenPhoto(false);
  };

  useEffect(() => {
    if (customerId && performerObjId) {
      const url = `/customermedia/list/?customer=${customerId}&character=${performerObjId}`;
      dispatchProfileData(url);
    }
  }, [customerId]);

  const changeImageLikeStauts = (isPhotoLiked, imgObjId) => {
    const formData = new FormData();
    formData.append('character', performerObjId);
    formData.append('image', imgObjId);
    if (isPhotoLiked) {
      // Like
      return cutomerImageLike(formData).then((result) => {
        const { value: { data: { isliked_all: isLikeAll } } } = result;
        fetchUpdateProfileData(isLikeAll);
        return result;
      });
    }
    // DisLike
    return cutomerImageDislike(formData).then((result) => {
      const { value: { data: { isliked_all: isLikeAll } } } = result;
      fetchUpdateProfileData(isLikeAll);
      return result;
    });
  };

  const customerAllImageLike = () => {
    customerAllImagesLike({ character: performerObjId, customer: customerId })
      .then(() => {
        fetchUpdateProfileData(true);
      });
  };

  return (
    <>
      {isOpenPhoto && (
        <Lightbox
          ClassName="Lightbox"
          mainSrc={mainSrcPicCon}
          onCloseRequest={closePhoto}
        />
      )}
      {loadingData ? (
        <LoadingSpinner />
      ) : (
        <>
          <div onClick={history.goBack} className="back-btn-wrap" onKeyUp={history.goBack} role="button" tabIndex={0}>
            <BackButtonIcon />
          </div>
          <div className="profileHead justify-content-between">
            <div className="d-flex align-items-center">
              <div className="profile-img-wrap" onClick={pictureControl} onKeyUp={pictureControl} role="button" tabIndex={0}>
                <img src={customerProfileData.profileImage || customerAvatar} alt="profileImage" className="profileImage" />
              </div>
              <div className="profile-content-wrap">
                <p className="profile-username">{customerProfileData.userName}</p>
                <p className="profile-user-about">{customerProfileData.aboutCustomer}</p>
              </div>
            </div>
            <div className="like-btn-wrap">
              {customerPicture.length ? (
                <Button className="like-all-btn" onClick={customerAllImageLike} disabled={customerProfileData.likeAll}>
                  <FeedFavIcon />
                  <span>Like All</span>
                </Button>
              ) : ''}
            </div>
          </div>
          {customerPicture.length ? (
            <ul className="feed-card-ul profile-feed-card-ul">
              {customerPicture.map(post => (
                <ProfileCard
                  key={post.id}
                  imgObjId={post.id}
                  image={post.image}
                  isLiked={post.isLiked}
                  pictureControl={pictureControl}
                  changeImageLikeStauts={changeImageLikeStauts}
                  isLikeAll={customerProfileData.likeAll}
                />
              ))}
            </ul>
          ) : (
            <InitialScreen />
          )}
        </>
      )}
    </>
  );
}

CustomerProfile.defaultProps = {
  performerObjId: 0,
  customerPicture: [],
  customerProfileData: {},
  loadingData: false,
};

CustomerProfile.propTypes = {
  performerObjId: PropTypes.number,
  dispatchProfileData: PropTypes.func.isRequired,
  customerPicture: PropTypes.instanceOf(Array),
  customerProfileData: PropTypes.instanceOf(Object),
  loadingData: PropTypes.bool,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  cutomerImageLike: PropTypes.func.isRequired,
  cutomerImageDislike: PropTypes.func.isRequired,
  customerAllImagesLike: PropTypes.func.isRequired,
  fetchUpdateProfileData: PropTypes.func.isRequired,
};
export default CustomerProfile;
