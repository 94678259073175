/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Card, CardBody,
  CardHeader, CardFooter, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import Lightbox from 'react-image-lightbox';
import InfiniteScroll from 'react-infinite-scroll-component';

import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import iconChat from '../../assets/svgs/PhonebookChat';
import customerAvatar from '../../assets/images/customerAvatar.png';
import placeHolderImg from '../../assets/svgs/placeholder-img.svg';
import LoadingSpinner from '../../utils/LoadingSpinner';
import CustomModal from '../../components/CustomModal/index';
import { utcToLocalTimeConverter, checkCloudinaryUrl, showOnlineStatus } from '../../utils/helpers';
import MEDIA_CONST from '../../utils/constant';
import crownIcon from '../../assets/svgs/icon-crown.svg';

import './style.scss';
import CallIcon from '../../assets/svgs/Call.svg';
import VideoIcon from '../../assets/svgs/Video.svg';
import MissedCallIcon from '../../assets/svgs/MissedCall.svg';
import MissedVideoIcon from '../../assets/svgs/MissedVideo.svg';
import postMissedCallsData from '../../actions/actionMissedCalls';

const mapChatLogData = (results = []) => results.map(result => ({
  id: result.session_id,
  objid: result.objid,
  createDataTime: result.create_datetime,
  totalMsg: (result.char2cust + result.cust2char),
  title: result.customer_screen_name,
  lastMsg: result.preview_message_perf,
  messages: [],
  premium: result.billing_flag,
  lastMessageTime: result.last_activity,
  unread: result.unread_messages_to_char_count,
  custPic: result.customer_profile_pic,
  custId: result.customer_objid,
  sessionStatus: result.active ? 'on' : 'off',
  performerContinueMsgCount: 0,
  iscurrentlyActiveCust: false,
  isPremiumCustomer: result.is_premium_customer,
  sendEmailNotification: result.send_email_notification,
  customerHostReview: result.customer_host_review,
  isCustomerOnline: result.is_customer_online,
  isFavCustomer: result.is_favourite_customer,
}));

const fileExists = (url) => {
  const http = new XMLHttpRequest();
  http.open('HEAD', url, false);
  http.send();
  if (http.status !== 404) {
    // eslint-disable-next-line jsx-a11y/media-has-caption
    return <audio src={url} controls controlsList="nodownload, noplaybackrate" />;
  }
  return <img className="img-msg" src={placeHolderImg} alt="chat msgs img" height="100px" width="100px" />;
};

const Chatlog = (props) => {
  const {
    match: { params: { id: customerId = 0 } = {} } = {},
    getCustomerChatLog,
    checkActiveSessionAvailable,
    setConversation,
    // conversation,
    getSessionMsgs,
    removeChatMsgHistory,
    chatlogData,
    callsLogData,
    chatMsgData,
    perfObjid,
    minMessageToInitaiteChat,
    history,
    isTier2,
    loaders: { chatLogLoader, msgHistoryLoader } = {},
    location: { state: { customer: customerData } = {} } = {},
    next,
  } = props;

  const [sessionList, setSessionList] = useState([]);
  const [callLogsList, setCallLogsList] = useState([]);
  const [currentOpenSession, setCurrentOpenSession] = useState(null);
  const [msgHistoryList, setMsgHistoryList] = useState([]);
  const [disableResumeChat, setDisableResumeChat] = useState(false);
  const [isResumeModalshow, setIsResumeModalShow] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [isOpenPhoto, setOpenPhoto] = useState(false);
  const [mainSrcPicCon, setMainSrcPicCon] = useState('');
  const { location: { pathname } } = history;
  const {
    VIDEO, IMAGE, VIDEO_CLOUDFLARE_BASE_URL, AUDIO,
  } = MEDIA_CONST;

  const dispatch = useDispatch();

  const pictureControl = (event = {}) => {
    const imageFile = event && event.target && event.target.data;
    if (imageFile) {
      setOpenPhoto(true);
      setMainSrcPicCon(imageFile);
    }
  };

  useEffect(() => {
    if (!customerData) {
      history.push('/phonebook');
    }
  }, []);

  const callApi = (pageno) => {
    const url = `/cfgxmppsessions/list/?chat_log=True&customer_objid=${customerId}&character_objid=${perfObjid}&cust2char__gt=-1&page_size=20&page=${pageno}`;
    getCustomerChatLog(url, pageno);
  };

  useEffect(() => {
    if (customerId) {
      callApi(nextPage);
    }
    return removeChatMsgHistory;
  }, [customerId]);

  useEffect(() => {
    if (currentOpenSession && currentOpenSession.id) {
      const url = `api/chatmsg/history/?session_objid=${currentOpenSession.id}&exclude_msg_paths=SESSION_CMD|Sys2Cust&page_size=1000&delivery_status=Sent|Read|Undelivered&ordering=objid`;
      getSessionMsgs(url);
    }
  }, [currentOpenSession]);

  const extractedVideoId = (url = '') => url.split('/').pop() || '';

  const renderMessages = (msgtext) => {
    if (checkCloudinaryUrl(msgtext) === VIDEO && extractedVideoId(msgtext)) {
      return (
        <iframe
          src={`${VIDEO_CLOUDFLARE_BASE_URL}${extractedVideoId(msgtext)}`}
          title={`video_${extractedVideoId(msgtext)}`}
          // style={{"border: none;"}}
          height="300"
          width="300"
          allowFullScreen={`${extractedVideoId(msgtext)}`}
        />
      );
    }
    if (checkCloudinaryUrl(msgtext) === IMAGE) {
      return (
        <object data={msgtext} role="presentation" onClick={pictureControl} type="image/png" height="100px" width="100px">
          <img className="placeholder-obj" src={placeHolderImg} alt="chat msgs img" height="100px" width="100px" />
        </object>
      );
    }
    if (checkCloudinaryUrl(msgtext) === AUDIO) {
      return (
        fileExists(msgtext)
      );
    }
    return <span className="message">{msgtext}</span>;
  };

  useEffect(() => {
    const msgListUIArray = chatMsgData.map((msg) => {
      if (msg.media) {
        console.log(extractedVideoId(msg.media), 'msg.media', checkCloudinaryUrl(msg.media));
      }
      return (
        <li key={msg.objid} className={`${msg.msgPath} chatLogMsgs`}>
          {renderMessages(msg.msgtext)}
          <div className="msgLogged">
            {msg.emoji_object && msg.emoji_object?.image_path && (
              <div className="emoji-reaction-wrap">
                <img
                  src={msg.emoji_object.image_path}
                  alt="emoji"
                  className="left-emoji-reaction"
                />
              </div>
            )}
          </div>
          <span className="time">
            {moment(utcToLocalTimeConverter(msg.createDateTime)).format('HH:mm')}
            <span className="chat-status">{msg.chat_status}</span>
          </span>
        </li>
      );
    });
    setMsgHistoryList(msgListUIArray);
  }, [chatMsgData]);

  useEffect(() => {
    const sessionListUIArray = chatlogData.map(session => (
      // eslint-disable-next-line
      <li key={session.id} className={(currentOpenSession && session.id === currentOpenSession.id) ? 'active' : ''} onClick={() => setCurrentOpenSession(session)}>
        <span className="chatIcon">{iconChat()}</span>
        <div className="date-wrap">
          <p>{`${session.totalMsg} Messages`}</p>
          <p className="time">
            {moment(session.createDateTime).format('YYYY-MM-DD HH:mm')}
          </p>
        </div>
      </li>
    ));
    setSessionList(sessionListUIArray);
  }, [chatlogData]);

  function secondsToTimeString(seconds) {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
  }

  useEffect(() => {
    const { id } = props.match.params;
    if (callsLogData && callsLogData.length && id) {
      const sessionListUIArray1 = callsLogData?.filter(session => (
        Number(session.customer2cst_contact_objid) === Number(id)
      )).map(session => (
        session.video_voice_sessions
      ));
      if (sessionListUIArray1.length) {
        const sessionListUIArray = sessionListUIArray1[0].map((session) => {
          const seconds = session.duration_seconds;
          const formatted = secondsToTimeString(seconds);
          const callstatus = session.status;
          return (
            <li key={session.create_datetime} style={{ borderBottom: '1px solid #3E3F40' }}>
              {callstatus === 'complete'
                ? <span className="chatIcon">{session.call_type !== null ? <img src={CallIcon} alt="Call" /> : <img src={VideoIcon} alt="Call" />}</span>
                : <span className="chatIcon">{session.call_type !== null ? <img src={MissedCallIcon} alt="Call" /> : <img src={MissedVideoIcon} alt="Call" />}</span>
              }
              <div className="date-wrap">
                {/* <p>{`${session.totalMsg} Messages`}</p> */}
                <p>
                  {callstatus === 'complete'
                    ? <span>Duration:</span>
                    : (
                      <span>
                        Missed
                      </span>
                    )
                  }
                  {' '}
                  {' '}
                  {callstatus === 'complete' ? formatted : ''}
                </p>
                <p className="time" style={{ fontSize: '12px' }}>
                  {moment(session.create_datetime).format('YYYY-MM-DD HH:mm')}
                </p>
              </div>
            </li>
          );
        });
        setCallLogsList(sessionListUIArray);
      }
    }
    // eslint-disable-next-line react/destructuring-assignment
  }, [callsLogData, props.match.params.id]);

  // const createNewChatRoom = (updatedConv = []) => {
  //   const newSession = {
  //     id: 0,
  //     custId: customerId,
  //     title: customerData.customerName,
  //     messages: [],
  //     premium: 0,
  //     unread: 0,
  //     iscurrentlyActiveCust: true,
  //     custPic: customerData.customerProfilePic,
  //     sessionStatus: 'pending',
  //     customerchanneltype: '',
  //     performerContinueMsgCount: 0,
  //     lastMessageTime: new Date(),
  //     isPremiumCustomer: customerData.isPremiumCustomer,
  //     customerHostReview: customerData.customerHostReview,
  //     isCustomerOnline: customerData.isCustomerOnline,
  //   };
  //   const updatedConversation = updatedConv.map(conv => ({
  //     ...conv,
  //     iscurrentlyActiveCust: false,
  //   }));
  //   updatedConversation.unshift(newSession);
  //   setConversation(updatedConversation);
  //   history.push('/chat');
  // };

  const checkActiveSession = () => {
    const { id } = props.match.params;
    // setDisableResumeChat(true);
    if (id) {
      // checkActiveSessionAvailable(url)
      //   .then((result) => {
      //     const { data: { results = [] } = {} } = result;
      //     setConversation(mapChatLogData(results));
      //     setTimeout(() => {
      //       history.replace({ pathname: '/chat', state: { fromLogsPage: true, id } });
      //     });
      //     setDisableResumeChat(false);
      //   });
      setTimeout(() => {
        history.replace({ pathname: '/chat', state: { fromLogsPage: true, id } });
      }, 400);
    }
  };

  const handleLoadMore = () => {
    callApi(nextPage + 1);
    setNextPage(nextPage + 1);
  };

  const renderResumeModal = () => {
    if (customerData && customerData.isCustomerBlockedByChar) {
      return (
        <CustomModal
          body="You can not chat, you blocked this customer."
          buttonText="Ok"
          footer="Dismiss"
          footerLink={() => setIsResumeModalShow(false)}
          open={isResumeModalshow}
          onClick={() => setIsResumeModalShow(false)}
          toggle={() => setIsResumeModalShow(false)}
        />
      );
    }
    return (
      customerData && customerData.isChatInitiateBlock ? (
        <CustomModal
          header={customerData && customerData.isChatInitiateBlock ? 'Not Enabled' : 'Note'}
          body={customerData && customerData.isChatInitiateBlock
            ? 'This customer has not enabled you for initiating a chat.'
            : ''}
          buttonText={customerData && customerData.isChatInitiateBlock ? 'Ok' : 'Resume'}
          footer="Dismiss"
          footerLink={() => setIsResumeModalShow(false)}
          open={isResumeModalshow}
          onClick={customerData && customerData.isChatInitiateBlock
            ? () => setIsResumeModalShow(false) : checkActiveSession}
          toggle={() => setIsResumeModalShow(false)}
        />
      ) : ''
    );
  };

  const handleResumeChat = () => {
    if (customerData && (!customerData.isChatInitiateBlock)) {
      checkActiveSession();
    } else {
      setIsResumeModalShow(true);
    }
  };

  const handleViewMessage = (customer) => {
    const missedCalls = JSON.parse(localStorage.getItem('missedCallers')) || [];

    // Find the index of the object with the matching messageId
    const index = missedCalls.findIndex(call => Number(call.id) === Number(customer.customerObjid));

    if (index !== -1) {
      // If the object exists in the array, reset its count or remove it
      const updatedMissedCalls = [...missedCalls];
      updatedMissedCalls.splice(index, 1); // Remove object from the array
      // if (updatedMissedCalls[index].count > 1) {
      //   updatedMissedCalls[index].count = 0; // Reset count
      // } else {
      //   updatedMissedCalls.splice(index, 1); // Remove object from the array
      // }
      // Update missedCalls array in local storage
      localStorage.setItem('missedCallers', JSON.stringify(updatedMissedCalls));
      dispatch(postMissedCallsData(updatedMissedCalls));
    }
  };

  useEffect(() => {
    if (pathname.startsWith('/customercallslog/') && customerData && customerData?.missedCallCount) {
      handleViewMessage(customerData);
    }
  }, []);

  return (
    <>
      {renderResumeModal()}
      {isOpenPhoto && (
        <Lightbox
          ClassName="Lightbox"
          mainSrc={mainSrcPicCon}
          onCloseRequest={() => setOpenPhoto(false)}
        />
      )}

      <section>
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <h3 className="topHead">
            {pathname.startsWith('/customercallslog/') ? 'Call Logs' : 'Chat Logs'}
          </h3>
          <Button
            color="primary"
            outline
            type="button"
            onClick={history.goBack}
            className="mt-4 back-btn"
          >
            Back
          </Button>
        </div>
        <div className="chatlog-card-wrapper">
          {pathname.startsWith('/customercallslog/') ? (
            <Card className="col-lg-12">
              <CardHeader>
                <span className="cust_img_with_crown">
                  <img src={(customerData && customerData.customerProfilePic) || customerAvatar} alt="customer Profile" height="50px" width="50px" />
                  {customerData && customerData.isPremiumCustomer ? <img src={crownIcon} className="is_premium_customer" alt="crown" /> : ''}
                  {customerData && showOnlineStatus(
                    customerData?.isCustomerOnline, customerData?.sendEmailNotification,
                  )}
                  {/* {customerData
                  && customerData.isCustomerOnline
                  ? <span className="online-small-icon" />
                  : <span className="offline-small-icon" />} */}
                </span>
                <span className="customer-name">{customerData && customerData.customerName}</span>
              </CardHeader>
              {/* {chatLogLoader && nextPage === 1 ? <LoadingSpinner /> : ( */}
              <ul>
                {/* <InfiniteScroll
                    dataLength={callLogsList.length}
                    next={handleLoadMore}
                    hasMore={next}
                    loader={<LoadingSpinner />}
                    height={400}
                  >
                    {callLogsList}
                  </InfiniteScroll> */}
                {callLogsList}
              </ul>
              {/* )} */}
            </Card>
          ) : pathname.startsWith('/customerChatlog/') ? (
            <>
              <Card className="col-lg-5">
                <CardHeader>
                  <span className="cust_img_with_crown">
                    <img src={(customerData && customerData.customerProfilePic) || customerAvatar} alt="customer Profile" height="50px" width="50px" />
                    {customerData && customerData.isPremiumCustomer ? <img src={crownIcon} className="is_premium_customer" alt="crown" /> : ''}
                    {customerData && showOnlineStatus(
                    customerData?.isCustomerOnline, customerData?.sendEmailNotification,
                    )}
                    {/* {customerData && customerData.isCustomerOnline
                    ? <span className="online-small-icon" />
                    : <span className="offline-small-icon" />} */}
                  </span>
                  <span className="customer-name">{customerData && customerData.customerName}</span>
                </CardHeader>
                {chatLogLoader && nextPage === 1 ? <LoadingSpinner /> : (
                  <ul>
                    <InfiniteScroll
                      dataLength={sessionList.length}
                      next={handleLoadMore}
                      hasMore={next}
                      loader={<LoadingSpinner />}
                      height={400}
                    >
                      {sessionList}
                    </InfiniteScroll>
                  </ul>
                )}
              </Card>
              <Card className="col-md-7">
                {currentOpenSession ? (
                  <>
                    <CardHeader>
                      {moment(currentOpenSession.createDateTime).format('YYYY-MM-DD HH:MM')}
                    </CardHeader>
                    <CardBody>
                      {msgHistoryLoader ? <LoadingSpinner /> : (
                        <ul>
                          {msgHistoryList}
                        </ul>
                      )}
                    </CardBody>
                    <CardFooter>
                      <Button
                        color="primary"
                        outline
                        type="button"
                        // onClick={checkActiveSession}
                        onClick={handleResumeChat}
                        disabled={disableResumeChat}
                      >
                        Resume Chat
                      </Button>
                    </CardFooter>
                  </>
                ) : (
                  <div className="d-flex align-items-center no-convo-wrap">
                    <svg width="131" height="130" viewBox="0 0 131 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M65.675 130C54.2353 129.917 43.0174 126.834 33.1414 121.06V121.06L6.79185 129.731C6.30359 129.827 5.8013 129.827 5.31305 129.731C4.82479 129.827 4.3225 129.827 3.83425 129.731C2.75292 129.421 1.82124 128.728 1.21273 127.782C0.962478 127.221 0.833136 126.614 0.833136 126.001C0.833136 125.387 0.962478 124.78 1.21273 124.219L9.88389 98.2058V97.8697C3.94776 87.8771 0.769954 76.488 0.674988 64.8656C0.701565 52.0156 4.53633 39.4617 11.6948 28.7902C18.8532 18.1187 29.0141 9.80844 40.8936 4.90936C52.7731 0.0102711 65.8382 -1.25778 78.4381 1.26542C91.038 3.78862 102.607 9.98985 111.684 19.0856C120.761 28.1813 126.939 39.7634 129.436 52.3685C131.933 64.9736 130.638 78.036 125.714 89.9054C120.791 101.775 112.459 111.918 101.773 119.055C91.0869 126.191 78.5251 130 65.675 130V130ZM33.4103 111.381C34.2922 111.389 35.154 111.645 35.8974 112.12C47.4619 119.418 61.3109 122.198 74.796 119.927C88.2812 117.657 100.456 110.495 108.993 99.8118C117.53 89.1287 121.829 75.6738 121.069 62.0199C120.309 48.3661 114.543 35.4715 104.873 25.8018C95.2036 16.1322 82.309 10.366 68.6551 9.60597C55.0013 8.84594 41.5463 13.1454 30.8632 21.6821C20.1801 30.2189 13.0185 42.3939 10.7478 55.879C8.47708 69.3642 11.2567 83.2131 18.555 94.7777C18.9208 95.3502 19.1583 95.9951 19.2511 96.6681C19.3439 97.3411 19.2899 98.0262 19.0928 98.6763L12.371 118.102L31.797 111.381C32.3274 111.255 32.8799 111.255 33.4103 111.381V111.381Z" fill="#595E68" />
                      <path d="M65.6751 69.6387C64.9994 69.7819 64.3002 69.7724 63.6287 69.6109C62.9571 69.4494 62.3301 69.1399 61.7934 68.7051C61.2568 68.2703 60.824 67.7211 60.5267 67.0976C60.2295 66.4741 60.0752 65.7921 60.0752 65.1014C60.0752 64.4107 60.2295 63.7287 60.5267 63.1053C60.824 62.4818 61.2568 61.9326 61.7934 61.4978C62.3301 61.063 62.9571 60.7535 63.6287 60.592C64.3002 60.4305 64.9994 60.421 65.6751 60.5642C66.8004 60.7004 67.837 61.2441 68.5888 62.0924C69.3407 62.9408 69.7558 64.0351 69.7558 65.1687C69.7558 66.3022 69.3407 67.3965 68.5888 68.2449C67.837 69.0932 66.8004 69.6369 65.6751 69.7731V69.6387Z" fill="#595E68" />
                      <path d="M43.6948 69.6379C42.7775 69.6379 41.8808 69.3659 41.1181 68.8563C40.3553 68.3466 39.7609 67.6223 39.4098 66.7748C39.0588 65.9273 38.9669 64.9947 39.1459 64.095C39.3248 63.1953 39.7666 62.3689 40.4152 61.7203C41.0639 61.0716 41.8903 60.6299 42.79 60.4509C43.6897 60.272 44.6222 60.3638 45.4697 60.7149C46.3172 61.0659 47.0416 61.6604 47.5512 62.4231C48.0609 63.1858 48.3329 64.0826 48.3329 64.9999C48.3329 66.23 47.8442 67.4097 46.9744 68.2795C46.1046 69.1493 44.9249 69.6379 43.6948 69.6379V69.6379Z" fill="#595E68" />
                      <path d="M87.5881 69.6379C86.6708 69.6379 85.7741 69.3659 85.0114 68.8563C84.2486 68.3466 83.6542 67.6223 83.3031 66.7748C82.9521 65.9273 82.8602 64.9947 83.0392 64.095C83.2182 63.1953 83.6599 62.3689 84.3085 61.7203C84.9572 61.0716 85.7836 60.6299 86.6833 60.4509C87.583 60.272 88.5156 60.3638 89.363 60.7149C90.2105 61.0659 90.9349 61.6604 91.4445 62.4231C91.9542 63.1858 92.2262 64.0826 92.2262 64.9999C92.2262 66.23 91.7375 67.4097 90.8677 68.2795C89.9979 69.1493 88.8182 69.6379 87.5881 69.6379V69.6379Z" fill="#595E68" />
                    </svg>
                    <p>Sorry, no conversation to show</p>
                  </div>
                )}
              </Card>
            </>
          ) : ''}

        </div>
      </section>
    </>
  );
};

Chatlog.defaultProps = {
  chatlogData: [],
  callsLogData: [],
  perfObjid: 0,
  chatMsgData: [],
  loaders: {},
  minMessageToInitaiteChat: 0,
  next: null,
};

Chatlog.propTypes = {
  chatlogData: PropTypes.instanceOf(Array),
  callsLogData: PropTypes.instanceOf(Array),
  getCustomerChatLog: PropTypes.func.isRequired,
  removeChatMsgHistory: PropTypes.func.isRequired,
  checkActiveSessionAvailable: PropTypes.func.isRequired,
  setConversation: PropTypes.func.isRequired,
  // conversation: PropTypes.instanceOf(Array).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  getSessionMsgs: PropTypes.func.isRequired,
  perfObjid: PropTypes.number,
  history: PropTypes.instanceOf(Object).isRequired,
  chatMsgData: PropTypes.instanceOf(Array),
  loaders: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object).isRequired,
  minMessageToInitaiteChat: PropTypes.string,
  isTier2: PropTypes.bool.isRequired,
  next: PropTypes.string,
};

export default withRouter(Chatlog);
