export default function (state = [], action = {}) {
  switch (action.type) {
    case 'GET_USER_IP_ADDRESS_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'GET_USER_IP_ADDRESS_FULFILLED': {
      const { data } = action.payload;
      const ipAddress = data ? data.split('"')[3] : '';
      return {
        ...state,
        isLoading: false,
        data: ipAddress,
        error: false,
      };
    }
    case 'GET_USER_IP_ADDRESS_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    default:
      return {
        ...state,
      };
  }
}
