/* eslint-disable camelcase */
import { connect } from 'react-redux';
import CustomAlert from '../components/CustomAlert';
import manageAlertDisplay from '../actions/actionHandleAlert';

export const mapStateToProps = state => ({
  visible: state.alert.visible,
  message: state.alert.message,
});

export const mapDispatchToProps = dispatch => ({
  dismissAlert: () => dispatch(manageAlertDisplay()),
});

const CustomAlertContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomAlert);

export default CustomAlertContainer;
