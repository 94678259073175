import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
} from 'reactstrap';
import './style.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import FeedFavIcon from '../../../assets/svgs/feed-fav-icon';
import DisLikeHeartIcon from '../../../assets/svgs/disLikeHeartIcon';
import FeedUserIcon from '../../../assets/svgs/feed-user-icon';

function CustomerGrid(props) {
  const {
    id,
    image,
    userName,
    bio,
    pictureControl,
    isLiked,
    changeImageLikeStauts,
    imgObjId,
  } = props;

  const [isPhotoLiked, setIsPhotoLiked] = useState(isLiked);
  const [likeButtonDisable, setLikeButtonDisable] = useState(false);

  const chagePhotoStatus = (photoLikeStatus) => {
    setLikeButtonDisable(true);
    setIsPhotoLiked(!photoLikeStatus);
    changeImageLikeStauts(!photoLikeStatus, imgObjId)
      .then(() => {
        setLikeButtonDisable(false);
      })
      .catch(() => {
        setIsPhotoLiked(photoLikeStatus);
        setLikeButtonDisable(false);
      });
  };

  return (
    <li className="feed-card-li">
      <Card className="feed-card-wrap">
        <button
          type="button"
          onClick={pictureControl}
          className="p-0 border-0 imgBtn"
        >
          <CardImg
            className="feedCardImage"
            variant="top"
            alt="feedImage"
            src={image}
          />
        </button>
        <CardBody>
          <button
            type="button"
            className="addWish like"
            onClick={() => chagePhotoStatus(isPhotoLiked)}
            disabled={likeButtonDisable}
          >
            {isPhotoLiked ? <FeedFavIcon /> : <DisLikeHeartIcon />}
          </button>
          <Link
            to={`/customerProfile/${id}`}
            className="feed-user-icon like"
          >
            <FeedUserIcon />
          </Link>
          <CardTitle>{userName}</CardTitle>
          <CardText>{bio}</CardText>
        </CardBody>
      </Card>
    </li>
  );
}

CustomerGrid.defaultProps = {
  bio: '',
};

CustomerGrid.propTypes = {
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  bio: PropTypes.string,
  pictureControl: PropTypes.func.isRequired,
  isLiked: PropTypes.bool.isRequired,
  changeImageLikeStauts: PropTypes.func.isRequired,
  imgObjId: PropTypes.number.isRequired,
};

export default CustomerGrid;
