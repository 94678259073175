/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Modal, ModalHeader, ModalBody, Button, Label, Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import './style.scss';

const VoiceChatRateModal = (props) => {
  const {
    className,
    open,
    toggle,
    updateProfile,
    voiceChatRateRes,
    charId,
    takeFieldValueFun,
  } = props;

  const minVoiceCallRate = JSON.parse(localStorage.getItem('minVoiceCallRate'));
  const maxVoiceCallRate = JSON.parse(localStorage.getItem('maxVoiceCallRate'));

  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setValue(voiceChatRateRes);
  }, [voiceChatRateRes]);

  const handleSubmit = () => {
    const maxNum = Number(maxVoiceCallRate) || 200;
    const minNum = Number(minVoiceCallRate) || 28;
    if (!value) {
      setError(true);
      setErrorMsg("Field can't be empty!");
      setTimeout(() => {
        setError(false);
      }, 3000);
      return;
    }

    if (value.includes('.')) {
      setError(true);
      setErrorMsg("Please enter numbers only.");
      setTimeout(() => {
        setError(false);
      }, 3000);
      return;
    }

    if (value < minNum) {
      setError(true);
      setErrorMsg(`Credit Must be ${minNum} or Greater!`);
      setTimeout(() => {
        setError(false);
      }, 3000);
      return;
    }
    if (value > maxNum) {
      setError(true);
      setErrorMsg(`Credit Must be less then ${maxNum}!`);
      setTimeout(() => {
        setError(false);
      }, 3000);
      return;
    }
    const payload = {
      id: charId,
      voiceChatRate: value,
    };
    updateProfile(payload);
    takeFieldValueFun(value);
    toggle();
  };

  const onChangeHandler = (e) => {
    if (isNaN(e.target.value)) {
      setError(true);
      setErrorMsg("Please enter numbers only");
      setTimeout(() => {
        setError(false);
      }, 3000);
      return false;
    }
    if (e.target.value.charAt(0) === '0') {
      setError(true);
      setErrorMsg("First digit can't be 0");
      setTimeout(() => {
        setError(false);
      }, 3000);
      return false;
    }
    setValue(e.target.value);
  };

  return (
    <Modal
      className={`EditInfo-Modal modal-center ${className}`}
      isOpen={open}
      toggle={toggle}
      backdrop={false}
    >
      <ModalHeader className="chat-modal-header" toggle={toggle} />
      <ModalBody className="chat-modal-body">
        <Form className="mt-3">
          <Label htmlFor="headline">
            Enter Voice Call Rate (Credits/Min)
          </Label>
          {/* {renderVoiceChatRate()} */}
          <input
            id="creditsLabel"
            name="creditInput"
            type="text"
            className="form-control credit-field"
            value={value}
            onChange={onChangeHandler}
            placeholder="Enter Amount (Credits)"
          />
          { error
            && <span style={{color: 'red', marginTop: '5px', display: 'block', fontSize: '12px'}}>{errorMsg}</span>
          } 
        </Form>
        <div className="text-center">
          <Button type="submit" onClick={handleSubmit} color="primary" className="doneBtn text-white">Save</Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

VoiceChatRateModal.defaultProps = {
  className: '',
  open: false,
  voiceChatRateRes: 0,
  charId: '',
  errors: {
    updateChar: false,
    // headline: '',
  },
};

VoiceChatRateModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  takeFieldValueFun: PropTypes.func.isRequired,
  voiceChatRateRes: PropTypes.number,
  charId: PropTypes.number,
  errors: PropTypes.shape({
    updateChar: PropTypes.bool,
    // headline: PropTypes.string,
  }),
};

export default VoiceChatRateModal;
