import { connect } from 'react-redux';
import ContinueToChatInMobile from '../scenes/ContinueToChatInMobile';
import mobileChatAction from '../actions/actionMobileChat';
import getSysConfigAction from '../actions/actionGetSysConfig';
import getPerformer from '../actions/actionGetPerformer';

export const mapStateToProps = (state) => {
  const { mobileLogin: { data: results } } = state;
  return ({
    mobileLoginData: results,
  });
};

export const mapDispatchToProps = dispatch => ({
  mobileChatActionFun: data => dispatch(mobileChatAction(data)),
  getSysConfig: url => dispatch(getSysConfigAction(url)),
  getPerformer: objectId => dispatch(getPerformer(objectId)),
});

const MobileChatContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContinueToChatInMobile);

export default MobileChatContainer;
