import { connect } from 'react-redux';

import Roulette from '../scenes/Roulette';
import handleRoulette from '../actions/actionHandleRoulette';
import rouletteHeartbeat from '../actions/actionRouletteHeartbeat';
import putStatusUpdate from '../actions/actionPutStatus';
import { updatePresence } from '../actions/actionGetPresense';

const getTimeoutConfig = (sysConfig = []) => {
  const timeout = sysConfig.find(result => result.config_name === 'ROULETTE_SCREEN_TIMEOUT_PERFORMER');
  return (timeout && parseInt(timeout?.value, 10)) || 0;
};

const mapStateToProps = (state) => {
  const {
    roulette: {
      data: {
        isRouletteEnable = false,
        isHeartBeatApiOn = 0,
        currentTimeConst,
        notAvailableTimer,
      } = {},
    } = {},
    presence: {
      data:
      {
        chatStatus = false,
        phoneStatus = false,
        videoStatus = false,
      } = {}, isLoading = false,
    } = {},
    login: { data: { perfcharacter = [] } = {} } = {},
    mobileLogin: { mobileData: { charObjID = 0 } = {} } = {},
  } = state;

  const localData = JSON.parse(localStorage.getItem('mobileChatData'));
  let charId = 0;
  if (perfcharacter.length > 0) {
    const { objid } = perfcharacter[0];
    charId = objid;
  }
  if (charObjID) {
    charId = charObjID || localData.character_objid;
  }
  // let presRoulette = false;
  // if (presResults.length > 0) {
  //   presRoulette = true;
  // }

  const { sysConfig: { data: { results: sysConfigResults = [] } = {} } = {} } = state;
  const hearbeatTimeoutConfig = sysConfigResults.length ? getTimeoutConfig(sysConfigResults) : 0;

  return {
    isRouletteEnable,
    isHeartBeatApiOn,
    currentTimeConst,
    objid: charId,
    // presRoulette,
    chatStatus,
    phoneStatus,
    videoStatus,
    isLoading,
    notAvailableTimer,
    hearbeatTimeoutConfig,
  };
};

const mapDispatchToProps = dispatch => ({
  handleRoulette: data => dispatch(handleRoulette(data)),
  updateStatus: sendData => dispatch(putStatusUpdate(sendData)),
  rouletteHeartbeat: data => dispatch(rouletteHeartbeat(data)),
  updatePresence: data => dispatch(updatePresence(data)),
});

const RouletteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Roulette);

export default RouletteContainer;
