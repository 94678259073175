import { connect } from 'react-redux';
import TestSetup from '../scenes/TestSetup';
import getCharacterProductList from '../actions/actionGetCharacterProductList';

export const mapDispatchToProps = dispatch => ({
  getProductList: url => dispatch(getCharacterProductList(url)),
});


const TestSetupContainer = connect(
  mapDispatchToProps,
)(TestSetup);

export default TestSetupContainer;
