export default function (state = [], action = {}) {
  switch (action.type) {
    case 'GET_PAYMENT_HISTORY_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'GET_PAYMENT_HISTORY_FULFILLED':
      return {
        ...state,
        isLoading: false,
        data: { ...action.payload.data },
        error: false,
      };

    case 'GET_PAYMENT_HISTORY_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case 'GET_TRANSACTIONS_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'GET_TRANSACTIONS_FULFILLED':
      return {
        ...state,
        isLoading: false,
        data: { ...action.payload.data },
        error: false,
      };

    case 'GET_TRANSACTIONS_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case 'GET_TIPS_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'GET_TIPS_FULFILLED':
      return {
        ...state,
        isLoading: false,
        data: { ...action.payload.data },
        error: false,
      };

    case 'GET_TIPS_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case 'REFUND_TIP_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'REFUND_TIP_FULFILLED':
      return {
        ...state,
        isLoading: false,
        error: false,
      };

    case 'REFUND_TIP_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case 'POST_PAYOUT_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'POST_PAYOUT_FULFILLED':
      return {
        ...state,
        isLoading: false,
        error: false,
      };

    case 'POST_PAYOUT_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    default:
      return {
        ...state,
      };
  }
}
