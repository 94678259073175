import React from 'react';
import {
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
} from 'reactstrap';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
// import marked from 'marked';
import notificationBell from '../../../../assets/svgs/notification';
import useMarked from '../../../../components/UseMarkedHook';
import './style.scss';
import NoDataAvailable from '../../../../components/NoDataAvailable';

export const MessageCardProps = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.number,
  isurgent: PropTypes.bool,
  subject: PropTypes.string,
  arrivalTime: PropTypes.string,
}));

// base element use for open link in new tab.
export const renderMessages = (messages) => {
  if (messages && messages.length > 0) {
    return (
      messages.map((message) => {
        const html = useMarked(message.messageContent);
        return (
          <Row key={message.id} className="notificationBox py-3">
            <Col xs={9} className="notificationMsg">
              <div className="d-flex">
                {message.isurgent ? notificationBell('#f07563') : notificationBell('#8e8f92')}
                <div className="pl-12">
                  <h6 className={message.isurgent ? 'latestNotificationText mb-0' : 'mb-0'}>
                    {message.subject}
                  </h6>
                  <div className="pera mb-0">
                    <base target="_blank" />
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={3} className="text-right">
              <small className="text-muted">{message.arrivalTime}</small>
            </Col>
          </Row>
        );
      })
    );
  }

  return <NoDataAvailable />;
};

const MessagesCard = (props) => {
  const { messages, count } = props;
  const sortedMessages = sortBy(messages, m => !m.isurgent);

  return (
    <Card className="customCard systemMsg bg-dark h-100">
      <CardHeader className="customCardHeader">
        <h2>
          System Messages
          {
            count ? (
              <span className="badge badge-secondary text-primary customBadge pt-1">
                {count}
              </span>
            ) : ''
          }
        </h2>
      </CardHeader>
      <CardBody className="mr-10">
        {renderMessages(sortedMessages)}
      </CardBody>
    </Card>
  );
};

MessagesCard.defaultProps = {
  messages: [{
    id: 0,
    isurgent: false,
    subject: 'N/A',
    arrivalTime: 'N/A',
  }],
  count: 0,
};

MessagesCard.propTypes = {
  messages: MessageCardProps,
  count: PropTypes.number,
};

export default MessagesCard;
