import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PayoutSettings from '../scenes/PayoutSettings';
import postPayoutDetails from '../actions/actionPayoutSettings';

const mapStateToProps = (state) => {
  const {
    payoutSettings, login, performer, form,
  } = state;
  const loginData = (login?.data) || {};
  const {
    perfperformer: { objid: perfObjId },
  } = loginData;
  const performerData = (performer?.data) || {};
  const { perf2cst_contact: perf2cstContactId } = performerData;
  const { payoutSettingsForm } = form;
  return {
    payoutSettings,
    perfObjId,
    perf2cstContactId,
    payoutSettingsForm,
  };
};

const mapDispatchToProps = dispatch => ({
  postPayoutDetails: data => dispatch(postPayoutDetails(data)),
  //   getPhonebookData: data => dispatch(getPhonebookData(data)),
});

const PayoutSettingsForm = reduxForm({
  form: 'payoutSettingsForm',
})(PayoutSettings);

const PayoutSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PayoutSettingsForm);

export default PayoutSettingsContainer;
