import React from 'react';
import {
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
} from 'reactstrap';
import PropTypes from 'prop-types';

import NoDataAvailable from '../../../../components/NoDataAvailable';
import { renderRating, showOnlineStatus } from '../../../../utils/helpers';
import './style.scss';
import reviewAvatar from '../../../../assets/svgs/review avatar.svg';
import crownIcon from '../../../../assets/svgs/icon-crown.svg';

export const MyReviewsCardProps = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.number,
  rating: PropTypes.number,
  comment: PropTypes.string,
  name: PropTypes.string,
}));

export const renderReviews = (reviews) => {
  if (reviews && reviews.length > 0) {
    return (
      reviews.map(review => (
        <div key={review.id} className="reviewDetails">
          <div className="d-flex align-items-center">
            <div className="review_image_crown">
              {
                review.profilePic
                  ? <img src={review.profilePic} alt="ProfilePic" className="review-profile" width="41px" height="41px" />
                  : <img src={reviewAvatar} alt="revew" />
              }
              {review.isPremiumCustomer ? <img src={crownIcon} alt="crown" className="is_premium_customer" /> : ''}
              {showOnlineStatus(review.isCustomerOnline, review.sendEmailNotification)}
            </div>
            <div className="pl-12 flex-grow-1">
              <Row className="align-items-center">
                <Col className="reviewHead"><h6 className="mb-0 pt-1">{review.name}</h6></Col>
                <Col className="reviewRating text-right">{renderRating(review.rating)}</Col>
              </Row>
              {/* <Row>
                <Col className="reviewDesc">
                  <p className="mb-0">{review.comment}</p>
                </Col>
              </Row> */}
            </div>
          </div>

        </div>
      ))
    );
  }

  return <NoDataAvailable />;
};

const MyReviewsCard = (props) => {
  const { reviews, history } = props;

  return (
    <Card className="customCard reviewCard bg-dark h-100">
      <CardHeader className="customCardHeader">
        <h2>
          <button
            type="button"
            className="bg-none text-white"
            onClick={() => history.push('/reviews')}
          >
            My Reviews
          </button>
        </h2>
      </CardHeader>
      <CardBody className="mr-10">
        {renderReviews(reviews)}
      </CardBody>
    </Card>
  );
};

MyReviewsCard.defaultProps = {
  reviews: [{
    id: 0,
    rating: 0,
    comment: 'N/A',
    name: 0,
  }],
};

MyReviewsCard.propTypes = {
  reviews: MyReviewsCardProps,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default MyReviewsCard;
