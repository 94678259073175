const postWhispererData = data => ({
  type: 'POST_WHISPERER_DATA',
  payload: data,
});

export const pushNotificationAlert = data => ({
  type: 'PUSH_NOTIFICATION_ALERT',
  payload: data,
});

export default postWhispererData;
