import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import PropTypes from 'prop-types';

import LoadingSpinner from '../../utils/LoadingSpinner';
import CustomerGrid from './CustomerGrid';
import InitialScreen from '../../components/NoDataAvailable';

import './style.scss';

function FeedMenu(props) {
  const {
    getFeedData,
    customerFeedData,
    performerObjId,
    loadingData,
    cutomerImageLike,
    cutomerImageDislike,
  } = props;

  const [isOpenPhoto, setOpenPhoto] = useState(false);
  const [mainSrcPicCon, setMainSrcPicCon] = useState(null);


  const pictureControl = (event = {}) => {
    const imageFile = event && event.target && event.target.src;
    if (imageFile) {
      setOpenPhoto(true);
      setMainSrcPicCon(imageFile);
    }
  };

  const closePhoto = () => {
    setOpenPhoto(false);
  };

  useEffect(() => {
    if (performerObjId) {
      const url = `/customermedia/list/?character=${performerObjId}&media_type=2&hide_blocked=true`;
      getFeedData(url);
    }
  }, [performerObjId]);

  const changeImageLikeStauts = (isPhotoLiked, imgObjId) => {
    const formData = new FormData();
    formData.append('character', performerObjId);
    formData.append('image', imgObjId);
    if (isPhotoLiked) {
      // Like
      return cutomerImageLike(formData);
    }
    // DisLike
    return cutomerImageDislike(formData);
  };

  return (
    <>
      {isOpenPhoto && (
        <Lightbox
          ClassName="Lightbox"
          mainSrc={mainSrcPicCon}
          onCloseRequest={closePhoto}
        />
      )}
      {loadingData ? (
        <LoadingSpinner />
      ) : (
        <ul className="feed-card-ul">
          {customerFeedData.length ? (
            customerFeedData.map(post => (
              <CustomerGrid
                key={post.imageId}
                id={post.id}
                imgObjId={post.imageId}
                userName={post.name}
                image={post.image}
                bio={post.bio}
                isLiked={post.isLiked}
                pictureControl={pictureControl}
                changeImageLikeStauts={changeImageLikeStauts}
              />
            ))
          ) : (
            <InitialScreen />
          )}
        </ul>
      )}
    </>
  );
}

FeedMenu.defaultProps = {
  customerFeedData: [],
  performerObjId: 0,
  loadingData: false,
};

FeedMenu.propTypes = {
  getFeedData: PropTypes.func.isRequired,
  customerFeedData: PropTypes.instanceOf(Array),
  performerObjId: PropTypes.number,
  loadingData: PropTypes.bool,
  cutomerImageLike: PropTypes.func.isRequired,
  cutomerImageDislike: PropTypes.func.isRequired,
};

export default FeedMenu;
