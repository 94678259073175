/* eslint-disable */
import React from 'react';

export default () => (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25" cy="25" r="23.3967" stroke="#FD7663" stroke-width="3.2066"/>
        <g clip-path="url(#clip0_4952_2571)">
        <path d="M25.0005 10.7656C21.0862 10.7656 17.9016 13.9502 17.9016 17.8645C17.9016 21.7788 21.0862 24.9633 25.0005 24.9633C28.9148 24.9633 32.0993 21.7788 32.0993 17.8645C32.0993 13.9502 28.9148 10.7656 25.0005 10.7656Z" fill="white"/>
        <path d="M33.8327 29.607C31.8892 27.6336 29.3127 26.5469 26.5779 26.5469H23.4229C20.6881 26.5469 18.1115 27.6336 16.168 29.607C14.234 31.5707 13.1689 34.1628 13.1689 36.9059C13.1689 37.3416 13.5221 37.6947 13.9577 37.6947H36.043C36.4787 37.6947 36.8318 37.3416 36.8318 36.9059C36.8318 34.1628 35.7667 31.5707 33.8327 29.607Z" fill="white"/>
        </g>
        <line x1="37.4531" y1="6.32254" x2="11.2992" y2="42.4764" stroke="#FD7663" stroke-width="3.2066"/>
        <defs>
        <clipPath id="clip0_4952_2571">
        <rect width="26.9231" height="26.9231" fill="white" transform="translate(11.5388 10.7695)"/>
        </clipPath>
        </defs>
    </svg>
);

/* eslint-enable */
