import React from 'react';
import { connect } from 'react-redux';

import getPerformer, { updatePerformerData } from '../actions/actionGetPerformer';
import postRequsetPayout from '../actions/actionPostRequestPayout';

export const CreditsWrapper = props => <props.component {...props} />;

const changeCreditIntoAmount = (amount, configResult) => {
  const creditToUsd = configResult.find(config => config.config_name === 'Credit_to_USD');
  return (Number(amount) * Number(creditToUsd.value));
};

const getCreditData = (performerD, amountAffiliate) => ({
  amount: performerD.unrequested_usd_amt,
  credits: performerD.unrequested_credits,
  affiliateCredit: performerD.total_affiliate_revenue_credits,
  affiliateAmount: amountAffiliate.toFixed(2),
});

export const mapStateToProps = (state) => {
  const { performer: { data: performerData = {}, isLoading = false } = {} } = state;
  const { login: { data: { perfperformer: { objid } } = {} } } = state;
  const { sysConfig: { data: { results: configResult = [] } = {} } = {} } = state;
  const {
    payoutSettings,
  } = state;

  let amountAffiliate = 0;
  if (performerData && performerData.total_affiliate_revenue_credits) {
    amountAffiliate = changeCreditIntoAmount(
      performerData.total_affiliate_revenue_credits, configResult,
    );
  }
  const creditData = getCreditData(performerData, amountAffiliate);
  return {
    ...creditData,
    objectId: objid,
    isLoading,
    performerData,
    payoutSettings,
  };
};

export const mapDispatchToProps = dispatch => ({
  getPerformer: objectId => dispatch(getPerformer(objectId)),
  requestPayOut: data => dispatch(postRequsetPayout(data)),
  setPerformersData: data => dispatch(updatePerformerData(data)),
});

const CreditsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditsWrapper);

export default CreditsContainer;
