/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Emoji } from 'emoji-picker-react';
import EmojiSVG from '../../../../../assets/svgs/Emoji.svg';

import EmojiList from './EmojiList';
import api from '../../../../../actions/api';
import { utcToLocalTimeConverter } from '../../../../../utils/helpers';
// import EmojiSlider from './EmojiSlider'; // Your EmojiSlider component
import setConversationAction from '../../../../../actions/actionChat';

const EmojiSelector = ({
  activeUserId, msgId, incomingEmojiDB, msgTime, isActiveSession, sessionID, localSessionID,
}) => {
  const [showEmojiList, setShowEmojiList] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState('');
  const conversations = useSelector(state => state.chat.conv);
  const dispatch = useDispatch();

  useEffect(() => {
    if (incomingEmojiDB?.emojiUnicode) {
      setSelectedEmoji(incomingEmojiDB?.emojiUnicode);
    } else if (incomingEmojiDB?.imagePath) {
      setSelectedEmoji(incomingEmojiDB?.imagePath);
    }
  }, [incomingEmojiDB?.emojiUnicode, incomingEmojiDB?.imagePath]);

  const renderSelectedEmoji = (selectedEmo) => {
    if (selectedEmo.includes('https://')) {
      return <img src={selectedEmo} alt="Emoji" />;
    }
    return <Emoji unified={selectedEmo} size="25" lazyLoad />;
  };

  useEffect(() => {
    const msg = document.getElementById(`msg${msgId}`);
    if (msg && showEmojiList) {
      // eslint-disable-next-line no-unused-expressions, array-callback-return
      msg.firstElementChild.classList.add('dynamic-slider-cls');
    } else {
      // eslint-disable-next-line no-unused-expressions, array-callback-return
      msg.firstElementChild.classList.remove('dynamic-slider-cls');
    }
  }, [showEmojiList]);

  const onSelectedEmoji = (emoji) => {
    if (isActiveSession && sessionID === localSessionID) {
      setSelectedEmoji(emoji.unified);
      setShowEmojiList(false);
      document.querySelector('.chatOutMsgTime').classList.add('isReacted');
      const activeSession = conversations?.find(
        session => session.objid === activeUserId,
      );
      if (activeSession) {
        // eslint-disable-next-line prefer-destructuring
        const messages = activeSession.messages;
        // eslint-disable-next-line consistent-return, array-callback-return
        const updatedMessages = messages.map((msg) => {
          if (Number(msg.objid) === Number(msgId)) {
            return {
              ...msg,
              emoji_object: `${emoji.unified}`,
            };
          }
          return msg;
        });

        const UpdatedConversation = conversations?.map((sess) => {
          if (sess.objid === activeUserId) {
            return {
              ...sess,
              messages: updatedMessages,
            };
          }
          return sess;
        });
        dispatch(setConversationAction(UpdatedConversation));
      }
    }
  };


  const deleteHandler = async () => {
    if (isActiveSession && sessionID === localSessionID) {
      try {
        await api.delete(`/chatmsgemoji/delete/${msgId}/`);
        setSelectedEmoji('');
        const activeSession = conversations?.find(
          session => session.objid === activeUserId,
        );
        if (isActiveSession && sessionID === localSessionID) {
          // eslint-disable-next-line prefer-destructuring
          const messages = activeSession.messages;
          // eslint-disable-next-line consistent-return, array-callback-return
          const updatedMessages = messages.map((msg) => {
            if (Number(msg.objid) === Number(msgId)) {
              return {
                ...msg,
                emoji_object: null,
              };
            }
            return msg;
          });
          const UpdatedConversation = conversations?.map((sess) => {
            if (sess.objid === activeUserId) {
              return {
                ...sess,
                messages: updatedMessages,
              };
            }
            return sess;
          });
          dispatch(setConversationAction(UpdatedConversation));
        }
      } catch (error) {
        console.error('Error Deleting emoji:', error);
      }
    }
  };

  return (
    <>
      <div className="message">
        <div
          className="EmojiSelector"
          onClick={() => setShowEmojiList(!showEmojiList)}
        >
          <img src={EmojiSVG} alt="" />
        </div>
        {showEmojiList && isActiveSession && sessionID === localSessionID && (
        <EmojiList
          activeUserId={activeUserId}
          onSelectedEmoji={onSelectedEmoji}
          msgId={msgId}
          setShowEmojiList={setShowEmojiList}
        />
        )}
        {selectedEmoji && selectedEmoji !== undefined
          ? (
            <div
              className="selectedEmoji"
              onClick={deleteHandler}
            >
              {selectedEmoji && renderSelectedEmoji(selectedEmoji)}
              {/* <Emoji unified={selectedEmoji} size="25" /> */}
              {/* <img src={selectedEmoji && selectedEmoji} alt="Emoji" /> */}
            </div>
          )
          : ''
        }
      </div>
      <span
        className={selectedEmoji ? 'chatOutMsgTime isReacted' : 'chatOutMsgTime'}
      >
        {moment(utcToLocalTimeConverter(msgTime)).format('HH:mm')}
      </span>
    </>
  );
};

export default EmojiSelector;
