import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';

import arousrLogo from '../../../../assets/svgs/logo.svg';
import './style.scss';

const ChatInitiatePrimiumModal = (props) => {
  const {
    className,
    open,
    toggle,
    header,
    body,
    footer,
    buttonText,
    onClick,
    isRadioButton = false,
    isInputFeild,
    footerLink,
    htmlElements,
  } = props;

  const handleClick = () => {
    onClick();
    toggle();
  };


  return (
    <Modal
      className={`chat-modal tip-Modal ${className}`}
      isOpen={open}
      toggle={toggle}
      backdrop={false}
    >
      <ModalHeader className="chat-modal-header" toggle={toggle}>
        <img
          src={arousrLogo}
          alt="arousr-logo"
          className="modal-center-img"
        />
      </ModalHeader>
      <ModalBody className="chat-modal-body">
        <div className="text-center">
          <h4>{header}</h4>
          <p>
            {body}
          </p>
          {(isRadioButton || isInputFeild) && htmlElements}
          <button type="button" onClick={handleClick} className="btn">{buttonText}</button>
        </div>
      </ModalBody>
      <ModalFooter className="chat-modal-footer">
        <p role="presentation" onClick={footerLink}>{footer}</p>
      </ModalFooter>
    </Modal>
  );
};

ChatInitiatePrimiumModal.defaultProps = {
  className: '',
  open: false,
  header: '',
  body: '',
  buttonText: 'OK',
  footer: '',
  onClick: () => {},
  footerLink: () => {},
  isRadioButton: false,
  isInputFeild: false,
  htmlElements: [],
};

ChatInitiatePrimiumModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  header: PropTypes.string,
  body: PropTypes.string,
  footer: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  footerLink: PropTypes.func,
  isRadioButton: PropTypes.bool,
  isInputFeild: PropTypes.bool,
  htmlElements: PropTypes.instanceOf(Array),
};

export default ChatInitiatePrimiumModal;
