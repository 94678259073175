import { connect } from 'react-redux';

import getCustomerChatLog from '../actions/actionChatLog';
import setConversation from '../actions/actionChat';
import { getSessionMsgs, removeChatMsgHistory } from '../actions/actionGetMsgsHistory';
import Chatlog from '../scenes/Chatlog';

const mapChatLogData = (results = []) => results.map(result => ({
  id: result.objid,
  createDateTime: result.create_datetime,
  totalMsg: (result.char2cust + result.cust2char),
}));

const mapChatMsgData = (msgs = []) => msgs.map(msg => ({
  ...msg,
  msgPath: msg.msg_path,
  createDateTime: msg.unformatted_create_datetime,
  msgtext: msg.message_txt,
  isMedia: msg.media,
  objid: msg.objid,
  media: msg.media,
  chat_status: msg.msg_path === 'Char2Cust' ? msg.delivery_status : '',
}));

const mapSysConfigData = (sysConfigDataRes = []) => {
  const minMessagesToInitiateChat = sysConfigDataRes.find(result => result.config_name === 'PERF_INIT_CHAT_MIN_MSG_PAID') || {};
  return minMessagesToInitiateChat.value;
};

const mapStateToProps = (state) => {
  const {
    chatLog: {
      data: { results = [], next } = {}, isLoading: chatLogLoader = false,
    } = {},
    callLogs: { data: callLogss },
    login: { data: { perfcharacter = [] } = {} } = {},
    chatMsgHistory: {
      data: { results: msgHistoryResults = [] } = {},
      isLoading: msgHistoryLoader = false,
    } = {},
    chat: { conv = [] } = {},
    sysConfig: { data: { results: sysConfigResults = [] } = {} } = {},
  } = state;

  const perfObjid = (perfcharacter.length && perfcharacter[0].objid) || 0;
  const isTier2 = (perfcharacter.length && perfcharacter[0].is_tier2) || false;

  return {
    chatlogData: mapChatLogData(results),
    callsLogData: callLogss,
    minMessageToInitaiteChat: mapSysConfigData(sysConfigResults),
    perfObjid,
    chatMsgData: mapChatMsgData(msgHistoryResults),
    loaders: {
      chatLogLoader,
      msgHistoryLoader,
    },
    conversation: conv,
    isTier2,
    next,
  };
};

const mapDispatchToProps = dispatch => ({
  getCustomerChatLog: url => dispatch(getCustomerChatLog(url)),
  checkActiveSessionAvailable: url => getCustomerChatLog(url).payload,
  getSessionMsgs: url => dispatch(getSessionMsgs(url)),
  removeChatMsgHistory: () => dispatch(removeChatMsgHistory()),
  setConversation: data => dispatch(setConversation(data)),
});

const CustomerChatlogConstainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Chatlog);

export default CustomerChatlogConstainer;
