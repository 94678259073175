export default function (state = [], action = {}) {
  switch (action.type) {
    case 'GET_AUTHENTICATION_TOKEN_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'GET_AUTHENTICATION_TOKEN_FULFILLED':
      localStorage.setItem('authToken', action.payload.data.token);
      return {
        ...state,
        isLoading: false,
        data: { ...action.payload.data },
        error: false,
      };

    case 'GET_AUTHENTICATION_TOKEN_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    default:
      return {
        ...state,
      };
  }
}
