import api from './api';

const postPayoutDetails = data => ({
  type: 'POST_PAYOUT_DETAILS',
  payload: api.post('/cstpaymentconfig/create/', data),
});

export const getPayoutDetails = id => ({
  type: 'GET_PAYOUT_DETAILS',
  payload: api.get(`/cstpaymentconfig/list/?payment_config2cst_contact=${id}`),
});

export default postPayoutDetails;
