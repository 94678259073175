import React from 'react';

export default fill => (
  <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4164 17.5937C16.177 17.5937 18.4149 15.3552 18.4149 12.5937C18.4149 9.83233 16.177 7.59375 13.4164 7.59375C10.6559 7.59375 8.41797 9.83233 8.41797 12.5937C8.41797 15.3552 10.6559 17.5937 13.4164 17.5937Z" fill={fill} />
    <path d="M42.757 14.2926C41.0575 12.5926 38.2584 12.5926 36.5589 14.2926L24.6626 26.1926L21.5136 23.0426C19.8141 21.3426 17.015 21.3426 15.3155 23.0426L0.919922 37.4426V40.7176C0.919922 43.2176 2.24451 45.3926 4.2439 46.6176C5.26858 47.2176 6.4932 47.5926 7.7928 47.5926H10.292V50.4176V58.2176C10.292 58.9676 10.7419 59.6676 11.4417 59.9426C11.6916 60.0426 11.9165 60.0926 12.1665 60.0926C12.6663 60.0926 13.1411 59.8926 13.491 59.5426L23.338 49.6926L25.4374 47.5926H46.056H54.0285C57.8274 47.5926 60.9014 44.5176 60.9014 40.7176V32.4426L42.757 14.2926Z" fill={fill} />
    <path d="M12.184 60.0928C11.9416 60.0928 11.6992 60.0453 11.4668 59.9503C10.767 59.6603 10.3096 58.9753 10.3096 58.2178V47.5928H7.81038C4.01905 47.5928 0.9375 44.5103 0.9375 40.7178V6.96777C0.9375 2.91527 4.88878 0.0927734 8.43519 0.0927734H54.0461C57.8374 0.0927734 60.919 3.17527 60.919 6.96777V40.7178C60.919 44.5103 57.8374 47.5928 54.0461 47.5928H25.4549L13.5086 59.5428C13.1512 59.9028 12.6714 60.0928 12.184 60.0928ZM8.43519 3.84277C6.57826 3.84277 4.68634 5.42027 4.68634 6.96777V40.7178C4.68634 42.4403 6.08841 43.8428 7.81038 43.8428H12.184C13.2187 43.8428 14.0585 44.6828 14.0585 45.7178V53.6903L23.3556 44.3903C23.708 44.0378 24.1853 43.8403 24.6802 43.8403H54.0461C55.7681 43.8403 57.1701 42.4378 57.1701 40.7153V6.96777C57.1701 5.24527 55.7681 3.84277 54.0461 3.84277H8.43519Z" fill={fill} />
    <path d="M4.08528 39.5938L1.43359 36.9413L15.3293 23.0413C17.0413 21.3313 19.8204 21.3313 21.5274 23.0413L24.6764 26.1913L36.5728 14.2913C38.2847 12.5813 41.0639 12.5813 42.7708 14.2913L60.3654 31.8913L57.7137 34.5438L40.1192 16.9438C39.8742 16.6988 39.4669 16.6988 39.2194 16.9438L25.9985 30.1688C25.2662 30.9013 24.0791 30.9013 23.3468 30.1688L18.8732 25.6938C18.6283 25.4488 18.2209 25.4488 17.9735 25.6938L4.08528 39.5938Z" fill={fill} />
  </svg>

);
