import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';
import './style.scss';
import prevArrow from '../../assets/svgs/prev_arrow';
import nextArrow from '../../assets/svgs/next_arrow';

const prevPageNumbers = [2, 1];
const nextPageNumbers = [1, 2];


class Paginate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.renderPrevious2 = this.renderPrevious2.bind(this);
    this.renderNext2 = this.renderNext2.bind(this);
    this.changeCurrentPage = this.changeCurrentPage.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPreviousClick = this.onPreviousClick.bind(this);

    const { totalCount, pageSize, currentPage } = props;
    this.state = {
      current: currentPage,
      items: pageSize,
      numberOfPages: Math.ceil(totalCount / pageSize),
    };
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    const { totalCount, pageSize, currentPage } = nextProps;
    const numberOfPages = Math.ceil(totalCount / pageSize);
    if (prevState.numberOfPages !== numberOfPages) {
      return { numberOfPages, current: currentPage };
    }
    return null;
  }

  onNextClick() {
    const { current } = this.state;
    const next = current + 1;
    this.changeCurrentPage(next);
  }

  onPreviousClick() {
    const { current } = this.state;
    const previous = current - 1;
    this.changeCurrentPage(previous);
  }

  changeCurrentPage(current) {
    const { numberOfPages } = this.state;
    const { onPageClick, pageSize, totalCount } = this.props;
    let items = current * pageSize;
    if (current === numberOfPages) {
      items = totalCount; // pageSize + (totalCount - (current * pageSize));
    }

    this.setState({ items });
    onPageClick(current);

    if (current !== 0 && current !== numberOfPages + 1) {
      this.setState({ current });
    }
  }

  renderPrevious2(current, number) {
    const previous = current - number;

    if (previous > 0) {
      return (
        <PaginationItem key={previous} onClick={() => this.changeCurrentPage(previous)}>
          <PaginationLink role="button">
            {previous}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return '';
  }

  renderNext2(current, number) {
    const { numberOfPages } = this.state;
    const next = current + number;

    if (next <= numberOfPages) {
      return (
        <PaginationItem key={next} onClick={() => this.changeCurrentPage(next)}>
          <PaginationLink role="button">
            {next}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return '';
  }

  // display none
  renderItemsCount() {
    const { items } = this.state;
    const { pageSize, totalCount } = this.props;
    if (pageSize >= totalCount) {
      return (`${totalCount} items`);
    }

    return (`${items} items of ${totalCount}`);
  }

  render() {
    const { current, numberOfPages } = this.state;
    return (
      <Pagination aria-label="" className="mt-3 paginationComponent">
        <span className="align-self-center mr-3 color-dark-grey d-none">
          {this.renderItemsCount()}
        </span>
        <PaginationItem
          disabled={current === 1 || (numberOfPages === 0) || false}
        >
          <PaginationLink
            previous
            role="button"
            onClick={this.onPreviousClick}
            name="previous"
          >
            { prevArrow('red')}
          </PaginationLink>
        </PaginationItem>

        {prevPageNumbers.map(page => this.renderPrevious2(current, page))}

        <PaginationItem className="active">
          <PaginationLink previous role="button">
            {current}
          </PaginationLink>
        </PaginationItem>

        {nextPageNumbers.map(page => this.renderNext2(current, page))}

        <PaginationItem
          disabled={(current === numberOfPages) || (numberOfPages === 0)}
        >
          <PaginationLink
            next
            role="button"
            name="next"
            onClick={this.onNextClick}
          >
            {nextArrow()}
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  }
}

Paginate.defaultProps = {
  totalCount: 0,
  pageSize: 10,
  currentPage: 1,
};

Paginate.propTypes = {
  totalCount: PropTypes.number,
  pageSize: PropTypes.number,
  onPageClick: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
};

export default Paginate;
