import { connect } from 'react-redux';
import EngagementScore from '../scenes/Dashboard/components/EngagementScore';
import engagmentScoreAction from '../actions/actionEngagmenstScore';

const mapStateToProps = (state) => {
  const {
    login: { data: { perfcharacter = [{}] } = {} } = {},
  } = state;

  const charObjId = perfcharacter[0]?.objid || 0;

  return {
    charObjId,
  };
};

const mapDispatchToProps = dispatch => ({
  engagmentScoreFunction: data => dispatch(engagmentScoreAction(data)),
});

const EngagementScoreContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EngagementScore);

export default EngagementScoreContainer;
