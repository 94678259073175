import { connect } from 'react-redux';
import {
  exclutionCountryStateList,
  deleteExcludedCountry,
  updateExcludedList,
} from '../actions/actionExclutionCountryList';
import Visibility from '../scenes/Visibility';
import getCountryStateList from '../actions/actionGetCountryStateList';
import excludeRegion from '../actions/actionExcludeRegion';

const getOptionList = (regions) => {
  const updateList = regions.map(region => ({
    ...region,
    isChecked: false,
  }));
  return updateList;
};

const mapStateToProps = (state) => {
  const { login: { data: { perfcharacter = [] } } } = state;

  const { mobileLogin: { mobileData: { charObjID = 0 } = {} } } = state;

  const { exclutionList: { data: excludedList = [], isLoading: tagListLoading } } = state;
  const {
    stateCountry: { data: optionsList = [], isLoading: countryStateListLoading },
  } = state;
  const localData = JSON.parse(localStorage.getItem('mobileChatData'));
  const perfcharacterObjid = (perfcharacter.length > 0 && perfcharacter[0]?.objid) || 0;
  // const stingyfyExcludedList = JSON.stringify(excludedList);
  const regionList = optionsList.length ? getOptionList(optionsList) : [];
  if (perfcharacter.length > 0) {
    return {
      perfcharacterObjid,
      excludedList,
      tagListLoading,
      countryStateListLoading,
      regionList,
    };
  }
  return {
    perfcharacterObjid: charObjID || localData.character_objid,
    excludedList,
    tagListLoading,
    countryStateListLoading,
    regionList,
  };
};

const mapDispatchToProps = dispatch => ({
  actionExcludedRegionList: data => dispatch(exclutionCountryStateList(data)),
  deleteCountry: data => dispatch(deleteExcludedCountry(data)),
  updateExcludedList: list => dispatch(updateExcludedList(list)),
  getRegionList: data => dispatch(getCountryStateList(data)),
  addExcludeRegion: data => dispatch(excludeRegion(data)),
});

const VisibilityContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Visibility);

export default VisibilityContainer;
