import React, { useState, useEffect } from 'react';
import { Button, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
// import Select from '../../../../components/CustomSelect';
import './style.scss';

const MediaFilter = (props) => {
  const { getCharacterMediaDataWithType, filterSelectedValue } = props;
  const [selectedValues, setSelectedValues] = useState('');
  const [selectValue, setSelectValue] = useState('');

  useEffect(() => {
    setSelectValue(filterSelectedValue);
  }, [filterSelectedValue]);

  const clickOnFilter = () => {
    getCharacterMediaDataWithType(selectedValues);
  };

  const handleTypeChange = (event) => {
    const mediaType = event.target.value || '';
    setSelectedValues(mediaType);
    setSelectValue(mediaType);
  };

  return (
    <div className="filterWrap">
      <Label for="mediaType" className="filterLabel">
        Types
        <Input type="select" id="mediaType" value={selectValue} onChange={event => handleTypeChange(event)}>
          <option value="public-images">Public Photos</option>
          <option value="premium-images">Premium Photos</option>
          <option value="public-videos">Public Videos</option>
          <option value="premium-videos">Premium Videos</option>
          <option value="private">Private Photos</option>
        </Input>
      </Label>
      <Button color="primary" className="filterBtn" outline onClick={clickOnFilter}>Filter</Button>
    </div>
  );
};

MediaFilter.defaultProps = {
  filterSelectedValue: '',
};

MediaFilter.propTypes = {
  getCharacterMediaDataWithType: PropTypes.func.isRequired,
  filterSelectedValue: PropTypes.string,
};

export default MediaFilter;
