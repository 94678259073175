/* eslint-disable camelcase */
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import putStatusUpdate from '../actions/actionPutStatus';
import updateLoginData from '../actions/actionUpdateData';
import StatusCard from '../scenes/Dashboard/components/StatusCard';
import rouletteHeartbeat from '../actions/actionRouletteHeartbeat';
import { getPresenceUser, updatePresence } from '../actions/actionGetPresense';

// import handleRoulette from '../actions/actionHandleRoulette';

const statusAdapter = (charData = {}, presResults = {}) => {
  const { objid } = charData;
  const {
    chatStatus,
    phoneStatus,
    videoStatus,
  } = presResults;

  return {
    charId: objid,
    chat: chatStatus,
    phone: phoneStatus,
    video: videoStatus,
  };
};

const updateStore = (dispatch, id, availability, type) => {
  const presence = `char_${type}_online`;
  const payload = {
    action: 'presence',
    char_objid: id,
    presence: type,
    state: availability ? 'available' : 'offline',
  };

  dispatch(putStatusUpdate(payload)).then((response) => {
    const { value: { data = [] } = {} } = response;
    const charStatus = {
      [presence]: data[0][presence],
    };
    const presenceData = {
      chatStatus: data[0].char_sms_online,
      phoneStatus: data[0].char_voice_online,
      videoStatus: data[0].char_video_online,
    };
    dispatch(updatePresence(presenceData));
    dispatch(updateLoginData(charStatus, 'character'));
  });
};

export const mapStateToProps = (state) => {
  const {
    // form: { charStatusForm: { values } = {} } = {},
    performer: { data: { characters = [{}] } = {} } = {},
    presence: { data: presResults = {} } = {},
  } = state;
  const data = statusAdapter(characters[0], presResults);
  return {
    data,
  };
};

export const mapDispatchToProps = dispatch => ({
  putStatusUpdate: (id, availability, type) => updateStore(dispatch, id, availability, type),
  getPresenceUser: url => dispatch(getPresenceUser(url)),
  rouletteHeartbeat: data => dispatch(rouletteHeartbeat(data)),
});

const StatusCardForm = reduxForm({
  form: 'charStatusForm',
})(StatusCard);

const StatusCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatusCardForm);

export default StatusCardContainer;
