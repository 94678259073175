import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import './style.scss';

const NoDataAvailable = ({ message, className }) => (
  <Row className={`no-data-available ${className}`}>
    <Col className="text-center">
      {message ? <span>{message}</span> : 'No data available'}
    </Col>
  </Row>
);

NoDataAvailable.defaultProps = {
  message: '',
  className: '',
};
NoDataAvailable.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
};

export default NoDataAvailable;
