export default function (state = {}, action = {}) {
  switch (action.type) {
    case 'CHAT_COUNT_NOTIFICATION_REQUEST':
      return {
        ...state, loading: true,
      };
    case 'CHAT_COUNT_NOTIFICATION_SUCCESS':
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case 'CHAT_COUNT_NOTIFICATION_FAILED':
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: true,
      };
    case 'INCREMENT_CHAT_COUNT_NOTIFICATION':
      return {
        ...state,
        data: {
          count: state?.data?.count
            ? state?.data?.count + 1
            : 1,
        },
      };
    default:
      return {
        ...state,
      };
  }
}
