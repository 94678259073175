export default function (state = [], action = {}) {
  switch (action.type) {
    case 'CHANGE_VIDEO_CALL_DATA':
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: false,
      };

    default:
      return {
        ...state,
      };
  }
}
