/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Media,
  Row, Col, Badge,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import meter from '../../assets/svgs/meter';
import editProfile from '../../assets/svgs/edit_profile';
import phonebookIcon from '../../assets/svgs/phonebook_icon';
// import report from '../../assets/svgs/report';
import setting from '../../assets/svgs/setting';
import settingicon from '../../assets/svgs/settingicon';
import Affiliate from '../../assets/svgs/affiliate';
import visibility from '../../assets/svgs/visibility';
import platformRules from '../../assets/svgs/platformRules';
import favCustomerIcon from '../../assets/svgs/favcust';
// import schedule from '../../assets/svgs/schedule';
// import faqs from '../../assets/svgs/messageInfo3';
// import chatLog from '../../assets/svgs/wireFrame3';
// import offlineChat from '../../assets/svgs/messageNew3';
import chat from '../../assets/svgs/message';
// import FeedIcon from '../../assets/svgs/FeedIcon';
// import comments from '../../assets/svgs/messageNote3';
import review from '../../assets/svgs/starBig3';
import media from '../../assets/svgs/autoPlay3';
import testsetupsvg from '../../assets/svgs/testsetupsvg';
import balance from '../../assets/svgs/money3';
// import arrow from '../../assets/svgs/nextArrow';
import pencil from '../../assets/svgs/pencil';
import girlAvatar from '../../assets/images/provider_female_avatar.jpg';
import './style.scss';
import { getChatCountData } from '../../actions/actionChatCount';
import { ResetConverstationAction } from '../../actions/actionChat';
// import { ResetConverstationAction } from '../../actions/actionChat';

export const SidebarProps = PropTypes.shape({
  name: PropTypes.string,
  chatStatus: PropTypes.boolean,
  phoneStatus: PropTypes.boolean,
  videoStatus: PropTypes.boolean,
  charProfileImage: PropTypes.string,
  charId: PropTypes.number,
  characterClass: PropTypes.string,
});

const Sidebar = (props) => {
  const {
    data: {
      // avatar = '',
      name = '',
      chatStatus = false,
      phoneStatus = false,
      videoStatus = false,
      charProfileImage,
      charId,
      // totalUnreadMessge,
      characterClass,
    },
    getPresenceUser,
    history,
  } = props;

  const { location: { pathname } } = history;
  const dispatch = useDispatch();
  const [totalMissedCalls, setTotalMissedCalls] = useState();
  // const [msgCount, setMsgCount] = useState(totalUnreadMessge || '');
  const reduxMissedCalls = useSelector(state => state.missedCallers);
  const ActiveCharacter = useSelector(state => state.chatCount?.data);

  useEffect(() => {
    const presenceUrl = `/perfcharactergroup/list/?objid2perf_character=${charId}&group=PRESENCE_SMS|PRESENCE_VOICE|PRESENCE_VIDEO`;
    getPresenceUser(presenceUrl);
  }, []);

  const getTotalCount = (array) => {
    const totalCount = array.reduce((total, obj) => total + obj.count, 0);
    return totalCount;
  };

  useEffect(() => {
    const missCallsCount = localStorage.getItem('missedCallers') && JSON.parse(localStorage.getItem('missedCallers'));
    if (missCallsCount && missCallsCount.length) {
      setTotalMissedCalls(getTotalCount(missCallsCount));
    }
  }, []);

  useEffect(() => {
    if (reduxMissedCalls?.data) {
      setTotalMissedCalls(getTotalCount(reduxMissedCalls?.data));
    }
  }, [reduxMissedCalls]);

  useEffect(() => {
    if (charId && pathname !== '/chat') {
      dispatch(getChatCountData(charId));
    }
  }, [charId, pathname]);

  useEffect(() => {
    if (pathname !== '/chat') {
      dispatch(ResetConverstationAction());
    }
  }, [pathname]);


  return (
    <nav id="sidebar">
      <div className="sidebar-header">
        <Media className="flex-column align-items-center">
          <Media left>
            {charProfileImage ? (
              <img
                src={charProfileImage}
                alt="profile"
              />
            )
              : <img src={girlAvatar} alt="profile" /> }
            <Link to="/profile">
              {pencil('#FD7663')}
            </Link>
          </Media>

          <Media body>

            <Media heading>
              {name}
            </Media>

            <p>
              Status:
              {' '}
              { chatStatus || phoneStatus || videoStatus
                ? <span className="online">Online</span>
                : <span className="offline">Offline</span>
              }
            </p>
          </Media>
        </Media>
      </div>

      <Col className="sidebar-body">
        <Link to="/dashboard">
          <Row className={pathname === '/dashboard' ? 'active' : ''}>
            <Col xs={3} className="text-center">
              {meter(pathname === '/dashboard' ? '#fff' : '')}
            </Col>

            <Col xs={9} className="p-0 cursor-pointer">Dashboard</Col>
          </Row>
        </Link>

        {/* <Link to="/feed">
          <Row className={pathname === '/feed' ||
          pathname.includes('/customerProfile/')  ? 'active' : ''}>
            <Col xs={3} className="text-center">
              {FeedIcon(pathname === '/feed' ? '#fff' : '')}
            </Col>

            <Col xs={7} className="p-0 cursor-pointer">Feed</Col>

          </Row>
        </Link> */}

        <Link to="/phonebook">
          {/* eslint-disable-next-line */}
          <Row className={pathname === '/phonebook' || pathname.includes('/customerChatlog/')  ? 'active' : ''}>
            <Col xs={3} className="text-center phonebooksvg">
              {phonebookIcon(pathname === '/phonebook' ? '#000' : '')}
            </Col>

            <Col xs={5} className="p-0 cursor-pointer">Phonebook</Col>
            <Col xs={4}>{totalMissedCalls ? <Badge pill className="whiteBadge">{totalMissedCalls}</Badge> : ''}</Col>
            {/* <Col xs={2}>{arrow(hash === '#/feed' ? '#fff' : '')}</Col> */}
          </Row>
        </Link>
        <Link to="/chat">
          <Row className={pathname === '/chat' ? 'active' : ''}>
            <Col xs={3} className="text-center">
              {chat(pathname === '/chat' ? '#fff' : '')}
            </Col>
            <Col xs={5} className="p-0 cursor-pointer">Chat</Col>
            {/* <Col xs={4}>
              {pathname !== '/chat' ?
              (ActiveCharacter?.count ?
                <Badge pill className="whiteBadge tester">{ActiveCharacter?.count}</Badge> : '') :
              (totalUnreadMessge ?
                <Badge pill className="whiteBadge">{totalUnreadMessge}</Badge> : '')}
            </Col> */}
            {/* {pathname !== '/chat' ? (
              <Col xs={4}>
                {ActiveCharacter?.count ?
                <Badge pill className="whiteBadge tester">{ActiveCharacter?.count}</Badge> : ''}
              </Col>
            ) : (
              <Col xs={4}>{totalUnreadMessge ?
              <Badge pill className="whiteBadge">{totalUnreadMessge}</Badge> : ''}</Col>)
            } */}

            {ActiveCharacter?.count ? <Badge pill className="whiteBadge tester">{ActiveCharacter?.count}</Badge> : ''}

            {/* <Col xs={2}>{arrow(pathname === '/chat' ? '#fff' : '')}</Col> */}


            {/* <Col xs={2}>{arrow(pathname === '/chat' ? '#fff' : '')}</Col> */}
          </Row>
        </Link>
        <Link to="/favoritecustomer">
          {/* eslint-disable-next-line */}
          <Row className={pathname === '/favoritecustomer' ? 'active' : ''}>
            <Col xs={3} className="text-center">
              {favCustomerIcon(pathname === '/favoritecustomer' ? '#fff' : '')}
            </Col>

            <Col xs={9} className="p-0 cursor-pointer">Favorite Customer</Col>

            {/* <Col xs={2}>{arrow(pathname === '/profile' ? '#fff' : '')}</Col> */}
          </Row>
        </Link>
        <Link to="/profile">
          <Row className={pathname === '/profile' ? 'active' : ''}>
            <Col xs={3} className="text-center stroke">
              {editProfile(pathname === '/profile' ? '#fff' : '')}
            </Col>

            <Col xs={7} className="p-0 cursor-pointer">Profile</Col>

            {/* <Col xs={2}>{arrow(pathname === '/profile' ? '#fff' : '')}</Col> */}
          </Row>
        </Link>

        {/* <Row>
          <Col xs={3} className="text-center">
            {report()}
          </Col>

          <Col xs={7} className="p-0 cursor-pointer">Report</Col>

          <Col xs={2}>{arrow()}</Col>
        </Row> */}

        <Link to="/balance">
          <Row className={pathname === '/balance' ? 'active' : ''}>
            <Col xs={3} className="text-center balaceLogo">
              {balance(pathname === '/balance' ? '#fff' : '')}
            </Col>

            <Col xs={7} className="p-0 cursor-pointer">Balance</Col>

            {/* <Col xs={2}>{arrow(pathname === '/balance' ? '#fff' : '')}</Col> */}
          </Row>
        </Link>
        <Link to="/settings">
          <Row className={pathname === '/settings' ? 'active' : ''}>
            <Col xs={3} className="text-center settingicon stroke">
              {settingicon(pathname === '/settings' ? '#fff' : '')}
            </Col>
            <Col xs={7} className="p-0 cursor-pointer">Settings</Col>
          </Row>
        </Link>
        <Link to="/myInfo">
          {/* eslint-disable-next-line */}
          <Row className={pathname === '/myInfo' || pathname.includes('/myinfo/')  ? 'active' : ''}>
            <Col xs={3} className="text-center phonebooksvg">
              {editProfile(pathname === '/myInfo' ? '#fff' : '')}
            </Col>

            <Col xs={7} className="p-0 cursor-pointer">My Info</Col>

            {/* <Col xs={2}>{arrow(hash === '#/feed' ? '#fff' : '')}</Col> */}
          </Row>
        </Link>
        <Link to="/media">
          <Row className={pathname === '/media' ? 'active' : ''}>
            <Col xs={3} className="text-center">
              {media()}
            </Col>

            <Col xs={7} className="p-0 cursor-pointer">Media</Col>

            {/* <Col xs={2}>{arrow(pathname === '/media' ? '#fff' : '')}</Col> */}
          </Row>
        </Link>
        <Link to="/test-setup">
          <Row className={pathname === '/test-setup' ? 'active' : ''}>
            <Col xs={3} className="text-center testsetupsvg">
              {testsetupsvg(pathname === '/test-setup' ? '#000' : '')}
            </Col>

            <Col xs={7} className="p-0 cursor-pointer">Test Setup</Col>

            {/* <Col xs={2}>{arrow(pathname === '/media' ? '#fff' : '')}</Col> */}
          </Row>
        </Link>

        <Link to="/reviews">
          <Row className={pathname === '/reviews' ? 'active' : ''}>
            <Col xs={3} className="text-center">
              {review(pathname === '/reviews' ? '#fff' : '')}
            </Col>

            <Col xs={7} className="p-0 cursor-pointer">Reviews</Col>

            {/* <Col xs={2}>{arrow(pathname === '/reviews' ? '#fff' : '')}</Col> */}
          </Row>
        </Link>

        {/* <Row>
          <Col xs={3} className="text-center">
            {comments()}
          </Col>

          <Col xs={7} className="p-0 cursor-pointer">Comments</Col>

          <Col xs={2}>{arrow()}</Col>
        </Row> */}

        {/* <Row>
          <Col xs={3} className="text-center">
            {offlineChat()}
          </Col>

          <Col xs={7} className="p-0 cursor-pointer">Offline chat</Col>

          <Col xs={2}>{arrow()}</Col>
        </Row> */}

        {/* <Link to="/chatlog">
          <Row className={pathname === '/chatlog' ? 'active' : ''}>
            <Col xs={3} className="text-center">
              {chatLog(pathname === '/chatlog' ? '#fff' : '')}
            </Col>

            <Col xs={7} className="p-0 cursor-pointer">Chat log</Col>

            <Col xs={2}>{arrow(pathname === '/chatlog' ? '#fff' : '')}</Col>
          </Row>
        </Link> */}

        {/* <Row>
          <Col xs={3} className="text-center">
            {faqs()}
          </Col>

          <Col xs={7} className="p-0 cursor-pointer">FAQS</Col>

          <Col xs={2}>{arrow()}</Col>
        </Row> */}

        <Link to="/support">
          <Row className={pathname === '/support' ? 'active' : ''}>
            <Col xs={3} className="text-center">
              {setting(pathname === '/support' ? '#fff' : '')}
            </Col>

            <Col xs={7} className="p-0 cursor-pointer">Support</Col>

            {/* <Col xs={2}>{arrow(pathname === '/support' ? '#fff' : '')}</Col> */}
          </Row>
        </Link>

        {characterClass === 'Adult' ? (
          <Link to="/affiliate_program">
            <Row className={pathname === '/affiliate_program' ? 'active' : ''}>
              <Col xs={3} className="text-center">
                {Affiliate(pathname === '/affiliate_program' ? '#fff' : '')}
              </Col>

              <Col xs={9} className="p-0 cursor-pointer">Affiliate Program</Col>

              {/* <Col xs={2}>{arrow(pathname === '/affiliate_program' ? '#fff' : '')}</Col> */}
            </Row>
          </Link>
        ) : ''}

        <Link to="/visibility">
          <Row className={pathname === '/visibility' ? 'active' : ''}>
            <Col xs={3} className="text-center">
              {visibility(pathname === '/visibility' ? '#fff' : '')}
            </Col>

            <Col xs={7} className="p-0 cursor-pointer">My Visibility</Col>

            {/* <Col xs={2}>{arrow(pathname === '/visibility' ? '#fff' : '')}</Col> */}
          </Row>
        </Link>

        <a href="https://arousr.com/HostWiki" target="_blank">
          <Row className={pathname === '/platformRules' ? 'active' : ''}>
            <Col xs={3} className="text-center">
              {platformRules(pathname === '/platformRules' ? '#fff' : '')}
            </Col>

            <Col xs={7} className="p-0 cursor-pointer">Chat Host Wiki</Col>

            {/* <Col xs={2}>{arrow(pathname === '/support' ? '#fff' : '')}</Col> */}
          </Row>
        </a>

        {/* // <Link to="/schedule">
        //   <Row className={pathname === '/schedule' ? 'active' : ''}>
        //     <Col xs={3} className="text-center">
        //       {schedule(pathname === '/schedule' ? '#fff' : '')}
        //     </Col>

        //     <Col xs={7} className="p-0 cursor-pointer">Online Schedule</Col>

        //   </Row>
        // </Link> */}
      </Col>
    </nav>
  );
};

Sidebar.propTypes = {
  data: SidebarProps.isRequired,
  getPresenceUser: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  // characters: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
};

export default Sidebar;
