import api from './api';

export const exclutionCountryStateList = data => ({
  type: 'GET_EXCLUTION',
  payload: api.get(data),
});

export const deleteExcludedCountry = data => ({
  type: 'DELETE_EXC_COUNTRY',
  payload: api.delete(data),
});

export const updateExcludedList = data => ({
  type: 'UPDATE_EXCL_LIST',
  payload: data,
});
