import React, { useState } from 'react';

import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import CustomerReviews from '..';
import crownIcon from '../../../assets/svgs/icon-crown.svg';
import './CustomerReviewModal.scss';

const CustomerReviewModal = (props) => {
  const {
    header,
    className,
    open,
    toggle,
    activeUser,
    onClickFun,
  } = props;

  const [creativeRating, setCreativeRating] = useState();
  const [sexyRating, setSexyRating] = useState();
  const [generousRating, setGenerousRating] = useState();
  const [customerReviewRatingError, setCustomerReviewRatingError] = useState(false);

  const handleCreativeRating = (rate) => {
    setCreativeRating(rate);
  };
  const handleSexyRating = (rate) => {
    setSexyRating(rate);
  };
  const handleGenerousRating = (rate) => {
    setGenerousRating(rate);
  };

  function removeCustomerData(customerId) {
    const customerData = JSON.parse(localStorage.getItem('messageChat')) || {};
    if (customerData[customerId]) {
      delete customerData[customerId];
      localStorage.setItem('messageChat', JSON.stringify(customerData));
    }
  }

  const clearLocalStorage = () => {
    // eslint-disable-next-line no-unused-expressions
    activeUser?.custId && removeCustomerData(activeUser?.custId);
    // localStorage.removeItem('messageChat');
    setCreativeRating('');
    setSexyRating('');
    setGenerousRating('');
  };

  const onSubmitHandler = () => {
    if (creativeRating !== undefined || sexyRating !== undefined || generousRating !== undefined) {
      if (creativeRating !== undefined
        && sexyRating !== undefined && generousRating !== undefined) {
        const reviewData = {
          creative: creativeRating,
          sexy: sexyRating,
          generous: generousRating,
        };
        onClickFun(reviewData);
        // localStorage.removeItem('messageChat');
        // eslint-disable-next-line no-unused-expressions
        activeUser?.custId && removeCustomerData(activeUser?.custId);
        setCreativeRating('');
        setSexyRating('');
        setGenerousRating('');
        toggle();
        setCustomerReviewRatingError(false);
      } else {
        setCustomerReviewRatingError(true);
      }
    } else {
      toggle();
      // eslint-disable-next-line no-unused-expressions
      activeUser?.custId && removeCustomerData(activeUser?.custId);
      // localStorage.removeItem('messageChat');
      setCreativeRating('');
      setSexyRating('');
      setGenerousRating('');
    }
  };

  return (
    <Modal
      className={`CustomerReview-Modal ${className}`}
      isOpen={open}
      toggle={toggle}
      backdrop={false}
    >
      <ModalHeader className="chat-modal-header" toggle={toggle} onClick={clearLocalStorage}>
        {header}
      </ModalHeader>
      <ModalBody className="chat-modal-body">
        <div className="img_with_crown">
          {activeUser.custPic && activeUser.custPic !== 'None'
            ? <img className="rounded-circle" width="50" height="50" src={activeUser.custPic} alt="cuspic" />
            : <svg width="50" height="50" data-jdenticon-value="icon value" />}
          {activeUser && activeUser.isPremiumCustomer ? <img src={crownIcon} className="is_premium_customer" alt="crown" /> : ''}
        </div>
        <div className="online-user" />
        <span className="user-name">{activeUser && activeUser.title}</span>
        <div className="customer_reviews">
          <ul>
            <li>
              <span>
                Creativity :
              </span>
              <span>
                <CustomerReviews
                  value={parseInt(creativeRating, 10)}
                  newRatingHandlerFun={handleCreativeRating}
                />
              </span>
            </li>
            <li>
              <span>
                Sexiness :
              </span>
              <span>
                <CustomerReviews
                  value={parseInt(sexyRating, 10)}
                  newRatingHandlerFun={handleSexyRating}
                />
              </span>
            </li>
            <li>
              <span>
                Generosity :
              </span>
              <span>
                <CustomerReviews
                  value={parseInt(generousRating, 10)}
                  newRatingHandlerFun={handleGenerousRating}
                />
              </span>
            </li>
          </ul>
        </div>
        {customerReviewRatingError && <p className="rating-error">All Ratings are mandatory!</p>}
        <Button type="submit" color="primary" onClick={onSubmitHandler} className="review-submit-btn text-white">Submit</Button>
      </ModalBody>
    </Modal>
  );
};

CustomerReviewModal.defaultProps = {
  activeUser: {},
  header: '',
  className: '',
  open: false,
};

CustomerReviewModal.propTypes = {
  header: PropTypes.string,
  className: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  activeUser: PropTypes.instanceOf(Object),
  onClickFun: PropTypes.func.isRequired,
};

export default CustomerReviewModal;
