export default function (state = [], action = {}) {
  switch (action.type) {
    case 'GET_FAV_CUST_DATA_PENDING':
      return {
        ...state,
        isFavCustLoading: true,
        error: false,
      };
    case 'GET_FAV_CUST_DATA_FULFILLED':
      return {
        ...state,
        isFavCustLoading: false,
        data: action.payload.data,
        error: false,
      };
    case 'GET_FAV_CUST_DATA_REJECTED':
      return {
        ...state,
        isFavCustLoading: false,
        error: true,
      };

    case 'UPDATE_FAV_CUST_NOTE_DATA_PENDING':
      return {
        ...state,
        updateFavCustDataapiLoader: true,
        error: false,
      };

    case 'UPDATE_FAV_CUST_NOTE_DATA_FULFILLED': {
      const updatedResults = state.data.results.map((result) => {
        if (result.customer_objid === action.payload.data?.cst_contact_objid) {
          return {
            ...result,
            notes: action.payload.data.note,
          };
        }
        return result;
      });
      return {
        ...state,
        data: {
          ...state.data,
          results: updatedResults,
        },
        updateFavCustDataapiLoader: false,
        error: false,
      };
    }

    case 'UPDATE_FAV_CUST_NOTE_DATA_REJECTED':
      return {
        ...state,
        updateFavCustDataapiLoader: false,
        error: true,
      };

    default:
      return {
        ...state,
      };
  }
}
