import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

import clock from '../../assets/svgs/time';
import timer from '../../assets/svgs/time_count';
import star from '../../assets/svgs/star';
// import graph from '../../assets/svgs/graph';
import smile from '../../assets/svgs/smile';
import './style.scss';

export const BodyHeaderProps = PropTypes.shape({
  // ranking: PropTypes.number,
  rating: PropTypes.number,
  responseTime: PropTypes.number,
  avgSessionLength: PropTypes.number,
  session: PropTypes.number,
});

const BodyHeader = (props) => {
  const {
    data: {
      // ranking = 0,
      rating = 0,
      responseTime = 0,
      avgSessionLength = 0,
      session = 0,
    },
  } = props;

  return (
    <>
      {/* <Col>
        <Row>
          <Col>
            {graph()}
            <span> Ranking </span>
          </Col>
        </Row>

        <Row>
          <Col>
            <h5>{ranking}</h5>
          </Col>
        </Row>
      </Col> */}

      <Col lg={3} sm={6} className="mb-2 mb-lg-0">
        <div className="bg-dark p-3 rounded h-100">
          <Row className="mx-0 align-items-center">
            <div className="iconDiv">
              {star()}
            </div>
            <div className="iconTextWrp">
              <span> Customer Rating </span>
              <h5>{rating}</h5>
            </div>
          </Row>
        </div>
      </Col>

      <Col lg={3} sm={6} className="mb-2 mb-lg-0">
        <div className="bg-dark p-3 rounded h-100">
          <Row className="mx-0 align-items-center">
            <div className="iconDiv">
              {timer()}
            </div>
            <div className="iconTextWrp">
              <span> Response Time </span>
              <h5>
                {moment().startOf('day').seconds(responseTime).format('HH:mm:ss')}
                {/* {`${responseTime} min.`} */}
              </h5>
            </div>
          </Row>
        </div>
      </Col>

      <Col lg={3} sm={6} className="mb-2 mb-lg-0">
        <div className="bg-dark p-3 rounded h-100">
          <Row className="mx-0 align-items-center">
            <div className="iconDiv">
              {clock()}
            </div>
            <div className="iconTextWrp">
              <span>Avg. Session Length</span>
              <h5>
                {`${avgSessionLength} Messages`}
              </h5>
            </div>
          </Row>
        </div>
      </Col>

      <Col lg={3} sm={6} className="mb-2 mb-lg-0">
        <div className="bg-dark p-3 rounded h-100">
          <Row className="mx-0 align-items-center">
            <div className="iconDiv">
              {smile()}
            </div>
            <div className="iconTextWrp">
              <span>Sessions</span>
              <h5>
                {session}
              </h5>
            </div>
          </Row>
        </div>
      </Col>
    </>
  );
};

BodyHeader.defaultProps = {
  data: {
    // ranking: 0,
    rating: 0,
    responseTime: 0,
    avgSessionLength: 0,
    session: 0,
  },
};

BodyHeader.propTypes = {
  data: BodyHeaderProps,
};

export default BodyHeader;
