import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button, Modal, ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import './style.scss';

const ChatMediaOptionModal = (props) => {
  const {
    isOpen,
    handleChatMediaModal,
    getChatMediaPhotos,
    getChatMediaVideos,
    // chatMediaPhotosHandler,
    // chatMediaVideosHandler,
    chatMediaSharedPhotosVideosHandler,
  } = props;

  const [activeTab, setActiveTab] = React.useState('image-media');
  const [modal, setModal] = useState(false);
  const [chatMediaPhotosGallery, setChatMediaPhotosGallery] = useState([]);
  const [chatMediaVideosGallery, setChatMediaVideosGallery] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState('');
  const [selectedVideo, setSelectedVideo] = useState('');

  useEffect(() => {
    setModal(isOpen);
  }, []);


  const toggle = () => {
    handleChatMediaModal();
    setModal(!modal);
  };

  const selectChatMediaPhoto = (photoUrl) => {
    setSelectedPhoto(photoUrl);
  };

  const selectChatMediaVideo = (videoObj) => {
    setSelectedVideo(videoObj);
  };

  const sendMediaPhoto = () => {
    if (selectedPhoto) {
      chatMediaSharedPhotosVideosHandler(selectedPhoto);
      setSelectedPhoto('');
      toggle();
    }
  };

  const sendMediaVideo = () => {
    if (selectedVideo) {
      chatMediaSharedPhotosVideosHandler(selectedVideo);
      setSelectedVideo('');
      toggle();
    }
  };

  useEffect(() => {
    if (getChatMediaPhotos && getChatMediaPhotos.length) {
      const photoGallery = getChatMediaPhotos.map(photo => (
        <div key={`privateProduct${photo.product_id}`} className="chatMediaPhoto">
          <button type="button" onClick={() => selectChatMediaPhoto(photo)}>
            <img src={photo.product_thumb_url} alt={photo.product_name} height="50" width="50" />
          </button>
        </div>
      ));
      setChatMediaPhotosGallery(photoGallery);
    }
  }, [getChatMediaPhotos]);

  useEffect(() => {
    if (getChatMediaVideos && getChatMediaVideos.length) {
      const videoGallery = getChatMediaVideos.map(video => (
        <div key={`privateProduct${video.product_id}`} className="chatMediaPhoto">
          <button type="button" onClick={() => selectChatMediaVideo(video)}>
            <img src={video.product_thumb_url} alt={video.product_name} height="50" width="50" />
            <div className="video_play_icon">
              <span className="play_back_circle" />
              <i className="fa fa-play" aria-hidden="true" />
            </div>
          </button>
        </div>
      ));
      setChatMediaVideosGallery(videoGallery);
    }
  }, [getChatMediaVideos]);


  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="chatMediaModal">
        <ModalHeader toggle={toggle}>Chat Media</ModalHeader>
        <ModalBody>
          <div className="chat-media-tabs-menu">
            <Nav tabs fill>
              <NavItem>
                <NavLink active={activeTab === 'image-media'} onClick={() => setActiveTab('image-media')}>
                  Photo
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={activeTab === 'video-media'} onClick={() => setActiveTab('video-media')}>
                  Video
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="image-media">
                {chatMediaPhotosGallery && chatMediaPhotosGallery.length ? (
                  <section className="chatMedia-gallery-grid">
                    {chatMediaPhotosGallery}
                  </section>

                ) : (
                  <div className="text-center">No Photos Found!</div>
                )
                }
                {chatMediaPhotosGallery && chatMediaPhotosGallery.length ? (
                  <Button className="sendBtn" color="primary" disabled={!selectedPhoto} onClick={sendMediaPhoto}>Send</Button>
                ) : ''
                }
              </TabPane>
              <TabPane tabId="video-media">
                {chatMediaVideosGallery && chatMediaVideosGallery.length ? (
                  <section className="chatMedia-gallery-grid">
                    {chatMediaVideosGallery}
                  </section>
                ) : (
                  <div className="text-center">No Videos Found!</div>
                )
                }
                {chatMediaVideosGallery && chatMediaVideosGallery.length ? (
                  <Button className="sendBtn" color="primary" disabled={!selectedVideo} onClick={sendMediaVideo}>Send</Button>
                ) : ''
                }
              </TabPane>
            </TabContent>
          </div>
        </ModalBody>
        {/* <ModalFooter>
          <Button className="sendBtn" color="primary" disabled={!selectedPhoto}>Send</Button>
        </ModalFooter> */}
      </Modal>
    </div>
  );
};

ChatMediaOptionModal.defaultProps = {
  isOpen: false,
  getChatMediaPhotos: [],
  getChatMediaVideos: [],
};

ChatMediaOptionModal.propTypes = {
  isOpen: PropTypes.bool,
  handleChatMediaModal: PropTypes.func.isRequired,
  getChatMediaPhotos: PropTypes.instanceOf(Array),
  getChatMediaVideos: PropTypes.instanceOf(Array),
  chatMediaSharedPhotosVideosHandler: PropTypes.func.isRequired,
};

export default ChatMediaOptionModal;
