/* eslint-disable */
import React from 'react';

export default () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="white" fill-opacity="0.03"/>
    <g filter="url(#filter0_i_4929_3593)">
    <circle cx="16" cy="16" r="16" fill="white" fill-opacity="0.05"/>
    </g>
    <g clip-path="url(#clip0_4929_3593)">
    <path d="M23.8046 15.1956L17.8081 9.19911C17.6176 9.00922 17.3318 8.95124 17.0819 9.05453C16.8327 9.15714 16.6708 9.40101 16.6708 9.67019V12.338C12.8371 12.4952 9.49236 15.0837 8.42098 18.7929C7.95725 20.4013 7.99323 21.7078 8.00654 22.2002L8.00921 22.3295C8.00921 22.6293 8.20974 22.8925 8.49824 22.9718C8.55755 22.9878 8.61683 22.9957 8.67548 22.9957C8.90601 22.9957 9.12587 22.8751 9.24781 22.6706C11.8869 18.2345 15.3835 18.1086 16.6708 18.2339V21.6632C16.6708 21.9324 16.8327 22.1763 17.0819 22.2789C17.3318 22.3828 17.6176 22.3248 17.8081 22.1343L23.8046 16.1378C24.0651 15.8772 24.0651 15.4561 23.8046 15.1956Z" fill="white"/>
    </g>
    <defs>
    <filter id="filter0_i_4929_3593" x="0" y="0" width="33" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="1" dy="5"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.595833 0 0 0 0 0.595833 0 0 0 0 0.595833 0 0 0 0.3 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_4929_3593"/>
    </filter>
    <clipPath id="clip0_4929_3593">
    <rect width="16" height="16" fill="white" transform="translate(8 8)"/>
    </clipPath>
    </defs>
    </svg>
);

/* eslint-enable */
