import api from './api';


export const getCallsData = url => ({
  type: 'GET_CALLS_DATA',
  payload: api.get(url),
});

export const editCustomerCallsNote = data => ({
  type: 'UPDATE_CALLS_DATA',
  payload: api.put('/cstnotes/update/', data),
});
