import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import persistState from 'redux-localstorage';
import rootReducer from './reducers';

// const enhancer = compose(
//   applyMiddleware(
//     thunk,
//     promise,
//   ),
//   persistState('login'),
//   // window.devToolsExtension(),
// );
/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */
const enhancer = composeEnhancers(
  applyMiddleware(
    thunk,
    promise,
  ),
  persistState(['login', 'sysConfig', 'performer']),
  // window.devToolsExtension(),
);

/* eslint-disable no-underscore-dangle */
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default function configureStore() {
  const store = createStore(rootReducer, {}, enhancer);
  // const store = createStore(
  //   rootReducer, /* preloadedState, */
  //   composeEnhancers(
  //     applyMiddleware(
  //       thunk,
  //       promise,
  //     ),
  //     persistState('login'),
  //   ),
  // );
  return store;
}
/* eslint-enable */
