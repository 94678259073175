export default function (state = [], action = {}) {
  switch (action.type) {
    case 'GET_REVIEWS_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'GET_REVIEWS_FULFILLED':
      return {
        ...state,
        isLoading: false,
        next: action.payload.data.next,
        previous: action.payload.data.previous,
        count: action.payload.data.count,
        data: action.payload.data.results,
        error: false,
      };

    case 'GET_REVIEWS_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    default:
      return {
        ...state,
      };
  }
}
