export default function (state = [], action = {}) {
  switch (action.type) {
    case 'POST_WHISPERER_DATA':
      return {
        ...state,
        msg: action.payload,
        notification: false,
      };
    case 'PUSH_NOTIFICATION_ALERT':
      return {
        ...state,
        notification: true,
      };
    default:
      return {
        ...state,
        notification: false,
      };
  }
}
