import React, { useState, useEffect } from 'react';
import {
  Card, CardBody, Button,
  CardHeader, Label, Badge,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { Field } from 'redux-form';
import { PropTypes } from 'prop-types';
import AvatarEditor from 'react-avatar-editor';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import gallery from '../../assets/svgs/gallery';
import cameraIconOutline from '../../assets/svgs/camera_outline';
import cameraIcon from '../../assets/svgs/camera_icon';
import { renderFileInput } from '../../utils/formUtils';
import CustomModal from '../../components/CustomModal';

const ImagePanel = (props) => {
  const {
    backgroundPic,
    primaryPic,
    updateProfile,
    charId,
    formData: { backgroundPicUrl, primaryPicUrl } = {},
    reset,
    isCharacterLoading,
  } = props;
  const [isImageValid, setisImageValid] = useState(true);
  const [backgroundPicModal, setBackgroundPicModal] = useState(false);
  const [leavePageModal, setLeavePageModal] = useState(false);
  const [leaveBackgroundEditing, setLeaveBackgroundEditing] = useState(false);
  const [editBackgroundImage, setBackgroundImage] = useState(false);
  const [primaryPicModal, setPrimaryPicModal] = useState(false);
  const [editPrimaryImage, setPrimaryImage] = useState(false);
  const [primaryPicValidSizeModal, setPrimaryPicValidSizeModal] = useState(false);
  const [afterUpdateProfilePicture, setAfterUpdateProfilePicture] = useState(false);

  useEffect(() => {
    if (editBackgroundImage && (backgroundPicUrl.length > 0)) {
      const img = new Image();
      img.src = backgroundPicUrl;
      img.onload = () => {
        if ((img.naturalHeight > 0 && img.naturalHeight < 300)
          || (img.naturalWidth > 0 && img.naturalWidth < 900)
        ) {
          setBackgroundPicModal(true);
        } else {
          setisImageValid(true);
        }
      };
    }
  }, [backgroundPicUrl]);

  const togglePrimaryPicModal = () => setPrimaryPicModal(!primaryPicModal);
  useEffect(() => {
    if (editPrimaryImage && (primaryPicUrl.length > 0)) {
      const img = new Image();
      img.src = primaryPicUrl;
      img.onload = () => {
        if ((img.naturalHeight > 0 && img.naturalHeight < 140)
          || (img.naturalWidth > 0 && img.naturalWidth < 140)
        ) {
          setPrimaryPicValidSizeModal(true);
          // setisImageValid(false);
        } else {
          setisImageValid(true);
          togglePrimaryPicModal();
        }
      };
    }
  }, [primaryPicUrl]);

  const [editInfo, setEditInfo] = useState(true);
  const [scale, setScale] = useState(1);
  const [updatingField, setUpdatingField] = useState();
  const [backgroundEditor, setBackgroundEditor] = useState({});
  const [primaryEditor, setPrimaryEditor] = useState({});

  const toggleLeavePageModal = () => setLeavePageModal(!leavePageModal);
  const setBackgroundPicRef = (avatarEditor = {}) => setBackgroundEditor(avatarEditor);
  const setPrimaryPicRef = (avatarEditor = {}) => setPrimaryEditor(avatarEditor);

  const resetBackgroundPicPanel = () => {
    setBackgroundImage(false);
    setUpdatingField('');
    setisImageValid(false);
    reset();
  };

  const resetPrimaryPicPanel = () => {
    togglePrimaryPicModal();
    setUpdatingField('');
    setisImageValid(false);
    setPrimaryImage(false);
    reset();
  };

  /* required for initialising editor ref */
  useEffect(() => {
    if (backgroundEditor == null) {
      setBackgroundPicRef();
    }
  }, [backgroundEditor]);

  const onSave = (picture) => {
    let canvas = {};
    if ((picture === 'backgroundPic') && backgroundEditor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      canvas = backgroundEditor.getImage().toDataURL();
      fetch(canvas)
        .then(res => res.blob())
        .then((blob) => {
          const payload = {
            id: charId,
            [picture]: blob,
          };

          updateProfile(payload);
          setAfterUpdateProfilePicture(!afterUpdateProfilePicture);
          setBackgroundPicRef();
        });
    }

    if ((picture === 'primaryPic') && primaryEditor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      canvas = primaryEditor.getImage().toDataURL();
      fetch(canvas)
        .then(res => res.blob())
        .then((blob) => {
          const payload = {
            id: charId,
            [picture]: blob,
          };

          updateProfile(payload);
          setAfterUpdateProfilePicture(!afterUpdateProfilePicture);
          setPrimaryPicRef();
        });
    }

    if (picture === 'primaryPic') {
      togglePrimaryPicModal();
      setPrimaryImage(false);
    }

    if (picture === 'backgroundPic') {
      setBackgroundImage(false);
    }
    // setUpdatingField('');
  };

  const renderBackgroundEditor = () => {
    if (editBackgroundImage && isImageValid && backgroundEditor) {
      const { canvas: { offsetParent: { clientWidth } = {} } = {} } = backgroundEditor;
      return (
        <>
          <AvatarEditor
            ref={setBackgroundPicRef}
            image={backgroundPicUrl}
            width={clientWidth}
            height={300}
            border={1}
            onMouseMove={() => setEditInfo(false)}
            onMouseUp={() => setEditInfo(true)}
          />
          {editInfo && (
            <Badge>
              <i className="fa fa-arrows" aria-hidden="true" />
              Drag to move photo
            </Badge>
          )}
        </>
      );
    }

    if ((updatingField === 'backgroundPic') && isCharacterLoading) {
      return (
        <>
          <div
            className="backgroundPic blurImage"
            style={{
              backgroundImage: `url(${backgroundPic})`,
            }}
          >
            { backgroundPic
              ? ''
              : gallery('#DBDCDE', 'bgPlaceholder')
            }
          </div>
          <i className="fa fa-spinner fa-spin" />
        </>
      );
    }

    return (
      <div
        className="backgroundPic"
        style={{
          backgroundImage: `url(${backgroundPic})`,
        }}
      >
        { backgroundPic
          ? ''
          : gallery('#DBDCDE', 'bgPlaceholder')
        }
      </div>
    );
  };

  const renderPrimaryEditor = () => (
    <>
      <AvatarEditor
        ref={setPrimaryPicRef}
        image={primaryPicUrl}
        width={300}
        height={300}
        border={100}
        borderRadius={140}
        color={[255, 255, 255, 0.6]} // RGBA
        scale={scale}
        onMouseMove={() => setEditInfo(false)}
        onMouseUp={() => setEditInfo(true)}
      />
      {editInfo && (
        <Badge>
          <i className="fa fa-arrows" aria-hidden="true" />
          Drag to reposition photo
        </Badge>
      )}
    </>
  );

  const renderPrimaryPicModal = () => {
    const minScale = 1;
    const maxScale = 3;
    return (
      <Modal
        id="editPrimaryPicModal"
        size="lg"
        isOpen={primaryPicModal}
        toggle={togglePrimaryPicModal}
        backdrop={false}
      >
        <ModalHeader>
          Create profile photo
        </ModalHeader>
        <ModalBody>
          {renderPrimaryEditor()}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <div className="d-flex justify-content-between w-100">
            <div>
              <Button className="" outline color="primary" onClick={() => setScale(1)}>
                Reset
              </Button>
            </div>
            <div className="d-flex align-items-center ml120">
              <i
                className="fa fa-minus"
                aria-hidden="true"
                onClick={() => setScale(() => {
                  if (scale <= minScale) {
                    return scale;
                  }
                  return scale - 0.1;
                })}
              />
              <InputRange
                maxValue={maxScale}
                minValue={minScale}
                step={0.01}
                value={scale}
                onChange={setScale}
              />
              <i
                className="fa fa-plus"
                aria-hidden="true"
                onClick={() => setScale(() => {
                  if (scale > (maxScale - 0.1)) {
                    return scale;
                  }
                  return scale + 0.1;
                })}
              />
            </div>
            <div>
              <Button
                className="mr-2"
                onClick={toggleLeavePageModal}
                color="primary"
                outline
              >
                Cancel
              </Button>
              <Button
                color="primary"
                className="text-white"
                onClick={() => {
                  onSave('primaryPic');
                  reset();
                }}
              >
                Save changes
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  };

  const renderProfilePic = () => {
    if (primaryPic) {
      return (
        <div
          className="primaryPic"
          style={{
            backgroundImage: `url(${primaryPic})`,
          }}
        />
      );
    }
    return <div className="profile-placeholder" />;
  };

  return (
    <Card className="imagePanel mb-5 position-relative">
      <CustomModal
        header="Try a different image"
        body="Your background picture must be at least 900 pixels wide and 300 pixels tall"
        open={backgroundPicModal}
        toggle={() => setBackgroundPicModal(() => {
          if (backgroundPicModal) {
            /* Close background image editing if picture not valid */
            setBackgroundImage(false);
          }

          return !backgroundPicModal;
        })}
      />

      <CustomModal
        header="Try a different image"
        body="Your profile picture must be at least 140 pixels wide and 140 pixels tall"
        open={primaryPicValidSizeModal}
        toggle={() => setPrimaryPicValidSizeModal(() => {
          if (primaryPicValidSizeModal) {
            /* Close background image editing if picture not valid */
            setPrimaryImage(false);
          }
          // setPrimaryPicValidSizeModal(false);
          return !primaryPicValidSizeModal;
        })}
      />

      <CustomModal
        header="Changes not saved"
        body="The changes you made to the picture won't be saved if you close this dialogue"
        buttonText="LEAVE THIS PAGE"
        footer="Stay on this page"
        open={leavePageModal}
        footerLink={toggleLeavePageModal}
        onClick={updatingField === 'primaryPic' ? resetPrimaryPicPanel : resetBackgroundPicPanel}
        toggle={toggleLeavePageModal}
      />

      <CustomModal
        header="Changes not saved"
        body="The changes you made to background picture won't be saved if you close this dialogue"
        buttonText="OK"
        footer="CANCEL"
        footerLink={() => setLeaveBackgroundEditing(!leaveBackgroundEditing)}
        open={leaveBackgroundEditing}
        onClick={resetBackgroundPicPanel}
        toggle={() => setLeaveBackgroundEditing(!leaveBackgroundEditing)}
      />

      <CustomModal
        header="Media was submitted"
        body="If approved, changes will appear within 72 hours"
        buttonText="OK"
        // footer="CANCEL"
        // footerLink={() => setAfterUpdateProfilePicture(false)}
        open={afterUpdateProfilePicture}
        // onClick={resetBackgroundPicPanel}
        toggle={() => setAfterUpdateProfilePicture(false)}
      />

      {renderPrimaryPicModal()}

      <CardHeader className="picture p-0">
        {(backgroundPic || backgroundPicUrl) ? renderBackgroundEditor() : gallery('#DBDCDE', 'bgPlaceholder')}
      </CardHeader>

      <CardBody className="text-right p-0">
        <div className="profilePic">
          {((updatingField === 'primaryPic') && isCharacterLoading) ? (
            <>
              <div
                className="primaryPic blurImage"
                style={{
                  backgroundImage: `url(${primaryPic})`,
                }}
              />
              <i className="fa fa-spinner fa-spin" />
            </>
          ) : (
            <>
              {renderProfilePic()}
              <div className="updateProfilePic">
                {editPrimaryImage ? (
                  <Label
                    htmlFor="primaryPic"
                    className="primaryPic"
                    onClick={() => setLeaveBackgroundEditing(!leaveBackgroundEditing)}
                  >
                    {cameraIcon()}
                    {/* <span>Update</span> */}
                  </Label>
                ) : (
                  <Field
                    name="primaryPic"
                    type="file"
                    handleChange={() => {
                      setPrimaryImage(true);
                      setUpdatingField('primaryPic');
                    }}
                    customInput={(
                      <Label htmlFor="primaryPic" className="primaryPic">
                        {cameraIcon()}
                        {/* <span>Update</span> */}
                      </Label>
                    )}
                    component={renderFileInput}
                    accept="image/jpeg, image/jpg"
                  />
                )}
              </div>
            </>
          )}
        </div>

        {(editBackgroundImage && isImageValid) ? (
          <div className="absoluteImgGroup">
            <Button
              className="mr-2"
              onClick={() => toggleLeavePageModal()}
              color="primary"
              outline
            >
              Cancel
            </Button>
            <Button onClick={() => onSave('backgroundPic')} className="text-white" color="primary">
              Save changes
            </Button>
          </div>
        ) : (
          <Field
            name="backgroundPic"
            type="file"
            customInput={(
              <Label htmlFor="backgroundPic" className="backgroundPic">
                <div>{cameraIconOutline()}</div>
                <div>Update Photo</div>
              </Label>
            )}
            handleChange={() => {
              setBackgroundImage(true);
              setUpdatingField('backgroundPic');
            }}
            component={renderFileInput}
            accept="image/jpeg, image/jpg"
          />
        )}
      </CardBody>
    </Card>
  );
};

ImagePanel.defaultProps = {
  charId: -1,
  backgroundPic: '',
  primaryPic: '',
  isCharacterLoading: false,
  formData: {
    primaryPicUrl: '',
    backgroundPicUrl: '',
  },
  reset: () => {},
};

ImagePanel.propTypes = {
  charId: PropTypes.number,
  backgroundPic: PropTypes.string,
  primaryPic: PropTypes.string,
  updateProfile: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    primaryPicUrl: PropTypes.string.isRequired,
    backgroundPicUrl: PropTypes.string.isRequired,
  }),
  reset: PropTypes.func,
  isCharacterLoading: PropTypes.bool,
};

export default ImagePanel;
