import React from 'react';

export default fill => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect x="2" y="2" width="9" height="9" rx="2" fill={fill} />
    <rect x="13" y="2" width="9" height="9" rx="2" fill={fill} />
    <rect x="2" y="13" width="9" height="9" rx="2" fill={fill} />
    <rect x="13" y="13" width="9" height="9" rx="2" fill={fill} />
  </svg>
);
