export default function (state = [], action = {}) {
  const getData = () => {
    const { data: { perfcharacter = [{}], perfperformer = {} } = {} } = state;

    if (action.dataToUpdate === 'character') {
      const characters = [];
      perfcharacter.forEach((char, index) => {
        if (index === 0) {
          characters.push({
            ...char,
            ...action.payload,
          });
        } else {
          characters.push(char);
        }
      });

      return {
        ...state.data,
        perfcharacter: characters,
      };
    }

    if (action.dataToUpdate === 'performer') {
      const performer = {
        ...perfperformer,
        ...action.payload,
      };

      const data = {
        ...state.data,
        perfperformer: performer,
      };
      return data;
    }

    return {
      ...state.data,
      ...action.payload,
    };
  };

  switch (action.type) {
    case 'PERF_LOGIN_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'PERF_LOGIN_FULFILLED':
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        error: false,
      };

    case 'PERF_LOGIN_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case 'UPDATE_DATA':
      return {
        ...state,
        data: getData(),
        isLoading: false,
        error: false,
      };

      // case 'GET_PERFORMER_PENDING':
      //   return {
      //     ...state,
      //     isLoading: true,
      //     error: false,
      //   };

      // case 'GET_PERFORMER_FULFILLED':
      //   return {
      //     ...state,
      //     isLoading: false,
      //     data: {
      //       ...state.data,
      //       perfperformer: { ...action.payload.data },
      //     },
      //     error: false,
      //   };

      // case 'GET_PERFORMER_REJECTED':
      //   return {
      //     ...state,
      //     isLoading: false,
      //     error: true,
      //   };

    default:
      return {
        ...state,
      };
  }
}
