import {
  FETCH_DATA_FAILURE, FETCH_DATA_REQUEST, FETCH_DATA_SUCCESS, SET_CURRENT_PAGE,
} from '../actions/actionGetMsgsHistory';

const initialState = {
  data: [],
  currentPage: 1,
  totalCount: 0,
  loading: false,
  error: null,
};

const uniqueMessageId = message => message.objid;

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_DATA_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newMessages = action.data.filter(
        message => !state.data.some(
          existingMessage => uniqueMessageId(existingMessage) === uniqueMessageId(message),
        ),
      );
      return {
        ...state,
        data: [...newMessages, ...state.data],
        totalCount: action.totalCount,
        loading: false,
        error: null,
      };

    case FETCH_DATA_FAILURE:
      return { ...state, loading: false, error: action.error };

    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.page, data: [] };

    default:
      return state;
  }
};
