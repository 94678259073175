import React from 'react';
import {
  Row, Col, Card,
  CardBody, CardText,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import refreshIcon from '../../../assets/svgs/refresh.svg';
import '../style.scss';

const AffiliateCreditCard = (props) => {
  const {
    affiliateAmount,
    affiliateCredit,
    isLoading,
    objectId = -1,
    getPerformer,
  } = props;

  return (
    <Row className="mb-3">
      <Col xs={12}>
        <Card className="customCard Credits-customCard bg-primary p-4 customCardHeader">
          <CardBody>
            <div className="aff-card-heading mb-3">Affiliate Earnings</div>
            <Row className="align-items-end">
              <Col sm={7}>
                <h4 className="amount mb-0">{`$${affiliateAmount}`}</h4>
                <h6 className="amtTitle">Your current balance</h6>
              </Col>
              <Col sm={5} className="text-right">
                <CardText className="mb-0 creditamount">{`${affiliateCredit}`}</CardText>
                <h6 className="mb-0 amtTitle">CREDITS</h6>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="text-right" sm={12}>
                <Button className="creditBtn px-3 mb-0 refreshBtn" onClick={() => getPerformer(objectId)}>
                  {
                    isLoading
                      ? <div className="css-loader mr-1" style={{ position: 'static' }} />
                      : (
                        <img
                          role="presentation"
                          className="mr-1"
                          src={refreshIcon}
                          alt="refresh_credits_avatar"
                          width="16"
                        />
                      )
                  }
                  <div className="ml-1">Refresh</div>
                </Button>
              </Col>
            </Row>
            <Row className="credit-update-info" style={{ left: '54px' }}>This information is updated every 5 minutes.</Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

AffiliateCreditCard.defaultProps = {
  affiliateCredit: 0,
  affiliateAmount: 0,
  objectId: -1,
  isLoading: false,
};

AffiliateCreditCard.propTypes = {
  affiliateAmount: PropTypes.number,
  affiliateCredit: PropTypes.number,
  objectId: PropTypes.number,
  getPerformer: PropTypes.func.isRequired,
  // eslint-disable-next-line
  // history: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default AffiliateCreditCard;
