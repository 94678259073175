import React from 'react';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import './OtpVerificationModal.scss';

const DeleteConfirmationModal = (props) => {
  const {
    className,
    header,
    body,
    open,
    toggle,
    onClick,
    deleteId,
  } = props;

  const confirmDelete = (id) => {
    onClick(id);
    toggle();
  };

  const closeModal = () => {
    toggle();
  };

  return (
    <Modal
      className={`EditInfo-Modal delete-number-modal ${className}`}
      isOpen={open}
      toggle={toggle}
      backdrop={false}
    >
      <ModalHeader className="chat-modal-header" toggle={toggle} />
      <ModalBody className="chat-modal-body">
        <h4>{header}</h4>
        <p>{body}</p>
        <div className="d-flex">
          <Button
            type="submit"
            color="primary"
            className="btn btn-primary mr-2"
            onClick={() => confirmDelete(deleteId)}
          >
            Ok
          </Button>
          <Button
            type="submit"
            color="primary"
            className="btn btn-primary"
            onClick={closeModal}
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
      {/* <ModalFooter className="chat-modal-footer">
        <p role="presentation" onClick={footerLink}>{footer}</p>
      </ModalFooter> */}
    </Modal>
  );
};

DeleteConfirmationModal.defaultProps = {
  className: '',
  header: '',
  body: '',
  open: false,
  deleteId: 0,
};

DeleteConfirmationModal.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  deleteId: PropTypes.number,
};

export default DeleteConfirmationModal;
