import React from 'react';

export default () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7997 20.9607C-8.01326 8.59859 6.5561 -4.82137 12.7997 3.66458C19.0441 -4.82137 33.6135 8.59859 12.7997 20.9607Z"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);
