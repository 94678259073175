// Remove Default when add anoter export
const MEDIA_CONST = {
  VIDEO_BASE_URL: 'https://watch.cloudflarestream.com/',
  VIDEO: 'video',
  IMAGE_BASE_URL: 'https://res.cloudinary.com/tfonia/image',
  IMAGE: 'image',
  VIDEO_CLOUDFLARE_BASE_URL: 'https://iframe.videodelivery.net/',
  AUDIO_BASE_URL: 'https://res.cloudinary.com/tfonia/video',
  AUDIO: 'audio',
};

export default MEDIA_CONST;
