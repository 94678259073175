import api from './api';

/*
const getPaymentHistory = cstContactObjectId => ({
  type: 'GET_PAYMENT_HISTORY',
  payload: api.get(`/cfgsettlementtransactions/list/?settlement2cst_contact=${cstContactObjectId}`),
});
*/

const getPaymentHistory = url => ({
  type: 'GET_PAYMENT_HISTORY',
  payload: api.get(url),
});

/*
export const getTransactions = cstContactObjectId => ({
  type: 'GET_TRANSACTIONS',
  payload: api.get(`/cfgcredittransactions/list/?performer2cst_contact=
  ${cstContactObjectId}&trans_type=SMS001|DE0006|DE0007|DE003`),
});
*/

export const getTransactions = url => ({
  type: 'GET_TRANSACTIONS',
  payload: api.get(url),
});

/*
export const getTips = cstContactObjectId => ({
  type: 'GET_TIPS',
  payload: api.get(`/cfgcredittransactions/list/?performer2cst_contact=$
  {cstContactObjectId}&trans_type=TIP001`),
});
*/

export const getTips = url => ({
  type: 'GET_TIPS',
  payload: api.get(url),
});

export const refundTip = objid => ({
  type: 'REFUND_TIP',
  payload: api.put(`/cfgcredittransactions/refund/${objid}/`),
});

export default getPaymentHistory;
