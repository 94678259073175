import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button, Modal, ModalHeader,
  ModalBody, ModalFooter,
} from 'reactstrap';
import './style.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from '../../../../utils/LoadingSpinner';

const MediaOptionModal = (props) => {
  const {
    isOpen,
    handlePrivateMediaModal,
    getPrivateMedia,
    privateProductList,
    next,
    privateMediaCount,
    getMediaPrivateModal,
  } = props;

  const [modal, setModal] = useState(false);
  const [privatePhotosGallery, setPrivatePhotosGallery] = useState([]);
  const [selectedImage, setselectedImage] = useState('');
  const [dataLoaded, setDataLoaded] = useState(0);
  const [showLoader, setShowLoader] = useState(0);
  useEffect(() => {
    setModal(isOpen);
    getPrivateMedia();
    setDataLoaded(1);
    setTimeout(() => {
      setShowLoader(1);
    }, 2000);
    return (() => {
      setPrivatePhotosGallery([]);
      // dataLoaded = false;
    });
  }, []);

  const toggle = () => {
    handlePrivateMediaModal();
    setModal(!modal);
  };

  const sendPrivateImage = (picURL) => {
    setselectedImage(picURL);
  };

  useEffect(() => {
    if (privateProductList && privateProductList.length) {
      let privateGallery = [];
      if (dataLoaded > 1) {
        const newPrivateGallery = privateProductList.map(photo => (
          <div key={`privateProduct${photo.product_id}`} className="privatePhoto">
            <button type="button" onClick={() => sendPrivateImage(photo.product_thumb_url)}>
              <img src={photo.product_thumb_url} alt={photo.product_name} height="50" width="50" />
            </button>
          </div>
        ));
        privateGallery = [...privatePhotosGallery, ...newPrivateGallery];
      } else if (dataLoaded === 1) {
        const newPrivateGallery = privateProductList.map(photo => (
          <div key={`privateProduct${photo.product_id}`} className="privatePhoto">
            <button type="button" onClick={() => sendPrivateImage(photo.product_thumb_url)}>
              <img src={photo.product_thumb_url} alt={photo.product_name} height="50" width="50" />
            </button>
          </div>
        ));
        privateGallery = [...newPrivateGallery];
        // dataLoaded = true;
      }
      setPrivatePhotosGallery(privateGallery);
    }
  }, [privateProductList]);

  const shareImage = () => {
    if (selectedImage) {
      const msg = {
        media: selectedImage,
      };
      getMediaPrivateModal(msg);
      // sendMessage(msg);
      setselectedImage('');
      toggle();
    }
  };

  const handleLoadMore = () => {
    if (next) {
      setDataLoaded(dataLoaded + 1);
      getPrivateMedia(next);
    }
  };
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="privateModal">
        <ModalHeader toggle={toggle}>Private Photo</ModalHeader>
        <ModalBody>
          {showLoader > 0
            && (privatePhotosGallery && privatePhotosGallery.length ? (
              <InfiniteScroll
                dataLength={privatePhotosGallery.length}
                next={handleLoadMore}
                /* eslint-disable */
                hasMore={privatePhotosGallery.length < privateMediaCount}
                /* eslint-enable */
                // loader={<LoadingSpinner />}
                // height={433}
                scrollableTarget="scrollableDiv"
              >
                <section id="scrollableDiv" className="private-gallery-grid">
                  {privatePhotosGallery}
                </section>
              </InfiniteScroll>
            ) : (
              <div className="text-center">No Media Found</div>
            )
            )
          }
          {showLoader === 0 && <div className="height-250"><LoadingSpinner /></div>}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={!selectedImage} onClick={shareImage}>Share</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

MediaOptionModal.defaultProps = {
  isOpen: false,
  privateProductList: [],
  next: null,
  privateMediaCount: 0,
};

MediaOptionModal.propTypes = {
  isOpen: PropTypes.bool,
  handlePrivateMediaModal: PropTypes.func.isRequired,
  getPrivateMedia: PropTypes.func.isRequired,
  privateProductList: PropTypes.instanceOf(Array),
  next: PropTypes.string,
  privateMediaCount: PropTypes.bool,
  getMediaPrivateModal: PropTypes.func.isRequired,
};

export default MediaOptionModal;
