export default function (state = [], action = {}) {
  switch (action.type) {
    case 'GET_LAST_CALL_DATA_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'GET_LAST_CALL_DATA_FULFILLED':
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        error: false,
      };

    case 'GET_LAST_CALL_DATA_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.payload.response.data,
      };

    default:
      return {
        ...state,
      };
  }
}
