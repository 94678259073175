import React, { Suspense } from 'react';
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { isUserAgentMobile } from './utils/helpers';
import interceptor from './utils/interceptor';
import LoadingSpinner from './utils/LoadingSpinner';
import LoginContainer from './containers/LoginContainer';
import DashboardContainer from './containers/DashboardContainer';
import PhonebookContainer from './containers/PhonebookContainer';
import BalanceContainer from './containers/BalanceContainer';
import ReviewsContainer from './containers/ReviewsContainer';
import ProfileContainer from './containers/ProfileContainer';
import VisibilityContainer from './containers/VisibilityContainer';
// import PlateformRulesContainer from './containers/PlateformRulesContainer';
import PlatformRules from './scenes/PlatformRules';
import VideoCallContainer from './containers/VideoCallContainer';
// import ChatlogContainer from './containers/ChatlogContainer';
import ChatContainer from './containers/ChatContainer';
import AffiliateContainer from './containers/AffiliateContainer';
import MediaContainer from './containers/MediaContainer';
import RouletteContainer from './containers/RouletteContainer';
import FeedContainer from './containers/FeedContainer';
import CustomerProfileContainer from './containers/CustomerProfileContainer';
import BodyWrapper from './containers/BodyWrapperContainer';
import SupportContainer from './containers/SupportContainer';
import CustomerChatlogConstainer from './containers/CustomerChatlogConstainer';
// import SidebarContainer from './containers/SidebarContainer';
import NotFoundPage from './scenes/404 Page';
import NotForMobile from './scenes/NotAllowMobilePage';
import MobileChatContainer from './containers/MobileChatContainer';
import LogoutMobileOnRoulletteOff from './scenes/MobileLogoutOnRoulletteOff';
import MyInfoContainer from './containers/MyInfoContainer';
import EditInfoContainer from './containers/EditInfoContainer';
import SettingsContainer from './containers/SettingsContainer';
import TestSetupContainer from './containers/TestSetupContainer';
import FavCustomerContainer from './containers/FavCustomerContainer';

const BaseRoutes = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => <Component {...props} />
    }
  />
);

BaseRoutes.propTypes = {
  component: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  interceptor();
  const userId = localStorage.getItem('loggedInUserId');
  const isMobile = isUserAgentMobile() || false;
  return (
    <Route
      {...rest}
      render={props => (userId
        // eslint-disable-next-line react/forbid-prop-types
        ? <Component {...props} /> // : <Component {...props} />)
        : <Redirect to={{ pathname: isMobile ? '/not-mobile' : '/login', state: { from: props.location } }} />)
      }
    />
  );
};

PrivateRoute.defaultProps = {
  location: window.location,
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
};


let renderRoutes = '';

if (isUserAgentMobile()) {
  renderRoutes = () => (
    <>
      <BodyWrapper>
        <div className="minDeskTopHeight">
          <Switch>
            <Route exact path="/chat" component={ChatContainer} />
          </Switch>
        </div>
      </BodyWrapper>
    </>
  );
}

renderRoutes = () => (
  <>
    <BodyWrapper>
      <div className="minDeskTopHeight">
        <Switch>
          <Route exact path="/dashboard" component={DashboardContainer} />
          <Route exact path="/balance" component={BalanceContainer} />
          <Route exact path="/reviews" component={ReviewsContainer} />
          <Route exact path="/media" component={MediaContainer} />
          <Route exact path="/profile" component={ProfileContainer} />
          <Route exact path="/chat" component={ChatContainer} />
          <Route exact path="/favoritecustomer" component={FavCustomerContainer} />
          <Route exact path="/support" component={SupportContainer} />
          <Route exact path="/affiliate_program" component={AffiliateContainer} />
          <Route exact path="/visibility" component={VisibilityContainer} />
          <Route exact path="/platformRules" component={PlatformRules} />
          <Route exact path="/feed" component={FeedContainer} />
          <Route exect path="/phonebook" component={PhonebookContainer} />
          <Route exact path="/customerProfile/:id" component={CustomerProfileContainer} />
          <Route exact path="/customerChatlog/:id" component={CustomerChatlogConstainer} />
          <Route exact path="/customercallslog/:id" component={CustomerChatlogConstainer} />
          <Route exact path="/roulette" component={RouletteContainer} />
          <Route exact path="/video-call" component={VideoCallContainer} />
          <Route exact path="/voice-call" component={VideoCallContainer} />
          <Route exact path="/404" component={NotFoundPage} />
          <Route exact path="/" component={DashboardContainer} />
          <Route exect path="/myinfo" component={MyInfoContainer} />
          <Route exect path="/editinfo" component={EditInfoContainer} />
          <Route exect path="/settings" component={SettingsContainer} />
          <Route exect path="/test-setup" component={TestSetupContainer} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </BodyWrapper>
  </>
);

const baseRoutes = () => (
  <Switch>
    <Route exact path="/login" component={LoginContainer} />
    <PrivateRoute path="/" component={renderRoutes} />
    <Route exact path="/" component={LoginContainer} />
  </Switch>
);

export default () => (
  <Router>
    <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        <Route exact path="/not-mobile" component={NotForMobile} />
        <Route exact path="/continue-to-chat/:id" component={MobileChatContainer} />
        <Route exact path="/continue-to-desktop" component={LogoutMobileOnRoulletteOff} />
        <BaseRoutes path="/" component={baseRoutes} />
      </Switch>
    </Suspense>
  </Router>
);
