/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ReactInternetSpeedMeter } from 'react-internet-meter';
import PropTypes from 'prop-types';
import 'strophe.js';
import { useDispatch } from 'react-redux';
import api from '../../actions/api';
import PortalFooter from '../PortalFooter';
import VideoChatModal from '../VideoChatModal';
import CustomAlertContainer from '../../containers/CustomAlertContainer';
import VideoCallHeader from '../../scenes/VideoCall/component/VideoHeader';
import favicon from '../../assets/images/favicon_new.ico';
import notificationSound from '../../assets/sound/notification.wav';
import callnotification from '../../assets/sound/IncomingTone.wav';
import playSound from '../../utils/playSound';
import PortalHeaderContainer from '../../containers/PortalHeaderContainer';
import SidebarContainer from '../../containers/SidebarContainer';
import { checkCloudinaryUrl, isUserAgentMobile } from '../../utils/helpers';
import RouletteTimePopup from '../TimeSessionPopup';
import CustomModal from '../CustomModal';
import CustomerOnlineStatusModal from '../CustomerOnlineStatusModal';
import './style.scss';
import postMissedCallsData from '../../actions/actionMissedCalls';
import postWhispererData, { pushNotificationAlert } from '../../actions/actionWhisperer';
import { postChatCountData } from '../../actions/actionChatCount';

const audio = new Audio(callnotification);

const BodyWrapper = (props) => {
  const {
    children,
    // bodyHeaderData,
    xmpp_user,
    xmpp_pass,
    endVideoSessionAction,
    allConversation,
    setConversationFunction,
    notiPermission,
    history,
    isRouletteEnable,
    changeVideoCallData,
    reduxVideoCallData,
    removeToken,
    updatePresence,
    checkActiveSessionAvailable,
    perfObjid,
  } = props;
  const xmppService = process.env.REACT_APP_XMPP_SERVICE;
  const xmppDomain = process.env.REACT_APP_XMPP_DOMAIN;
  // const xmppService = 'https://test.tpchat.com/bosh';
  // const xmppDomain = 'test.tpchat.com';

  const [jid] = useState(xmpp_user);
  const { Strophe } = window;
  const [connection] = useState(new Strophe.Connection(xmppService));
  const [customer, setCustomer] = useState({});
  const [videoModal, setVideoModal] = useState(false);
  const [videoId, setVideoId] = useState('');
  const [setTimeoutEnd, setSetTimeoutEnd] = useState(0);
  const parseConv = allConversation.length > 0 ? JSON.parse(allConversation) : [];
  const [conversations, setConversations] = useState(parseConv);
  const [messageNotification, setMessageNotification] = useState('');
  const [emojiNotificaiton, setEmojiNotification] = useState('');
  const [newNotificationId, setnewNotificationId] = useState(0);
  const [videoChatData, setVideoChatData] = useState({});
  const [offlineModal, setOfflineModal] = useState(false);
  const [isVoiceCall, setIsVoiceCall] = useState(false);
  const [isCustomerOnlineNow, setIsCustomerOnlineNow] = useState(false);
  const [isNotificationId, setIsNotificationId] = useState(0);
  const [originalMediaURL, setOriginalMediaURL] = useState('');
  const [isCustomerFavouriteState, setIsCustomerFavouriteState] = useState(false);
  const dispatch = useDispatch();

  const [videoSessionIdState, setVideoSessionIdState] = useState();

  const [isCustomerFavouriteNow, setIsCustomerFavouriteNow] = useState(false);

  // ----------- bosh connection process start.. ------------
  // ----------- notifiction recive handle start  --------

  let isConnected = false;

  // const connectionToBosh = () => {
  //   const fullJID = `${jid}@${xmppDomain}`;
  //   connection.connect(fullJID, xmpp_pass, onConnect, 60, 1);
  //   window.name = 'winArousrGirls';
  // };

  // Function to reload the page
  const reloadPage = () => {
    console.log('page reloaded!');
    window.location.reload(true);
  };

  // localStorage.removeItem('videoCallCustomerName');
  const connectionHandler = (client) => {
    const getElem = (msg, name) => {
      const unescapeHtml = (str) => {
        const temp = document.createElement('div');
        if (temp && str) {
          temp.innerHTML = str;
          const str2 = temp.childNodes[0].nodeValue;
          temp.removeChild(temp.firstChild);
          // Strophe.getText makes unescaping twice necessary
          temp.innerHTML = str2;
          const result = temp.childNodes[0].nodeValue;

          if (str.parentNode === null) {
            return false;
          }
          temp.removeChild(temp.firstChild);
          return result;
        }
        return '';
      };

      const elem = msg.getElementsByTagName(name);
      const el = elem.length > 0 ? unescapeHtml(Strophe.getText(elem[0])) : '';
      return el;
    };
    console.log('Getting Notification Message');
    console.log(client);
    const elem = name => getElem(client, name);
    const name = elem('custscreenname');
    const cstScreenName = elem('customer_screen_name');
    const requestType = elem('messagetype');
    const body = elem('body');
    const character = elem('custscreenname');
    const messagetype = elem('messagetype'); // Cust2char, Sys2Char
    const customerobjid = parseInt(elem('custobjid'), 10);
    const media = elem('media');
    const customerchanneltype = elem('customerchanneltype');
    const customerId = elem('custid');
    const videoSessionId = elem('videoSessionId');
    const custPic = elem('custpic');
    const isPremiumChat = elem('billingflag');
    const interruptcmd = elem('interruptcmd') || '';
    const chatlimit = elem('chatlimit') || '';
    const customerProfilePic = elem('customer_profile_pic') || '';
    const isRouletteType = elem('roulette_call') || 0;
    const videoCallType = elem('call_type') || 0;
    const notficationType = elem('notification_type') || '';
    const newNotficationType = elem('notificationtype') || '';
    const suggestedMessage = elem('bot_suggested_message') || '';
    const isVideoPresenceOn = elem('presence_video') === 'False' ? false : true || '';
    const isChatPreseceOn = elem('presence_sms') === 'False' ? false : true || '';
    const isPhonePreseceOn = elem('presence_voice') === 'False' ? false : true || '';
    const sessionId = parseInt(elem('session_id'), 10) || 0;
    const objid = parseInt(elem('objid'), 10);
    const sessionObjid = parseInt(elem('session_objid'), 10) || 0;
    const characterID = parseInt(elem('character_id'), 10);
    const characterObjid = parseInt(elem('characterobjid'), 10);
    const isPremiumCust = elem('is_premium_customer') === 'False' ? false : true || '';
    const customerReviewForm = elem('show_review_form') === 'False' ? false : true || '';
    const customerHostReview = elem('customer_host_review') || null;
    const isCustomerOnline = elem('is_customer_online') === 'False' ? false : true || '';
    const voiceCall = elem('voice_call') === 'False' ? false : true || '';
    const notificationId = parseInt(elem('notification_id'), 10);
    const onlineNotificationcustomerObjid = parseInt(elem('customer_objid'), 10);
    const customerProfilePicOnlineCust = elem('customer_profile_pic_url') || '';
    // const Emoji = elem('emoji');
    const MsgId = elem('chat_msg');
    const OriginalURL = elem('actual_url');
    const customerObjID = elem('customer_objid');
    const messageObjid = elem('message_objid');
    const emojiUnicode = elem('emoji_unicode');
    const chatType = elem('chat_type');
    const isCustomerFavourite = elem('is_favourite_customer') === 'False' ? false : true || '';
    // const msgMobileType = elem('messagetype');
    const newNotification = {
      name,
      requestType,
      notficationType,
      body,
      character,
      messagetype,
      customerobjid,
      media,
      customerchanneltype,
      customerId,
      videoSessionId,
      custPic,
      isPremiumChat,
      interruptcmd,
      chatlimit,
      isRouletteType,
      customerProfilePic,
      videoCallType,
      isVideoPresenceOn,
      isChatPreseceOn,
      isPhonePreseceOn,
      sessionId,
      objid,
      sessionObjid,
      characterID,
      isPremiumCust,
      characterObjid,
      customerReviewForm,
      customerHostReview,
      isCustomerOnline,
      voiceCall,
      cstScreenName,
      notificationId,
      onlineNotificationcustomerObjid,
      customerProfilePicOnlineCust,
      OriginalURL,
      customerObjID,
      messageObjid,
      chatType,
      newNotficationType,
      suggestedMessage,
      isCustomerFavourite,
    };
    setMessageNotification(newNotification);
    setOriginalMediaURL({
      URL: OriginalURL,
      chatType,
      NotificationType: notficationType,
      customer_objid: customerObjID,
      messageObjid,
    });

    setEmojiNotification({
      Emoji: emojiUnicode,
      MsgId,
      customerobjid,
      body,
      NotificationType: newNotficationType,
      sessionObjid,
    });
    // console.log('Emoji', Emoji);
    // if (MsgId) {
    //   // const activeUser = JSON.parse(localStorage.getItem('activeUser'));
    //   // if (activeUser) {
    //   //   const Foundmsg = activeUser.messages.find((msg) => {
    //   //     console.log(msg.objid, MsgId); return msg.objid === Number(MsgId);
    //   //   });
    //   //   localStorage.setItem('activeUser',
    //   //     JSON.stringify({
    //   //       ...activeUser,
    //   //       messages: [...activeUser.messages,
    //   //         { ...Foundmsg, emoji_object: { image_path: Emoji } }],
    //   //     }));
    //   //   console.log({ Foundmsg, activeUser, MsgId });
    //   // }

    //   const EmojiReactionImg = document.querySelector(`#emoji${MsgId}`);
    //   const msg = document.querySelector(`#msg${MsgId}`);
    //   const chatStatus = msg.querySelector('.chat-status');
    //   if (EmojiReactionImg && Emoji) {
    //     if (chatStatus) {
    //       chatStatus.classList.add('isReacted');
    //     }
    //     const ImageElement = document.createElement('img');
    //     ImageElement.src = Emoji;
    //     ImageElement.className = 'left-emoji-reaction';
    //     EmojiReactionImg.innerHTML = '';
    //     EmojiReactionImg.appendChild(ImageElement);
    //     EmojiReactionImg.className = 'incoming-emoji-reaction ';
    //   } else {
    //     EmojiReactionImg.className = '';
    //     EmojiReactionImg.innerHTML = '';
    //     if (chatStatus) {
    //       chatStatus.classList.remove('isReacted');
    //       msg.classList.add('isnot-reacted');
    //     }
    //   }
    // }
    return true;
  };

  const onConnect = (status) => {
    console.log('onConnect Function called');
    const { Status } = Strophe;
    if (status === Status.CONNECTED) {
      console.log('BOSH is Connected');
      connection.send(window.$pres().c('status').t('Available'));
      connection.addHandler(connectionHandler, null, 'message', null, null, null);
      isConnected = true;
    } else if (status === Status.CONNECTING) {
      console.log('Connecting..: ');
    } else if (status === Status.DISCONNECTED) {
      console.log('Disconnected : ');
      isConnected = false;
      // add retry code
      // connectionToBosh();
    } else if (status === Status.DISCONNECTING) {
      console.log('Disconnecting...: ');
    } else if (status === Status.ATTACHED) {
      console.log('The connection has been attached: ');
    } else if (status === Status.AUTHFAIL) {
      console.log('The authentication attempt failed: ');
    } else if (status === Status.AUTHENTICATING) {
      console.log('The connection is authenticating: ');
    } else if (status === Status.CONNFAIL) {
      console.log('The connection attempt failed: ');
      reloadPage();
    } else if (status === Status.ERROR) {
      console.log('An error has occurred: ');
      reloadPage();
    } else if (status === Status.CONNTIMEOUT) {
      console.log('Connection TimeOut error has occurred: ');
      reloadPage();
      // setTimeout(connectionToBosh, 5000);
    } else {
      console.log('UNKNOWN STATUS =  For : ');
    }
  };

  // Function to attempt connection
  const tryBOSHConnect = () => {
    console.log('trying to connect in every 60 seconds- isConnected', isConnected);
    if (!isConnected) {
      console.log('Attempting to connect...');
      const fullJID = `${jid}@${xmppDomain}`;
      connection.connect(fullJID, xmpp_pass, onConnect, 60, 1);
      window.name = 'winArousrGirls';
    }
  };

  // initially try to connect BOSH
  useEffect(() => {
    tryBOSHConnect();
    // Attempt to connect every 60 seconds if not connected
    setInterval(tryBOSHConnect, 60000);
  }, []);

  // ragister service worker
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const swUrl = `${process.env.PUBLIC_URL}/message-sw.js`;
      navigator.serviceWorker.register(swUrl);
    } else {
      console.log('service workers not supported!');
    }
  }, []);
  // ------------ bosh connection process end .. ----------
  // ------------ handle notification end.. ------------

  useEffect(() => {
    if (newNotificationId) {
      const updateCurrentActiveRoom = conversations.map((conv) => {
        const { id } = conv;
        if (id === newNotificationId) {
          return {
            ...conv,
            iscurrentlyActiveCust: true,
            unread: 0,
          };
        }
        return {
          ...conv,
          iscurrentlyActiveCust: false,
        };
      });
      setnewNotificationId(0);
      setConversations(updateCurrentActiveRoom);
      setConversationFunction(updateCurrentActiveRoom);
    }
  }, [newNotificationId]);
  const createNewChatNotification = (elem, isEmojiNotificaiton = false) => {
    const {
      body: text,
      customerobjid,
      name,
    } = elem;
    const img = favicon;
    let title = '';
    const finalText = checkCloudinaryUrl(text);
    // const notification = new Notification(title, options);

    if (isEmojiNotificaiton) {
      // title = elem?.NotificationType;
      title = '';
    } else {
      title = `New Message from ${name}`;
    }

    Notification.requestPermission((result) => {
      if (result === 'granted') {
        const options = {
          body: finalText,
          icon: img,
          silent: true,
          data: { url: `${window.location.origin}` },
          tag: 'renotify',
          renotify: true,
          customerobjid,
        };

        let safariAgent = navigator.userAgent.indexOf('Safari') > -1;
        const chromeAgent = navigator.userAgent.indexOf('Chrome') > -1;
        if ((chromeAgent) && (safariAgent)) {
          safariAgent = false;
        }

        if (safariAgent) {
          if ('Notification' in window) {
            const notification = new Notification(title, options);
            // console.log(notification);
            notification.onclick = () => {
              console.log('notification clicked');
              if (window.location.hash === '#/chat') {
                history.push('/chat');
              } else {
                const win = window.open('/#/chat', '_blank');
                win.focus();
                // history.push('/chat');
              }
              notification.close();
            };
          }
        } else if ('serviceWorker' in navigator) {
          const swUrl = `${process.env.PUBLIC_URL}/message-sw.js`;
          navigator.serviceWorker.register(swUrl).then((registration) => {
            if (registration.active) {
              registration.showNotification(title, options);
            }
          });

          navigator.serviceWorker.addEventListener('message', (event) => {
            console.log(event.data.msg, event.data.url);
            if (event.data.msg === 'click') {
              console.log('notification clicked 296');
              setnewNotificationId(customerobjid);
            }
          });
        }
      }
    });

    if (JSON.parse(localStorage.getItem('isVideoVoiceCall')) !== true) {
      playSound(notificationSound);
    }
    // document.addEventListener('visibilitychange', () => {
    //   if (document.visibilityState === 'visible') {
    //     notification.close();
    //   }
    // });
    // notification.onclick = (event) => {
    //   event.preventDefault();
    //   setnewNotificationId(customerobjid);
    //   window.focus();
    //   history.push('/chat');
    // };
  };

  // ---------- reject video request ----------

  const rejectRequest = (action = 'reject') => {
    localStorage.removeItem('isVideoVoiceCall');
    const formData = new FormData();
    formData.append('action', action);
    formData.append('video_session_id', videoId);
    formData.append('device_id', ' ');
    // localStorage.removeItem('videoCallCustomerName');
    endVideoSessionAction(formData);
    // console.log(JSON.parse(localStorage.getItem('videoChat')).id, videoId);
    if (JSON.parse(localStorage.getItem('videoChat')) && JSON.parse(localStorage.getItem('videoChat')).id !== videoId) {
      localStorage.removeItem('videoChat');
    }
    setVideoModal(false);
  };


  const updateLastMsgStatus = (session) => {
    let updatedSession = {};
    const messages = [...session.messages] || [];
    const msgLength = messages.length;
    let updatedMsg = {};
    if (msgLength) {
      updatedMsg = {
        ...messages[msgLength - 1],
        chat_status: 'Failed',
      };
    }
    messages.pop();
    messages.push(updatedMsg);
    updatedSession = {
      ...session,
      messages,
    };
    return updatedSession;
  };

  const updateConversations = (currentActiveSession = {}) => {
    const updatedConversation = conversations.filter(conv => conv.id !== currentActiveSession.id);
    updatedConversation.unshift(currentActiveSession);
    setConversations(updatedConversation);
    setConversationFunction(updatedConversation);
  };

  const updatePremiumchat = (msg, currentActiveSession) => {
    const isPremiumChatEnable = parseInt(msg.isPremiumChat, 10) || 0;
    const session = {
      ...currentActiveSession,
      premium: isPremiumChatEnable,
    };
    if (history.location.pathname === '/chat') updateConversations(session);
  };

  const addMessage = (msg, session = {}) => {
    let currentActiveSession = {};
    const messages = [...session.messages] || [];
    messages.push(msg);
    const lastMessageTime = new Date(msg.currentUTC).getTime();
    currentActiveSession = {
      ...session,
      messages,
      lastMessageTime,
    };
    updatePremiumchat(msg, currentActiveSession);
  };

  const onMessage = (elem) => {
    const {
      body, character, messagetype,
      customerobjid, media, customerchanneltype,
      custPic, isPremiumChat, interruptcmd, sessionId,
      objid, sessionObjid = 0, isPremiumCust, isCustomerOnline,
    } = elem;
    console.log('elem', elem);
    const currentUTC = new Date().toUTCString();
    let newMsg = {
      direction: 'in',
      objid,
      text: body,
      status: 'unread',
      chat_status: '',
      custId: customerobjid,
      sessionId,
      type: messagetype,
      currentUTC,
      media,
      isPremiumChat,
      interruptcmd,
    };

    const sessionStatus = interruptcmd === 'ENDSESSION' ? 'off' : 'on';
    // eslint-disable-next-line no-unused-expressions
    interruptcmd === 'ENDSESSION' && localStorage.setItem('sessionStatus', JSON.stringify(false));
    localStorage.setItem('sessionID', JSON.stringify(sessionId));
    const convIdx = conversations.findIndex(e => e.custId === customerobjid);
    let session = (convIdx !== -1)
      ? {
        ...conversations[convIdx], sessionStatus, objid: sessionObjid, isCustomerOnline,
      }
      : ({
        id: sessionId,
        custId: customerobjid,
        title: character,
        messages: [],
        premium: false,
        unread: 0,
        iscurrentlyActiveCust: false,
        custPic,
        objid: sessionObjid,
        isPremiumCustomer: isPremiumCust,
        isCustomerOnline,
        sessionStatus,
      });

    if (messagetype === 'Cust2Char') {
      session = {
        ...session,
        lastMsg: body,
        day: 'Today',
        time: 'Now',
        sessionStatus,
        customerchanneltype,
        performerContinueMsgCount: 0,
        custPic,
        isPremiumCustomer: isPremiumCust,
        isCustomerOnline,
        sessionId: session.session_id,
        objid: sessionObjid,
      };
    } else {
      session = {
        ...session,
        lastMsg: body,
        day: 'Today',
        time: 'Now',
        sessionStatus,
        customerchanneltype,
        custPic,
        isPremiumCustomer: isPremiumCust,
        isCustomerOnline,
        sessionId: session.session_id,
        objid: sessionObjid,
      };
      if (body.includes('Last message was not sent')) {
        session = updateLastMsgStatus(session);
      }
    }

    const currentActiveCustindex = conversations.findIndex(e => e.iscurrentlyActiveCust === true);
    const currentActiveCust = currentActiveCustindex !== -1
      ? conversations[currentActiveCustindex]
      : {};
    if (currentActiveCust.custId === customerobjid) {
      newMsg = {
        ...newMsg,
        status: 'read',
      };
      if (objid) {
        const formData = new FormData();
        formData.append('message_objid', objid);
        formData.append('session_objid', sessionObjid);
        formData.append('user_type', 'character');
        api.put('/chatmsg/read/', formData).catch(err => console.log(err));
      }
    } else {
      session.unread += 1;
      dispatch(postChatCountData(1));
    }
    addMessage(newMsg, session);
  };

  useEffect(() => {
    if (messageNotification) {
      const {
        requestType, name, customerId, videoSessionId, chatlimit,
        body, isRouletteType, customerProfilePic, videoCallType,
        messagetype, isChatPreseceOn, isPhonePreseceOn,
        isVideoPresenceOn, isPremiumCust, characterObjid, customerobjid,
        customerReviewForm, interruptcmd, customerHostReview, voiceCall, suggestedMessage,
        notficationType, newNotficationType, cstScreenName, notificationId, customerObjID,
        isCustomerOnline, onlineNotificationcustomerObjid, customerProfilePicOnlineCust, objid,
        isCustomerFavourite,
      } = messageNotification;
      localStorage.setItem('messageNotification', JSON.stringify(objid));

      if (newNotficationType === 'chat_bot_suggestion' && suggestedMessage) {
        dispatch(postWhispererData(suggestedMessage));
        dispatch(pushNotificationAlert());
      }

      if (requestType === 'missedcall') {
        createNewChatNotification({
          body: `You have missed a call from ${cstScreenName}!`,
          customerobjid: customerId,
          name: cstScreenName,
        }, true);
        const missedCallers = localStorage.getItem('missedCallers') && JSON.parse(localStorage.getItem('missedCallers'));
        if (missedCallers && missedCallers.length) {
          const newMissedCallers = missedCallers.map((cust) => {
            if (cust.id === customerObjID) {
              // Increment count if customer found
              return {
                id: cust.id,
                count: cust.count + 1,
              };
            }
            // Otherwise, return the existing customer
            return cust;
          });

          // If the customer was not found, add a new entry
          if (!newMissedCallers.some(cust => cust.id === customerObjID)) {
            newMissedCallers.push({
              count: 1,
              id: customerObjID,
            });
          }

          localStorage.setItem('missedCallers', JSON.stringify(newMissedCallers));
          dispatch(postMissedCallsData(newMissedCallers));
        } else {
          // If there are no missed callers, create a new entry
          const newMissedCaller = {
            count: 1,
            id: customerObjID,
          };
          localStorage.setItem('missedCallers', JSON.stringify([newMissedCaller]));
          dispatch(postMissedCallsData([newMissedCaller]));
        }
      }

      setVideoSessionIdState(messageNotification.videoSessionId);

      // if roullette is Unable logout from mobile device
      if (isUserAgentMobile() === true) {
        if (messagetype === 'unavailable_on_roulette') {
          history.push('/continue-to-desktop');
        }
      }

      // // Handling flow without Interrupt and show media in realtime
      // if (notficationType === 'media_purchased') {

      // }

      if (notficationType === 'customer_presence_notification') {
        if (JSON.parse(localStorage.getItem('notificationID')) && JSON.parse(localStorage.getItem('notificationID')) === isNotificationId) {
          setIsCustomerOnlineNow(false);
          setIsCustomerFavouriteNow(false);
          setIsNotificationId(notificationId);
        } else {
          localStorage.setItem('notificationID', JSON.stringify(notificationId));
          setCustomer({
            cstScreenName,
            onlineNotificationcustomerObjid,
            requested: true,
            customerProfilePicOnlineCust,
            customerHostReviewObj: customerHostReview,
            isPremiumCustomer: isPremiumCust,
            isCustomerOnline,
          });
          const onlineStatusBoj = {
            customerHostReviewObj: customerHostReview,
          };
          localStorage.setItem('customerHostReviewLocal', onlineStatusBoj?.customerHostReviewObj);
          setIsCustomerOnlineNow(true);
          setIsCustomerFavouriteNow(true);
          setIsNotificationId(notificationId);
        }
      } else {
        setIsCustomerOnlineNow(false);
        setIsCustomerFavouriteNow(false);
      }

      if (messagetype === 'endvideorequest') {
        setVideoModal(false);
        localStorage.removeItem('videoChat');
        localStorage.removeItem('videoSessionIdLocal');
      }

      if (messagetype === 'presenceUpdate') {
        const presenceData = {
          chatStatus: isChatPreseceOn,
          phoneStatus: isPhonePreseceOn,
          videoStatus: isVideoPresenceOn,
        };
        setOfflineModal(true);
        updatePresence(presenceData);
        setVideoModal(false);
        localStorage.removeItem('videoSessionIdLocal');
      }

      if (localStorage.getItem('videoSessionIdLocal') && localStorage.getItem('videoSessionIdLocal') === videoSessionIdState) {
        console.log('it is a duplicate xmpp notificaiton');
        return;
      }

      if (requestType === 'VidReq' && !(body.includes('https'))) {
        const videoChat = {
          customerName: name,
          custId: customerId,
          id: videoSessionId,
          chatTime: chatlimit,
          isRouletteCall: isRouletteType,
          customerPic: customerProfilePic,
          callType: videoCallType,
          isHostAvailableForPaidCall: false,
          isPremiumCustomer: isPremiumCust,
          charObjID: characterObjid,
          customerObjectId: customerobjid,
          customerHostReviewObj: customerHostReview,
          videoCallRed: true,
          isCustomerFavourite,
        };

        localStorage.setItem('isVideoVoiceCall', JSON.stringify(true));
        // eslint-disable-next-line no-unused-expressions
        videoChat.customerHostReviewObj && localStorage.setItem('customerHostReview', videoChat?.customerHostReviewObj);
        setIsVoiceCall(false);
        setIsCustomerFavouriteState(isCustomerFavourite);
        const { id } = reduxVideoCallData;
        if (isRouletteEnable) {
          sessionStorage.clear();
          switch (videoCallType) {
            case '2':
              // roulette free call
              localStorage.setItem('videoChat', JSON.stringify(videoChat));
              changeVideoCallData({
                ...videoChat,
                isHostAvailableForPaidCall: true,
              });
              history.push('/video-call');
              break;

            case '3':
              // roulette free to paid
              console.log('=====================shift free to paid call =====================');
              localStorage.setItem('videoChat', JSON.stringify(videoChat));
              changeVideoCallData({
                ...videoChat,
                isHostAvailableForPaidCall: false,
              });
              break;

            case 'None':
              // paid call
              if (localStorage.getItem('videoChat') && id !== videoSessionId) {
                // remove token
                console.log('=====================sin paid call aleray in call=====================s');
                removeToken({});
                // reject free roulette call
                const formData = new FormData();
                formData.append('action', 'end');
                formData.append('video_session_id', id);
                formData.append('device_id', ' ');
                endVideoSessionAction(formData).then(() => {
                  // create new call
                  console.log('=====================sin paid call success api =====================s');
                  changeVideoCallData({
                    ...videoChat,
                    isHostAvailableForPaidCall: false,
                  });
                  localStorage.setItem('videoChat', JSON.stringify(videoChat));
                  history.push('/video-call');
                })
                  .catch((err) => {
                    const { code } = err.response.data;
                    console.log(code, 'get error');
                  });
              } else if (id !== videoSessionId) {
                changeVideoCallData({
                  ...videoChat,
                  isHostAvailableForPaidCall: false,
                });
                console.log('=====================s', ' not in call ', '=====================s');
                // create new call
                localStorage.setItem('videoChat', JSON.stringify(videoChat));
                history.push('/video-call');
              } else {
                console.log('same video session id');
              }
              break;
            default:
              console.log('Not catered');
          }
        } else {
          // paid call
          if (history.location.pathname === '/video-call') {
            return;
          }
          if (history.location.pathname === '/voice-call') {
            return;
          }
          if (videoCallType !== 'None') {
            console.log('not catred');
            return;
          }
          changeVideoCallData({
            ...videoChat,
            isHostAvailableForPaidCall: false,
          });

          localStorage.setItem('videoSessionIdLocal', videoChat.id);

          localStorage.setItem('videoChat', JSON.stringify(videoChat));

          // playSound(notificationSound);
          setVideoId(videoSessionId);
          setVideoChatData(videoChat);
          setIsVoiceCall(false);
          setCustomer({ name, requested: true });
          setVideoModal(true);
        }
      } else if (requestType === 'VocReq' && !(body.includes('https'))) {
        const videoChat = {
          customerName: name,
          custId: customerId,
          id: videoSessionId,
          chatTime: chatlimit,
          isRouletteCall: isRouletteType,
          customerPic: customerProfilePic,
          callType: videoCallType,
          isHostAvailableForPaidCall: false,
          isPremiumCustomer: isPremiumCust,
          charObjID: characterObjid,
          customerObjectId: customerobjid,
          customerHostReviewObj: customerHostReview,
          voiceCall,
          videoCallRed: false,
          isCustomerFavourite,
        };
        localStorage.setItem('isVideoVoiceCall', JSON.stringify(true));
        if (history.location.pathname === '/voice-call') {
          return;
        }
        if (history.location.pathname === '/video-call') {
          return;
        }
        changeVideoCallData({
          ...videoChat,
          isHostAvailableForPaidCall: false,
        });
        localStorage.setItem('videoChat', JSON.stringify(videoChat));
        localStorage.setItem('videoSessionIdLocal', videoChat.id);

        // eslint-disable-next-line no-unused-expressions
        videoChat.customerHostReviewObj && localStorage.setItem('customerHostReview', videoChat?.customerHostReviewObj);

        // playSound(notificationSound);
        setVideoId(videoSessionId);
        setVideoChatData(videoChat);
        setCustomer({ name, requested: true });
        setIsVoiceCall(voiceCall);
        setVideoModal(true);
        setIsCustomerFavouriteState(isCustomerFavourite);
      } else if (requestType === 'Cust2Char' || requestType === 'Sys2Char') {
        const customerData = JSON.parse(localStorage.getItem('messageChat')) || {};
        // const messageChat = {
        //   [customerobjid]: {
        //     customerReviewForm,
        //     interruptcmd,
        //     customerHostReviewObjChat: customerHostReview,
        //   },
        // };

        // Check if the customer ID exists
        if (customerData[customerobjid]) {
          // Update the existing values
          customerData[customerobjid].customerReviewForm = customerReviewForm;
          customerData[customerobjid].interruptcmd = interruptcmd;
          customerData[customerobjid].customerHostReviewObjChat = customerHostReview;
        } else {
          // Add a new entry for the customer ID
          customerData[customerobjid] = {
            customerReviewForm,
            interruptcmd,
            customerHostReviewObjChat: customerHostReview,
          };
        }

        localStorage.setItem('messageChat', JSON.stringify(customerData));
        // eslint-disable-next-line no-unused-expressions
        // messageChat.customerHostReviewObjChat &&
        // localStorage.setItem('customerHostReviewLocal', messageChat?.customerHostReviewObjChat);
        // localStorage.setItem('messageChat', JSON.stringify(messageChat));
        if (window.Notification && (Notification.permission === 'granted' || notiPermission === 'granted')) {
          if (!isRouletteEnable) {
            createNewChatNotification(messageNotification);
          }
        }
        if (isUserAgentMobile()) {
          onMessage(messageNotification);
        } else if (!isRouletteEnable) {
          onMessage(messageNotification);
        }
        // eslint-disable-next-line
        // onMessage(messageNotification);
      }
      // else if (messagetype === 'endvideorequest') {
      //   setVideoModal(false);
      //   localStorage.removeItem('videoChat');
      // }
      // else if (messagetype === 'presenceUpdate') {
      //   const presenceData = {
      //     chatStatus: isChatPreseceOn,
      //     phoneStatus: isPhonePreseceOn,
      //     videoStatus: isVideoPresenceOn,
      //   };
      //   setOfflineModal(true);
      //   updatePresence(presenceData);
      // }
    }
  }, [messageNotification]);

  useEffect(() => {
    if (emojiNotificaiton && emojiNotificaiton?.NotificationType.startsWith('emoji')) {
      const activeSession = conversations.find(
        session => session.objid === emojiNotificaiton.sessionObjid,
      );
      if (activeSession) {
        // eslint-disable-next-line prefer-destructuring
        const messages = activeSession.messages;
        // eslint-disable-next-line consistent-return, array-callback-return
        const updatedMessages = messages.map((msg) => {
          if (Number(msg.objid) === Number(emojiNotificaiton.MsgId)) {
            return {
              ...msg,
              emoji_object: emojiNotificaiton.Emoji,
            };
          }
          return msg;
        });

        const UpdatedConversation = conversations.map((sess) => {
          if (sess.objid === emojiNotificaiton.sessionObjid) {
            return {
              ...sess,
              messages: updatedMessages,
            };
          }
          return sess;
        });

        setConversationFunction(UpdatedConversation);
      }
      if (window.Notification && (Notification.permission === 'granted' || notiPermission === 'granted')) {
        const StructuredNotification = {
          ...emojiNotificaiton,
        };
        if (!isRouletteEnable && emojiNotificaiton.NotificationType === 'emoji_reacted') {
          createNewChatNotification(StructuredNotification, true);
        }
      }
    }
  }, [emojiNotificaiton]);

  useEffect(() => {
    const { newNotficationType } = messageNotification;

    if (newNotficationType === 'offline_chat_notification') {
      if (window.Notification && (Notification.permission === 'granted' || notiPermission === 'granted')) {
        createNewChatNotification(messageNotification);
        if (history.location.pathname !== '/chat') {
          dispatch(postChatCountData(1));
        }
      }
    }
  }, [messageNotification]);

  useEffect(() => {
    if (originalMediaURL && originalMediaURL.customer_objid && originalMediaURL.NotificationType === 'media_purchased') {
      const activeSession = conversations.find(
        session => Number(session.custId) === Number(originalMediaURL.customer_objid),
      );
      console.log({ activeSession, originalMediaURL, conversations });
      if (activeSession) {
        // eslint-disable-next-line prefer-destructuring
        const messages = activeSession.messages;

        const updatedMessages = messages.map((msg) => {
          if (Number(msg.objid) === Number(originalMediaURL.messageObjid)) {
            return {
              ...msg,
              chat_type: originalMediaURL.chatType,
              text: originalMediaURL.URL,
              media: originalMediaURL.URL,
            };
          }
          return msg;
        });

        const UpdatedConversation = conversations.map((sess) => {
          if (Number(sess.custId) === Number(originalMediaURL.customer_objid)) {
            return {
              ...sess,
              messages: updatedMessages,
            };
          }
          return sess;
        });

        setConversationFunction(UpdatedConversation);
      }
    }
  }, [originalMediaURL, messageNotification]);

  // ---------- video modal control -----------
  useEffect(() => {
    const { isRouletteType, videoCallType } = videoChatData;
    if (videoId && isRouletteType === 'None' && videoCallType === 'None') {
      sessionStorage.setItem('connectionDisclouserName', '');
    }
  }, [videoId]);

  // ---------- video modal control end -----------

  // This useEffect function is reponsible for playing ring on voice and video call
  useEffect(() => {
    console.log('audio useeffect');
    if (videoModal) {
      audio.currentTime = 0;
      audio.play();
    } else {
      audio.pause();
    }
    const handleTimeout = () => {
      audio.pause();
    };
    const timeout = setTimeout(handleTimeout, 30000);
    return () => {
      clearTimeout(timeout);
    };
  }, [videoModal]);
  // This useEffect function Ends

  const toggleModal = () => {
    // localStorage.removeItem('videoCallCustomerName');
    setVideoModal(false);
    setIsCustomerOnlineNow(false);
  };

  // -------- performer ignore video request. ----------
  useEffect(() => {
    if (!isRouletteEnable) {
      if (videoModal) {
        const setTimeOutID = setTimeout(() => {
          // if (localStorage.getItem('videoChat') &&
          // localStorage.getItem('videoChat').id !== videoId) {
          //   localStorage.removeItem('videoChat');
          // }
          // localStorage.removeItem('videoChat');
          rejectRequest('unanswered');
          setVideoModal(false);
        }, 30000);
        setSetTimeoutEnd(setTimeOutID);
      } else if (setTimeoutEnd) {
        clearTimeout(setTimeoutEnd);
      }
    }
  }, [videoModal]);

  useEffect(() => {
    if (isCustomerOnlineNow) {
      setTimeout(() => {
        setIsCustomerOnlineNow(false);
      }, 5000);
      // clearInterval(setTimeOutIDonlineStatus);
    }
  }, [isCustomerOnlineNow]);

  // -----------handle video call end --------------

  // -----------chat handle start --------------

  useEffect(() => {
    const updatedConversations = allConversation.length > 0 ? JSON.parse(allConversation) : [];
    setConversations(updatedConversations);
  }, [allConversation]);

  // ------------ chat handle end -------------

  const showMessageInToast = (speed) => {
    if (speed < 6) {
      toast.error('Your network connection is running too slow.');
    }
  };

  useEffect(() => {
    const activeUserExist = conversations
      .findIndex(e => e?.iscurrentlyActiveCust === true);
    if (activeUserExist !== -1) {
      const updatedActiveUser = conversations[activeUserExist];
      setCustomer(updatedActiveUser);
    }
  }, [conversations]);

  const createNewChatRoom = (updatedConv = []) => {
    const newSession = {
      id: 0,
      custId: customer.onlineNotificationcustomerObjid,
      title: customer.cstScreenName,
      messages: [],
      premium: 0,
      unread: 0,
      iscurrentlyActiveCust: true,
      custPic: customer.customerProfilePicOnlineCust,
      sessionStatus: 'pending',
      customerchanneltype: '',
      performerContinueMsgCount: 0,
      lastMessageTime: new Date(),
      isPremiumCustomer: customer.isPremiumCustomer,
      customerHostReview: customer.customerHostReviewObj,
      isCustomerOnline: customer.isCustomerOnline,
    };
    const updatedConversation = updatedConv.map(conv => ({
      ...conv,
      iscurrentlyActiveCust: false,
    }));
    updatedConversation.unshift(newSession);
    setConversationFunction(updatedConversation);
    history.push('/chat');
  };

  const checkActiveSession = () => {
    const url = `/cfgxmppsessions/list/?customer_objid=${customer.onlineNotificationcustomerObjid}&character_objid=${perfObjid}&pagesize=1`;
    checkActiveSessionAvailable(url)
      .then((result) => {
        const { data: { results = [] } = {} } = result;
        const sessionActive = (results.length && results[0].active) || false;
        // when session is active
        if (sessionActive) {
          // then check session id
          const activeSesstionId = (results.length && results[0].session_id) || 0;
          const currentActiveSesstion = conversations
            .find(session => session.id === activeSesstionId);
          if (!currentActiveSesstion) {
            createNewChatRoom(conversations);
            return;
          }
          currentActiveSesstion.iscurrentlyActiveCust = true;
          currentActiveSesstion.unread = 0;
          // reopen the room
          const updatedConversation = conversations.map((cust) => {
            if (cust.id === activeSesstionId) {
              return currentActiveSesstion;
            }
            if (cust && cust.iscurrentlyActiveCust) {
              return {
                ...cust,
                iscurrentlyActiveCust: false,
              };
            }
            return cust;
          });
          setConversationFunction(updatedConversation);
          // redirect to chat page
          history.push('/chat');
          return;
        }
        // create session
        const updatedConv = conversations.filter(session => session.id !== 0);
        createNewChatRoom(updatedConv);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const missedCallers = localStorage.getItem('missedCallers') && JSON.parse(localStorage.getItem('missedCallers'));
    if (missedCallers && missedCallers.length) {
      dispatch(postMissedCallsData(missedCallers));
    }
  }, []);

  const renderUI = () => {
    if (history.location.pathname === '/video-call') {
      return (
        <>
          <ReactInternetSpeedMeter
            txtSubHeading="Your network connection is running too slow."
            outputType="alert"
            customClassName="BandWidthMessagetoast dis-none"
            // txtMainHeading="Opps..."
            pingInterval={60000} // milliseconds
            thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
            threshold={6}
            imageUrl="https://res.cloudinary.com/tfonia/image/upload/v1641551560/characters/unqxymik2kvxbn0fobui.jpg"
            downloadSize="414164" // bytes
            callbackFunctionOnNetworkDown={speed => showMessageInToast(speed)}
            callbackFunctionOnNetworkTest={speed => console.log(speed)}
          />
          <VideoCallHeader />
          <div className="wrapper">
            <div id="content">
              <CustomAlertContainer />
              <div className="contentInner h-100">
                {/* <Row className="mb-4 topHeaderRanking">
                  <BodyHeader data={bodyHeaderData} />
                </Row> */}
                {children}
              </div>
            </div>
          </div>
        </>
      );
    }

    if (history.location.pathname === '/roulette') {
      return (
        <div className="wrapper">
          <div id="content">
            <CustomAlertContainer />
            <div className="contentInner h-100">
              {/* <Row className="mb-4 topHeaderRanking">
                <BodyHeader data={bodyHeaderData} />
              </Row> */}
              {children}
            </div>
          </div>
        </div>
      );
    }
    if (history.location.pathname === '/voice-call') {
      return (
        <div className="wrapper">
          <div id="content">
            <CustomAlertContainer />
            <div className="contentInner h-100">
              {/* <Row className="mb-4 topHeaderRanking">
                <BodyHeader data={bodyHeaderData} />
              </Row> */}
              {children}
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        {isUserAgentMobile() ? '' : <PortalHeaderContainer />}
        <div className="wrapper">
          {isUserAgentMobile()
            ? ''
            : (
              <>
                <VideoChatModal
                  name={customer.name}
                  toggleModal={toggleModal}
                  open={videoModal}
                  rejectRequest={() => rejectRequest()}
                  isVoiceCall={isVoiceCall}
                  isCustomerFavourite={isCustomerFavouriteState}
                />
                <CustomerOnlineStatusModal
                  name={customer.cstScreenName}
                  toggleModal={toggleModal}
                  open={isCustomerOnlineNow}
                  onClick={checkActiveSession}
                  isCustomerFavouriteNow={isCustomerFavouriteNow}
                />
              </>
            )
          }
          {isUserAgentMobile() ? '' : <SidebarContainer />}
          <div id="content">
            <CustomAlertContainer />
            <div className="contentInner h-100">
              {/* <Row className="mb-4 topHeaderRanking">
                <BodyHeader data={bodyHeaderData} />
              </Row> */}
              {children}
            </div>
          </div>
        </div>
        {isUserAgentMobile() ? '' : <PortalFooter />}
      </>
    );
  };

  const renderOfflineModal = () => (
    <CustomModal
      header={isVoiceCall ? 'Voice Availability Turned Off' : 'Video Availability Turned Off'}
      body={isVoiceCall ? 'We have marked your availability as offline considering you are not available for voice calls. Please turn ON your availability from Dashboard again.' : 'We have marked your availability as offline considering you are not available for video calls. Please turn ON your availability from Dashboard again.'}
      buttonText="OK"
      open={offlineModal}
      toggle={() => setOfflineModal(false)}
    />
  );

  return (
    <>
      <RouletteTimePopup />
      {renderOfflineModal()}
      {renderUI()}
    </>
  );
};

BodyWrapper.defaultProps = {
  // bodyHeaderData: {},
  // sidebarData: {},
  xmpp_user: '',
  xmpp_pass: '',
  allConversation: '',
  notiPermission: '',
  isRouletteEnable: false,
  reduxVideoCallData: null,
  perfObjid: 0,
};

BodyWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  // PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  // bodyHeaderData: BodyHeaderProps,
  // sidebarData: SidebarProps,
  xmpp_user: PropTypes.string,
  xmpp_pass: PropTypes.string,
  endVideoSessionAction: PropTypes.func.isRequired,
  changeVideoCallData: PropTypes.func.isRequired,
  updatePresence: PropTypes.func.isRequired,
  reduxVideoCallData: PropTypes.instanceOf(Object),
  removeToken: PropTypes.func.isRequired,
  setConversationFunction: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allConversation: PropTypes.string,
  notiPermission: PropTypes.string,
  history: PropTypes.instanceOf(Object).isRequired,
  isRouletteEnable: PropTypes.bool,
  checkActiveSessionAvailable: PropTypes.func.isRequired,
  perfObjid: PropTypes.number,
};

export default BodyWrapper;
