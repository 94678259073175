import api from './api';

export const getPresenceUser = url => ({
  type: 'GET_PRESENCE',
  payload: api.get(url),
});

export const updatePresence = data => ({
  type: 'UPDATE_PRESENCE_DATA',
  payload: data,
});
