import React from 'react';
import { Row, Col, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { checkCloudinaryUrl } from '../../../../utils/helpers';
import crownIcon from '../../../../assets/svgs/icon-crown.svg';

const LastActivity = ({ time }) => {
  const formattedTime = moment(new Date(time)).calendar();
  let displayTime = formattedTime;

  if (displayTime.includes('Yesterday') || displayTime.includes('Last')) {
    const [tempTime] = displayTime.split('at');
    displayTime = tempTime;
  }

  return (
    <span className="chat_date d-flex">
      <div className="ml-7">{displayTime}</div>
    </span>
  );
};


LastActivity.defaultProps = {
  time: 0,
};

LastActivity.propTypes = {
  time: PropTypes.number,
};

const Customer = (props) => {
  const {
    title,
    online,
    lastmessage,
    lastMessageTime,
    handleCustomerList,
    showUnread,
    activeUser,
    custPic,
    onChatRoomHandler,
    isPremiumCustomer,
    isCustomerOnline,
    sendEmailNotification,
  } = props;

  // const lastMsg = lastmessages //messages && messages[messages.length - 1];

  const reduceLastMessage = (msgStr) => {
    if (msgStr && msgStr.length > 33) {
      const finalStr = msgStr.slice(0, 30);
      return `${finalStr}...`;
    }
    return msgStr;
  };

  const showOnlineStatus = (CustomerOnline, EmailNotification) => {
    if (CustomerOnline) {
      return <span className="online-small-icon" />;
      // eslint-disable-next-line no-else-return
    } else if (EmailNotification) {
      return <span className="online-away-icon" />;
    } else {
      return <span className="offline-small-icon" />;
    }
  };

  return (
    <>
      <div className={activeUser ? 'cursor-pointer chat-card active-bg-color' : 'chat-card cursor-pointer'} onClick={onChatRoomHandler} role="presentation">
        <Row onClick={() => handleCustomerList()}>
          <Col xs={3}>
            <div className="chat_img text-center chat-list">
              {
              custPic && custPic !== 'None'
                ? <img width="50" height="50" src={custPic} alt="customerprofilepic" />
                : <svg width="50" height="50" data-jdenticon-value="icon value" />
              }
              {online}
              <div className="online-user" />
              {isPremiumCustomer ? <img src={crownIcon} className="is_premium_customer" alt="crown" /> : ''}
              {showOnlineStatus(isCustomerOnline, sendEmailNotification)}
              {/* {isCustomerOnline ?
                <span className="online-small-icon" /> :
                <span className="offline-small-icon" />} */}
            </div>
          </Col>
          <Col xs={9}>
            <div className={`chat_ib ${showUnread && 'unread'}`}>
              <h5>
                <div className="ellipsisData ellipsisWidth d-flex justify-content-between align-items-start">
                  <div className="SenderName">{title}</div>
                  {lastmessage && <LastActivity time={lastMessageTime} />}
                </div>
              </h5>
              <div className="d-flex justify-content-between align-items-start">
                <span className="lastmsg">{reduceLastMessage(checkCloudinaryUrl(lastmessage))}</span>
                <span>{showUnread ? <Badge pill className="mr-4 numberOfMsg">{showUnread}</Badge> : ''}</span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

Customer.defaultProps = {
  title: '',
  online: false,
  lastmessage: '',
  lastMessageTime: 0,
  // active: false,
  // messages: [],
  showUnread: 0,
  activeUser: false,
  custPic: '',
  isPremiumCustomer: false,
  isCustomerOnline: false,
  sendEmailNotification: false,
};

Customer.propTypes = {
  title: PropTypes.string,
  online: PropTypes.bool,
  // active: PropTypes.bool,
  handleCustomerList: PropTypes.func.isRequired,
  showUnread: PropTypes.number,
  lastmessage: PropTypes.string,
  lastMessageTime: PropTypes.number,
  activeUser: PropTypes.bool,
  custPic: PropTypes.string,
  onChatRoomHandler: PropTypes.func.isRequired,
  isPremiumCustomer: PropTypes.bool,
  isCustomerOnline: PropTypes.bool,
  sendEmailNotification: PropTypes.bool,


  // messages: PropTypes.arrayOf(PropTypes.shape({
  //   chat_status: PropTypes.string,
  //   currentUTC: PropTypes.string,
  //   direction: PropTypes.string,
  //   text: PropTypes.string,
  // })),
};

export default Customer;
