/* eslint-disable camelcase */
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Support from '../scenes/Support';
import createSupportEmailAction from '../actions/actionCreateSupportEmail';
import getUserIp from '../actions/actionGetUserIP';
import manageAlertDisplay from '../actions/actionHandleAlert';

const getSupportDataAdapter = (loginData) => {
  const { data: { auth2contact_objid = {} } } = loginData;
  return {
    fullName: (auth2contact_objid.first_name && auth2contact_objid.last_name) ? `${auth2contact_objid.first_name} ${auth2contact_objid.last_name}` : '',
    screenName: auth2contact_objid.screen_name || '',
    emailAddress: auth2contact_objid.primary_email_addr || '',
    phoneNumber: auth2contact_objid.cstphonenumbers[0].number || '',
  };
};


export const mapStateToProps = store => ({
  ...getSupportDataAdapter(store.login),
  ipAddress: store.userIp.data || '',
});

export const mapDispatchToProps = dispatch => ({
  createSupportEmail: sendData => dispatch(createSupportEmailAction(sendData)),
  getUserIp: () => dispatch(getUserIp()),
  showAlert: (status, message) => dispatch(manageAlertDisplay(status, message)),
});

const FormWrapper = reduxForm({
  form: 'support',
})(Support);

const SupportContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormWrapper);

export default SupportContainer;
