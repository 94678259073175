import React, { useEffect, useState } from 'react';
import { FlagIcon } from 'react-flag-kit';
import PropTypes from 'prop-types';
import {
  CardHeader,
  Card,
  CardBody,
  Row,
  Col,
  Label,
  Button,
} from 'reactstrap';
import { toast } from 'react-toastify';
import api from '../../actions/api';
import EditInfoModal from './components/EditInfoModal/EditInfoModal';
import OtpVerificationModal from './components/EditInfoModal/OtpVerificationModal';
import DeleteConfirmationModal from './components/EditInfoModal/DeleteConfirmationModal';

import './style.scss';

const EditInfo = (props) => {
  const {
    perfObjid,
    // getPerformerInfoFun,
  } = props;
  const [performerData, setPerformerData] = useState([]);
  const [showEditInfoModal, setShowEditInfoModal] = useState(false);
  const [showOtpVerificationModal, setShowOtpVerificationModal] = useState(false);
  const [confirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [addedNumberData, setAddedNumberData] = useState({});
  const [countries, setContries] = useState([]);
  const [states, setStates] = useState([]);
  const [countryId, setCountryId] = useState();
  const [stateObjId, setStateObjidId] = useState();
  const [cstAddDetails, setCstAddDetails] = useState({});
  const [add1, setAdd1] = useState('');
  const [add2, setAdd2] = useState('');
  const [country, setCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [city, setCity] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [isLoading, setIsloading] = useState(false);
  // const [cstContactObjId, setCstContactObjId] = useState('');
  // const [activeCstListId, setActiveCstListId] = useState();
  const [counter, setCounter] = useState();

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);


  // Get all countries from API
  const getCountriesList = () => {
    api.get('/api/cfgcountry/list/?page_size=1000')
      .then((countryRes) => {
        setContries(countryRes.data.results);
      })
      .catch(err => console.log(err));
  };

  // Get all states from API
  const getStatesList = (cidVlue = '') => {
    if (cidVlue || countryId) {
      api.get(`api/cfgstateprov/list/?cfg_country_objid=${cidVlue || countryId}&page_size=1000`)
        .then((stateResp) => {
          setStates(stateResp.data.results);
        })
        .catch(err => console.log(err));
    }
  };

  const onCountryChange = (event) => {
    const cid = event.target.value;
    setCountryId(cid);
    setCountry(event.target.value);
  };

  const onStateChange = (event) => {
    const sobjid = event.target.value;
    setStateObjidId(sobjid);
    setSelectedState(event.target.value);
  };

  useEffect(() => {
    getCountriesList();
  }, []);

  useEffect(() => {
    getStatesList();
  }, [countryId]);

  const getperformDataLoad = () => {
    api.get(`/perfcharacter/list/?objid=${perfObjid}`)
      .then((response) => {
        setPerformerData(response.data.results);
        const cstAddDetailObj = {
          addressLineOne: response.data.results[0].cstcontact.cstaddresses[0].address_1,
          addressLineTwo: response.data.results[0].cstcontact.cstaddresses[0].address_2,
          country: response.data.results[0]
            .cstcontact.cstaddresses[0].country && response.data.results[0]
            .cstcontact.cstaddresses[0].country.objid,
          stateName: response.data.results[0]
            .cstcontact.cstaddresses[0].address2cfg_state_prov_objid,
          city: response.data.results[0].cstcontact.cstaddresses[0].city,
          zipcode: response.data.results[0].cstcontact.cstaddresses[0].zipcode,
          objid: response.data.results[0].cstcontact.cstaddresses[0].objid,
          cstContacts: response.data.results[0].cstcontact.cstphonenumbers,
        };
        setCstAddDetails(cstAddDetailObj);
        setAdd1(cstAddDetailObj.addressLineOne);
        setAdd2(cstAddDetailObj.addressLineTwo);
        setCountry(cstAddDetailObj.country);
        setSelectedState(cstAddDetailObj.stateName); // Need ID in get api
        setCity(cstAddDetailObj.city);
        setZipcode(cstAddDetailObj.zipcode);
        getStatesList(cstAddDetailObj.country);
      })
      .catch(err => console.log(err));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (add1 && add2 && country && city && zipcode) {
      const formData = new FormData();
      formData.append('objid', cstAddDetails.objid); // 52550
      formData.append('address_1', add1);
      formData.append('address_2', add2);
      formData.append('country', countryId || country);
      formData.append('city', city);
      formData.append('zipcode', zipcode);
      formData.append('address2cfg_state_prov_id', stateObjId || selectedState);
      setIsloading(true);
      // Address update API
      api.put('api/cstaddress/update/', formData)
        .then(() => {
          setIsloading(false);
          toast.success('Address updated Successfully!');
        })
        .catch(() => {
          setIsloading(false);
          toast.error('Something went Wrong!');
        });
    } else {
      toast.error('All Fields are required!');
    }
  };

  const deletePhoneNumber = (id) => {
    if (cstAddDetails.cstContacts.length > 1) {
      api.delete(`api/cstphonenumbers/delete/${id}/`)
        .then(() => {
          getperformDataLoad();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.error('You must have atleast one phone number.');
    }
  };

  const confirmDeleteHandler = (selecteddelId) => {
    setDeleteId(selecteddelId);
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    getperformDataLoad();
  }, []);

  const getAddedNewNumberData = (addNumberObj) => {
    setAddedNumberData(addNumberObj);
  };

  const createPhoneNumber = (contactObj) => {
    const formData = new FormData();
    formData.append('number', contactObj.phoneNumber.replace('+', ''));
    formData.append('number_type', 'Home');
    formData.append('isverified', 0);
    formData.append('ccode', contactObj.dialCode);
    formData.append('country_code', contactObj.countryCode.toUpperCase()); // get it from contact number library
    formData.append('phone_number2cst_contact', performerData[0].character2cst_contact);
    // Create and phone number API call
    setIsloading(true);
    api.post('/api/cstphonenumbers/create/', formData)
      .then((response) => {
        const {
          phone_number2cst_contact: phoneNum2CstContact,
          number: phoneNumber,
          ccode: countryCode,
        } = response.data;
        const addedNewNumObj = {
          number: phoneNumber,
          ccode: countryCode,
        };
        getAddedNewNumberData(addedNewNumObj);
        const formDatas = new FormData();
        formDatas.append('number', phoneNumber);
        formDatas.append('phone_number2cst_contact', phoneNum2CstContact);
        setIsloading(false);
        // Send Verification Code on mobile API call
        api.post('api/cstphonenumbers/send-twilio-code/', formDatas)
          .then(() => {
            setShowEditInfoModal(false);
            setShowOtpVerificationModal(true);
            setCounter(59);
          })
          .catch(() => {
            toast.error('Cannot send SMS to landline phone numbers');
          });
      })
      .catch((error) => {
        if (error.response && (error.response.status === 400)) {
          toast.error('phone number already exist');
          setIsloading(false);
        }
      });
  };

  const resendVerificationCode = (contactNum) => {
    setCounter(59);
    const formData = new FormData();
    formData.append('number', contactNum);
    formData.append('phone_number2cst_contact', performerData[0].character2cst_contact);
    api.post('api/cstphonenumbers/send-twilio-code/', formData)
      .then(() => {})
      .catch(() => {
        toast.error('Something went wrong!');
      });
  };

  const getVerificationOTP = (verificationCode) => {
    const formData = new FormData();
    formData.append('number', addedNumberData.number);
    formData.append('verification_code', verificationCode);
    // Verify code sent to mobile API.
    api.post('api/cstphonenumbers/verify-twilio-code/', formData)
      .then(() => {
        setShowOtpVerificationModal(false);
        getperformDataLoad();
      })
      .catch((error) => {
        if (error.response && (error.response.status === 400)) {
          toast.error('Verification code is incorrect');
        }
      });
    return '';
  };

  const setDefaultContact = (id) => {
    // setActiveCstListId(id);
    const formData = new FormData();
    formData.append('objid', id);
    formData.append('default', true);
    api.put('api/cstphonenumbers/update/', formData)
      .then(() => {
        getperformDataLoad();
      })
      .catch(err => console.log(err));
  };

  const renderEditInfoModal = () => (
    <EditInfoModal
      header=""
      body=""
      buttonText="OK"
      open={showEditInfoModal}
      // onClick={resetBackgroundPicPanel}
      toggle={() => setShowEditInfoModal(false)}
      getNumberFromModal={createPhoneNumber}
      isLoading={isLoading}
    />
  );

  const renderOtpVerificationModal = () => (
    <OtpVerificationModal
      header="Verification"
      body="We have sent a verification code to"
      buttonText="OK"
      open={showOtpVerificationModal}
      // onClick={resetBackgroundPicPanel}
      toggle={() => setShowOtpVerificationModal(false)}
      getVerificationOTP={getVerificationOTP}
      sentCodeOnNumber={addedNumberData.number}
      ccode={addedNumberData.ccode}
      resendVerificationCode={resendVerificationCode}
      counter={counter}
    />
  );

  const renderDeleteConfirmationModal = () => (
    <DeleteConfirmationModal
      header="Confirmation"
      body="Are you sure, You want to delete!"
      // buttonText="OK"
      open={confirmationModal}
      onClick={deletePhoneNumber}
      deleteId={deleteId}
      toggle={() => setShowConfirmationModal(false)}
    />
  );

  const renderPerformerData = performerData.map((performer) => {
    const numbers = performer.cstcontact.cstphonenumbers.map((cstNum) => {
      const {
        objid,
        // number,
        splitted_number: splittedNumber,
        ccode,
        country_code: countryCode,
      } = cstNum;
      return (
        <div key={objid} className={cstNum?.default ? 'list_content active_list' : 'list_content'}>
          <li
            key={objid}
            id={objid}
            role="presentation"
            onClick={() => setDefaultContact(objid)}
          >
            <FlagIcon code={countryCode.toUpperCase()} size={15} />
            <span>
              +
              {ccode}
              -
            </span>
            <span>{splittedNumber}</span>
          </li>
          <span>
            {cstNum?.default ? (
              <i className="fa fa-check" style={{ backgroundColor: 'white' }} />
            ) : (
              <i className="fa fa-trash" role="presentation" onClick={() => confirmDeleteHandler(objid)} />
            )
            }
          </span>
        </div>
      );
    });
    return numbers;
  });

  return (
    <>
      {renderEditInfoModal()}
      {renderOtpVerificationModal()}
      {renderDeleteConfirmationModal()}

      <form className="mt-3 edit-info" onSubmit={e => handleFormSubmit(e)}>
        <Card className="customCard bg-dark pt-3 pl-3 edit-info">
          <span style={{ color: '#f07664' }}>Note: *Information shown on this page are not visible to any customer on Arousr</span>
          <CardBody id="performer-profile">
            <CardHeader className="customCardHeader pl-0">
              <h2>Phone Number</h2>
            </CardHeader>
            <p>{performerData[0] && performerData[0].cstcontact.cstphonenumbers.length > 1 ? 'Click on any number to set it primary.' : ''}</p>
            <ul>
              {renderPerformerData}
            </ul>
            <Button type="button" onClick={() => setShowEditInfoModal(true)} color="primary" className="updateBtn px-5 addBtn">+ Add phone No.</Button>
          </CardBody>
          <CardBody id="performer-profile">
            <CardHeader className="customCardHeader pl-0">
              <h2>Address Detail</h2>
            </CardHeader>
            <Row className="pt-5">
              <Col md={9}>
                <Row className="row_detail">
                  <Col xs={6}>
                    <Label htmlFor="add1">
                      Address Line 1
                    </Label>
                    <input
                      className="form-control"
                      type="text"
                      name="addline_one"
                      value={add1}
                      onChange={e => setAdd1(e.target.value)}
                    />
                  </Col>
                  <Col xs={6}>
                    <Label htmlFor="headline">
                      Address Line 2
                    </Label>
                    <input
                      className="form-control"
                      type="text"
                      name="addline_two"
                      value={add2}
                      onChange={e => setAdd2(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="row_detail">
                  <Col xs={6}>
                    <Label htmlFor="Country">
                      Country
                    </Label>
                    <select className="form-control" value={country} onChange={event => onCountryChange(event)}>
                      {/* <option>Country</option> */}
                      {countries.map(countrySel => (
                        <option
                          key={countrySel.objid}
                          value={countrySel.objid}
                        >
                          {countrySel.country_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col xs={6}>
                    <Label htmlFor="State">
                      State
                    </Label>
                    <select
                      className="form-control"
                      onChange={event => onStateChange(event)}
                      value={selectedState}
                      name={selectedState}
                    >
                      {/* <option>State</option> */}
                      {states.map(selectstate => (
                        <option
                          key={selectstate.objid}
                          value={selectstate.objid}
                        >
                          {selectstate.state_prov}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
                <Row className="row_detail">
                  <Col xs={6}>
                    <Label htmlFor="City">
                      City
                    </Label>
                    <input
                      className="form-control"
                      type="text"
                      name="city"
                      value={city}
                      onChange={e => setCity(e.target.value)}
                    />
                  </Col>
                  <Col xs={6}>
                    <Label htmlFor="zipcode">
                      Zipcode
                    </Label>
                    <input
                      className="form-control"
                      type="text"
                      name="zipcode"
                      value={zipcode}
                      onChange={e => setZipcode(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xs={4}>
                    {isLoading ? (
                      <Button color="primary" className="updateBtn px-5" disabled>
                        Updating...
                        <i className="fa fa-spinner" />
                      </Button>
                    ) : (
                      <Button color="primary" className="updateBtn px-5">Update</Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </>
  );
};

EditInfo.defaultProps = {
  perfObjid: 0,
};

EditInfo.propTypes = {
  perfObjid: PropTypes.number,
  // getPerformerInfoFun: PropTypes.func.isRequired,
};

export default EditInfo;
