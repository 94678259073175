import React from 'react';

const DoubleBounce = () => (
  <div className="sk-spinner sk-spinner-double-bounce">
    <div className="sk-double-bounce1" />
    <div className="sk-double-bounce2" />
  </div>
);

export default DoubleBounce;
