// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { UAParser } from 'ua-parser-js';
import React from 'react';
import MEDIA_CONST from './constant';
import star from '../assets/svgs/rating-star';

const {
  AUDIO_BASE_URL,
  AUDIO,
  VIDEO_BASE_URL,
  VIDEO,
  IMAGE,
  IMAGE_BASE_URL,
} = MEDIA_CONST;

export const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / (1024 ** i), 2)} ${sizes[i]}`;
};

export const timer = (isCallEnd, count) => {
  if (!isCallEnd) {
    let time;
    if (count < 60) {
      const sec = count;
      time = `00:${sec < 10 ? `0${sec}` : sec}`;
    } else if (count < 3600) {
      const min = Math.floor(count / 60);
      const sec = Math.floor(count % 60);
      time = `${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`;
    } else {
      const hour = Math.floor(count / 3600);
      const min = Math.floor((count % 3600) / 60);
      const sec = Math.floor((count % 3600) % 60);
      time = `${hour < 10 ? `0${hour}` : hour}:${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`;
    }
    const countDown = document.getElementById('counter');
    if (countDown) {
      countDown.innerHTML = time;
      countDown.innerHTML = time;
      const sValue = setTimeout(() => timer(false, count - 1), 1000);
      document.getElementById('counter').setAttribute('svalue', sValue);
    }
  }
};

export const removeTimer = () => {
  const sValue = document.getElementById('counter')?.getAttribute('svalue');
  if (sValue) {
    clearInterval(sValue);
  }
};

export const renderRating = (rating) => {
  const stars = [];
  for (let totalRating = 1; totalRating <= 5; totalRating += 1) {
    let fill = '#bab8ba';
    if (rating >= totalRating) {
      fill = '#f07563';
    }
    stars.push(star(fill, totalRating));
  }
  return stars;
};

export const getHistory = () => createBrowserHistory();

export const setScrollAtBottom = (props) => {
  const { elementId = '' } = props;
  const elementFinalId = `#${elementId}`;
  const messageBody = document.querySelector(elementFinalId);
  if (messageBody) {
    messageBody.scrollTop = messageBody.scrollHeight;
  }
};

// used for removing cloudinary link of image.
// export const checkCloudinaryUrl = lastmessage => (lastmessage.includes('https://res.cloudinary.com/') ? 'image' : (lastmessage.includes('https://watch.cloudflarestream.com') ? 'video' : lastmessage));

export const checkCloudinaryUrl = (lastmessage) => {
  if (lastmessage.includes(IMAGE_BASE_URL)) {
    return IMAGE;
  }
  if (lastmessage.includes(VIDEO_BASE_URL)) {
    return VIDEO;
  }
  if (lastmessage.includes(AUDIO_BASE_URL)) {
    return AUDIO;
  }
  if (lastmessage.includes('product_objid')) {
    return 'Media';
  }
  return lastmessage;
};


// let newHistory;
// export const getHistory = () => newHistory;
// export const setHistory = () => {
//   newHistory = createBrowserHistory();
// };

// const parseDate = (time) => {
//   const splitTime = time.split(' ');
//   const splitDate = splitTime[0].replaceAll('-', '/');
//   splitTime[0] = splitDate;
//   return splitTime[1].includes('GMT') ? `${splitTime[0]} ${splitTime[1]}` :
//  `${splitTime[0]} ${splitTime[1]} GMT`;
// };

export const utcToLocalTimeConverter = (time) => {
  // const validDate = time.includes('-') ? time : parseDate(time);
  // if (time.includes('GMT') || time.includes('Z')) {
  //   return new Date(time);
  // }
  const dt = new Date(time);
  return dt.toString();
};

// Check device type mobile
export const isUserAgentMobile = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  return result?.device?.type === 'mobile';
};

export const encryptDataUtil = (data, n) => {
  let encryptedData = '';
  const leng = data.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < leng - n; i++) {
    encryptedData += 'x';
  }
  if (n < leng) {
    encryptedData += data.slice(leng - n, leng);
  }
  return encryptedData;
};

export const showOnlineStatus = (isCustomerOnline, sendEmailNotification) => {
  if (isCustomerOnline) {
    return <span className="online-small-icon" />;
  // eslint-disable-next-line no-else-return
  } else if (sendEmailNotification) {
    return <span className="online-away-icon" />;
  }
  return <span className="offline-small-icon" />;
};
