/*eslint-disable*/
import React from 'react';

export default fill => (
<svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.71436 4.42857V3.28571C6.71436 2.52381 7.1715 1 9.00007 1C10.8286 1 11.2858 2.52381 11.2858 3.28571V4.42857" stroke="white" strokeWidth="1.5"/>
<path d="M5.57129 15.8568H7.857" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.57129 12.4288H12.4284" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.57129 9H12.4284" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M1 6.71455C1 5.45218 2.02335 4.42883 3.28571 4.42883H14.7143C15.9767 4.42883 17 5.45218 17 6.71455V19.286C17 20.5483 15.9767 21.5717 14.7143 21.5717H3.28571C2.02335 21.5717 1 20.5483 1 19.286V6.71455Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


);
