import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import './style.scss';

const ContinueToChatInMobile = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    mobileChatActionFun, match, history, getPerformer, getSysConfig,
  } = props;

  useEffect(() => {
    const url = 'cfgsysconfig/list/?config_names_in=Credit_to_USD,MIN_CHAR_LIMIT,MINIMUM_BALANCE_REQUEST_PAYMENT,PERF_INIT_CHAT_MIN_MSG_PAID,MIN_IMAGE_PURCHASE_CREDITS,MIN_VIDEO_PURCHASE_CREDITS,MIN_AUDIO_PURCHASE_CREDITS,ROULETTE_SCREEN_TIMEOUT_PERFORMER,min_voice_call_rate,max_voice_call_rate,min_video_call_rate,max_video_call_rate,WHISPERER_TIMEOUT,is_chatbot_enabled';
    getSysConfig(url);
  }, []);

  const getOriginalToken = () => {
    const formToken = new FormData();
    formToken.append('token', match.params.id);
    mobileChatActionFun(formToken).then((response) => {
      const { value: { data: { token } = {} } = {} } = response;
      localStorage.setItem('loggedInUserId', token);
      localStorage.setItem('authToken', token);
      localStorage.setItem('mobileChatData', JSON.stringify(response.value.data));
      const performerId = response.value.data && response.value.data.performer_objid;
      console.log('performerId', performerId);
      if (performerId > 0) {
        setTimeout(() => {
          getPerformer(performerId)
            .then((resp) => {
              localStorage.setItem('mobileDefaultMediaPrices', JSON.stringify(resp.value.data.characters[0]));
            })
            .catch((error) => {
              console.log('getPerformer error in mobile', error);
            });
        }, 3000);
      }
      history.push('/chat');
    }).catch((error) => {
      if (error.response && ((error.response.status === 400) || (error.response.status === 401))) {
        console.log('status code', error.response.status);
        toast.error('Link expired. Re-launch roulette and scan QR code again.');
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="continue-to-chat">
        <Row>
          <Col xs="12">
            <div className="text text-center">
              <p>You can continue chat while being available on roulette</p>
              <button className="btn btn-primary text-white" type="button" onClick={getOriginalToken}>Continue to chat</button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

ContinueToChatInMobile.defaultProps = {
  history: {},
};

ContinueToChatInMobile.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  history: PropTypes.instanceOf(Object),
  mobileChatActionFun: PropTypes.func.isRequired,
  getPerformer: PropTypes.func.isRequired,
};

export default ContinueToChatInMobile;
