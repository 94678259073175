// eslint-disable-next-line import/no-cycle
import api from './api';
import setConversationAction from './actionChat';
import { updateConversationWithMsg } from '../utils/ChatHelpers';
import { getChatCountData } from './actionChatCount';

export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const getSessionMsgs = url => ({
  type: 'GET_MSG_HISTORY',
  payload: api.get(url),
});

export const removeChatMsgHistory = () => ({
  type: 'GET_MSG_HISTORY_REMOVE',
  payload: [],
});

export const fetchDataRequest = () => ({ type: FETCH_DATA_REQUEST });
export const fetchDataSuccess = (data, totalCount) => (
  { type: FETCH_DATA_SUCCESS, data, totalCount }
);
export const fetchDataFailure = error => ({ type: FETCH_DATA_FAILURE, error });
export const setCurrentPage = page => ({ type: SET_CURRENT_PAGE, page });


export const getPastChats = (
  url,
  activeUserID,
  conversations,
  setactiveUser,
  isScroll,
) => async (dispatch) => {
  try {
    dispatch(fetchDataRequest());
    const { data } = await api.get(url);
    const {
      // eslint-disable-next-line camelcase
      results, count, session_data,
    } = data;
    localStorage.setItem('sessionID', JSON.stringify(session_data?.session_id) || '');
    localStorage.setItem('sessionStatus', JSON.stringify(session_data?.session_active) || '');
    dispatch(fetchDataSuccess(results, count));
    const msgObjid = (results.length && results[0].objid) || 0;
    if (results[0].session2cfg_xmpp_sessions_objid) {
      const formData = new FormData();
      formData.append('message_objid', msgObjid);
      formData.append('session_objid', results[0].session2cfg_xmpp_sessions_objid);
      formData.append('user_type', 'character');
      api.put('/chatmsg/read/', formData).then(() => {
        setTimeout(() => dispatch(getChatCountData(results[0].char2perf_character_objid)), 1200);
      }).catch(err => console.log(err));
    }
    updateConversationWithMsg(
      dispatch,
      activeUserID,
      results,
      conversations,
      setConversationAction,
      setactiveUser,
      isScroll,
    );
  } catch (error) {
    // Dispatch failure action with the error
    dispatch(fetchDataFailure(error));
  }
};
