import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';

import arousrLogo from '../../assets/svgs/logo.svg';
import './style.scss';

const CustomModal = (props) => {
  const {
    className,
    open,
    toggle,
    header,
    body,
    footer,
    buttonText,
    buttonText2,
    onClick,
    onClick2,
    isRadioButton = false,
    isInputFeild,
    footerLink,
    htmlElements,
    displayNone,
  } = props;

  const handleClick = () => {
    onClick();
    toggle();
  };
  const handleClick2 = () => {
    onClick2();
    toggle();
  };


  return (
    <Modal
      className={`chat-modal tip-Modal ${className}`}
      isOpen={open}
      toggle={toggle}
      backdrop={false}
    >
      <ModalHeader className="chat-modal-header" toggle={toggle} style={{ display: `${displayNone}` }}>
        <img
          src={arousrLogo}
          alt="arousr-logo"
          className="modal-center-img"
        />
      </ModalHeader>
      <ModalBody className="chat-modal-body">
        <div style={{ textAlign: 'center', marginBottom: '15px' }}>
          {`${displayNone}`
            ? (
              <img
                src={arousrLogo}
                alt="arousr-logo"
                className="modal-center-img"
              />
            ) : ''}
        </div>
        <div className="text-center">
          <h4>{header}</h4>
          {body}
          {(isRadioButton || isInputFeild) && htmlElements}
          {buttonText2 ? (
            <div className="double_buttons">
              <button type="button" onClick={handleClick2} className="btn continue mr-3">{buttonText2}</button>
              <button type="button" onClick={handleClick} className="btn bankdetails">{buttonText}</button>
            </div>
          ) : <button type="button" onClick={handleClick} className="btn">{buttonText}</button>
        }
        </div>
      </ModalBody>
      <ModalFooter className="chat-modal-footer">
        <p role="presentation" onClick={footerLink}>{footer}</p>
      </ModalFooter>
    </Modal>
  );
};

CustomModal.defaultProps = {
  className: '',
  open: false,
  header: '',
  body: '',
  buttonText: 'OK',
  buttonText2: '',
  footer: '',
  onClick: () => {},
  onClick2: () => {},
  footerLink: () => {},
  isRadioButton: false,
  isInputFeild: false,
  htmlElements: [],
  displayNone: '',
};

CustomModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  header: PropTypes.string,
  body: PropTypes.string,
  footer: PropTypes.string,
  buttonText: PropTypes.string,
  buttonText2: PropTypes.string,
  onClick: PropTypes.func,
  onClick2: PropTypes.func,
  footerLink: PropTypes.func,
  isRadioButton: PropTypes.bool,
  isInputFeild: PropTypes.bool,
  htmlElements: PropTypes.instanceOf(Array),
  displayNone: PropTypes.string,
};

export default CustomModal;
