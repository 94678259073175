/* eslint-disable camelcase */
import React from 'react';
// import { Badge, Nav, Button } from 'reactstrap';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.scss';
import companyLogo from '../../assets/images/logo.png';
import { removeCookies } from '../../utils/cookies';
// import messageIcons from '../../assets/svgs/messageIcon.svg';
// import notification from '../../assets/svgs/NotificationAlert.svg';

// const PortalHeader = ({ logout, data: { notifications = 0, messages = 0 } }) => (
const PortalHeader = ({
  logout,
  updateStatus, ids: { perfPerformerObjId, cfgObjid, charId },
}) => {
  const logoutClick = () => {
    // localStorage.removeItem('videoCallCustomerName');
    const sendData = new FormData();
    sendData.set('perfperformer_objid', perfPerformerObjId);
    sendData.set('cfg_objid', cfgObjid);
    const payload = {
      action: 'presence',
      char_objid: charId,
      presence: 'sms|voice|video',
      state: 'offline',
    };
    updateStatus(payload)
      .then(({ value: { data = [] } = {} }) => {
        const {
          char_sms_online = false,
          char_video_online = false,
          char_voice_online = false,
        } = data[0];
        if (!char_sms_online || !char_video_online || !char_voice_online) {
          logout(sendData)
            .then(() => {
              localStorage.clear();
              // setCookies('user_type', '', -1);
              removeCookies('user_type', { domain: '.arousr.com', expires: new Date(0).toUTCString() });
              window.location.replace('/');
            });
        }
      });
  };

  return (
    <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 bg-dark shadow-sm pageHeaderCls">
      <h5 className="my-0 mr-md-auto font-weight-normal companyLogoCls">
        <Link to="/dashboard"><img src={companyLogo} alt="LOGO" /></Link>
      </h5>

      {/* <Nav className="my-2 my-md-0 mr-md-3">
        <div className="text-dark notificationIcon">
          <img src={notification} alt="notification" />

          {notifications > 0 && <Badge color="secondary">{notifications}</Badge>}
        </div>

        <div className="text-dark notificationIcon">
          <img src={messageIcons} alt="message Icons" />

          {messages > 0 && <Badge color="secondary">{messages}</Badge>}
        </div>
      </Nav> */}

      <Button
        className="hostBlog"
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://arousr.com/content/chat-host-information/"
        >
          Hosts Blog
        </a>
      </Button>
      <Button
        className="logOut"
        color="primary"
        onClick={logoutClick}
      >
        Log out
      </Button>
    </div>
  );
};
PortalHeader.defaultProps = {
  ids: {},
};

PortalHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  data: PropTypes.shape({
    notifications: PropTypes.number,
    messages: PropTypes.number,
  }).isRequired,
  ids: PropTypes.shape({
    charId: PropTypes.number,
    perfPerformerObjId: PropTypes.number,
    cfgObjid: PropTypes.number,
  }),
};

export default PortalHeader;
