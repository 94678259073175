import axios from 'axios';

const configurations = {
  withCredentials: true,
  crossDomain: true,
  // baseURL: 'https://testapi.telepass.cc/api',
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

export default axios.create(configurations);
