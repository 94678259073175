import { connect } from 'react-redux';
import setConversation from '../actions/actionChat';
import getCustomerChatLog from '../actions/actionChatLog';
import { getPhonebookData, editCustomerNote } from '../actions/actionCustomerPhonebook';
import Phonebook from '../scenes/Phonebook';
import getCustomerOnlineStatus from '../actions/actionGetCustomersOnlineStatus';
import { editCustomerCallsNote, getCallsData } from '../actions/actionCustomerCallLogs';

const mapPhoneBookData = (phoneBookData, data) => phoneBookData.map((customer) => {
  const index = data?.findIndex(contact => contact.objid === customer.customer_objid);
  return {
    customerObjid: customer.customer_objid,
    customerName: customer.customer_screen_name,
    custmerNote: customer.notes,
    customerProfilePic: customer.customer_profile_pic,
    isCustomerBlockedByChar: customer.is_blocked_by_char,
    isChatInitiateBlock: customer.chat_initiate_block,
    isPremiumCustomer: customer.is_premium_customer,
    customerHostReview: customer.customer_host_review,
    sendEmailNotification: customer.send_email_notification,
    // eslint-disable-next-line no-nested-ternary
    isCustomerOnline: data ? index !== -1 ? data[index].is_online : customer.is_customer_online
      : customer.is_customer_online,
  };
});

const mapCallsLogData = logData => logData && logData.length && logData.map(customer => ({
  customerObjid: customer.customer2cst_contact_objid,
  customerName: customer.customer_screen_name,
  custmerNote: customer.notes,
  customerProfilePic: customer.customer_profile_pic,
  isCustomerBlockedByChar: customer.is_blocked_by_char,
  isChatInitiateBlock: customer.chat_initiate_block,
  isPremiumCustomer: customer.is_premium_customer,
  customerHostReview: customer.customer_host_review,
  sendEmailNotification: customer.send_email_notification,
  isCustomerOnline: customer.is_customer_online,
}));

const mapSysConfigData = (sysConfigDataRes = []) => {
  const minMessageToInitaiteChat = sysConfigDataRes.find(result => result.config_name === 'PERF_INIT_CHAT_MIN_MSG_PAID') || {};
  return minMessageToInitaiteChat.value;
};

const mapStateToProps = (state) => {
  const {
    phonebook: {
      data: { count, results = [] } = {},
      getPhonebookDataapiLoader,
      updatePhonebookDataapiLoader,
    } = {},
    callLogs: { data: callLogss, getCallsDataapiLoader: callLogsLoader, count: voiceLogsCOunter },
    login: { data: { perfcharacter = [] } = {} } = {},
    chat: { conv = [] } = {},
    sysConfig: { data: { results: sysConfigResults = [] } = {} } = {},
    customerOnlineStatus,
  } = state;

  const perfObjid = (perfcharacter.length && perfcharacter[0].objid) || 0;
  const isTier2 = (perfcharacter.length && perfcharacter[0].is_tier2) || false;

  return {
    contactsOnlineContainer: customerOnlineStatus.data,
    callLogsLoader,
    phoneBookData: mapPhoneBookData(results, customerOnlineStatus.data),
    callsLogData: mapCallsLogData(callLogss),
    minMessageToInitaiteChat: mapSysConfigData(sysConfigResults),
    getPhonebookDataapiLoader,
    updatePhonebookDataapiLoader,
    perfObjid,
    conversation: conv,
    count,
    totalCallsCount: voiceLogsCOunter,
    isTier2,
  };
};

const mapDispatchToProps = dispatch => ({
  getPhonebookData: data => dispatch(getPhonebookData(data)),
  getCallsData: URL => dispatch(getCallsData(URL)),
  editCustomerNote: data => dispatch(editCustomerNote(data)),
  editCustomerCallsNote: data => dispatch(editCustomerCallsNote(data)),
  setConversation: data => dispatch(setConversation(data)),
  checkActiveSessionAvailable: url => getCustomerChatLog(url).payload,
  getCustomerOnlineStatus: data => dispatch(getCustomerOnlineStatus(data)),
});

const PhonebookContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Phonebook);

export default PhonebookContainer;
