/* eslint-disable */
class Polling {
	_interval = null;
	set(fn, params, immediate = false) {
	  this.clear();
	  if (immediate) {
	    fn(params);
	  }
	  this._interval = setInterval(fn.bind(null, params), 30000);
	}
	clear() {
	  if (this._interval) {
	    clearInterval(this._interval);
	  }
	}
}

export default new Polling();
