import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'react-toastify/dist/ReactToastify.css';

import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import Routes from './routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './assets/css/styles.scss';

const store = configureStore();

console.log(process.env, process.env.NODE_ENV);

if (window.location.href.includes('arousr.com')) {
  console.log('in side sentry config');
  // eslint-disable-next-line
  const env = window.location.href.includes('testchat') ? 'staging' : window.location.href.includes('dev') ? 'development' : 'production';
  Sentry.init({
    dsn: 'https://0866ded8726b4120bed762a08a1744cb@o425026.ingest.sentry.io/5818036',
    environment: env,
    release: 'performer-portal-2@release-v0.0.25',
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    })],
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.02,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ToastContainer />
    <Routes />
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
