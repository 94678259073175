import React, { useState } from 'react';
// import PhoneInput from 'react-phone-number-input';
import { PhoneInput } from 'react-contact-number-input';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';

import './EditInfoModal.scss';

const EditInfoModal = (props) => {
  const {
    className,
    open,
    toggle,
    getNumberFromModal,
    isLoading,
  } = props;

  const [contactData, setContactData] = useState({});
  const [hasErrorState, setHasErrorState] = useState(true);
  const [contactErrMsg, setContactErrMsg] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleOnChange = (countryNumberObj) => {
    if (!countryNumberObj.countryData) {
      setBtnDisabled(true);
    }

    if (countryNumberObj.countryData) {
      if (countryNumberObj.hasError === false) {
        setBtnDisabled(false);
      } else setBtnDisabled(true);
      setHasErrorState(countryNumberObj.hasError);
      setContactErrMsg(countryNumberObj.message);
      if (countryNumberObj.phoneNumber === '') {
        setContactErrMsg('Enter Number with Country code!');
      }
      const contactNumDataObj = {
        dialCode: countryNumberObj.countryData.dialCode,
        countryCode: countryNumberObj.countryData.countryCode,
        // phoneNumber: countryNumberObj.phoneNumber,
        phoneNumber: countryNumberObj.validData.phoneNumber,
      };
      setContactData(contactNumDataObj);
    }
  };

  const handleSubmit = (e) => {
    // alert(value);
    e.preventDefault();
    getNumberFromModal(contactData);
    const contactNumDataObj = {
      dialCode: '',
      countryCode: '',
      phoneNumber: '',
    };
    setContactData(contactNumDataObj);
  };

  return (
    <Modal
      className={`EditInfo-Modal ${className}`}
      isOpen={open}
      toggle={toggle}
      backdrop={false}
    >
      <ModalHeader className="chat-modal-header" toggle={toggle} />
      <ModalBody className="chat-modal-body">
        <i className="fa fa-phone  mb-3" />
        <h4 className="mb-3">Add phone Number</h4>
        <form onSubmit={handleSubmit}>
          <PhoneInput
            onChange={handleOnChange}
          />
          {hasErrorState ? <span className="contactErrorMsg">{contactErrMsg}</span> : ''}
          <div className="text-center">
            {isLoading ? (
              <Button type="submit" disabled={btnDisabled} color="primary" className="doneBtn text-white">
                Verify
                <i className="fa fa-spinner" />
              </Button>
            ) : (
              <Button type="submit" disabled={btnDisabled} color="primary" className="doneBtn text-white">Verify</Button>
            )}
          </div>
        </form>

      </ModalBody>
      {/* <ModalFooter className="chat-modal-footer">
        <p role="presentation" onClick={footerLink}>{footer}</p>
      </ModalFooter> */}
    </Modal>
  );
};

EditInfoModal.defaultProps = {
  className: '',
  open: false,
  isLoading: false,
};

EditInfoModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  getNumberFromModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default EditInfoModal;
