/* eslint-disable */
import React from 'react';

export default () => (
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.44444 4.44444H2.96296V5.92593H4.44444V4.44444Z" fill="#FD7663"/>
<path d="M5.92593 4.44444H7.40741V5.92593H5.92593V4.44444Z" fill="#FD7663"/>
<path d="M10.3704 4.44444H8.88889V5.92593H10.3704V4.44444Z" fill="#FD7663"/>
<path d="M0 1.48148V13.3333L3.55556 10.6667C3.81172 10.4738 4.1238 10.3698 4.44444 10.3704H11.8519C12.6701 10.3704 13.3333 9.70709 13.3333 8.88889V1.48148C13.3333 0.663282 12.6701 0 11.8519 0H1.48148C0.663282 0 0 0.663282 0 1.48148Z" fill="#FD7663"/>
<rect width="1.66667" height="1.66667" transform="matrix(1 0 0 -1 2.5 6.6665)" fill="#292A2B"/>
<rect width="1.66667" height="1.66667" transform="matrix(1 0 0 -1 5.8335 6.6665)" fill="#292A2B"/>
<rect width="1.66667" height="1.66667" transform="matrix(1 0 0 -1 9.16699 6.6665)" fill="#292A2B"/>
</svg>

);
