/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import PropTypes from 'prop-types';

import './MediaVideoModal.scss';


const MediaVideoModal = (props) => {
  const {
    open,
    videoId,
    setOpen,
  } = props;

  console.log('open', open);

  const [modal, setModal] = useState(true);

  const toggle = () => {
    setModal(!modal);
    setOpen(state => !state);
  };

  useEffect(() => {
    setModal(true);
    setOpen(true);
  }, []);

  return (
    <Modal
      className="video-Modal MainChatVideoModal"
      style={{ maxWidth: '1000px', height: '400px' }}
      isOpen={open}
      backdrop
    >
      <ModalHeader className="chat-modal-header" toggle={toggle} />
      <ModalBody className="chat-modal-body">
        <div className="text-center">
          <iframe
            src={`https://iframe.videodelivery.net/${videoId}`}
            title={`video_${videoId}`}
            height="365px"
            width="700px"
            allowFullScreen
            controls="0"
            autoPlay="0"
            frameBorder="0"
            scrolling="no"
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

MediaVideoModal.defaultProps = {
  open: true,
  videoId: '',
  setOpen: () => {},
};

MediaVideoModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  videoId: PropTypes.string,
};

export default MediaVideoModal;
