import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';

import { getCookies, setCookies } from '../../utils/cookies';
import popupLogo from '../../assets/images/timesession-popup-img.svg';

import './popupstyle.scss';

const RouletteTimePopup = (props) => {
  const { history } = props;
  const [modal, setModal] = useState(false);
  const [RTPTimeoutIdentityNo, setRTPTimeoutIdentityNo] = useState(0);
  const [isDST, setIsDST] = useState(false);
  console.log('isDST', isDST);
  const isShowRTP = getCookies('isShowRTP');
  const roulettePopuupTimeHour = isDST ? 23 : 24;
  const roulettePopuupTimeMin = 0;
  const roulettePopuupTimeSec = 0;
  const roulettePopuupTimeMS = 0;

  const toggle = () => setModal(!modal);

  const getFirstSundayInNovember = (year) => {
    const november = DateTime.fromObject({ year, month: 11, day: 1 });
    // November 1st of the given year
    const firstNovemberSunday = november.startOf('month').set({ weekday: 7 });
    // Set to the first day of November and move to Sunday
    return firstNovemberSunday;
  };

  const getSecondSundayInMarch = (year) => {
    const march = DateTime.fromObject({ year, month: 3, day: 1 });
    // March 1st of the given year
    const secondMarchSunday = march.startOf('month').plus({ weeks: 1 }).set({ weekday: 7 });
    // Set to the first day of March and move to the second Sunday
    return secondMarchSunday;
  };

  // // Check if today is within the daylight saving time period
  const isDSTActive = (date, dstStart, dstEnd) => {
    console.log('date', date);
    return date >= dstStart && date < dstEnd;
  };

  useEffect(() => {
    const currentYear = DateTime.local().year;
    const today = DateTime.local();
    // const anynew = DateTime.fromObject({ year: today.year, month: 5, day: 1 });
    // check for specific date
    const formated = today.toFormat('yyyy-MM-dd');
    const firstSundayNovember = getFirstSundayInNovember(currentYear);
    const secondSundayMarch = getSecondSundayInMarch(currentYear);
    // eslint-disable-next-line max-len
    const isDSTval = isDSTActive(formated, secondSundayMarch.toISODate(), firstSundayNovember.toISODate());
    console.log('Is daylight saving time active today', isDSTval);
    setIsDST(isDSTval);
  }, [isDST]);

  const setNextTimePopup = () => {
    let timeForNextPopup = 0;
    const currentDate = new Date();
    let nextTimeObj = new Date();
    nextTimeObj.setUTCHours(
      roulettePopuupTimeHour,
      roulettePopuupTimeMin,
      roulettePopuupTimeSec,
      roulettePopuupTimeMS,
    );
    if (nextTimeObj - currentDate > 0) {
      timeForNextPopup = nextTimeObj - currentDate;
    } else {
      nextTimeObj = new Date();
      nextTimeObj.setDate(currentDate.getDate() + 1);
      nextTimeObj.setUTCHours(
        roulettePopuupTimeHour,
        roulettePopuupTimeMin,
        roulettePopuupTimeSec,
        roulettePopuupTimeMS,
      );
      timeForNextPopup = nextTimeObj - currentDate;
    }
    const identityNo = setTimeout(() => {
      setModal(true);
    }, timeForNextPopup);
    setRTPTimeoutIdentityNo(identityNo);
  };

  const showPopup = () => {
    const currentUTCHour = new Date().getUTCHours();
    if (currentUTCHour >= roulettePopuupTimeHour
      || currentUTCHour < ((roulettePopuupTimeHour + 4) % 24)) {
      setModal(true);
      clearTimeout(RTPTimeoutIdentityNo);
    }
    if (RTPTimeoutIdentityNo === 0) {
      setNextTimePopup();
    }
    const expire = new Date();
    expire.setDate(expire.getDate());

    if (currentUTCHour >= roulettePopuupTimeHour) {
      expire.setDate(expire.getDate() + 1);
    }
    expire.setUTCHours(
      roulettePopuupTimeHour,
      roulettePopuupTimeMin,
      roulettePopuupTimeSec,
      roulettePopuupTimeMS,
    );
    const options = {
      expires: expire,
    };
    setCookies('isShowRTP', true, options);
  };

  const handleOnclikOnRoulette = () => {
    setModal(false);
    history.push('/dashboard');
  };

  useEffect(() => {
    if (isShowRTP) {
      setNextTimePopup();
    } else {
      showPopup();
    }
    return (() => {
      console.log('isShow popup', RTPTimeoutIdentityNo);
      if (RTPTimeoutIdentityNo) {
        console.log('isShow popup');
      }
    });
  }, [isShowRTP]);

  return (
    <div>
      <Modal className="timer-popup-main-wrap" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <div className="roulette-time-popup-wrap">
            <div className="roulette-time-popup">
              <img src={popupLogo} alt="popup-timer-logo" />
              <h3>It’s Roulette Hour!</h3>
              <h4>
                <span>Launch Roulette and earn Money for Free Chats!</span>
                <p>Extended sessions pay your Video Call Rate.</p>
              </h4>
              <div className="popup-divider" />
              <Button onClick={handleOnclikOnRoulette} className="launch-btn">GO TO ROULETTE</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

RouletteTimePopup.defaultProps = {
  history: {},
};

RouletteTimePopup.propTypes = {
  history: PropTypes.instanceOf(Object),
};

export default RouletteTimePopup;
