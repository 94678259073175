import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';


import Media from '../scenes/Media';
import getCharacterProductList from '../actions/actionGetCharacterProductList';
import editMedia from '../actions/actionEditMedia';
import createNewMedia from '../actions/actionCreateNewMedia';
import manageAlertDisplay from '../actions/actionHandleAlert';

const resultWithMediaAccessor = (mediaData = {}) => {
  const { results = [] } = mediaData;
  let resultUpdate = [];
  resultUpdate = results && results.map(result => (
    {
      ...result,
      // mediaAccessor,
    }
  ));
  return resultUpdate;
};

const resetFormValues = (dispatch) => {
  dispatch(reset('uploadMediaForm'));
};

export const mapStateToProps = (state) => {
  const { login: { data: { perfcharacter = [] } }, productList } = state;
  const { form: { uploadMediaForm: { values = {} } = {} } = {} } = state;
  const objectId = (perfcharacter.length > 0 && perfcharacter[0].objid) || -1;
  const loadingData = productList && productList.isLoading;
  const mediaData = productList && productList.data;
  let finalResult;
  if (mediaData) {
    finalResult = resultWithMediaAccessor(mediaData);
  }
  let formValue = {};
  if (values) {
    formValue = values;
  }
  return {
    objectId,
    loadingData,
    finalResult,
    mediaData,
    formValue,
  };
};

export const mapDispatchToProps = dispatch => ({
  getProductList: url => dispatch(getCharacterProductList(url)),
  editMedia: data => dispatch(editMedia(data)),
  createNewMedia: data => dispatch(createNewMedia(data)),
  showAlert: (status, message) => dispatch(manageAlertDisplay(status, message)),
  resetMediaFormValues: () => resetFormValues(dispatch),
});

const UploadMediaForm = reduxForm({
  form: 'uploadMediaForm',
  enableReinitialize: true,
})(Media);

const MediaContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadMediaForm);

export default MediaContainer;
