/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import { TiArrowForward } from 'react-icons/ti';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { Emoji } from 'emoji-picker-react';
import { setScrollAtBottom, utcToLocalTimeConverter } from '../../../../utils/helpers';
import placeHolderImg from '../../../../assets/svgs/mediaNoLongerAvailable.svg';
import 'react-image-lightbox/style.css';
import EmojiSelector from './Emojis/EmojiReaction';
import ForwardMediaModal from '../ForwardMediaModal';
import ForwardArrow from '../../../../assets/svgs/ForwardArrow';
import LoadingSpinner from '../../../../utils/LoadingSpinner';


const fileExists = (url) => {
  const http = new XMLHttpRequest();
  http.open('HEAD', url, false);
  http.send();
  if (http.status !== 404) {
    return <audio src={url} controls controlsList="nodownload, noplaybackrate" />;
  }
  return <img className="mediaDeletedSVG" src={placeHolderImg} alt="chat msgs img" />;
};


const textMsgRender = ({
  text: mediaText = '',
  creditValue = 0,
  videoId = '',
  pictureControl,
  mediaType: UploadedMediaType,
  chatStatus = '', media = '', msgTime, audioURL,
  msgId,
  outgoingEmojiDB,
  newProductID,
  HandleModal,
  msg,
  productId,
  SubmitForwardRequest,
  activeUserId,
  chatType,
}) => {
  let mediaType = '';
  // console.log("mediaText,textMsgRender", mediaText);
  // check on url
  if (mediaText.includes('https://watch.cloudflarestream.com')) {
    mediaType = 'video';
  }
  if (mediaText.includes('https://res.cloudinary.com/tfonia/image/')) {
    mediaType = 'image';
  }
  if (mediaText.includes('https://res.cloudinary.com/tfonia/video/')) {
    mediaType = 'audio';
  }
  if (mediaType === 'video') {
    return (
      <div className={creditValue > 0 ? 'bluredImgBox d-flex align-items-center' : 'd-flex align-items-center'}>
        {productId && chatType === 'media_chat_video_purchased' ? (
          <div
            className="mr-3 cursor-pointer"
            role="presentation"
            onKeyPress={() => {}}
            onClick={() => HandleModal({
              creditValue,
              mediaType: mediaType || msg?.mediaType,
              media: media || mediaText,
              newProductID: productId,
              audioURL,
              pictureControl,
              videoId,
              msgId,
              isOpen: true,
              msg,
              SubmitForwardRequest,
              activeUserId,
            })}
          >
            <ForwardArrow />
          </div>
        ) : ''}
        <iframe
          src={mediaText}
          title={`video_${videoId}`}
          height="300"
          width="300"
          allowFullScreen
        />
        {
          creditValue && creditValue > 0
            ? (
              <span className="creditValueClass">
                {creditValue }
                <span className="ml-1">Credits</span>
              </span>
            ) : ''
        }
      </div>
    );
  }

  if (mediaType === 'image') {
    return (
      <div className={creditValue > 0 ? 'bluredImgBox d-flex align-items-center' : 'd-flex align-items-center'}>
        {productId && chatType === 'media_chat_image_purchased' ? (
          <div
            className="mr-3 cursor-pointer"
            role="presentation"
            onKeyPress={() => {}}
            onClick={() => HandleModal({
              creditValue,
              mediaType: mediaType || msg?.mediaType,
              media: media || mediaText,
              newProductID: productId,
              pictureControl,
              videoId,
              msgId,
              isOpen: true,
              msg,
              SubmitForwardRequest,
              activeUserId,
            })}
          >
            <ForwardArrow />
          </div>
        ) : ''}

        {chatType === 'media_chat_image_unpurchased_deleted' || chatType === 'media_deleted' ? (
          <img className="mediaDeletedSVG" src={placeHolderImg} alt="chat msgs img" />
        )
          : (
            <>
              <object className={chatType} data={mediaText} role="presentation" onClick={pictureControl} type="image/png" height="100px" width="100px">
                <img className="placeholder-obj" src={placeHolderImg} alt="chat msgs img" height="100px" width="100px" />
              </object>
              { creditValue && creditValue > 0
                ? (
                  <span className="creditValueClass">
                    {creditValue }
                    <span className="ml-1">Credits</span>
                  </span>
                ) : ''}
            </>
          )
        }


      </div>
    );
  }

  if (mediaType === 'audio') {
    return (
      <div className={creditValue > 0 ? 'bluredImgBox d-flex align-items-center' : 'd-flex align-items-center'}>
        {productId && chatType === 'media_chat_audio_purchased' ? (
          <div
            className="mr-3 cursor-pointer"
            role="presentation"
            onKeyPress={() => {}}
            onClick={() => HandleModal({
              creditValue,
              mediaType: mediaType || msg?.mediaType,
              media: media || mediaText,
              newProductID: productId,
              audioURL,
              pictureControl,
              videoId,
              msgId,
              isOpen: true,
              msg,
              SubmitForwardRequest,
              activeUserId,
            })}
          >
            <ForwardArrow />
          </div>
        ) : ''}
        {fileExists(mediaText)}
        {
          creditValue && creditValue > 0
            ? (
              <span className="creditValueClass">
                {creditValue }
                <span className="ml-1">Credits</span>
              </span>
            ) : ''
        }
      </div>
    );
  }
  return <p className="messageText">{mediaText}</p>;
};


const outgoingMsg = (incomingMsgProps) => {
  const {
    creditValue,
    videoId,
    mediaType: UploadedMediaType,
    chatStatus = '', media = '', pictureControl, msgTime, audioURL,
    msgId,
    outgoingEmojiDB,
    newProductID,
    HandleModal,
    msg,
    productId,
    SubmitForwardRequest,
    activeUserId,
    chatType,
    messageText,
    emoji_unicode,
  } = incomingMsgProps;
  let mediaType = '';

  if (chatType && chatType.includes('media_chat_video') || UploadedMediaType === 'video/mp4') {
    mediaType = 'video';
  }
  if (chatType && chatType.includes('media_chat_audio') || UploadedMediaType === 'audio/mp3') {
    mediaType = 'audio';
  }
  if (chatType && chatType.includes('media_chat_image') || UploadedMediaType?.includes('image/')) {
    mediaType = 'image';
  }

  return (
    <div className="ar_incoming_msg_box">
      <div className="incoming_msg" id={`msg${msgId}`}>
        <div className="received_msg">
          <div className="received_withd_msg">
            {/* <EmojiSelector emojis={emojis} /> */}
            <>
              {chatType === 'media_chat_image_unpurchased_deleted' || chatType === 'media_chat_video_unpurchased_deleted' || chatType === 'media_chat_audio_unpurchased_deleted' || chatType === 'media_deleted' ? (
                <img className="mediaDeletedSVG" src={placeHolderImg} alt="chat msgs img" />
              )
                : typeof media === 'string' && media.includes('blob:http') ? (
                  mediaType === 'video' && videoId ? (
                    <div className={creditValue > 0 ? 'bluredImgBox d-flex align-items-center' : 'd-flex align-items-center'}>
                      {productId && chatType === 'media_chat_video_purchased' ? (
                        <div
                          className="mr-3 cursor-pointer"
                          role="presentation"
                          onKeyPress={() => {}}
                          onClick={() => HandleModal({
                            creditValue,
                            mediaType: mediaType || msg?.mediaType,
                            media: media || messageText,
                            newProductID: productId,
                            audioURL,
                            pictureControl,
                            videoId,
                            msgId,
                            isOpen: true,
                            msg,
                            SubmitForwardRequest,
                            activeUserId,
                          })}
                        >
                          <ForwardArrow />
                        </div>
                      ) : ''}
                      <iframe
                        src={`https://iframe.videodelivery.net/${videoId}`}
                        title={`video_${videoId}`}
                       // style={{"border: none;"}}
                        height="300"
                        width="300"
                        allowFullScreen={`${videoId}`}
                      />
                      {
                       creditValue && creditValue > 0
                         ? (
                           <span className="creditValueClass">
                             {creditValue }
                             <span className="ml-1">Credits</span>
                           </span>
                         ) : ''
                     }
                    </div>
                  ) : mediaType === 'audio' ? (
                    <div className={creditValue > 0 ? 'bluredImgBox d-flex align-items-center' : 'd-flex align-items-center'}>
                      {productId && chatType === 'media_chat_audio_purchased' ? (
                        <div
                          className="mr-3 cursor-pointer"
                          role="presentation"
                          onKeyPress={() => {}}
                          onClick={() => HandleModal({
                            creditValue,
                            mediaType: mediaType || msg?.mediaType,
                            media: media || messageText,
                            newProductID: productId,
                            audioURL,
                            pictureControl,
                            videoId,
                            msgId,
                            isOpen: true,
                            msg,
                            SubmitForwardRequest,
                            activeUserId,
                          })}
                        >
                          <ForwardArrow />
                        </div>
                      ) : ''}
                      <audio className="chat_audio" preload="auto" controls controlsList="nodownload noplaybackrate">
                        <source src={media || messageText} />
                      </audio>
                      {
                       creditValue && creditValue > 0
                         ? (
                           <span className="creditValueClass audio-credits">
                             {creditValue }
                             <span className="ml-1">Credits</span>
                           </span>
                         ) : ''
                     }
                    </div>
                  ) : mediaType === 'image' && (
                  <div className={creditValue > 0 ? 'bluredImgBox d-flex align-items-center' : 'd-flex align-items-center'}>
                    {productId && chatType === 'media_chat_image_purchased' ? (
                      <div
                        className="mr-3 cursor-pointer"
                        role="presentation"
                        onKeyPress={() => {}}
                        onClick={() => HandleModal({
                          creditValue,
                          mediaType: mediaType || msg?.mediaType,
                          media: media || messageText,
                          newProductID: productId,
                          pictureControl,
                          videoId,
                          msgId,
                          isOpen: true,
                          msg,
                          SubmitForwardRequest,
                          activeUserId,
                        })}
                      >
                        <ForwardArrow />
                      </div>
                    ) : ''}


                    {chatType === 'media_chat_image_unpurchased_deleted' || chatType === 'media_deleted' ? (
                      <img className="mediaDeletedSVG" src={placeHolderImg} alt="chat msgs img" />
                    ) : (
                      <>
                        <object className={chatType} data={media || messageText} role="presentation" onClick={pictureControl} type="image/png" height="100px" width="100px">
                          <img className="mediaDeletedSVG" src={placeHolderImg} alt="chat msgs img" />
                        </object>
                        {
                       creditValue && creditValue > 0
                         ? (
                           <span className="creditValueClass">
                             {creditValue }
                             <span className="ml-1">Credits</span>
                           </span>
                         ) : ''
                  }
                      </>
                    ) }


                  </div>
                  )
                ) : (
                  textMsgRender({ ...incomingMsgProps })
                )
              }

            </>

            <span className={outgoingEmojiDB ? 'chat-status isReacted' : 'chat-status'}>
              {chatStatus === 'Sending'
                && (
                  <span>
                    {chatStatus}
                    <i className="fa fa-spinner fa-spin" />
                  </span>
                )
              }
              {msgTime && (
                <span className="chatInMsgTime">
                  {moment(utcToLocalTimeConverter(msgTime)).format('HH:mm')}
                </span>
              )}
              {(chatStatus === 'Sent') && chatStatus }
              {(chatStatus === 'Failed')
                && (
                  <span className="failed-msg">
                    {chatStatus}
                  </span>
                )
              }
            </span>

            <div id={`emoji${msgId}`} className={`incoming-emoji-reaction ${outgoingEmojiDB ? '' : 'isnot-reacted'}`}>
              {!outgoingEmojiDB?.includes('https://') ? (
                <Emoji unified={outgoingEmojiDB} size="25" className="left-emoji-reaction" />
              ) : (
                <img
                  src={outgoingEmojiDB}
                  alt="emoji"
                  className="left-emoji-reaction"
                />
              )
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const outgoingtextMsgRender = ({
  text: mediaText = '',
  videoId = '',
  pictureControl,
}) => {
  let mediaType = '';
  const extracedIdFromUrl = mediaText.split('/').pop();
  // console.log("mediaText",mediaText );
  // check on url
  // console.log({mediaText});
  if (mediaText.includes('https://watch.cloudflarestream.com')) {
    mediaType = 'video';
  }
  if (mediaText.includes('https://res.cloudinary.com/tfonia/image')) {
    mediaType = 'image';
  }
  if (mediaText.includes('https://res.cloudinary.com/tfonia/video')) {
    mediaType = 'audio';
  }
  if (mediaType === 'video') {
    return (
      <div>
        <iframe
          src={`https://iframe.videodelivery.net/${extracedIdFromUrl}`}
          title={`video_${videoId}`}
          height="300"
          width="300"
          allowFullScreen
        />
      </div>
    );
  }

  if (mediaType === 'image') {
    return (
      // <div>
      //   <object data={mediaText} role="presentation" onClick={pictureControl} type="image/png" height="100px" width="100px">
      //     <img className="mediaDeletedSVG" src={placeHolderImg} alt="chat msgs img" />
      //   </object>
      // </div>
      <div>
        <img
          src={mediaText}
          role="presentation"
          alt="chat message"
          height={100}
          width={100}
          className="validImage"
          onClick={pictureControl}
          onError={(e) => {
            e.target.className = 'mediaDeletedSVG';
            e.target.src = placeHolderImg;
            e.target.height = 'auto';
            e.target.width = 'auto';
          }}
        />
      </div>
    );
  }

  if (mediaType === 'audio') {
    return (
      <div className="audio">
        {fileExists(mediaText)}
      </div>
    );
  }
  return <p className="messageText incomingMessageText">{mediaText}</p>;
};


const incomingMsg = (outgoingMsgProps) => {
  const {
    // eslint-disable-next-line camelcase
    media = '', sessionID, chatStatus = '', emoji_unicode, isActiveSession, pictureControl, msgTime, emojis, activeUserId, msgId, incomingEmojiDB,
  } = outgoingMsgProps;
  const fallBackImage = document.createElement('img');
  fallBackImage.src = `${placeHolderImg}`;
  const mediaType = media && media.split('.').pop().toUpperCase();
  const allowAudioTypes = ['MP3', 'WEBM', 'M4A'];
  const localSessionID = localStorage.getItem('sessionID') ? JSON.parse(localStorage.getItem('sessionID')) : 0;
  return (
    <div className="ar_outgoing_msg_box">
      <div className="outgoing_msg" id={`msg${msgId}`}>
        <div className="sent_msg">
          {(
            <EmojiSelector
              emojis={emojis}
              activeUserId={activeUserId}
              msgId={msgId}
              incomingEmojiDB={{ imagePath: incomingEmojiDB, emojiUnicode: emoji_unicode }}
              msgTime={msgTime}
              sessionID={Number(sessionID)}
              isActiveSession={isActiveSession}
              localSessionID={Number(localSessionID)}
            />
          )}

          {media ? (allowAudioTypes.includes(mediaType) ? (
            <div className="audio">
              {fileExists(media)}
            </div>
          ) : (
            <img
              src={media}
              role="presentation"
              alt="chat message"
              height={100}
              width={100}
              className="validImage"
              onClick={pictureControl}
              onError={(e) => {
                e.target.className = 'mediaDeletedSVG';
                e.target.src = placeHolderImg;
                e.target.height = 'auto';
                e.target.width = 'auto';
              }}
            />
          )) : (
            outgoingtextMsgRender({ ...outgoingMsgProps })
          )}

          <span className="chat-status">
            {chatStatus}
          </span>
        </div>
      </div>
    </div>
  );
};

const Conversation = (props) => {
  // eslint-disable-next-line react/prop-types
  const {
    messages = [],
    activeUserId,
    isActiveSession,
    isRoomToggled,
    sendMessage,
    SubmitForwardRequest,
    fetchMoreData,
    infiniteloading,
  } = props;
  // console.log("propspropsprops", props);
  const [isOpenPhoto, setOpenPhoto] = useState(false);
  const [ModalParams, setModalParams] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [mainSrcPicCon, setMainSrcPicCon] = useState(null);
  const [conversations, setConversations] = useState(null);
  const [, setShouldDOMUpdate] = useState(false);
  const stingifyMessages = JSON.stringify(messages);
  let traversingDate = null;
  const LocalMessageID = localStorage.getItem('messageNotification');
  const {
    data, currentPage, totalCount, loading,
  } = useSelector(state => state.pastChats);

  useEffect(() => {
    const audios = document.querySelectorAll('audio');
    // Pause all <audio> elements except for the one that started playing.
    function pauseOtherAudios({ target }) {
      // eslint-disable-next-line no-restricted-syntax
      for (const audio of audios) {
        if (audio !== target) {
          audio.pause();
        }
      }
    }
    // Listen for the 'play' event on all the <audio> elements.
    // eslint-disable-next-line no-restricted-syntax
    for (const audio of audios) {
      audio.addEventListener('play', pauseOtherAudios);
    }
  }, [conversations]);

  const pictureControl = (event = {}) => {
    const imageFile = event && event.target && event.target.data;
    if (event.target && event.target.nodeName === 'IMG') {
      setOpenPhoto(true);
      setMainSrcPicCon(event.target.src);
    } else if (imageFile) {
      setOpenPhoto(true);
      setMainSrcPicCon(imageFile);
    }
  };

  const timeAt = (date) => {
    const msgDate = new Date(date);
    let finalDate = moment(msgDate).format('ddd, MMM D, h:mm A');

    if (msgDate.getFullYear() < new Date().getFullYear()) {
      finalDate = moment(msgDate).format('ddd, MMM D YYYY, h:mm A');
    }

    if (moment(msgDate).format('L') === moment().format('L')) {
      finalDate = `Today, ${moment(msgDate).format('h:mm A')}`;
    }

    if (moment(traversingDate).format('L') !== moment(msgDate).format('L')) {
      traversingDate = msgDate;
      return finalDate;
    }
    return '';
  };

  const HandleModal = (media) => {
    setShowModal(media.isOpen);
    setModalParams({ ...media, setShowModal });
  };

  const closePhoto = () => {
    setOpenPhoto(false);
  };

  useEffect(() => {
    // if (LocalMessageID) {
    setTimeout(() => setScrollAtBottom({ elementId: 'chatWindow' }), 500);
    // }
  }, [LocalMessageID, isRoomToggled]);

  const getMessage = (msg = {}) => {
    const creditValue = msg.creditValue || msg.product_price;
    const {
      text,
      mediaType,
      videoId,
      chat_status: chatStatus, media, direction, audioURL,
      // eslint-disable-next-line camelcase
      newProductID, product_objid, productId, message_txt: messageText,
      // eslint-disable-next-line camelcase
      chat_type: chatType,
      emoji_unicode,

    } = msg;

    if (direction === 'out') {
      return outgoingMsg({
        text,
        chatStatus,
        media,
        pictureControl,
        msgTime: msg.currentUTC,
        creditValue,
        mediaType,
        videoId,
        audioURL,
        msgId: msg.objid,
        outgoingEmojiDB: msg.emoji_object || msg.emoji_unicode,
        activeUserId,
        newProductID,
        // eslint-disable-next-line camelcase
        productId: product_objid || productId,
        HandleModal,
        SubmitForwardRequest,
        chatType,
        msg: direction === 'out' && msg,
        messageText,
        emoji_unicode,
      });
    }
    return incomingMsg({
      text,
      media,
      chatStatus,
      pictureControl,
      // eslint-disable-next-line react/destructuring-assignment
      emojis: props.emojis,
      emoji_unicode,
      msgTime: msg.currentUTC,
      activeUserId,
      isActiveSession,
      msgId: msg.objid,
      incomingEmojiDB: msg.emoji_object,
      sessionID: msg.sessionId,
      messageText,
    }, setShouldDOMUpdate);
  };

  const systemMsg = ({ text = '', sysMsgTime = '' }) => (
    <div className="system_msg_container">
      <div className="system_msg">
        <p className="messageText">{text}</p>
      </div>
      <span className="sysMsgTime">
        {moment(sysMsgTime).format('HH:mm')}
      </span>
    </div>
  );
  useEffect(() => {
    const parseMessages = JSON.parse(stingifyMessages);
    const uniqueObjectsMap = new Map();

    parseMessages.forEach((item) => {
      if (!uniqueObjectsMap.has(item.objid && item.emoji_object)) {
        uniqueObjectsMap.set(item.objid, item);
      }
    });
    const uniqueArray = Array.from(uniqueObjectsMap.values());

    const chatMessages = uniqueArray
      .sort((a, b) => new Date(a.currentUTC) - new Date(b.currentUTC))
      .map((msg, i) => (
        <React.Fragment key={`wh-${msg.objid}`}>
          {(msg.contentType === 'image')
            ? <img src={msg.text} alt="avatar" />
            : (
              <React.Fragment>
                <div className="text-center dateTimeStampChat">
                  {timeAt(utcToLocalTimeConverter(msg.currentUTC))}
                </div>
                {(msg.type && msg.type === 'Sys2Char')
                  ? (
                    systemMsg({ text: msg.text, sysMsgTime: msg.currentUTC })
                  ) : (
                    <div>
                      {getMessage(msg)}
                    </div>
                  )
                }
              </React.Fragment>
            )}

        </React.Fragment>
      ));
    setConversations(chatMessages);
  }, [stingifyMessages]);

  // const closePhoto = () => {
  //   setIsOpenPhoto(false);
  // };

  return (
    <>
      {showModal && <ForwardMediaModal media={ModalParams} sendMessage={sendMessage} />}
      {isOpenPhoto && (
      <Lightbox
        ClassName="Lightbox"
        mainSrc={mainSrcPicCon}
        onCloseRequest={closePhoto}
      />
      )}
      <div
        className="msg_history"
        id="chatWindow"
        style={{
          height: 'calc(100vh - 325px)',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        {/* Put the scroll bar always on the bottom */}
        <InfiniteScroll
          dataLength={data?.length}
          next={() => fetchMoreData(true)}
          inverse
          hasMore
          // loader={<LoadingSpinner />}
          scrollableTarget="chatWindow"
          scrollThreshold={0.8}
        >
          {conversations}
        </InfiniteScroll>
        <span className="ar-chatLoader-absolute">{infiniteloading && <LoadingSpinner />}</span>
      </div>
    </>
  );
};

Conversation.defaultProps = {
  messages: [],
  emojis: [],
  activeUserId: 0,
  isActiveSession: false,
  isRoomToggled: false,
};

Conversation.prototype = {
  // eslint-disable-next-line
  messages: PropTypes.array,
  activeUserId: PropTypes.number,
  isActiveSession: PropTypes.bool,
  isRoomToggled: PropTypes.bool,
  fetchMoreData: PropTypes.func,
};

export default React.memo(Conversation);
