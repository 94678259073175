export function secondsToHms(d) {
  // const h = Math.floor(Number(d) / 3600);
  const m = Math.floor((Number(d)) / 60);
  const s = Math.floor(Number(d) % 60);

  // const hDisplay = h > 0 ? h + (h === 1 ? ': ' : ':') : '';
  const mDisplay = m > 0 ? `${m}:` : '0:';
  const sDisplay = s < 10 ? `0${s}` : `${s}`;
  return mDisplay + sDisplay;
}

export function setMin(durationSeconds) {
  const temp = durationSeconds || '0';
  return secondsToHms(temp);
}
