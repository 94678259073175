import { connect } from 'react-redux';
import FavoriteCustomer from '../scenes/FavoriteCustomer';
import setConversation from '../actions/actionChat';
import getCustomerChatLog from '../actions/actionChatLog';
import { getFavouriteCustomerData, editCustomerNote } from '../actions/actionFavouriteCustomer';
import getCustomerOnlineStatus from '../actions/actionGetCustomersOnlineStatus';
import { editCustomerCallsNote, getCallsData } from '../actions/actionCustomerCallLogs';

const mapFavCustData = (favCustData, data) => favCustData.map((customer) => {
  const index = data?.findIndex(contact => contact.objid === customer.customer_objid);
  console.log('index', index);
  return {
    customerObjid: customer.customer_objid,
    customerName: customer.customer_screen_name,
    custmerNote: customer.notes,
    customerProfilePic: customer.customer_profile_pic,
    isCustomerBlockedByChar: customer.is_blocked_by_char,
    isChatInitiateBlock: customer.chat_initiate_block,
    isPremiumCustomer: customer.is_premium_customer,
    customerHostReview: customer.customer_host_review,
    sendEmailNotification: customer.send_email_notification,
    // eslint-disable-next-line no-nested-ternary
    isCustomerOnline: data ? index !== -1 ? data[index].is_online : customer.is_customer_online
      : customer.is_customer_online,
  };
});

const mapCallsLogData = logData => logData && logData.length && logData.map(customer => ({
  customerObjid: customer.customer2cst_contact_objid,
  customerName: customer.customer_screen_name,
  custmerNote: customer.notes,
  customerProfilePic: customer.customer_profile_pic,
  isCustomerBlockedByChar: customer.is_blocked_by_char,
  isChatInitiateBlock: customer.chat_initiate_block,
  isPremiumCustomer: customer.is_premium_customer,
  customerHostReview: customer.customer_host_review,
  sendEmailNotification: customer.send_email_notification,
  isCustomerOnline: customer.is_customer_online,
}));

const mapSysConfigData = (sysConfigDataRes = []) => {
  const minMessageToInitaiteChat = sysConfigDataRes.find(result => result.config_name === 'PERF_INIT_CHAT_MIN_MSG_PAID') || {};
  return minMessageToInitaiteChat.value;
};

const mapStateToProps = (state) => {
  const {
    favCustomerReducer: {
      data: { count, results = [] } = {},
      isFavCustLoading,
      updateFavCustDataapiLoader,
    } = {},
    callLogs: { data: callLogss, getCallsDataapiLoader: callLogsLoader, count: voiceLogsCOunter },
    login: { data: { perfcharacter = [] } = {} } = {},
    chat: { conv = [] } = {},
    sysConfig: { data: { results: sysConfigResults = [] } = {} } = {},
    customerOnlineStatus,
  } = state;
  const perfCharObjid = (perfcharacter.length && perfcharacter[0].objid) || 0;
  return {
    perfCharObjid,
    callLogsLoader,
    isFavCustLoading,
    updateFavCustDataapiLoader,
    favCustomertData: mapFavCustData(results, customerOnlineStatus.data),
    callsLogData: mapCallsLogData(callLogss),
    minMessageToInitaiteChat: mapSysConfigData(sysConfigResults),
    conversation: conv,
    count,
    totalCallsCount: voiceLogsCOunter,
  };
};
const mapDispatchToProps = dispatch => ({
  favouriteCustomerDataFun: data => dispatch(getFavouriteCustomerData(data)),
  getCallsData: URL => dispatch(getCallsData(URL)),
  editCustomerNote: data => dispatch(editCustomerNote(data)),
  setConversation: data => dispatch(setConversation(data)),
  editCustomerCallsNote: data => dispatch(editCustomerCallsNote(data)),
  getCustomerChatLog: url => dispatch(getCustomerChatLog(url)),
  getCustomerOnlineStatus: data => dispatch(getCustomerOnlineStatus(data)),
});
const FavCustomerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FavoriteCustomer);

export default FavCustomerContainer;
