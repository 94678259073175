/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import SemiCircleProgressBar from 'react-progressbar-semicircle';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalFooter,
  // ModalHeader,
  Row,
  Col,
  Button,
} from 'reactstrap';
import arousrLogo from '../../assets/svgs/ArousrNewLogo.svg';
import './style.scss';


const VideoChatModal = (props) => {
  const {
    open,
    name, toggleModal, rejectRequest, isVoiceCall, isCustomerFavourite,
  } = props;

  const [newReviewObj, setNewReviewObj] = useState(null);
  const customerHostReviewObj = localStorage.getItem('customerHostReview') || null;

  useEffect(() => {
    if (customerHostReviewObj !== null) {
      let str = customerHostReviewObj;
      str = str.replace(/'/g, '"');
      const reviewObj = JSON.parse(str);
      const reviewObjVal = Object.values(reviewObj);
      const stateObj = {
        creative: reviewObjVal[0],
        generous: reviewObjVal[1],
        sexy: reviewObjVal[2],
      };
      setNewReviewObj(stateObj);
    }
  }, [customerHostReviewObj]);

  const acceptCall = () => {
    toggleModal();
  };

  return (
    <Modal
      className="video_chat_modal"
      isOpen={open}
      toggle={toggleModal}
      backdrop={false}
    >
      {/* <ModalHeader className="bdr-btm-none" toggle={toggleModal} /> */}
      <ModalBody className="chat-request">
        <Row className="align-items-center">
          <Col md="2">
            <img className="arousr_logo" src={arousrLogo} alt="logo" />
          </Col>
          <Col md="10 pl-0">
            <h4 className="arg-modal-title">{isVoiceCall ? 'Voice Call' : 'Video Chat'}</h4>
            <span className="arg-modal-desc">
              You have a
              {isVoiceCall ? ' voice ' : ' video '}
              chat request from&nbsp;
              {isCustomerFavourite ? 'favorite customer' : ''}
              &nbsp;
              {name}
            </span>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="bdr-t-none">
        <>
          {newReviewObj
          && (
            <div className="chat-customer-review chat-model">
              <div className="chat-reviews">
                <div className="position-relative">
                  <div className="bar-seprate-line-wrap">
                    <span className="bar-seprate-line bar-line-1" />
                    <span className="bar-seprate-line bar-line-2" />
                    <span className="bar-seprate-line bar-line-3" />
                    <span className="bar-seprate-line bar-line-4" />
                  </div>
                  <SemiCircleProgressBar
                    percentage={newReviewObj.creative * 20}
                    strokeWidth={7}
                    diameter={50}
                    stroke="#ff7167"
                    background="#3E3E3E"
                  />
                  <div className="title_container">
                    <span className="rating_number">
                      {newReviewObj.creative}
                    </span>
                  </div>
                </div>
                <div>
                  <span className="rating_title">Creativity</span>
                </div>
              </div>
              <div className="chat-reviews">
                <div className="position-relative">
                  <div className="bar-seprate-line-wrap">
                    <span className="bar-seprate-line bar-line-1" />
                    <span className="bar-seprate-line bar-line-2" />
                    <span className="bar-seprate-line bar-line-3" />
                    <span className="bar-seprate-line bar-line-4" />
                  </div>
                  <SemiCircleProgressBar
                    percentage={newReviewObj.sexy * 20}
                    strokeWidth={7}
                    diameter={50}
                    stroke="#ff7167"
                    background="#3E3E3E"
                  />
                  <div className="title_container">
                    <span className="rating_number">
                      {newReviewObj.sexy}
                    </span>
                  </div>
                </div>
                <div>
                  <span className="rating_title">Sexiness</span>
                </div>
              </div>
              <div className="chat-reviews">
                <div className="position-relative">
                  <div className="bar-seprate-line-wrap">
                    <span className="bar-seprate-line bar-line-1" />
                    <span className="bar-seprate-line bar-line-2" />
                    <span className="bar-seprate-line bar-line-3" />
                    <span className="bar-seprate-line bar-line-4" />
                  </div>
                  <SemiCircleProgressBar
                    percentage={newReviewObj.generous * 20}
                    strokeWidth={7}
                    diameter={50}
                    stroke="#ff7167"
                    background="#3E3E3E"
                  />
                  <div className="title_container">
                    <span className="rating_number">
                      {newReviewObj.generous}
                    </span>
                  </div>
                </div>
                <div>
                  <span className="rating_title">Generosity</span>
                </div>
              </div>

              {/* <div className="chat-reviews">
                <span>Creative : </span>
                <span>
                  <i className="fa fa-star" />
                  {newReviewObj.creative}
                </span>
              </div>
              <div className="chat-reviews">
                <span>Sexy : </span>
                <span>
                  <i className="fa fa-star" />
                  {newReviewObj.sexy}
                </span>
              </div>
              <div className="chat-reviews">
                <span>Generous : </span>
                <span>
                  <i className="fa fa-star" />
                  {newReviewObj.generous}
                </span>
              </div> */}
            </div>
          )}
          <div className="button-panel">
            <Button onClick={rejectRequest} color="primary" outline>Reject</Button>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}

            <a href={isVoiceCall ? '/#/voice-call' : '/#/video-call'} target="_blank">
              <Button onClick={acceptCall} color="primary text-white">
                Accept
              </Button>
            </a>
          </div>
        </>
      </ModalFooter>
    </Modal>
  );
};

VideoChatModal.defaultProps = {
  open: false,
  name: 'N/A',
  isVoiceCall: false,
  isCustomerFavourite: false,
};

VideoChatModal.propTypes = {
  open: PropTypes.bool,
  name: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  rejectRequest: PropTypes.func.isRequired,
  isVoiceCall: PropTypes.bool,
  isCustomerFavourite: PropTypes.bool,
};

export default VideoChatModal;
