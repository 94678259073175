// const LOCATIONS = [
//   { value: 240, label: 'United States' },
//   { value: 41, label: 'Canada' },
//   { value: 0, label: 'Other (International)' },
// ];

const LOCATIONS = [
  { value: 'United States', label: 'United States' },
  { value: 'Canada', label: 'Canada' },
  { value: 'UK & Euro Zone (IBAN)', label: 'UK & Euro Zone (IBAN)' },
  { value: 'Other(International)', label: 'Other' },
];

export const PREFERENCE_US = [
  { value: 'Direct', label: 'Direct Deposit (USA)' },
  // { value: 'Wire', label: 'Wire Transfer' },
];

export const PREFERENCE_CANADA = [
  { value: 'Direct', label: 'Direct Deposit (Canada)' },
];

export const PREFERENCE_EUROZONE = [
  { value: 'Wise', label: 'Direct Deposit (wise)' },
];

export const PREFERENCE_OTHERS = [
  { value: 'Wire', label: 'Wire Transfer' },
  { value: 'Cosmo', label: 'Cosmo Transfer' },
];

export const PREFERENCES = {
  'United States': PREFERENCE_US,
  Canada: PREFERENCE_CANADA,
  'UK & Euro Zone (IBAN)': PREFERENCE_EUROZONE,
  'Other(International)': PREFERENCE_OTHERS,
};

export const US_ACCOUNT_TYPES = [
  { value: 'savings', label: 'Savings' },
  { value: 'checking', label: 'Checking' },
];

export default LOCATIONS;
