export const actionSetConversationFromLogs = (
  conv, fromLogs = false, callback = null,
) => async (dispatch, getState) => {
  if (!getState().chat?.conv || !getState().chat?.conv?.length) {
    dispatch({
      type: 'SET_CONVERSATION',
      payload: conv,
    });
  }
  if (fromLogs && callback !== null && getState().chat?.conv?.length) {
    callback(conv);
  }
};

const actionSetConversation = conv => async (dispatch) => {
  dispatch({
    type: 'SET_CONVERSATION',
    payload: conv,
  });
};

export const ResetConverstationAction = () => ({
  type: 'CLEAR_CONVERSATION',
});

export default actionSetConversation;
