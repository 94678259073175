import { connect } from 'react-redux';
import EditInfo from '../scenes/EditInfo';
// import { getPerformerInfoAction } from '../actions/actionGetPerformer';

const mapStateToProps = (state) => {
  const {
    login: { data: { perfcharacter = [] } = {} } = {},

  } = state;

  const perfObjid = (perfcharacter.length && perfcharacter[0].objid) || 0;

  return {
    perfObjid,
  };
};

const mapDispatchToProps = () => ({
  // getPerformerInfoFun: data => dispatch(getPerformerInfoAction(data)),
  //   getPhonebookData: data => dispatch(getPhonebookData(data)),
});

const EditInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditInfo);

export default EditInfoContainer;
