import api from './api';


export const getPhonebookData = url => ({
  type: 'GET_PHONEBOOK_DATA',
  payload: api.get(url),
});

export const editCustomerNote = data => ({
  type: 'UPDATE_PHONEBOOK_DATA',
  payload: api.put('/cstnotes/update/', data),
});
