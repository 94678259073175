/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import {
  Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, ButtonGroup,
} from 'reactstrap';
import PropTypes from 'prop-types';
import './style.scss';
import { useCallback } from 'react';
import inputEditIcon from '../../../../assets/images/inputEditIcon.svg';
import api from '../../../../actions/api';
import { showOnlineStatus } from '../../../../utils/helpers';
import crownIcon from '../../../../assets/svgs/icon-crown.svg';
import { useSelector } from 'react-redux';
import NoCustomer from '../../../../assets/svgs/NoCustomer';
import LoadingSpinner from '../../../../utils/LoadingSpinner';


const ForwardMediaModal = (props) => {
  // const { isOpen, sendMedia } = props;
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCustomersFetching, setIsCustomersFetching] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const ReduxState = useSelector((state) => state.performer.data);
  const activeUser = useSelector((state) => state.pastChats?.data);
  // const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [customerList, setCustomerList] = useState([]);

  const toggle = () => {
    // closeShareMediaPopup();
    setModal(!modal);
    props.media.setShowModal(false)
  };

  useEffect(() => {
    let ActiveSessionID = localStorage.getItem('sessionID') ? localStorage.getItem('sessionID') : '';
    const fetchCustomers = async () => {
      try {
        setIsCustomersFetching(true);
        const url = `/api/cfgxmppsessions/list/?product_id=${props.media.newProductID}&character_objid=${ReduxState.characters[0].objid}&status=active&media_forwarding_sessions=true&cust2char__gt=-1&exclude_msg_paths=SESSION_CMD|Sys2Cust`;
        let {data} = await api.get(url);
        console.log({ActiveSessionID}, data.results,  activeUser[0]?.customer2cst_contact_objid);
        
        setCustomerList(data.results.filter((customer) => Number(customer.customer_objid) !== Number(activeUser.length ? activeUser[0]?.customer2cst_contact_objid : ActiveSessionID)))
        setIsCustomersFetching(false);
      } catch (error){
        console.log({error});
        setIsCustomersFetching(false);
      }
    }
    fetchCustomers();
  }, []);
 
  const handleCheckboxChange = (id) => {
    const updatedObjects = customerList?.map((obj) =>
      obj.objid === id ? { ...obj, checked: !obj.checked } : obj
    );
    let isAllTheItemsChecked = updatedObjects.every((obj) => obj.checked);
    if (isAllTheItemsChecked) {
      setSelectAll(isAllTheItemsChecked)
    }
    setCustomerList(updatedObjects);
    setSelectAll(isAllTheItemsChecked)
    if (loading) {
      setLoading(false);
    }
    setErrorMsg('');

  };

  const handleSelectAllChange = () => {
    // Update the state to toggle all checkboxes
    const updatedObjects = customerList.map((obj) => ({ ...obj, checked: !selectAll }));
    // setCustomerList(!selectAll);
    setSelectAll(!selectAll);
    if (loading) {
      setLoading(false);
    }
    setCustomerList(updatedObjects);
    setErrorMsg('');
  };

  const renderCustomerList = (customersList) => {
    if (customersList && customersList.length > 0) {
     return (
     <div className='w-100'>
      <div className='d-flex align-items-center justify-content-between'>
        <span className='ar-session-title'>Active Session</span>
        <label className='ar-label-select-all ar-checkbox'>
          Select All
          <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
        </label>
      </div>
      <div className='ar_active_customer_list'>
      {customersList.map((customer, index) => {
        return (
            <div className='d-flex align-items-center justify-content-between mt-2 ar_active_customer_single' key={customer.objid}>
              <div>
              <label 
                  htmlFor={customer.objid}
                  className='d-flex align-items-center'
                >
                    <div className="chat_img text-center chat-list m-0">
                      {
                      customer.customer_profile_pic && customer.customer_profile_pic !== 'None'
                        ? <img width="50" height="50" src={ customer.customer_profile_pic} alt="customerprofilepic" />
                        : <svg width="50" height="50" data-jdenticon-value="icon value" />
                      }
                      {customer.is_customer_online}
                      <div className="online-user" />
                      {customer.is_premium_customer ? <img src={crownIcon} className="is_premium_customer" alt="crown" /> : ''}
                      {showOnlineStatus(customer.is_customer_online, customer.send_email_notification)}
                    </div>
                  <span className='ar-session-host-name'> {customer.customer_screen_name}</span>
                </label> 
              </div>
              <div className='d-flex align-items-center justify-center'>
              {customer.purchased_product_customer_list.includes(customer.customer_objid) && (
                 <small className='mb-2 text-danger text-stronger'>This Media is Already Shared</small> 
                )}
                  <label className='ar-checkbox'>
                    <input
                      id={customer.objid}
                      type="checkbox"
                      checked={customer.checked || false}
                      onChange={() => handleCheckboxChange(customer.objid)}
                    />
                  </label>
              </div>
            </div>
        )
      }
    )}
      </div>
    </div>
    )} 
    if (!isCustomersFetching) {
      return <div className='d-block w-100 text-center mt-3 pt-3'><NoCustomer /><div className='mt-3'>No Active Customers</div></div>
    }
    
  }

  useEffect(() => {
    setModal(true);
  }, []);

  function isURL(str) {
    // Regular expression for matching URLs
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  
    // Test if the string matches the URL regex
    return urlRegex.test(str);
  }

  // Media Type checking
  const getMediaType = (selectedMediaObj) => {
    console.log({selectedMediaObj});
    let mediaType = '';
    if (selectedMediaObj === "VOICE" || selectedMediaObj === 'media_chat_audio_purchased') {
      return mediaType = 'audio';
    } else {

      const fileName = selectedMediaObj;
      let extension = ''
      if (isURL(selectedMediaObj) ) {
        extension = fileName?.split('.')?.pop();
      } else {
        extension = fileName?.split('/')?.pop();
      }
  
      const extensionUpper = extension?.toUpperCase();
      const allowVideoTypes = ['WEBM', 'MP4', 'M4P', 'M4V', 'AVI', 'WMV', 'MOV', '3GP','QTFF', 'QT', 'QUICKTIME'];
      const allowImageTypes = ['TIFF', 'JPG', 'JPEG', 'PNG', 'BMP', 'JFIF'];
      // const allowedExtensions = /(\.tiff|\.jpg|\.jpeg|\.png|\.bmp|\.jfif|\.mp4|\.avi|\.mov|\.3gp|\.wmv|\.webm)$/i;
  
      if (allowVideoTypes?.includes(extensionUpper) || selectedMediaObj === 'media_chat_video_purchased') {
        return mediaType = 'video';
      }
      else if (allowImageTypes?.includes(extensionUpper) || selectedMediaObj === 'media_chat_image_purchased') {
        return mediaType = 'image';
      }
      return mediaType;
    }
 
  }

  // const selectedFileUrl = URL.createObjectURL(selectedMedia.media);
  const shareMedia = () => {
  setLoading(true);
  const isCustomerChecked = customerList.some((cust) => cust?.checked) ? false : true;

  if (isCustomerChecked) {
    setErrorMsg('Please Select Atleast One Customer to Enable the Share button');
    setSuccessMsg("");
    return;
  } else {
    setErrorMsg('');
  }
    const customerIDs = customerList.length > 0 && customerList.filter((el) => el.checked).map((el) => el.customer_objid);
if (customerIDs.length > 0) {
  const processCustomers = async () => {
    try {
      for (const customerId of customerIDs) {
        await props.media.SubmitForwardRequest(props.media.msg, customerId, props.media.newProductID);
        setSuccessMsg('Media Shared Successfully');
        setErrorMsg("");
      }
    } catch (error) {
      setError(true);
      setLoading(false);
      setErrorMsg(error);
      setSuccessMsg("");
    }
  };
  processCustomers();
  setTimeout(() => {
    setModal(!modal);
    props.media.setShowModal(false)
  }, 2000);
}
   
    // closeShareMediaPopup();
  }

  // const renderMediaType = useCallback(() => { 
  //   switch (getMediaType(selectedMedia)) {
  //     case 'video':
  //       return (<video src={selectedFileUrl} width="100%" controls></video>);
  //     case 'audio':
  //       return (
  //       <>
  //         <div className='ar-audio-pop-head'>
  //           <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_4091_6761)"><path d="M37.4176 23.2603C37.4176 22.383 36.7065 21.6719 35.8292 21.6719C34.952 21.6719 34.2408 22.383 34.2408 23.2603C34.2399 29.4684 29.2065 34.5002 22.9984 34.4993C16.7916 34.4984 11.7603 29.467 11.7594 23.2603C11.7594 22.383 11.0482 21.6719 10.1709 21.6719C9.29369 21.6719 8.58252 22.383 8.58252 23.2603C8.5909 30.6046 14.1129 36.7715 21.4117 37.5878V41.8236H15.6352C14.7579 41.8236 14.0468 42.5348 14.0468 43.4121C14.0468 44.2893 14.7579 45.0005 15.6352 45.0005H30.3651C31.2423 45.0005 31.9535 44.2893 31.9535 43.4121C31.9535 42.5348 31.2423 41.8236 30.3651 41.8236H24.5886V37.5878C31.8872 36.7715 37.4093 30.6046 37.4176 23.2603Z" fill="#FD7663"/><path d="M22.9997 0C18.1076 0 14.1416 3.96588 14.1416 8.85814V23.2016C14.1474 28.0914 18.11 32.0539 22.9997 32.0597C27.8895 32.054 31.852 28.0914 31.8579 23.2016V8.85814C31.8579 3.96588 27.892 0 22.9997 0Z" fill="#FD7663"/></g><defs><clipPath id="clip0_4091_6761"><rect width="45" height="45" fill="white" transform="translate(0.5)"/></clipPath></defs></svg>
  //           <span className='media-share-pop-title'>Audio</span>
  //         </div>
  //         <audio className='audioPlayer' preload="auto" controls controlsList="nodownload noplaybackrate">
  //           <source src={selectedFileUrl} />
  //         </audio>
  //       </>
  //       );
  //     case 'image':
  //       return (<img src={selectedFileUrl} className="mb-3"/>)
  //   }
  // }, [selectedMedia])


  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="sharePrivateMedia-modal forwardMediaModal" data="public modal">
        <ModalHeader className="chat-modal-header camHeader" toggle={toggle}>Forward Media</ModalHeader>
        <ModalBody className="chat-modal-body paid-pic-modal">
          <div className='image-wrap'>      
            {(props.media.mediaType) === 'image' ?
              <img src={props.media.media}/> : (props.media.mediaType) === 'video' ? (
                <iframe
                src={props.media.media}
                title={`video_${props.media.videoId}`}
                // style={{"border: none;"}}
                height="300"
                width="300"
                allowFullScreen={`${props.media.videoId}`}
              />
              ) : (
                <audio className="chat_audio" preload="auto" controls controlsList="nodownload noplaybackrate">
                <source src={props.media.media} />
              </audio>
              )
             
            }
            {/* {renderMediaType()} */}
            <div className='mt-3'>{props.media.creditValue} Credits</div>
          </div>
          <div className="mt-3 mb-0">
          {isCustomersFetching ? (<div><LoadingSpinner customHeight={'height-200'} /></div>) : renderCustomerList(customerList)}
          </div>
          { errorMsg && <small className='text-danger text-stronger d-block mt-2'>{errorMsg}</small> }
          { successMsg && <small className='text-success text-stronger d-block mt-2'>{successMsg}</small> }
          {/* {customerList.some((cust) => !cust?.checked) ? <small className='text-danger text-stronger d-block mt-2'>Please Select Atleast One Customer to Enable the Share button</small> : '' } */}
          { customerList.length > 0 ? ( <ButtonGroup>
            <Button className="modal-btn" onClick={shareMedia} disabled={loading}>Share</Button>
          </ButtonGroup>) : ''}
        </ModalBody>
        <ModalFooter className="chat-modal-footer camFooter" />
      </Modal>
    </div>
  );
}

ForwardMediaModal.defaultProps = {
  isOpen: false,
};

ForwardMediaModal.propTypes = {
  isOpen: PropTypes.bool,
};

export default ForwardMediaModal;
