import api from './api';

const getCustomerOnlineStatus = customerIds => (
  async (dispatch) => {
    try {
      dispatch({
        type: 'FETCH_CUSTOMER_ONLINE_STATUS_LOADING',
      });
      const { data } = await api.get('api/customer/online_customer_status/', { params: { objids: customerIds.join(',') } });
      dispatch({
        type: 'FETCH_CUSTOMER_ONLINE_STATUS_SUCCESS',
        payload: data.results,
      });
    } catch (error) {
      dispatch({
        type: 'FETCH_CUSTOMER_ONLINE_STATUS_FAILED',
      });
    }
  });

export default getCustomerOnlineStatus;
