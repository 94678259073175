import React from 'react';
import chat from '../../../../../assets/svgs/message';
import './style.scss';

const InitialScreen = () => (
  <div className="IntialScreen">
    <div className="text-center">{chat()}</div>
    <div className="mt-3"> No conversations to show </div>
  </div>
);

export default InitialScreen;
