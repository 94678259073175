import React, { useEffect, useState } from 'react';

const AccessGuide = () => {
  const [browser, setBrowser] = useState('');

  const detectBrowser = () => {
    const { userAgent } = window.navigator;
    if (userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Edg') === -1 && userAgent.indexOf('OPR') === -1) {
      setBrowser('chrome');
    } else if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1 && userAgent.indexOf('Edg') === -1 && userAgent.indexOf('OPR') === -1) {
      setBrowser('safari');
    } else if (userAgent.indexOf('Firefox') > -1 && userAgent.indexOf('Seamonkey') === -1 && userAgent.indexOf('OPR') === -1) {
      setBrowser('firefox');
    } else if ((userAgent.indexOf('Edg') > -1 || userAgent.indexOf('Edge') > -1) && userAgent.indexOf('OPR') === -1) {
      setBrowser('edge');
    } else {
      setBrowser('unknown');
    }
  };

  useEffect(() => {
    detectBrowser();
  }, []);

  const renderNotificationsInstructions = () => {
    switch (browser) {
      case 'chrome':
        return (
          <div>
            <p className="text-orange">To Enable Notifications Permissions In Chrome:</p>
            <ol>
              <li>Click on the View site information icon just left to the site URL.</li>
              <li>Go to Site settings.</li>
              <li>Under The Permissions, Find Notifications And Select Allow.</li>
            </ol>
          </div>
        );
      case 'safari':
        return (
          <div>
            <p className="text-orange">To Enable Notifications Permissions In Safari:</p>
            <ol>
              <li>
                Go to Safari
                {'>'}
                {' '}
                Preferences.
              </li>
              <li>Click on the Websites tab.</li>
              <li>Find Notifications in the sidebar </li>
              <li>You will see the list of websites in the Right section </li>
              <li>Find chat.arousr.com and</li>
              <li>Select Allow. </li>
            </ol>
          </div>
        );
      case 'firefox':
        return (
          <div>
            <p className="text-orange">To Enable Notifications Permissions In Firefox:</p>
            <ol>
              <li>Click on the lock icon left to the URL.</li>
              <li>Click on More Information.</li>
              <li>Go to Permissions Tab And Select Allow for Receive Notifications.</li>
            </ol>
          </div>
        );
      case 'edge':
        return (
          <div>
            <p className="text-orange">To Enable Notifications Permissions In Edge:</p>
            <ol>
              <li>Click on the lock icon left to the URL.</li>
              <li>
                {'If you don\'t find Notfications under "Permissions for this site" Click on Permissions for this site'}
              </li>
              {' '}
              <li>
                You will be redirected to a new tab and should
                see the list of items that required permissions
              </li>
              <li>Under The Notifications, Select Allow.</li>
            </ol>
          </div>
        );
      default:
        return <p>Notifications Are Not Supported In This browser.</p>;
    }
  };

  const renderCameraMicrophoneInstructions = () => {
    switch (browser) {
      case 'chrome':
        return (
          <div>
            <p className="text-orange">To Enable Camera And Microphone Permissions In Chrome:</p>
            <ol>
              <li>Click on the View site information icon just left to the site URL.</li>
              <li>Go to Site settings.</li>
              <li>Under The Permissions, Find Camera and Microphone And Select Allow.</li>
            </ol>
          </div>
        );
      case 'safari':
        return (
          <div>
            <p className="text-orange">To Enable Camera And Microphone Permissions In Safari:</p>
            <ol>
              <li>
                Go to Safari
                {'>'}
                {' '}
                Preferences.
              </li>
              <li>Click on the Websites tab.</li>
              <li>Find Camera and Microphone in the sidebar.</li>
              <li>You will see the list of websites in the Right section </li>
              <li>Find chat.arousr.com and</li>
              <li>Select Allow. </li>

            </ol>
          </div>
        );
      case 'firefox':
        return (
          <div>
            <p className="text-orange">To Enable Camera And Microphone Permissions In Firefox:</p>
            <ol>
              <li>Click On the lock icon left to the URL.</li>
              <li>Click On More Information.</li>
              <li>Go To The Permissions Tab And Select Allow for Camera and Microphone.</li>
            </ol>
          </div>
        );
      case 'edge':
        return (
          <div>
            <p className="text-orange">To Enable Camera And Microphone Permissions In Edge:</p>
            <ol>
              <li>Click on the lock icon left to the URL.</li>
              <li>
                {'If you don\'t find Camera / Microphone under "Permissions for this site" Click on Permissions for this site'}
              </li>
              {' '}
              <li>
                You will be redirected to a new tab and should
                see the list of items that required permissions
              </li>
              <li>Under The Camera and Microphone, Select Allow.</li>
            </ol>
          </div>
        );
      default:
        return <p>Camera and microphone access are not supported in this browser.</p>;
    }
  };

  return (
    <div>
      <details className="ar_host_notification_setup_box">
        <summary className="ar_host_notification_setup_btn">HOW TO ALLOW YOUR BROWSER TO USE NOTIFICATIONS, CAMERA, AND MICROPHONE</summary>
        <p>
          <h3>Notifications</h3>
          {renderNotificationsInstructions()}
          <h3>Camera and Microphone</h3>
          {renderCameraMicrophoneInstructions()}
        </p>
      </details>
    </div>
  );
};

export default AccessGuide;
