import api from './api';

const getPerformer = objectId => ({
  type: 'GET_PERFORMER',
  payload: api.get(`/perfperformer/get/${objectId}/`),
});


export const updatePerformerData = data => ({
  type: 'UPDATE_GET_PERFORMER',
  payload: data,
});

export default getPerformer;
