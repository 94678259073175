import React from 'react';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import PropTypes from 'prop-types';

// import arousrLogo from '../../assets/svgs/logo.svg';
import './style.scss';

const ViewAbleInfoModal = (props) => {
  const {
    className,
    open,
    toggle,
    header,
    body,
  } = props;

  return (
    <Modal
      className={`chat-modal ${className}`}
      isOpen={open}
      toggle={toggle}
      backdrop={false}
    >
      <ModalHeader className="chat-modal-header" toggle={toggle}>
        <h4>{header}</h4>
      </ModalHeader>
      <ModalBody>
        <div>
          {body}
        </div>
      </ModalBody>
    </Modal>
  );
};

ViewAbleInfoModal.defaultProps = {
  className: '',
  open: false,
  header: '',
  body: '',
};

ViewAbleInfoModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  header: PropTypes.string,
  body: PropTypes.string,
};

export default ViewAbleInfoModal;
