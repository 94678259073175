import { useState, useEffect } from 'react';
// import sanitizeHTML from 'sanitize-html';
import marked from 'marked';

// import defaultOptions from './defaultOptions'

const useMarked = (markdown) => {
  const [html, setHtml] = useState(markdown);
  useEffect(() => {
    // if (options.markedOptions) {
    //   marked.setOptions(options.markedOptions)
    // }
    const tokens = marked.lexer(markdown);
    const htmlparse = marked.parser(tokens);
    // setHtml(sanitizeHTML(htmlparse));
    setHtml(htmlparse);
    // setHtml(
    //   options.skipSanitize ? html : sanitizeHTML(html, options.sanitizeOptions)
    // )
  }, [markdown]);

  return html;
};

export default useMarked;
