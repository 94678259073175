import { connect } from 'react-redux';
import CustomerProfile from '../scenes/CustomerProfile/index';
import { fetchProfileFeedData, fetchUpdateProfileData } from '../actions/actionCustomerProfile';
import { cutomerImageLike, cutomerImageDislike, customerAllImagesLike } from '../actions/actionCustomerImageLike';

const getCustomerPhotos = photosData => photosData.map((photoDate) => {
  const {
    image_url: image,
    objid: id,
    is_liked: isLiked,
  } = photoDate;
  return {
    id,
    image,
    isLiked,
  };
});

const getCustomerProfileData = (customerProfileList) => {
  const {
    bio: aboutCustomer,
    customer_profile_pic_url: profileImage,
    screen_name: userName,
    isliked_all: likeAll,
  } = customerProfileList;
  return ({
    aboutCustomer,
    profileImage,
    userName,
    likeAll,
  });
};

const mapStateToProps = (state) => {
  const {
    login: { data: { perfcharacter = [] } = {} } = {},
    customerProfile: { data: customerProfileData = {}, isLoading } = {},
  } = state;
  const { results = [] } = customerProfileData;

  const performerObjId = (perfcharacter.length && perfcharacter[0].objid) || 0;
  return {
    customerPicture: getCustomerPhotos(results),
    customerProfileData: getCustomerProfileData(customerProfileData),
    performerObjId,
    loadingData: isLoading,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatchProfileData: data => dispatch(fetchProfileFeedData(data)),
  fetchUpdateProfileData: data => dispatch(fetchUpdateProfileData(data)),
  cutomerImageLike: data => dispatch(cutomerImageLike(data)),
  cutomerImageDislike: data => dispatch(cutomerImageDislike(data)),
  customerAllImagesLike: data => dispatch(customerAllImagesLike(data)),
});

const CustomerProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerProfile);

export default CustomerProfileContainer;
