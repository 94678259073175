import { connect } from 'react-redux';
// import FeedMenu from '../scenes/Feed';
import fetchFeedData from '../actions/actionGetFeed';
import postCustomerPhotoLike from '../actions/actionPostLike';
import FeedMenu from '../scenes/Feed';
import { cutomerImageLike, cutomerImageDislike } from '../actions/actionCustomerImageLike';

const getCustomerFeedData = customerList => customerList.map((showFeed) => {
  const {
    customer: id,
    image_url: image,
    screen_name: name,
    customer_bio: bio,
    like_count: likeCount,
    is_liked: isLiked,
    objid: imageId,
  } = showFeed;
  return {
    id,
    image,
    name,
    bio,
    likeCount,
    isLiked,
    imageId,
  };
});
const mapStateToProps = (state) => {
  const {
    feed: { data: { results = [] } = {}, isLoading } = {},
    login: { data: { perfcharacter = [] } = {} } = {},
  } = state;

  const performerObjId = (perfcharacter.length && perfcharacter[0].objid) || 0;

  return {
    customerFeedData: getCustomerFeedData(results),
    performerObjId,
    loadingData: isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  getFeedData: data => dispatch(fetchFeedData(data)),
  postLike: data => dispatch(postCustomerPhotoLike(data)),
  cutomerImageDislike: data => dispatch(cutomerImageDislike(data)),
  cutomerImageLike: data => dispatch(cutomerImageLike(data)),
});
const FeedContainer = connect(mapStateToProps, mapDispatchToProps)(FeedMenu);

export default FeedContainer;
