import React, { useState, useEffect } from 'react';
// import 'react-dropzone-uploader/dist/styles.css';
// import Dropzone from 'react-dropzone-uploader';
import { Field } from 'redux-form';
import {
  Col, Row, Form,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {
  renderSelectField,
  renderField,
} from '../../../../utils/formUtils';
import {
  required, isValueSelected,
  maxFileSize, number, maxChar40,
  minChar15,
  maxChar150,
} from '../../../../utils/formUtils/validator';
import DragAndDrop from '../../../../components/DragAndDrop';
import './style.scss';

const AddMediaFile = (props) => {
  const {
    objectId,
    createNewMedia,
    handleSubmit,
    change,
    formValues: {
      title = '',
      description = '',
      credit = 0,
      mediaItem,
      mediaType,
    },
    resetMediaFormValues,
    handleAddMediaClick,
    halndleAddMediaModal,
    halndleAddPrivateMedia,
  } = props;

  const typeOptions = [
    {
      name: 'Select',
      value: '',
    },
    {
      name: 'Public',
      value: 'image',
    },
    {
      name: 'Premium',
      value: 'premium',
    },
    {
      name: 'Private',
      value: 'private',
    },
  ];

  const [isDisableDone, setisDisableDone] = useState(false);
  const [accessorOptions, setAccessorOptions] = useState(typeOptions);

  useEffect(() => {
    const tempOption = [...typeOptions];
    if (mediaItem === 'video') {
      const privateOption = typeOptions.findIndex(type => (type.name === 'Private'));
      if (privateOption !== 0) {
        tempOption.splice(privateOption, 1);
      }
    }
    setAccessorOptions(tempOption);
  }, [mediaItem]);

  const handleUploadNewMedia = (formValue) => {
    setisDisableDone(true);
    let accessorType = mediaItem;
    if (mediaType === 'private') {
      accessorType = 'private';
    }
    const formData = new FormData();
    formData.set('character', objectId);
    formData.set('description', formValue.description);
    formData.set('price', credit);
    formData.set('product_name', formValue.title);
    formData.set('type', accessorType);
    formData.set('file', formValue.imageToUpload);
    createNewMedia(formData).then(() => {
      setisDisableDone(false);
      if (accessorType === 'private') {
        halndleAddPrivateMedia(true);
      } else {
        halndleAddMediaModal(true);
      }
      resetMediaFormValues();
      handleAddMediaClick();
    }).catch(() => {
      setisDisableDone(false);
    });
  };


  const mediaOptions = [
    {
      name: 'Select',
      value: '',
    },
    {
      name: 'Photo',
      value: 'image',
    },
    {
      name: 'Video',
      value: 'video',
    },
  ];

  const renderAddMediaForm = () => {
    let titleChar = 0;
    let disChar = 0;
    if (title) {
      titleChar = title.length;
    }
    if (description) {
      disChar = description.length;
    }
    return (
      <>
        <Field
          type="text"
          label="Title"
          name="title"
          validate={[required, minChar15, maxChar40]}
          component={renderField}
          placeholder="Enter Title Here"
        />
        <span className="pull-right mt-1" style={{ fontSize: '0.75rem' }}>{`${titleChar}/40`}</span>
        <Field
          type="textarea"
          label="Description"
          name="description"
          component={renderField}
          validate={[required, minChar15, maxChar150]}
          placeholder="Enter Description Here"
        />
        <div className="pull-right mt-1" style={{ fontSize: '0.75rem' }}>{`${disChar}/150`}</div>
        {
          mediaType === 'premium' ? (
            <Field
              type="text"
              label="Price(Credits)"
              name="credit"
              component={renderField}
              validate={[required, number]}
              defaultValue="0"
              placeholder="Enter Credits Here"
            />
          )
            : ''
        }
        <Row className="mt-4">
          <Col>
            <Field
              type="select"
              label="Media"
              name="mediaItem"
              component={renderSelectField}
              options={mediaOptions}
              validate={[isValueSelected, required]}
              defaultSelected="Select"
            />
          </Col>
          <Col>
            <Field
              type="select"
              label="Type"
              name="mediaType"
              component={renderSelectField}
              options={accessorOptions}
              validate={[isValueSelected, required]}
              defaultSelected="Select"
            />

          </Col>
        </Row>
      </>
    );
  };

  // set image value in redux
  const handleOnDrop = (file) => {
    if (file.length) {
      change('imageToUpload', file[0]);
    }
  };

  return (
    <>
      <Form className="myForm" onSubmit={handleSubmit(handleUploadNewMedia)}>
        <Row>
          <Col>
            <div className="dropArea h-100 mt-3">
              <Field
                name="imageToUpload"
                component={DragAndDrop}
                type="file"
                handleOnDrop={handleOnDrop}
                validate={[required, maxFileSize]}
                fileType={mediaItem}
              />
            </div>
          </Col>
          <Col>
            {renderAddMediaForm()}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3 mb-2">
              {isDisableDone ? (
                <Button type="submit" color="primary" disabled={isDisableDone} className="addMediaDone text-white">
                  Loading
                  <i className="fa fa-spinner fa-spin" />
                </Button>
              ) : (
                <Button type="submit" color="primary" className="addMediaDone text-white">Done</Button>
              )
              }
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

AddMediaFile.defaultProps = {
  objectId: -1,
  formValues: {},
};

AddMediaFile.propTypes = {
  objectId: PropTypes.number,
  createNewMedia: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  formValues: PropTypes.instanceOf(Object),
  resetMediaFormValues: PropTypes.func.isRequired,
  handleAddMediaClick: PropTypes.func.isRequired,
  halndleAddMediaModal: PropTypes.func.isRequired,
  halndleAddPrivateMedia: PropTypes.func.isRequired,
};

export default AddMediaFile;
