import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PortalHeader from '../components/PortalHeader';

import logoutPerf from '../actions/actionLogoutPerf';
import putStatusUpdate from '../actions/actionPutStatus';

const getIds = ({ perfperformer = {}, objid, perfcharacter = [] }) => ({
  perfPerformerObjId: perfperformer.objid,
  cfgObjid: objid,
  charId: perfcharacter[0].objid,
});
export const mapStateToProps = state => ({
  data: {
    notifications: 2,
    messages: 1,
  },
  ids: (state.login.data) ? getIds(state.login.data) : {},
});

export const mapDispatchToProps = dispatch => ({
  logout: sendData => dispatch(logoutPerf(sendData)),
  updateStatus: sendData => dispatch(putStatusUpdate(sendData)),
});

const PortalHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PortalHeader);

export default withRouter(PortalHeaderContainer);
