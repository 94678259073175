/* eslint-disable */

import React from 'react';

export default () => (
<svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M12.1214 6.82788C11.5848 9.29433 8.97371 11.1658 5.83348 11.1658C5.38051 11.1658 4.93853 11.1269 4.51221 11.0528C5.37753 11.8034 6.64432 12.2769 8.05571 12.2769C8.83215 12.2769 9.56487 12.1336 10.211 11.8797C10.5254 11.7562 11.5748 12.1028 12.2305 12.3439C12.4506 12.4249 12.678 12.2165 12.6176 11.9899C12.4488 11.3564 12.2263 10.3838 12.3739 10.1296C12.6336 9.68216 12.7779 9.18688 12.7779 8.66583C12.7779 7.99466 12.5385 7.36627 12.1214 6.82788Z" fill="#FD7663"/>
<path d="M11.1112 5.88908C11.1112 8.19025 8.74827 10.0557 5.83344 10.0557C5.03956 10.0557 4.28663 9.91736 3.61122 9.66952C3.25246 9.53786 1.90131 9.91502 1.12111 10.1537C0.895731 10.2227 0.681525 9.99852 0.757692 9.77547C0.985064 9.10963 1.2996 8.04897 1.11122 7.75191C0.75572 7.19136 0.555664 6.55863 0.555664 5.88908C0.555664 3.58789 2.9186 1.72241 5.83344 1.72241C8.74827 1.72241 11.1112 3.58789 11.1112 5.88908Z" fill="#FD7663"/>
</svg>

);
