import api from './api';

export const cutomerImageLike = data => ({
  payload: api.post('/customermedialike/create/', data),
});

export const cutomerImageDislike = data => ({
  payload: api.post('/customermedialike/delete/', data),
});

export const customerAllImagesLike = data => ({
  payload: api.post('/customermedialike/bulk-create/', data),
});
