export default function (state = [], action = {}) {
  switch (action.type) {
    case 'GET_PERFORMER_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'GET_PERFORMER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        data: { ...action.payload.data },
        error: false,
      };

    case 'GET_PERFORMER_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case 'UPDATE_GET_PERFORMER':
      return {
        ...state,
        isLoading: false,
        data: { ...action.payload },
        error: false,
      };

    default:
      return {
        ...state,
      };
  }
}
