export default function (state = [], action = {}) {
  switch (action.type) {
    case 'MISSED_CALL_NOTIFICATION':
      return {
        ...state,
        data: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
