/* eslint-disable react/prop-types */
import React from 'react';
import { Input, Label } from 'reactstrap';

const PaymentMethodsSelection = ({ paymentOption, setPaymentOption }) => (
  <div className="ar_host_payment_radio_box">
    <span className="ar_head_text mb-4 d-block">If you have a personal account with one of these Payment Providers, please select.</span>
    <div className="d-flex align-items-center">
      <div className="form__radio-group">
        <Input id="cosmo" type="radio" className="form__radio-input" value="Cosmo" checked={paymentOption === 'Cosmo'} name="Cosmo" onChange={e => setPaymentOption(e.target.value)} />
        <Label htmlFor="cosmo" className="form__radio-label">
          <span className="form__radio-label-text">Cosmo Payment</span>
          <span className="form__radio-button" />
        </Label>
      </div>
      <div className="form__radio-group">
        <Input id="no-ac" type="radio" className="form__radio-input" name="Others" checked={paymentOption === 'Others'} value="Others" onChange={e => setPaymentOption(e.target.value)} />
        <Label htmlFor="no-ac" className="form__radio-label">
          <span className="form__radio-label-text">{'I don\'t have these'}</span>
          <span className="form__radio-button" />
        </Label>
      </div>
    </div>
  </div>
);

export default PaymentMethodsSelection;
