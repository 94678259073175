import { connect } from 'react-redux';
import getPerformer from '../actions/actionGetPerformer';
import getPaymentHistory, { getTips, getTransactions, refundTip } from '../actions/balanceActions';
import { getPayoutDetails } from '../actions/actionPayoutSettings';

import Balance from '../scenes/Balance';

const getMinPayoutBalance = (sysConfig = []) => {
  const minBalanceObj = sysConfig.find(result => result.config_name === 'MINIMUM_BALANCE_REQUEST_PAYMENT');
  return minBalanceObj.value;
};

export const mapStateToProps = (state) => {
  const loadingPerfCharacter = state.perfCharacter && state.perfCharacter.isLoading;
  const loadingPerfPerformer = state.perfPerformer && state.perfPerformer.isLoading;
  const loadingProfileData = state.login && state.login.isLoading;
  const loadingBalanceData = state.balance && state.balance.isLoading;
  const loadingData = (loadingPerfCharacter
    || loadingPerfPerformer || loadingProfileData || loadingBalanceData
  );
  const dashboardData = (state.login && state.login.data) || {};
  const balanceData = (state.balance && state.balance.data) || {};
  const { sysConfig: { data: { results: sysConfigResults = [] } = {} } = {} } = state;
  const minPayoutBalance = sysConfigResults.length ? getMinPayoutBalance(sysConfigResults) : 0;
  return ({
    loadingData,
    dashboardData,
    balanceData,
    minPayoutBalance,
  });
};

export const mapDispatchToProps = dispatch => ({
  getPerformer: objectId => dispatch(getPerformer(objectId)),
  getPaymentHistory: objectId => dispatch(getPaymentHistory(objectId)),
  getTransactions: objectId => dispatch(getTransactions(objectId)),
  getTips: objectId => dispatch(getTips(objectId)),
  refundTip: objectId => dispatch(refundTip(objectId)),
  getPayoutDetails: data => dispatch(getPayoutDetails(data)),
});

const BalanceContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Balance);

export default BalanceContainer;
