/* eslint-disable */
import React from 'react';
import {
  Alert,
} from 'reactstrap';
import './style.scss';

class CustomAlert extends React.PureComponent {
  render() {
    const { visible = false, dismissAlert, message = '' } = this.props;
    return (
      <Alert className="customAlert" isOpen={visible} toggle={dismissAlert}>
        {message}
      </Alert>
    );
  }
}

CustomAlert.defaultProps = {

};

CustomAlert.propTypes = {
  // handleSubmit: PropTypes.func.isRequired,
  // screenName: PropTypes.string,
  // isLoading: PropTypes.bool,
};

export default CustomAlert;
