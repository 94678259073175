import React, { useEffect, useState } from 'react';
import domtoimage from 'dom-to-image';

import PropTypes from 'prop-types';
import {
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import api from '../../actions/api';
import companyLogo from '../../assets/images/logo.png';
import girlAvatar from '../../assets/images/provider_female_avatar.jpg';
import LoadingSpinner from '../../utils/LoadingSpinner';
import './style.scss';


const MyInfo = (props) => {
  const {
    perfObjid,
  } = props;
  const [performerData, setPerformerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    api.get(`/perfcharacter/list/?objid=${perfObjid}`)
      .then((response) => {
        setPerformerData(response.data.results);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const exportPDF = () => {
    domtoimage.toJpeg(document.getElementById('performer-profile'), { quality: 0.95 }).then((dataUrl) => {
      const link = document.createElement('a');
      link.download = 'arousr-performer-profile.jpeg';
      link.href = dataUrl;
      link.click();
    });
  };

  const renderPerformerData = performerData.map((performer) => {
    const defaultNum = performer.cstcontact.cstphonenumbers;
    const defN = defaultNum.filter(num => num.default === true);
    return (
      <>
        <CardBody key={performer.objid} id="performer-profile">
          <h5 className="my-0 mr-md-auto font-weight-normal companyLogoCls">
            <img src={companyLogo} alt="LOGO" />
          </h5>
          <CardHeader className="customCardHeader pl-0"><h2>Your current information on Platform:</h2></CardHeader>
          <Row className="customCardHeaderList">
            <Col xs={4} className="text-left ">
              {performer.primary_pic_url ? <img className="rounded-circle performer-img" src={performer.primary_pic_url} alt="LOGO" /> : <img className="rounded-circle performer-img" src={girlAvatar} alt="noImage" />}
            </Col>
            <Col xs={8} className="">
              <table className="my-info-table">
                <tr>
                  <td>First Name</td>
                  <td>
                    {performer.cstcontact.first_name}
                  </td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>
                    {performer.cstcontact.last_name}
                  </td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>
                    {performer.cstcontact.cstaddresses[0].address_1}
                    &nbsp;
                    {performer.cstcontact.cstaddresses[0].address_2}
                    &nbsp;
                    {performer.cstcontact.cstaddresses[0].city}
                    &nbsp;
                    {performer.cstcontact.cstaddresses[0].zipcode}
                  </td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>
                    {performer.cstcontact.cstaddresses[0].country.country_name}
                  </td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>
                    {defN.length > 0 ? defN[0].number : '-'}
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>
                    {performer.cstcontact.primary_email_addr}
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </CardBody>
        <CardBody>
          <Row className="customCardHeaderList">
            <Col xs={4} className="text-left" />
            <Col xs={8} className="">
              <Row className="customCardHeaderList">
                <Col xs={12} className="">
                  <h6 className="text-primary mb-0">
                    To update, please send an email
                    <u>(applications@telepass.cc)</u>
                  </h6>
                </Col>
              </Row>
              <Row className="customCardHeaderList mt-3">
                <Col xs={12} className="">
                  <Button onClick={exportPDF} className="download-btn">Download Info</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </>
    );
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <section className="mt-3">
        <Card className="customCard bg-dark pt-3 pl-3">
          {renderPerformerData}
        </Card>
      </section>
    </>
  );
};

MyInfo.defaultProps = {
  perfObjid: 0,
};

MyInfo.propTypes = {
  perfObjid: PropTypes.number,
};

export default MyInfo;
