/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,ButtonGroup
} from 'reactstrap';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import './style.scss';

const CameraModal = (props) => {
  const { modalOpen, toggle, UploadImageFunction } = props;
  const [isSnap, setSnap] = useState(false);
  const [isCameraPresence, setCameraPresence] = useState(true);
  const [stream, setStream] = useState(null);

  useEffect(() => {
    handleCameraClick();
  }, [])

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  const onSnapPhotoClick = () => {
    setSnap(!isSnap);
    const canvas = document.getElementById('canvas');
    const context = canvas.getContext('2d');
    // const video = document.getElementById('videoElement');
    const videoElement = document.getElementById('videoElement');
    videoElement.controls = false;
    context.drawImage(videoElement, 0, 0, 640, 480);
  }


  const handleCameraClick = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const video = document.querySelector('#videoElement');
      video.controls = false;
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          video.srcObject = stream;
          setCameraPresence(true);
          setStream(stream);
        })
        .catch(error => {
          setCameraPresence(false);
        });
    }
  };

  const onSendClick = () => {
    const canvas = document.getElementById('canvas');
    const imageURL = canvas.toDataURL();
    const file = dataURLtoFile(imageURL, 'sendImage.jpeg');
    UploadImageFunction(file);
    onToggle();
  }

  const onRetryClick = () => {
    setSnap(!isSnap);
    const canvas = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  const onToggle = () => {
    let tracks;
    tracks = stream ? stream.getTracks() : [];

    tracks.forEach(function(track) {
      track.stop();
    });
    setStream({});
    const video = document.querySelector('#videoElement') || {};
    video.controls = false;
    video.srcObject = null;
    toggle();
  }

  return (
    <div>
      <Modal isOpen={modalOpen} toggle={onToggle} className="camera-modal">
        <ModalHeader className="chat-modal-header camHeader" toggle={onToggle} />
        <ModalBody className="chat-modal-body camModel">
          {(isCameraPresence)
            ? <video className="videoElement" id="videoElement" width="640" height="480" autoPlay playsInline></video>
            : <div>
                <p className="no-camera"> No camera found on your device. </p>
                <p className="no-camera">Tip : Allow camera permission for arousr if not done currently.</p>
              </div>
          }
          <ButtonGroup>
            {(isSnap)
              ? <>
                  <button className="modal-btn mr-2" onClick={onSendClick}>Share</button>
                  <button className="modal-btn ml-2" onClick={onRetryClick}>Retry</button>
                </>
              : (
                isCameraPresence ? (
                  <>
                    <button className="modal-btn mr-2" disabled={!isCameraPresence} id="snap" onClick={onSnapPhotoClick}>Snap</button>
                    <button className="ml-2 modal-btn" onClick={onToggle}>Cancel</button>
                  </>

                ) : ''
              )
            }
            {isCameraPresence ? '' : <button className="ml-2 modal-btn" onClick={onToggle}>Okay</button>}
            
          </ButtonGroup>
          {isCameraPresence && 
            <canvas id="canvas" className={(isSnap) ? 'canvas active' : 'canvas'} width="640" height="480"></canvas>
          }
        </ModalBody>
        <ModalFooter className="chat-modal-footer camFooter" />
      </Modal>
    </div>
  );
}

CameraModal.defaultProps = {
  modalOpen: false,
};

CameraModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

export default CameraModal;
