/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import {
  Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, ButtonGroup,
} from 'reactstrap';
import PropTypes from 'prop-types';
import './style.scss';
import { useCallback } from 'react';
import inputEditIcon from '../../../../assets/images/inputEditIcon.svg';

const shareMediaModal = (props) => {
  const ref = useRef('');
  const { isOpen, sendMedia, closeShareMediaPopup, selectedMedia, minImagePurchaseCredits,
  minVideoPurchaseCredits, minAudioPurchaseCredits, performerMediaPrices = {}, videoDuration } = props;

  const [modal, setModal] = useState(false);
  // const [imageCredits, setImageCredits] = useState(minImagePurchaseCredits);
  // const [videoCredits, setVideoCredits] = useState(minVideoPurchaseCredits);
  // const [audioCredits, setAudioCredits] = useState(minAudioPurchaseCredits);
  const [imageCredits, setImageCredits] = useState(performerMediaPrices.chatImagePrice);
  const [videoCredits, setVideoCredits] = useState(performerMediaPrices.chatVideoPrice);
  const [audioCredits, setAudioCredits] = useState(performerMediaPrices.chatAudioPrice);

  const [isPerformerMediaPrices, setIsPerformerMediaPrices] = useState(performerMediaPrices);
  const [isInputDisabled, setIsInputDisabled] = useState(false);


  // const [toggleSwitch, setToggleSwitch] = useState(true);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [creditToUSD, setCreditToUSD] = useState('');
  const [isCheckedDefault, setisCheckedDefault] = useState(false);

  const toggle = () => {
    closeShareMediaPopup();
    setModal(!modal);
  };

  useEffect(() => {
    setModal(isOpen);
  }, []);

  useEffect(() => {
    // if (!isCheckedDefault) {
    //   localStorage.setItem('photoDefaultPrice', JSON.stringify(performerMediaPrices.chatImagePrice));
    //   localStorage.setItem('audioDefaultPrice', JSON.stringify(performerMediaPrices.chatAudioPrice));
    //   localStorage.setItem('videoDefaultPrice', JSON.stringify(performerMediaPrices.chatVideoPrice));
    // }
    
    const photoDefaultPriceLocal = JSON.parse(localStorage.getItem('photoDefaultPrice'));
    const audioDefaultPriceLocal = JSON.parse(localStorage.getItem('audioDefaultPrice'));
    const videoDefaultPriceLocal = JSON.parse(localStorage.getItem('videoDefaultPrice'));

    if (photoDefaultPriceLocal) {
      setImageCredits(Number(photoDefaultPriceLocal));
    }
    if (audioDefaultPriceLocal) {
      setAudioCredits(Number(audioDefaultPriceLocal));
    }
    if (videoDefaultPriceLocal) {
      setVideoCredits(Number(videoDefaultPriceLocal));
    }
  }, [isCheckedDefault]);

  const handleInputDisabled = () => {
    setIsInputDisabled(false);
    if (isCheckedDefault) {
      setisCheckedDefault(!isCheckedDefault);
    }
  }

  const handleChange = (e) => {
    let isChecked = e.target.checked;
    setisCheckedDefault(isChecked);
    setIsInputDisabled(!isInputDisabled);
    if (isChecked) {
      if (getMediaType(selectedMedia) === 'image') {
        localStorage.setItem('photoDefaultPrice', JSON.stringify(imageCredits));
      } else if (getMediaType(selectedMedia) === 'audio') {
        localStorage.setItem('audioDefaultPrice', JSON.stringify(audioCredits));
      } else if (getMediaType(selectedMedia) === 'video') {
        localStorage.setItem('videoDefaultPrice', JSON.stringify(videoCredits));
      }
    }
  }

  const onChangeImageHandler = (e) => {
    if (isNaN(e.target.value)) {
      setError(true);
      setErrorMsg("Please enter numbers only");
      return false;
    }
    if (e.target.value.charAt(0) === '0') {
      setError(true);
      setErrorMsg("First digit can't be 0");
      return false;
    }
    setImageCredits(e.target.value);
    let maxNum = 4;
    if (e.target.value.length > maxNum) {
      setImageCredits(e.target.value.slice(0, maxNum));
      setError(true);
      setErrorMsg("Credit Must be less then 9999!");
      setTimeout(() => {
        setError(false);
      }, 2000);
    } else {
      setError(false);
    }
  }

  const onChangeVideoHandler = (e) => {
    if (isNaN(e.target.value)) {
      setError(true);
      setErrorMsg("Please enter numbers only");
      return false;
    }
    if (e.target.value.charAt(0) === '0') {
      setError(true);
      setErrorMsg("First digit can't be 0");
      return false;
    }
    setVideoCredits(e.target.value);
    let maxNum = 4;
    if (e.target.value.length > maxNum) {
      setVideoCredits(e.target.value.slice(0, maxNum));
      setError(true);
      setErrorMsg("Credit Must be less then 9999!");
      setTimeout(() => {
        setError(false);
      }, 2000);
    } else {
      setError(false);
    }
  }

  const onChangeAudioHandler = (e) => {
    if (isNaN(e.target.value)) {
      setError(true);
      setErrorMsg("Please enter numbers only");
      return false;
    }
    if (e.target.value.charAt(0) === '0') {
      setError(true);
      setErrorMsg("First digit can't be 0");
      return false;
    }
    setAudioCredits(e.target.value);
    let maxNum = 4;
    if (e.target.value.length > maxNum) {
      setAudioCredits(e.target.value.slice(0, maxNum));
      setError(true);
      setErrorMsg("Credit Must be less then 9999!");
      setTimeout(() => {
        setError(false);
      }, 2000);
    } else {
      setError(false);
    }
  }

  useEffect(() => {
    if (getMediaType(selectedMedia) === 'image') {
      setCreditToUSD((imageCredits * 0.05).toFixed(2));
    } else if (getMediaType(selectedMedia) === 'audio') {
      setCreditToUSD((audioCredits * 0.05).toFixed(2));
    } else if (getMediaType(selectedMedia) === 'video') {
      setCreditToUSD((videoCredits * 0.05).toFixed(2));
    }
  }, [imageCredits, audioCredits, videoCredits]);

  const blockInvalidChar = e => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();

  // Media Type checking
  const getMediaType = (selectedMediaObj) => {
    let mediaType = '';
    if (selectedMediaObj.name === "VOICE") {
      return mediaType = 'audio';
    } else {
      const fileName = selectedMediaObj.media.name;
     
      const extension = fileName.split('.').pop();
      const extensionUpper = extension.toUpperCase();
      const allowVideoTypes = ['WEBM', 'MP4', 'M4P', 'M4V', 'AVI', 'WMV', 'MOV', '3GP','QTFF', 'QT', 'QUICKTIME'];
      const allowImageTypes = ['TIFF', 'JPG', 'JPEG', 'PNG', 'BMP', 'JFIF'];
      // const allowedExtensions = /(\.tiff|\.jpg|\.jpeg|\.png|\.bmp|\.jfif|\.mp4|\.avi|\.mov|\.3gp|\.wmv|\.webm)$/i;
  
      if (allowVideoTypes.includes(extensionUpper)) {
        return mediaType = 'video';
      }
      else if (allowImageTypes.includes(extensionUpper)) {
        return mediaType = 'image';
      }
      return mediaType;
    }
 
  }
  const selectedFileUrl = URL.createObjectURL(selectedMedia.media);
  const shareMedia = () => {
    if (getMediaType(selectedMedia) === 'image') {
      if (!imageCredits) {
        setError(true);
        setErrorMsg("Field can't be empty!");
        return;
      }
      if (imageCredits < Number(minImagePurchaseCredits)) {
        setError(true);
        setErrorMsg(`Credit Must be ${minImagePurchaseCredits} or Greater!`);
        return;
      }

      if (imageCredits > 9999) {
        setError(true);
        setErrorMsg("Credit Must be less then 9999!");
        return;
      }
    }

    if (getMediaType(selectedMedia) === 'video') {
      if (!videoCredits) {
        setError(true);
        setErrorMsg("Field can't be empty!");
        return;
      }
      if (videoCredits < Number(minVideoPurchaseCredits)) {
        setError(true);
        setErrorMsg(`Credit Must be ${minVideoPurchaseCredits} or Greater!`);
        return;
      }

      if (videoCredits > 9999) {
        setError(true);
        setErrorMsg("Credit Must be less then 9999!");
        return;
      }
    }
    if (getMediaType(selectedMedia) === 'audio') {
      if (!audioCredits) {
        setError(true);
        setErrorMsg("Field can't be empty!");
        return;
      }
      if (audioCredits < Number(minAudioPurchaseCredits)) {
        setError(true);
        setErrorMsg(`Credit Must be ${minAudioPurchaseCredits} or Greater!`);
        return;
      }
      if (audioCredits > 9999) {
        setError(true);
        setErrorMsg("Credit Must be less then 9999!");
        return;
      }
    }

    let creditsContainer = 0;
    if (getMediaType(selectedMedia) === 'image') {
      creditsContainer = imageCredits;
    } else if (getMediaType(selectedMedia) === 'audio') {
      creditsContainer = audioCredits;
    } else if (getMediaType(selectedMedia) === 'video') {
      creditsContainer = videoCredits;
    }

    const mediaObj = {
      text: '',
      media: selectedMedia.media,
      mediaType: selectedMedia.mediaType,
      creditValue: creditsContainer,
      defaultCreditsBool: isCheckedDefault,
      duration: videoDuration,
    }
    sendMedia(mediaObj);
    closeShareMediaPopup();
  }

  const renderMediaType = useCallback(() => { 
    switch (getMediaType(selectedMedia)) {
      case 'video':
        return (<video src={selectedFileUrl} width="100%" controls></video>);
      case 'audio':
        return (
        <>
          <div className='ar-audio-pop-head'>
            <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_4091_6761)"><path d="M37.4176 23.2603C37.4176 22.383 36.7065 21.6719 35.8292 21.6719C34.952 21.6719 34.2408 22.383 34.2408 23.2603C34.2399 29.4684 29.2065 34.5002 22.9984 34.4993C16.7916 34.4984 11.7603 29.467 11.7594 23.2603C11.7594 22.383 11.0482 21.6719 10.1709 21.6719C9.29369 21.6719 8.58252 22.383 8.58252 23.2603C8.5909 30.6046 14.1129 36.7715 21.4117 37.5878V41.8236H15.6352C14.7579 41.8236 14.0468 42.5348 14.0468 43.4121C14.0468 44.2893 14.7579 45.0005 15.6352 45.0005H30.3651C31.2423 45.0005 31.9535 44.2893 31.9535 43.4121C31.9535 42.5348 31.2423 41.8236 30.3651 41.8236H24.5886V37.5878C31.8872 36.7715 37.4093 30.6046 37.4176 23.2603Z" fill="#FD7663"/><path d="M22.9997 0C18.1076 0 14.1416 3.96588 14.1416 8.85814V23.2016C14.1474 28.0914 18.11 32.0539 22.9997 32.0597C27.8895 32.054 31.852 28.0914 31.8579 23.2016V8.85814C31.8579 3.96588 27.892 0 22.9997 0Z" fill="#FD7663"/></g><defs><clipPath id="clip0_4091_6761"><rect width="45" height="45" fill="white" transform="translate(0.5)"/></clipPath></defs></svg>
            <span className='media-share-pop-title'>Audio</span>
          </div>
          <audio className='audioPlayer' preload="auto" controls controlsList="nodownload noplaybackrate">
            <source src={selectedFileUrl} />
          </audio>
        </>
        );
      case 'image':
        return (<img src={selectedFileUrl} className="mb-3"/>)
    }
  }, [selectedMedia])

  const renderContent = () => {
    switch (getMediaType(selectedMedia)) {
      case "video":
     return (<input 
      id="creditsLabel"
      name="creditInputVideo"
      type="text" 
      className="form-control credit-field" 
      value={videoCredits}
      onChange={onChangeVideoHandler}
      onKeyDown={blockInvalidChar}
      placeholder="Enter Amount (Credits)"
      disabled={isInputDisabled}
      // disabled={!toggleSwitch}
    />)
      case "audio":
        return (<input 
          id="creditsLabel"
          name="creditInputAudio"
          type="text" 
          className="form-control credit-field" 
          value={audioCredits}
          onChange={onChangeAudioHandler}
          onKeyDown={blockInvalidChar}
          placeholder="Enter Amount (Credits)"
          disabled={isInputDisabled}
          // disabled={!toggleSwitch}
        />)
        case "image":
          return (
            <input 
              id="creditsLabel"
              name="creditInputPhoto"
              type="text"
              className="form-control credit-field" 
              value={imageCredits}
              // onChange={(e) => setImageCredits(e.target.value)}
              onChange={onChangeImageHandler}
              onKeyDown={blockInvalidChar}
              placeholder="Enter Amount (Credits)"
              disabled={isInputDisabled}
              // disabled={!toggleSwitch}
            />
          )  
    }
  }


  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="sharePrivateMedia-modal" data="public modal">
        <ModalHeader className="chat-modal-header camHeader" toggle={toggle}>Share</ModalHeader>
        <ModalBody className="chat-modal-body paid-pic-modal">
          <div className='image-wrap'>
            {/* {(getMediaType(selectedMedia)) === 'image' ?
              <img src={selectedFileUrl}/> :
              <video src={selectedFileUrl} width="100%" controls></video>  
            } */}
            {renderMediaType()}
          </div>
          {/* <Row className="mt-2 mb-2">
            <Col xs={6} className=""> Is it paid? </Col>
            <Col xs={6} className="text-right ">
              <div className="custom-switch custom-control">
                <input id="creditSwitch" type="checkbox" className="custom-control-input" checked={toggleSwitch} onChange={(e) => setToggleSwitch(e.target.checked)} />
                <label className="custom-control-label" htmlFor="creditSwitch"></label>
              </div>
            </Col>
          </Row> */}
          <Row>
            <Col xs={12} className="mt-1">
              <label className="enter-credit-label">Enter Credits</label>
              <div className="input-edit-icon-wrap">
                {renderContent()}
                <img src= {inputEditIcon} alt="" onClick={handleInputDisabled} />
              </div>
              { error && <span style={{color: 'red', marginTop: '5px', display: 'block', fontSize: '14px'}}>{errorMsg}</span> }
              <div className="d-flex justify-content-between mt-2">
                <div className="checkbox-container">
                  <input type="checkbox"
                    id="default-credits"
                    name="defaultCredits"
                    value="" 
                    checked={isCheckedDefault} 
                    onChange={e => handleChange(e)}
                  />
                  <label htmlFor="default-credits">Save as default rate for {getMediaType(selectedMedia) === 'image' ? 'photo' : getMediaType(selectedMedia)} </label>
                </div>
                <div className="cost-to-customer">
                  <span>Cost to customer</span>
                  <span className="credit-usd">{creditToUSD} USD</span>
                </div>
              </div>
            </Col>
          </Row>
          <ButtonGroup>
            <Button className="ml-2 modal-btn" onClick={shareMedia}>Share</Button>
          </ButtonGroup>
        </ModalBody>
        <ModalFooter className="chat-modal-footer camFooter" />
      </Modal>
    </div>
  );
}

shareMediaModal.defaultProps = {
  isOpen: false,
};

shareMediaModal.propTypes = {
  isOpen: PropTypes.bool,
};

export default shareMediaModal;
