/* eslint-disable react/prop-types */
import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';
import moment from 'moment';

import Pagination from '../../components/Pagination';
import NoDataAvailable from '../../components/NoDataAvailable';
import LoadingSpinner from '../../utils/LoadingSpinner';
// import review from '../../assets/svgs/starBig3';
import { renderRating, showOnlineStatus } from '../../utils/helpers';
import './style.scss';
import crownIcon from '../../assets/svgs/icon-crown.svg';

class Reviews extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getMyReviews = this.getMyReviews.bind(this);
    const { reviews: { count } } = this.props;
    this.state = {
      totalCount: count,
    };
    this.pageSize = 10;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.totalCount !== nextProps.reviews.count) {
      return { totalCount: nextProps.reviews.count };
    }

    return null;
  }

  componentDidMount() {
    this.getMyReviews();
    window.scrollTo(0, 0);
  }

  getMyReviews(pageNumber) {
    const page = pageNumber || 1;
    const { getReviews, characterId } = this.props;
    const url = `/cstreview/list/?objid2perf_character=${characterId}&ispublished=1&isactive=1&page_size=${this.pageSize}&page=${page}`;
    getReviews(null, url);
  }

  screenNameFormatter = cell => (
    <span className="screen-name">
      {
        cell.custProfilePic
          ? <img src={cell.custProfilePic} alt={cell.name} height="30" width="30" className="mr-3 cust-profile-pic" />
          : <svg className="avatar-image" width="65" height="65" data-jdenticon-value={cell.name} />
      }
      {cell.name}
      {cell.isPremiumCustomer ? <img src={crownIcon} className="is_premium_customer" alt="crown" /> : ''}
      {showOnlineStatus(cell.isCustomerOnline, cell.sendEmailNotification)}
    </span>
  );

  ratingFormatter = cell => <span className="rating">{renderRating(cell)}</span>;

  dateFormatter = cell => moment(new Date(cell)).format('YYYY-MM-DD hh:mm:ss');

  render() {
    const { reviews: { data, isLoading } } = this.props;
    const { totalCount } = this.state;

    const columns = [{
      dataField: 'date',
      formatter: this.dateFormatter,
      text: 'Date',
      sort: true,
    }, {
      dataField: 'screenName',
      formatter: this.screenNameFormatter,
      text: 'Screen Name',
      sort: true,
    }, {
      dataField: 'rating',
      formatter: this.ratingFormatter,
      text: 'Rating',
      sort: true,
    }, {
      dataField: 'comment',
      text: 'Comment',
      classes: 'commentBody',
    }];

    const renderReviewsTable = () => {
      if (data.length <= 0) {
        return <NoDataAvailable />;
      }
      return (
        <BootstrapTable
          striped
          bootstrap4
          bordered={false}
          keyField="id"
          data={data}
          columns={columns}
        />
      );
    };

    return (
      <>
        <h2 className="mt-3" style={{ lineHeight: 1 }}>
          <span className="headTitle">Reviews</span>
        </h2>
        <Card className="reviews bg-dark">
          <CardHeader className="customCardHeader d-none">
            {/* <div className="headerBorderBottom" /> */}
          </CardHeader>
          <CardBody className="pt-3 tablefirstColSpacing">
            {isLoading ? <LoadingSpinner /> : (
              <div className="controller2">{renderReviewsTable()}</div>
            )}
            {totalCount > 0 && (
            <div className="pull-right">
              <Pagination
                totalCount={totalCount}
                pageSize={this.pageSize}
                onPageClick={this.getMyReviews}
              />
            </div>
            )}
          </CardBody>
        </Card>
      </>
    );
  }
}

Reviews.propTypes = {
  getReviews: PropTypes.func.isRequired,
  characterId: PropTypes.number.isRequired,
  reviews: PropTypes.shape({
    isLoading: PropTypes.bool,
    count: PropTypes.number,
    next: PropTypes.string,
  }).isRequired,
};

export default Reviews;
