import React from 'react';

export default () => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.057251 24.42C0.057251 10.9332 10.9908 0 24.4775 0C37.9643 0 48.8978 10.9332 48.8978 24.42C48.8978 37.9068 37.9643 48.84 24.4775 48.84C10.9908 48.84 0.057251 37.9068 0.057251 24.42Z" fill="#FFE6E2" />
    <path d="M35.9167 24.0808C35.9167 30.0524 30.8963 34.9516 24.6302 34.9516C18.3642 34.9516 13.3438 30.0524 13.3438 24.0808C13.3438 18.1092 18.3642 13.21 24.6302 13.21C30.8963 13.21 35.9167 18.1092 35.9167 24.0808Z" stroke="#FD7663" strokeWidth="2" />
    <path d="M24.6302 25.3021C27.0536 25.3021 29.0182 23.4799 29.0182 21.2321C29.0182 18.9843 27.0536 17.1621 24.6302 17.1621C22.2068 17.1621 20.2422 18.9843 20.2422 21.2321C20.2422 23.4799 22.2068 25.3021 24.6302 25.3021Z" fill="#FD7663" />
    <path d="M24.4813 27.0929C20.5035 27.0929 17.1948 28.716 14.9766 31.4071C17.1948 34.0982 20.5035 35.9565 24.4813 35.9565C28.4591 35.9565 31.9907 34.2158 34.2089 31.5247C31.9907 28.8336 28.4591 27.0929 24.4813 27.0929Z" fill="#FD7663" />
  </svg>
);
