import React from 'react';

export default fill => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
    <circle cx="12.5" cy="12.5" r="11.5" stroke={fill} strokeWidth="2" />
    <path d="M16 9.66667C16 11.5329 14.4539 13.0833 12.5 13.0833C10.5461 13.0833 9 11.5329 9 9.66667C9 7.80046 10.5461 6.25 12.5 6.25C14.4539 6.25 16 7.80047 16 9.66667Z" stroke={fill} strokeWidth="2.5" />
    <path d="M12.5009 24.0833C9.37815 24.0833 6.57497 22.7642 4.63244 20.6667C6.57497 18.5691 9.37815 17.25 12.5009 17.25C15.6235 17.25 18.4267 18.5692 20.3693 20.6667C18.4267 22.7642 15.6235 24.0833 12.5009 24.0833Z" stroke={fill} strokeWidth="2.5" />
  </svg>

);
