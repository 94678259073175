/* eslint-disable */
import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Form,
  Button,
} from 'reactstrap';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
// import LoadingSpinner from '../../utils/LoadingSpinner';
import setting from '../../assets/svgs/setting';
import { renderField, renderAttachFileInput, renderSelectField } from '../../utils/formUtils';
import {
  required,
  maxFileSize,
  spaceTrim,
  maxChar250,
  isValueSelected,
} from '../../utils/formUtils/validator';
import DragAndDrop from '../../components/DragAndDrop';

import './style.scss';

class Support extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      // ipAddress: '',
    };
  }

  componentDidMount() {
    // const { getUserIp } = this.props;
    // getUserIp();
    window.scrollTo(0, 0);

  }

  handleFormSubmit = (formData) => {
    const { fullName, screenName, emailAddress, createSupportEmail, showAlert, history, phoneNumber} = this.props;
    console.log(formData.imageToUpload);
    const formdata = new FormData();
    formdata.append('full_name', fullName);
    formdata.append('screen_name', screenName);
    formdata.append('email_address', emailAddress);
    formdata.append('file', formData.imageToUpload);
    formdata.append('account_number', 0);
    formdata.append('problem', formData.support);
    formdata.append('explanation', formData.description);
    formdata.append('phone_number', phoneNumber);
    formdata.append('contact_method', 'email');
    formdata.append('user_agent', navigator.userAgent);
    createSupportEmail(formdata)
      .then(() => {
        history.push('/dashboard');
        showAlert(true, 'Thank you submitting your message');
        setTimeout(() => {
          showAlert(false);
        }, 5000);
      });
  }

  handleFileChange = (event) => {
    const { files = [] } = event.target;
    if (files.length > 0) {
      this.setState({
        fileName: files[0].name,
      });
    }
  }

  handleOnDrop = (file) => {
    const { change } = this.props;
    if (file.length) {
      change('imageToUpload', file[0]);
      this.setState({ fileName: file[0] });
    }
  };

  render() {
    const supportOption = [
      {
        name: 'Select an option',
        value: '',
      },
      {
        name: 'Report a bug',
        value: 'facing an issue',
      },
      {
        name: 'Billing And Finance',
        value: 'billing and finance',
      },
      {
        name: 'General Question',
        value: 'general question',
      },
    ];
    const { handleSubmit } = this.props;
    const { fileName } = this.state;
    return (
      <Card className="support bg-none mt-3">
        <CardHeader className="customCardHeader p-0">
          <Row>
            <Col>
            <h2 className="pl-0">
              <span className="headTitle ">Support</span>
            </h2>

            <p className="faq_text">Before sending a message to Support please check out F.A.Q as your
              question may already be answered instead of waiting for response:
              <a href='https://arousr.com/content/chathost-f-a-q/' target='_blank'> FAQs</a>
            </p>

            <p className="faq_text">
            Please maintain polite/courteous communication when addressing our Staff. Message spamming/harassment/discrimination of any kind to our team will not be tolerated. We reserve the right to terminate those who cannot abide by this policy.
            <br/><b>Note: All communication with our Support Team is private and cannot be shared outside of Support communication. If you share exchanges with our Support Team externally or with Members/Chathosts this is also grounds for termination.</b>
            </p>

            {/* <p className="faq_text">Before sending a message to Support please check out F.A.Q as your
              question may already be answered instead of waiting for response:
              <a href='https://arousr.com/content/chathost-f-a-q/' target='_blank'> FAQs</a>
            </p> */}
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-0">
          <Form onSubmit={handleSubmit(this.handleFormSubmit)} className="supportForm">
            <Row>
              <Col md={6} className="removeSpacing">
                <Field
                  type="select"
                  name="support"
                  component={renderSelectField}
                  options={supportOption}
                  validate={[isValueSelected, required]}
                  defaultSelected="Issue"
                />
              </Col>
            </Row>
            <Row className="row_detail rowspacing-20">
            <Col md={6} className="setting_description">
                <Row className="support-description h-100">
                  <div className="issue-textarea h-100">
                    <Field
                      name="description"
                      validate={[required, spaceTrim, maxChar250]}
                      component={renderField}
                      type="textarea"
                      placeholder="Describe the issues you've encountered here. if you're giving suggestions, we'd appreciate more details!"
                    />
                  </div>
                </Row>
              </Col>
              <Col xs={6} className="setting_description">
                <Row className="h-100">
                  <Col xs={12} className="h-100">
                    <div className="dropArea h-100 mt-0">
                      <Field
                        name="imageToUpload"
                        component={DragAndDrop}
                        type="file"
                        handleOnDrop={this.handleOnDrop}
                        validate={[maxFileSize]}
                        // validationError="Please select file"
                      />
                    </div>
                    {/* <Field
                      type="file"
                      name="file"
                      validate={[required, maxFileSize]}
                      label={fileName}
                      onChange={event => this.handleFileChange(event)}
                      component={renderAttachFileInput}
                    /> */}
                  </Col>
                </Row>
              </Col>

              
            </Row>

            {/* <div className="borderBottom" /> */}

            {/* <Row className="row_detail">
              <Col xs={7}>
                <Row className="setting_details">
                  <Col xs={12} className="fs-20">Attach file</Col>
                  <Col className="help_text">JPEG, GIF or PNG, 10MB max.</Col>
                </Row>
              </Col>

              <Col xs={5} className="setting_description">
                <Row>
                  <Col xs={12}>
                    <Field
                      type="file"
                      name="file"
                      validate={[required, maxFileSize]}
                      label={fileName}
                      onChange={event => this.handleFileChange(event)}
                      component={renderAttachFileInput}
                    />
                  </Col>
                </Row>
              </Col>
            </Row> */}

            {/* <div className="borderBottom" /> */}

            <Row className="mt-5">
              <Col className="button-panel" xs={6}>
              <Button type="submit" className="sendBtn" color="primary" > Send </Button>
              </Col>
            </Row>

          </Form>
        </CardBody>
      </Card>
    );
  }
}

Support.defaultProps = {
  fullName: '',
  screenName: '',
  // ipAddress: '',
  emailAddress: '',
};

Support.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  createSupportEmail: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  getUserIp: PropTypes.func.isRequired,
  fullName: PropTypes.string,
  screenName: PropTypes.string,
  // ipAddress: PropTypes.string,
  emailAddress: PropTypes.string,
  change: PropTypes.func.isRequired,
  // reviews: PropTypes.shape({
  //   isLoading: PropTypes.bool,
  //   count: PropTypes.number,
  //   next: PropTypes.string,
  // }).isRequired,
};

export default Support;
