import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import Settings from '../scenes/Settings';
import getPerformer from '../actions/actionGetPerformer';
import setSmsNotifOff, { setSmsNotifOn } from '../actions/actionSettings';


export const mapStateToProps = (state) => {
  const performerData = (state?.performer?.data) || {};
  const loginData = (state?.login?.data) || {};
  const {
    perfperformer: { objid: perfObjId },
  } = loginData;
  return ({
    performerData,
    perfObjId,
  });
};

export const mapDispatchToProps = dispatch => ({
  getPerformer: objectId => dispatch(getPerformer(objectId)),
  setSmsNotifOff: data => dispatch(setSmsNotifOff(data)),
  setSmsNotifOn: data => dispatch(setSmsNotifOn(data)),
});

const SettingsForm = reduxForm({
  form: 'settingsForm',
})(Settings);

const SettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsForm);

export default SettingsContainer;
