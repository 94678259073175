export default function (state = [], action = {}) {
  const getPresenceStatus = presData => (
    presData.map(presence => (presence.objid2cfg_group?.group_name))
  );

  const statusAdapter = (presResults = []) => {
    const chatStatus = (presResults.length && presResults.includes('PRESENCE_SMS')) || false;
    const phoneStatus = (presResults.length && presResults.includes('PRESENCE_VOICE')) || false;
    const videoStatus = (presResults.length && presResults.includes('PRESENCE_VIDEO')) || false;
    return {
      chatStatus,
      phoneStatus,
      videoStatus,
    };
  };
  switch (action.type) {
    case 'GET_PRESENCE_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case 'GET_PRESENCE_FULFILLED':
      return {
        ...state,
        isLoading: false,
        data: statusAdapter(getPresenceStatus(action.payload.data.results)),
        error: false,
      };

    case 'GET_PRESENCE_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case 'UPDATE_PRESENCE_DATA':
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: false,
      };

    default:
      return {
        ...state,
      };
  }
}
