export default function (state = [], action = {}) {
  switch (action.type) {
    case 'MANAGE_ALERT_DISPLAY':
      return {
        ...state,
        visible: action.payload.show,
        message: action.payload.message,
      };

    default:
      return {
        ...state,
      };
  }
}
