import { connect } from 'react-redux';
import VideoCall from '../scenes/VideoCall';
import endVideoSession from '../actions/actionEndVideoSession';
import startVideoSession from '../actions/actionStartVideoSession';
import getEndCallData from '../actions/actionGetEndCallData';
import handleRoulette from '../actions/actionHandleRoulette';
import getLastCallData from '../actions/actionGettingLastCallData';
import { changeVideoCallData, removeTokenRedux } from '../actions/videoCallsAction';
import createCustomerReview from '../actions/actionCustomerReview';

// const endVideoCall = (dispatch, data) => {
//   localStorage.removeItem('videoCallCustomerName');
//   return dispatch(endVideoSession(data));
// };

export const mapStateToProps = (state = {}) => {
  const {
    startVideo: { data, isLoading } = {},
    // eslint-disable-next-line camelcase
    endVideo: { data: { status, duration_seconds, rate } = {} } = {},
    roulette: { data: { isRouletteEnable = false, isHeartBeatApiOn, currentTimeConst } = {} } = {},
    videoCallData: { data: reduxVideoCallData = {} } = {},
  } = state;
  return {
    videoSession: data,
    endSession: {
      status,
      duration_seconds,
      rate,
    },
    videoSessionLoading: isLoading,
    isRouletteEnable,
    isHeartBeatApiOn,
    currentTimeConst,
    reduxVideoCallData,
  };
};

export const mapDispatchToProps = dispatch => ({
  // endVideoSessionAction: data => endVideoCall(dispatch, data),
  endVideoSessionAction: data => dispatch(endVideoSession(data)),
  startVideoSessionAction: data => dispatch(startVideoSession(data)),
  getEndCallData: data => dispatch(getEndCallData(data)),
  removeToken: data => dispatch(removeTokenRedux(data)),
  handleRoulette: data => dispatch(handleRoulette(data)),
  changeVideoCallData: data => dispatch(changeVideoCallData(data)),
  getLastCallData: id => dispatch(getLastCallData(id)),
  createCustomerReviewFun: data => dispatch(createCustomerReview(data)),
});

const VideoCallContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoCall);

export default VideoCallContainer;
