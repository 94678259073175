import { connect } from 'react-redux';
import getReviews from '../actions/actionGetReviews';

import Reviews from '../scenes/Reviews';

export const getMyReviews = (reviews) => {
  const myReviews = [];
  if (reviews && reviews.length > 0) {
    reviews.forEach((review) => {
      myReviews.push({
        id: review.objid,
        date: review.create_datetime,
        rating: review.rating || 0,
        comment: review.comment || '',
        screenName: {
          name: review.customer_screen_name,
          custProfilePic: review.customer_profile_pic,
          isPremiumCustomer: review.is_premium_customer,
          isCustomerOnline: review.is_customer_online,
          sendEmailNotification: review.send_email_notification,
        },
      });
    });
  }
  return myReviews;
};

export const mapStateToProps = (state) => {
  let characterId = -1;
  if (state.login && state.login.data && state.login.data.perfcharacter[0]) {
    characterId = state.login.data.perfcharacter[0].objid;
  }

  return ({
    reviews: {
      ...state.reviews,
      data: getMyReviews(state.reviews.data),
    },
    characterId,
  });
};

export const mapDispatchToProps = dispatch => ({
  getReviews: (id, url) => dispatch(getReviews(id, url)),
});

const ReviewsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reviews);

export default ReviewsContainer;
