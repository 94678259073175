export default function (state = [], action = {}) {
  switch (action.type) {
    case 'SET_CONVERSATION':
      return {
        ...state,
        conv: action.payload,
      };
    case 'CLEAR_CONVERSATION':
      return {
        ...state,
        loading: false,
        conv: [],
      };
    default:
      return {
        ...state,
      };
  }
}
