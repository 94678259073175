import apiInstance from '../actions/api';
// import { getHistory } from './helpers';

export default () => {
  apiInstance.interceptors.request.use((config) => {
    const { authToken } = localStorage;
    let customConfig;
    if (authToken) {
      customConfig = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Token ${authToken}`,
        },
      };
    } else {
      customConfig = { ...config };
    }
    return customConfig;
  }, error => Promise.reject(error));
  // Add a response interceptor
  apiInstance.interceptors.response.use(response => response, (error) => {
    if (error && error.response && error.response.status === 401 && window.location.hash !== '#/login' && (!window.location.hash.includes('#/continue-to-chat'))) {
      localStorage.clear();
      window.location.replace('/');
    }
    return Promise.reject(error);
  });
};
