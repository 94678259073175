import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import Login from '../scenes/Login';
import loginPerf from '../actions/actionLoginPerf';
import getAuthenticationToken from '../actions/actionGetToken';
import createNewChatUser from '../actions/actionCreateNewChatUser';

export const mapStateToProps = (state) => {
  const { login = {} } = state;
  const { form: { loginForm: { values = {} } = {} } = {} } = state;
  const stingifyValue = JSON.stringify(values);
  return ({
    loginData: login,
    stingifyValue,
  });
};

export const mapDispatchToProps = dispatch => ({
  login: data => dispatch(loginPerf(data)),
  getAuthenticationToken: data => dispatch(getAuthenticationToken(data)),
  createChatUser: data => dispatch(createNewChatUser(data)),
});

const LoginForm = reduxForm({
  form: 'loginForm',
})(Login);

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);

export default LoginContainer;
