import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BodyWrapper from '../components/BodyWrapper';
import endVideoSession from '../actions/actionEndVideoSession';
import setConversation from '../actions/actionChat';
import { changeVideoCallData, removeTokenRedux } from '../actions/videoCallsAction';
import getCustomerChatLog from '../actions/actionChatLog';
import { updatePresence } from '../actions/actionGetPresense';

export const mapStateToProps = (state) => {
  const {
    performer: { data: { characters = [{}] } = {} } = {},
    login: { data: { perfcharacter = [] } = {} } = {},
    login: {
      data: {
        // eslint-disable-next-line camelcase
        xmmp_pass: xmmpPass, xmpp_user: xmppUser,
      } = {},
    } = {},
    chat: {
      conv = [],
    } = {},
    mobileLogin: { mobileData: { mobileUser, mobilePass } = {} } = {},
    chatNotification: { data: notiPermission = '' } = {},
    roulette: { data: { isRouletteEnable = false } = {} } = {},
    videoCallData: { data: reduxVideoCallData = {} } = {},
  } = state;
  const stringyfyConv = conv.length > 0 ? JSON.stringify(conv) : '';
  const localData = JSON.parse(localStorage.getItem('mobileChatData'));

  const perfObjid = (perfcharacter.length && perfcharacter[0].objid) || 0;
  if (xmmpPass && xmppUser) {
    return ({
      characters,
      xmpp_user: xmppUser,
      xmpp_pass: xmmpPass,
      allConversation: stringyfyConv,
      notiPermission,
      isRouletteEnable,
      reduxVideoCallData,
      perfObjid,
    });
  }
  return ({
    characters,
    xmpp_user: mobileUser || localData.xmpp_user,
    xmpp_pass: mobilePass || localData.xmmp_pass,
    allConversation: stringyfyConv,
    notiPermission,
    isRouletteEnable,
    reduxVideoCallData,
    perfObjid,
  });
};

export const mapDispatchToProps = dispatch => ({
  endVideoSessionAction: data => dispatch(endVideoSession(data)),
  setConversationFunction: data => dispatch(setConversation(data)),
  changeVideoCallData: data => dispatch(changeVideoCallData(data)),
  removeToken: data => dispatch(removeTokenRedux(data)),
  updatePresence: data => dispatch(updatePresence(data)),
  checkActiveSessionAvailable: url => getCustomerChatLog(url).payload,
});

const BodyWrapperContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BodyWrapper);

export default withRouter(BodyWrapperContainer);
