import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Button,
  CardHeader,
  Row,
  Col,
} from 'reactstrap';
import { toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Lightbox from 'react-image-lightbox';
import PropTypes from 'prop-types';

import VideoModal from './components/CustomVideoModal/VideoModal';
import Pagination from '../../components/Pagination';
import AddMediaFile from './components/AddMediaFile';
import NoDataAvailable from '../../components/NoDataAvailable';
import LoadingSpinner from '../../utils/LoadingSpinner';
import CustomModal from '../../components/CustomModal';
import MediaFilter from './components/MediaFilter';
// import EditMediaModal from './components/EditMediaModal';
import arrowDawn from '../../assets/images/arrow_circle.png';
import arrowUp from '../../assets/images/arrow_circleUp.png';
import copy from '../../assets/svgs/copy.svg';
import 'react-image-lightbox/style.css';
import './style.scss';

const Media = (props) => {
  const {
    loadingData,
    finalResult: results,
    getProductList,
    objectId,
    mediaData,
    formValue,
    // initialize,
    editMedia,
  } = props;
  const { SearchBar } = Search;
  const [isShowNewFile, setIsShowNewFile] = useState(false);
  // const [isEditMediaModal, setisEditMediaModal] = useState(false);
  // const [editModalData, setEditModalData] = useState({});
  const [currentPage, setcurrentPage] = useState(1);
  const [updateResults, setUpdatedResults] = useState([]);
  const [mainSrcPicCon, setmainSrcPicCon] = useState('');
  const [filterSelectedValue, setFilterSelectedValue] = useState('');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [addPrivateMedia, setPrivateMediaAdd] = useState(false);
  const [dynamicModalBody, setDynamicModalBody] = useState(false);
  const [isMediaDelete, setIsMediaDelete] = useState(false);
  const [isOpen, setOpen] = useState({
    openModal: false,
    videoId: '',
  });
  const pageSize = 10;
  const { count } = mediaData;

  const getCharacterMediaData = (pageNumber, mediaType = '') => {
    const page = pageNumber || 1;
    // let type = mediaType || 'image|video|profile|private';
    let type = mediaType || 'public-images';
    if (mediaType === 'images') {
      type = 'images|carl';
    }
    if (pageNumber > 1) {
      if (filterSelectedValue) {
        if (filterSelectedValue === 'images') {
          type = 'images|carl';
        } else {
          type = filterSelectedValue;
        }
      }
    }
    setcurrentPage(page);
    const updatedType = type.split('-');
    type = updatedType;
    let url = '';
    if (type.length === 2) {
      url = `/perfcharacterproduct/list/?character=${objectId}&page=${page}&page_size=10&ordering=-objid&isactive=1&is_published=1&is_rejected=0&type=${type[1]}&mode=${type[0]}`;
    } else {
      url = `/perfcharacterproduct/list/?character=${objectId}&page=${page}&page_size=10&ordering=-objid&isactive=1&is_published=1&is_rejected=0&type=${type[0]}`;
    }
    getProductList(url);
  };

  useEffect(() => {
    getCharacterMediaData();
    window.scrollTo(0, 0);
  }, []);

  // const editMediaModal = (data) => {
  //   setisEditMediaModal(true);
  //   const { description, product_name: edittitle, price } = data;
  //   setEditModalData(data);
  //   const initialFormValues = {
  //     editdescription: description,
  //     edittitle,
  //     editprice: `${price}`,
  //   };
  //   initialize(initialFormValues);
  // };

  const copyToClipBoard = (id) => {
    const copyText = document.getElementById(id);
    if (copyText.value) {
      navigator.clipboard.writeText(copyText.value);
      toast.success('Copied to clipboard');
    }
    // copyText.select();
    // document.execCommand('copy');
  };

  // eslint-disable-next-line consistent-return
  const deleteMedia = (rowData) => {
    const {
      character, objid, type, price,
    } = rowData;
    console.log('type', type);
    const data = {
      character,
      objid,
      isactive: 0,
    };
    if (count <= 3 && (type === 'image' || type === 'profile') && price === 0) {
      setIsMediaDelete(true);
      setDynamicModalBody('You can not delete photo. You need to keep at least 3 public photos. Please add new photos before proceeding.');
    } else if (count <= 1 && type === 'video' && price === 0) {
      setIsMediaDelete(true);
      setDynamicModalBody('You can not delete the video. You need to keep at least 1 public video. Please add a new video before proceeding.');
    } else {
      editMedia(data).then(() => {
        getCharacterMediaData(currentPage, filterSelectedValue);
      });
    }
  };

  const fileFieldFormatter = (cell, row) => (
    <div className="fileColumn">
      <span className="profileImg">
        {row.type === 'video' ? (
          <div className="iframe_video_container">
            <iframe
              src={`https://iframe.videodelivery.net/${row.video_id}`}
              title={`video_${row.video_id}`}
              height="65px"
              width="65px"
              // eslint-disable-next-line react/no-unknown-property
              controls="0"
              allowFullScreen
              autoPlay="0"
              frameBorder="0"
              scrolling="no"
            />
            <div
              className="video_overlay"
              onClick={() => setOpen({ openModal: true, videoId: row.video_id })}
              role="presentation"
            />
          </div>
        ) : (
          <img role="presentation" src={cell} alt="profile_pic" height="65px" width="65px" onClick={() => setmainSrcPicCon(cell)} />
        )
        }
      </span>
      <div>
        {/* <p className="mb-0">{row.product_name}</p> */}
        {/* <h4 className="userId">{`ID:${row.product_id}`}</h4> */}
        <label htmlFor="copyInput" style={{ marginLeft: '-3px' }}>
          <input type="text" value={row.product_url} id={`copyInput${row.product_id}`} readOnly style={{ position: 'fixed', left: '-9999px' }} />
          {row.product_url ? (
            <button type="button" onClick={() => copyToClipBoard(`copyInput${row.product_id}`)} className="p-0 bg-none border-0 ml-1">
              <img src={copy} alt="copy" width="11" className="img-fluid" />
              <span className="text-primary copyText"> copy link</span>
            </button>
          ) : ''
          }
        </label>
      </div>
    </div>
  );
  const descriptionFormatter = (cell, row) => (
    <div className="descriptionWrp">
      <p>{cell}</p>
      <span className="d-inline-flex align-items-center mobileColumn">
        {/* <Button outline color="primary" className="mr-2"
          onClick={() => editMediaModal(row)}>edit</Button> */}
        <Button outline color="primary" onClick={() => deleteMedia(row)}>delete</Button>
      </span>
    </div>
  );

  const MediaColoumn = [
    {
      dataField: 'product_thumb_url',
      formatter: fileFieldFormatter,
      text: 'File',
      sort: true,
      searchable: false,
      headerStyle: () => ({ width: '87px' }),
    }, {
      dataField: 'product_name',
      text: 'Title',
      sort: true,
      searchable: true,
      headerStyle: () => ({ width: '20%' }),
    }, {
      dataField: 'type',
      text: 'Media',
      sort: true,
      searchable: true,
      headerStyle: () => ({ width: '12%' }),
    }, {
      dataField: 'price',
      text: 'Price (Credits)',
      sort: true,
      searchable: true,
      // headerClasses: 'priceHeader',
      headerStyle: () => ({ width: '20%' }),
    }, {
      dataField: 'description',
      text: 'Description',
      formatter: descriptionFormatter,
      searchable: true,
      headerStyle: () => ({ width: '39%' }),
    },
  ];

  const handleAddMediaClick = () => {
    setIsShowNewFile(!isShowNewFile);
  };

  useEffect(() => {
    setUpdatedResults(results);
  }, [results]);

  const getCharacterMediaDataWithType = (selectValues) => {
    setFilterSelectedValue(selectValues);
    getCharacterMediaData(1, selectValues);
  };

  // const toggleMediaModal = () => {
  //   setisEditMediaModal(false);
  // };

  const closePhoto = () => {
    setmainSrcPicCon('');
  };


  const renderModalForNotDeletingMedia = () => (
    <CustomModal
      header="Media Can not Deleted"
      body={dynamicModalBody}
      buttonText="OK"
      open={isMediaDelete}
      toggle={() => setIsMediaDelete(false)}
    />
  );

  const renderModalForAddMedia = () => (
    <CustomModal
      header="Media was submitted"
      body="If approved, changes will appear within 72 hours"
      buttonText="OK"
      // footer="CANCEL"
      // footerLink={() => setAfterUpdateProfilePicture(false)}
      open={addModalOpen}
      // onClick={resetBackgroundPicPanel}
      toggle={() => setAddModalOpen(false)}
    />
  );

  const renderModalForAddPrivateMedia = () => (
    <CustomModal
      header="Media uploaded successfully"
      body=""
      buttonText="OK"
      // footer="CANCEL"
      // footerLink={() => setAfterUpdateProfilePicture(false)}
      open={addPrivateMedia}
      // onClick={resetBackgroundPicPanel}
      toggle={() => setPrivateMediaAdd(false)}
    />
  );

  const renderVideoModal = () => (
    <VideoModal
      header=""
      body=""
      buttonText="OK"
      // footer="CANCEL"
      // footerLink={() => setAfterUpdateProfilePicture(false)}
      open={isOpen.openModal}
      // onClick={resetBackgroundPicPanel}
      videoId={isOpen.videoId}
      toggle={() => setOpen({ open: false, videoId: '' })}
    />
  );

  const halndleAddMediaModal = (value) => {
    setAddModalOpen(value);
  };

  const halndleAddPrivateMedia = (value) => {
    setPrivateMediaAdd(value);
  };

  const renderTable = () => {
    if (loadingData) {
      return (
        <div style={{ height: '600px' }}>
          <LoadingSpinner />
        </div>
      );
    }

    if (results && results.length === 0) {
      return (
        <div style={{ height: '400px' }} className="no-data-available">
          <NoDataAvailable />
        </div>
      );
    }

    return (
      <div>
        <ToolkitProvider
          striped
          bootstrap4
          bordered={false}
          keyField="objid"
          data={updateResults}
          columns={MediaColoumn}
          search
        >
          {
            response => (
              <div>
                <div className="fillterRow">
                  <Row>
                    <Col md="8" />
                    <Col md="4" className="text-right">
                      <div className="invisible">a</div>
                      <SearchBar {...response.searchProps} />
                    </Col>
                  </Row>
                </div>
                <div className="myTable bg-dark mt-4 pt-3 card-body mediaTable">
                  <BootstrapTable
                    {...response.baseProps}
                  />
                </div>
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    );
  };

  return (
    <>
      {isOpen.open}
      {isOpen.videoId}
      {
        mainSrcPicCon && (
          <Lightbox
            ClassName="Lightbox"
            mainSrc={mainSrcPicCon}
            onCloseRequest={closePhoto}
          />
        )
      }
      {/* <EditMediaModal
        data={editModalData}
        open={isEditMediaModal}
        toggleMediaModal={toggleMediaModal}
        {...props}
      /> */}
      {renderVideoModal()}
      {renderModalForAddMedia()}
      {renderModalForAddPrivateMedia()}
      {renderModalForNotDeletingMedia()}
      <Card className="bg-none mt-3">
        <CardHeader className="p-0">
          <h2 style={{ lineHeight: 1 }}>
            <span className="headTitle">Media</span>
          </h2>
          <div className="bg-dark p-3 addMediaWraper mb-3">
            <div className="d-flex justify-content-between align-items-center" onClick={handleAddMediaClick} role="presentation">
              <h6 className="text-primary mb-0">ADD MEDIA</h6>
              <Button outline className="p-0 bg-none border-0 arrowBtn" onClick={handleAddMediaClick}>
                <img src={isShowNewFile ? arrowUp : arrowDawn} alt="arrow" />
              </Button>
            </div>
            {isShowNewFile && (
              <div className="mt-3">
                <AddMediaFile
                  {...props}
                  formValues={formValue}
                  handleAddMediaClick={handleAddMediaClick}
                  halndleAddMediaModal={halndleAddMediaModal}
                  halndleAddPrivateMedia={halndleAddPrivateMedia}
                />
              </div>
            )}
          </div>
        </CardHeader>
        <CardBody className="p-0">
          <MediaFilter
            getCharacterMediaDataWithType={getCharacterMediaDataWithType}
            filterSelectedValue={filterSelectedValue}
          />
          {renderTable()}
          {count > 0
            ? (
              <Pagination
                totalCount={count}
                pageSize={pageSize}
                onPageClick={getCharacterMediaData}
                currentPage={currentPage}
              />
            )
            : ''
          }
        </CardBody>
      </Card>
    </>
  );
};

Media.defaultProps = {
  loadingData: false,
  finalResult: [],
  count: 0,
  objectId: null,
  mediaData: {},
  formValue: {},
};

Media.propTypes = {
  loadingData: PropTypes.bool,
  count: PropTypes.number,
  finalResult: PropTypes.instanceOf(Array),
  getProductList: PropTypes.func.isRequired,
  objectId: PropTypes.number,
  mediaData: PropTypes.instanceOf(Object),
  formValue: PropTypes.instanceOf(Object),
  // initialize: PropTypes.func.isRequired,
  editMedia: PropTypes.func.isRequired,
};

export default Media;
