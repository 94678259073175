import React, { useEffect } from 'react';
import './style.scss';

const PlatformRules = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <div className="headingWrap">
        <h3 className="topHead">Platform Rules for Chat Hosts</h3>
      </div>
      <article>
        <p className="subText">
          {' '}
          All chat hosts are required to follow the rules listed below. Please note
          that chat sessions are monitored for potential issues. If you need
          clarifications, do not hesitate to contact support. If you are
          uncomfortable with the rules, Arousr is not the right platform for you.
        </p>
      </article>

      <article>
        <h5 className="subheadingText">1. Do not send members to other platforms</h5>
        <p className="subText">
          Advertising costs time and money, as you are probably aware if you are
          promoting your own brand online. Unlike other platforms who provide only
          billing and technology, Arousr provides you with traffic so you don’t
          have to build a customer base. Traffic comes from affiliates, other
          hosts who share their affiliate link paid advertising as well as Search
          Engine Marketing, Social Media and Branding campaigns. All these efforts
          cost time and money. When a chat host sends a member outside of the
          platform, everyone involved in the Arousr platform loses, including
          other chat hosts who promote the platform as affiliates. This is why
          exchanging external contact and payment information with members is
          forbidden. This includes for example : Snapchat, email, websites,
          OnlyFans, Twitter and so on. Arousr is not meant to generate free
          traffic for other websites or brands.
        </p>
      </article>
      <article>
        <h5 className="subheadingText">2. Do not try to meet members in person</h5>
        <p className="subText">
          Arousr is not a dating or escort site. Therefore any attempt to meet in
          person is forbidden.
        </p>
      </article>

      <article>
        <h5 className="subheadingText">3. Do not request payments outside of the platform</h5>
        <p className="subText">
          The only acceptable way of being paid and tipped is through the Arousr
          Credit System. Any other method is forbidden. This means no Paypal,
          Cashapp and so on.
        </p>
      </article>

      <article>
        <h5 className="subheadingText">4. No conversations involving underage persons</h5>
        <p className="subText">
          Do not accept to have conversations involving underage people (real or
          pretend). If a member requests this type of conversation, politely
          decline and if the member insists, block and report him. You can simply
          send #BLOCK or #REPORT in the chat session. This will prevent the member
          from contacting you again and will notify admins.
        </p>
      </article>

      <article>
        <h5 className="subheadingText">5. You must be the ONLY Person on camera at any time you are broadcasting on Arousr </h5>
        <p className="subText">
          Only you are legally permitted to be working on the platform at any
          time using voice/text/video. This includes children/pets/partners etc.
        </p>
      </article>

      <article>
        <h5 className="subheadingText">6. Screenshots of your sessions via Text/Video chat are strictly forbidden. </h5>
        <p className="subText">
          You are not authorized to share any internal data.
          Keep all correspondence you have on the platform, on the platform
        </p>
      </article>

      <article>
        <h5 className="subheadingText">What happens when a chat host breaches the rules?</h5>
        <p className="subText">
          Unfortunately, we have to close the account to prevent further damage.
        </p>
      </article>
    </main>
  );
};

export default PlatformRules;
