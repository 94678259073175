const MESSAGES = {
  NOTIF_SMS_HEADING: 'New Chat SMS Notification',
  NOTIF_SMS_SUB_HEADING: 'Get notified by SMS for new chat sessions',
  RESET_PASSWORD_HEADING: 'Reset Password',
  RESET_PASSWORD_SUB_HEADING: 'Enter your new password',
  RESET_PASSWORD_SUCCESS_HEADING: 'Password Reset Successful',
  RESET_PASSWORD_SUCCESS_SUB_HEADING: 'You can now login with your new password',
  RESET_PASSWORD_ERROR_HEADING: 'Password Reset Error',
  RESET_PASSWORD_ERROR_SUB_HEADING: 'Something went wrong. Please try again',
};

export default MESSAGES;
