import React from 'react';
import { Row, Col } from 'reactstrap';
import oopsSvg from '../../assets/svgs/oops';
import './style.scss';

const NotFoundPage = () => (
  <div className="not-found-page">
    <Row>
      <Col xs="4" />
      <Col xs="4">
        <div className="heading">
          {oopsSvg('#3b414a')}
          404
        </div>
        <div className="text">
          Sorry, this page does not exist
        </div>
      </Col>
    </Row>
  </div>
);

export default NotFoundPage;
