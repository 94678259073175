import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button, Modal, ModalHeader,
  ModalBody, Input,
  Label,
} from 'reactstrap';
import './style.scss';

import galleryPlacholder from '../../../../assets/svgs/galleryPlacholder';
import lockPlaceholder from '../../../../assets/svgs/lockPlacholder';
import galleryChatMediaPlacholder from '../../../../assets/svgs/galleryChatMediaPlacholder';

const MediaOptionModal = (props) => {
  const {
    isOpen,
    onUploadPicture,
    closeMediaPopup,
    handlePrivateMediaModal,
    handleChatMediaModal,
    getChatMediaPhotosFun,
    getChatMediaVideosFun,
  } = props;

  const [modal, setModal] = useState(false);

  useEffect(() => {
    setModal(isOpen);
  }, []);

  const toggle = () => {
    closeMediaPopup();
    setModal(!modal);
  };

  const chatMediaButtonClick = () => {
    getChatMediaPhotosFun();
    getChatMediaVideosFun();
    handleChatMediaModal();
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="chooseImageModal">
        <ModalHeader toggle={toggle}>Choose</ModalHeader>
        <ModalBody>
          <Label className="publicGallery" htmlFor="publicPicFile">
            <div>
              {galleryPlacholder('#666666')}
              <p className="uploadText">Public</p>
              <Input
                type="file"
                id="publicPicFile"
                className="file-input"
                accept="image/* video/*"
                onChange={onUploadPicture}
              />
            </div>
          </Label>
          <section className="privateGallery mb-3">
            <div>
              <Button type="button" onClick={handlePrivateMediaModal} className="bg-none">
                {lockPlaceholder('#666')}
              </Button>
              <p className="uploadText">Private</p>
            </div>
          </section>
          <section className="privateGallery">
            <div>
              <Button type="button" onClick={chatMediaButtonClick} className="bg-none">
                {galleryChatMediaPlacholder('#666666')}
              </Button>
              <p className="uploadText">Chat Media</p>
            </div>
          </section>

        </ModalBody>
      </Modal>
    </div>
  );
};

MediaOptionModal.defaultProps = {
  isOpen: false,
};

MediaOptionModal.propTypes = {
  isOpen: PropTypes.bool,
  onUploadPicture: PropTypes.func.isRequired,
  closeMediaPopup: PropTypes.func.isRequired,
  handlePrivateMediaModal: PropTypes.func.isRequired,
  handleChatMediaModal: PropTypes.func.isRequired,
  getChatMediaPhotosFun: PropTypes.func.isRequired,
  getChatMediaVideosFun: PropTypes.func.isRequired,
};

export default MediaOptionModal;
